import etherScanImg from 'assets/img/chains/etherscan.png';
import solScanImg from 'assets/img/chains/solscan.png';
import baseScanImg from 'assets/img/chains/basescan.png';
import bscScanImg from 'assets/img/chains/bscscan.png';
import EthIcon from 'assets/img/icons/eth-white.svg';
import BnbIcon from 'assets/img/chains/bnb-grey.svg';
import SolIcon from 'assets/img/chains/solana-grey.svg';
import { parseAmount } from '../../../app/dex/components/chains/units';

export function gasPrioritySuffix(chain: Chains | undefined) {
  return chain
    ? [Chains.Ethereum, Chains.Base, Chains.BinanceChain].includes(chain)
      ? 'gwei'
      : 'SOL'
    : 'gwei';
}

export function chainIsOnWebacy(chain: Chains | undefined) {
  return chain
    ? [Chains.Ethereum, Chains.Base, Chains.Solana].includes(chain)
    : false;
}

export function minimumSafeAmount(chain: Chains | undefined) {
  const safeValuesMap: Record<Chains, number> = {
    [Chains.Base]: ChainsSafeValues.Base,
    [Chains.Ethereum]: ChainsSafeValues.Ethereum,
    [Chains.BinanceChain]: ChainsSafeValues.BinanceChain,
    [Chains.Solana]: ChainsSafeValues.Solana,
  };

  return chain ? safeValuesMap[chain] ?? 0 : 0;
}

export function chainHasBribe(chain: Chains | undefined) {
  return chain ? [Chains.Solana].includes(chain) : false;
}

export function chainHasMemPool(chain: Chains | undefined) {
  return chain ? [Chains.Ethereum, Chains.Solana].includes(chain) : false;
}

export function chainHasTax(chain: Chains | undefined) {
  return chain
    ? [Chains.Ethereum, Chains.Base, Chains.BinanceChain].includes(chain)
    : false;
}

export function chainHasGasLimit(chain: Chains | undefined) {
  return chain
    ? [Chains.Ethereum, Chains.Base, Chains.BinanceChain].includes(chain)
    : false;
}

export function chainMinimumPayout(chain: Chains | undefined) {
  const payoutMap: Record<Chains, number> = {
    [Chains.Ethereum]: ChainsPayouts.EthereumBase,
    [Chains.Base]: ChainsPayouts.EthereumBase,
    [Chains.BinanceChain]: ChainsPayouts.BinanceChain,
    [Chains.Solana]: ChainsPayouts.Solana,
  };

  return chain ? payoutMap[chain] : 0;
}

export function chainMaxWithdraw(chain: Chains | undefined) {
  const maxWithdrawMap: Record<Chains, number> = {
    [Chains.Ethereum]: ChainsMaxWithdraw.EthereumBase,
    [Chains.Base]: ChainsMaxWithdraw.EthereumBase,
    [Chains.BinanceChain]: ChainsMaxWithdraw.BinanceChain,
    [Chains.Solana]: ChainsMaxWithdraw.Solana,
  };

  return chain ? maxWithdrawMap[chain] : 0;
}

export function chainProcessingFee(chain: Chains | undefined) {
  const feeMap: Record<Chains, number> = {
    [Chains.Ethereum]: ChainsProcessingFee.Ethereum,
    [Chains.Base]: ChainsProcessingFee.Base,
    [Chains.BinanceChain]: ChainsProcessingFee.BinanceChain,
    [Chains.Solana]: ChainsProcessingFee.Solana,
  };

  return chain ? feeMap[chain] : 0;
}

export function chainAsset(chain: Chains | undefined) {
  if (chain === Chains.BinanceChain) {
    return ChainAssets.BinanceChain;
  } else if (chain === Chains.Solana) {
    return ChainAssets.Solana;
  } else {
    return chain ? ChainAssets.EthereumBase : '';
  }
}

export function dexToolLink(chain: Chains | undefined) {
  const chainMap: Record<Chains, DexToolBase> = {
    [Chains.Ethereum]: DexToolBase.Ethereum,
    [Chains.BinanceChain]: DexToolBase.BinanceChain,
    [Chains.Base]: DexToolBase.Base,
    [Chains.Solana]: DexToolBase.Solana,
  };

  return chain ? chainMap[chain] : '';
}

export function dexName(protocol: Protocols) {
  const protocolMap: Partial<Record<Protocols, ProtocolsNames>> = {
    [Protocols.UniswapV3]: ProtocolsNames.UniswapV3,
    [Protocols.UniswapV2]: ProtocolsNames.UniswapV2,
    [Protocols.PancakeSwapV2]: ProtocolsNames.PancakeSwapV2,
    [Protocols.PancakeSwapV3]: ProtocolsNames.PancakeSwapV3,
    [Protocols.SushiSwapV2]: ProtocolsNames.SushiSwapV2,
    [Protocols.RaydiumAmm]: ProtocolsNames.RaydiumAmm,
    [Protocols.RaydiumCllm]: ProtocolsNames.RaydiumClmm,
    [Protocols.RaydiumCp]: ProtocolsNames.RaydiumCp,
    [Protocols.MeteoraDlmm]: ProtocolsNames.MeteoraDlmm,
    [Protocols.MeteoraDyn]: ProtocolsNames.MeteoraDyn,
    [Protocols.Moonshot]: ProtocolsNames.Moonshot,
    [Protocols.PumpFun]: ProtocolsNames.PumpFun,
    [Protocols.PumpSwap]: ProtocolsNames.PumpSwap,
  };

  return protocolMap[protocol] || '';
}

export function chainName(chain: Chains) {
  const chainMap: Partial<Record<Chains, ChainNames>> = {
    [Chains.Ethereum]: ChainNames.Ethereum,
    [Chains.BinanceChain]: ChainNames.BinanceChain,
    [Chains.Base]: ChainNames.Base,
    [Chains.Solana]: ChainNames.Solana,
  };

  return chainMap[chain] || ChainNames.Base;
}

export function chainScan(chain: Chains) {
  const scanMap: Partial<Record<Chains, ChainScans>> = {
    [Chains.Ethereum]: ChainScans.Ethereum,
    [Chains.BinanceChain]: ChainScans.BinanceChain,
    [Chains.Base]: ChainScans.Base,
    [Chains.Solana]: ChainScans.Solana,
  };

  return scanMap[chain] || ChainScans.Base;
}

export function chainPriorityGasFeeTitle(chain: Chains | undefined) {
  const title: Partial<Record<Chains, string>> = {
    [Chains.Solana]: 'Priority Fee',
  };

  return title[chain!] || 'Gas Priority';
}

export function chainPriorityGasFeeTooltip(chain: Chains | undefined) {
  const title: Partial<Record<Chains, string>> = {
    [Chains.Solana]:
      'This is the exact fee you will pay for your transaction (excluding the bribe and the signing fee).',
  };

  return (
    title[chain!] ||
    'This automatically adjusts your transaction gas to the average gas price and add the chosen gas priority on top of it.'
  );
}

export function chainAssetImg(chain: Chains | undefined) {
  const assetImgMap: Partial<Record<Chains, string>> = {
    [Chains.Ethereum]: EthIcon,
    [Chains.Base]: EthIcon,
    [Chains.BinanceChain]: BnbIcon,
    [Chains.Solana]: SolIcon,
  };

  return assetImgMap[chain!] || EthIcon;
}

export function chainScanImg(chain: Chains | undefined) {
  const scanImgMap: Partial<Record<Chains, string>> = {
    [Chains.Ethereum]: etherScanImg,
    [Chains.Base]: baseScanImg,
    [Chains.Solana]: solScanImg,
    [Chains.BinanceChain]: bscScanImg,
  };

  return scanImgMap[chain!] || baseScanImg;
}

export function chainMaxBuyGasPriority(chain: Chains | undefined) {
  const maxBuyGasMap: Partial<Record<Chains, number>> = {
    [Chains.Base]: 0.05,
    [Chains.Solana]: 0.0002,
    [Chains.Ethereum]: 1,
    [Chains.BinanceChain]: 0.1,
  };

  return maxBuyGasMap[chain!] ?? 0.1;
}

export function chainBuyBribe(chain: Chains | undefined) {
  const buyBribe: Partial<Record<Chains, number>> = {
    [Chains.Solana]: parseAmount(0.0001, chain, 'etherToWei'),
  };

  return buyBribe[chain!] ?? 0;
}

export function chainSellBribe(chain: Chains | undefined) {
  const sellBribe: Partial<Record<Chains, number>> = {
    [Chains.Solana]: parseAmount(0.0001, chain, 'etherToWei'),
  };

  return sellBribe[chain!] ?? 0;
}

export function chainDefaultBuyAmountInBaseAsset(chain: Chains | undefined) {
  const maxBuyGasMap: Partial<Record<Chains, number>> = {
    [Chains.Base]: parseAmount(0.05, chain, 'etherToWei'),
    [Chains.Solana]: parseAmount(0.1, chain, 'etherToWei'),
    [Chains.Ethereum]: parseAmount(0.05, chain, 'etherToWei'),
    [Chains.BinanceChain]: parseAmount(0.1, chain, 'etherToWei'),
  };

  return maxBuyGasMap[chain!] ?? 0.05;
}

export function defaultContracts(chain: Chains | undefined) {
  if (!chain) {
    return '0xB1a03EdA10342529bBF8EB700a06C60441fEf25d';
  }

  const d: Partial<Record<Chains, string>> = {
    [Chains.Base]: '0xB1a03EdA10342529bBF8EB700a06C60441fEf25d',
    [Chains.BinanceChain]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    [Chains.Solana]: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    [Chains.Ethereum]: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
  };

  return d[chain!] ?? '0xB1a03EdA10342529bBF8EB700a06C60441fEf25d';
}

export function chainDefaultBribeInBaseAsset(chain: Chains | undefined) {
  if (chain === Chains.BinanceChain || chain === Chains.Base || !chain) {
    return 0;
  }

  const maxBuyGasMap: Partial<Record<Chains, number>> = {
    [Chains.Solana]: chainBuyBribe(chain),
    [Chains.Ethereum]: parseAmount(0.025, chain, 'etherToWei'),
  };

  return maxBuyGasMap[chain!] ?? 0.01;
}

export function chainMinOrderAmountLimits(chain: Chains | undefined) {
  if (!chain) {
    return { max: 100, min: 0.025 };
  }

  const maxGasMap: Partial<Record<Chains, { min: number; max: number }>> = {
    [Chains.Solana]: {
      max: 100,
      min: 0.025,
    },
    [Chains.Ethereum]: {
      max: 5,
      min: 0.002,
    },
    [Chains.BinanceChain]: {
      max: 20,
      min: 0.005,
    },
    [Chains.Base]: {
      max: 5,
      min: 0.002,
    },
  };

  return maxGasMap[chain!] ?? { min: 0.00001, max: 100 };
}

export function chainGasFeeLimits(chain: Chains | undefined) {
  if (!chain) {
    return { min: 0.00001, max: 100 };
  }

  const maxGasMap: Partial<Record<Chains, { min: number; max: number }>> = {
    [Chains.Solana]: {
      max: 0.1,
      min: 0.00001,
    },
    [Chains.Ethereum]: {
      max: 100,
      min: 0.01,
    },
    [Chains.BinanceChain]: {
      max: 100,
      min: 0.0001,
    },
    [Chains.Base]: {
      max: 100,
      min: 0.0001,
    },
  };

  return maxGasMap[chain!] ?? { min: 0.00001, max: 100 };
}

export function chainDefaultBackupBribeInBaseAsset(chain: Chains | undefined) {
  if (chain === Chains.BinanceChain || chain === Chains.Base || !chain) {
    return 0;
  }

  const maxBuyGasMap: Partial<Record<Chains, number>> = {
    [Chains.Solana]: parseAmount(0.0002, chain, 'etherToWei'),
    [Chains.Ethereum]: parseAmount(0.01, chain, 'etherToWei'),
  };

  return maxBuyGasMap[chain!] ?? 0.01;
}

export function chainMaxSellGasPriority(chain: Chains | undefined) {
  const maxSellGasMap: Partial<Record<Chains, number>> = {
    [Chains.Base]: 0.05,
    [Chains.Solana]: 0.0001,
    [Chains.Ethereum]: 1,
    [Chains.BinanceChain]: 0.1,
  };

  return maxSellGasMap[chain!] ?? 0.1;
}

export const CHAINS = ['sol', 'base', 'eth', 'bsc'];

export enum ChainsPayouts {
  EthereumBase = 0.015,
  BinanceChain = 0.1,
  Solana = 0.2,
}

export enum ChainsProcessingFee {
  Ethereum = 0.005,
  Base = 0.0005,
  BinanceChain = 0.01,
  Solana = 0.02,
}

export enum ChainsMaxWithdraw {
  EthereumBase = 5,
  BinanceChain = 30,
  Solana = 70,
}

export enum Chains {
  Solana = 'sol',
  Ethereum = 'eth',
  Base = 'base',
  BinanceChain = 'bsc',
}

export enum DexToolBase {
  Solana = 'solana',
  Ethereum = 'ether',
  Base = 'base',
  BinanceChain = 'bsc',
}

export enum Protocols {
  UniswapV2 = 'UNISWAP_V2',
  UniswapV3 = 'UNISWAP_V3',
  PancakeSwapV2 = 'PANCAKESWAP_V2',
  PancakeSwapV3 = 'PANCAKESWAP_V3',
  SushiSwapV2 = 'SUSHISWAP_V2',
  RaydiumAmm = 'RAYDIUM_AMM',
  RaydiumCp = 'RAYDIUM_CP',
  RaydiumCllm = 'RAYDIUM_CLMM',
  PumpFun = 'PUMP_FUN',
  PumpSwap = 'PUMP_SWAP',
  MeteoraDyn = 'METEORA_DYN',
  MeteoraDlmm = 'METEORA_DLMM',
  Moonshot = 'MOONSHOT',
}

export enum ChainScans {
  Solana = 'solscan.io',
  Ethereum = 'etherscan.io',
  Base = 'basescan.org',
  BinanceChain = 'bscscan.com',
}

export enum ProtocolsNames {
  UniswapV2 = 'Uniswap V2.0',
  UniswapV3 = 'Uniswap V3.0',
  PancakeSwapV2 = 'Pancakeswap V2.0',
  PancakeSwapV3 = 'Pancakeswap V3.0',
  SushiSwapV2 = 'Sushiswap V2.0',
  SushiSwapV3 = 'Sushiswap V3.0',
  RaydiumAmm = 'Raydium v4',
  RaydiumCp = 'Raydium Cp',
  RaydiumClmm = 'Raydium Clmm',
  PumpFun = 'PumpFun',
  MeteoraDyn = 'Meteora Dyn',
  MeteoraDlmm = 'Meteora Dlmm',
  Moonshot = 'Moonshot',
  PumpSwap = 'PumpSwap',
}

export enum ChainNames {
  Solana = 'Solana',
  Ethereum = 'Ethereum',
  Base = 'Base',
  BinanceChain = 'BNB',
}

export enum ChainAssets {
  EthereumBase = 'ETH',
  BinanceChain = 'BNB',
  Solana = 'SOL',
}

export enum ChainsSafeValues {
  Ethereum = 0.025,
  Base = 0.01,
  BinanceChain = 0.1,
  Solana = 0.015,
}

export type GetDWalletsResponse = {
  data: DWalletDetails[];
};

export type GetDWalletDetailsResponse = {
  data: DWalletDetails;
};

export type DWalletDetails = {
  id: string;
  groupId: string;
  address: string;
  label: string;
  createdDatetime?: string;
  chainBalance: number;
  totalEthBalance: string;
  totalUsdBalance: string;
  chain: Chains;
  tokenBalances?: TokenBalance[];
};

export type TokenBalance = {
  name: string;
  chain: Chains;
  symbol: string;
  price: string;
  address: string;
  amount: number;
  decimals: number;
  hasIcon: boolean;
};

export type CreateDWalletBody = {
  label: string;
};
export type EditDWalletBody = {
  label: string;
};

export type CreatedWalletResponseDetail = {
  id: string;
  label: string;
  address: string;
  chain: Chains;
  chainBalance: string;
  createdDatetime: string;
  decryptedMnemonic: string;
  encryptedMnemonic: ArrayBuffer;
  decryptedPrivateKey: string;
  encryptedPrivateKey: ArrayBuffer;
  mnemonicIV: Uint8Array;
  privateKeyIV: Uint8Array;
  deleted: boolean;
  tokenBalances: any[];
};
export type CreateDWalletResponse = {
  data: CreatedWalletResponseDetail[];
};

export type WithdrawBody = {
  tokenAddress: string;
  amount: number;
  chain: string;
  vaultAddress: string;
  destinationAddress: string;
};

export type InitiateWithdrawParams = {
  chain: string;
  from_address: string;
  to_address: string;
  token_address?: string;
  amount: string;
};

export type BridgeParams = {
  groupId: string;
  srcChain: string;
  dstChain: string;
  srcChainTokenAmount: string;
};

export type CompleteWithdrawParams = {
  transfer_id: string;
  chain: string;
  from_address: string;
  to_address: string;
  token_address?: string;
  amount: string;
  otp: string;
};

export type InitiateBridge = {
  userId: number;
  srcVaultId: string;
  dstVaultId: string;
  srcChain: Chains;
  dstChain: Chains;
  srcVaultAddress: string;
  dstVaultAddress: string;
  srcTransactionHash?: string | null;
  dstTransactionHash?: string | null;
  srcAmount: number;
  dstAmount: number;
  gasAmount: number;
  feeAmount: number;
  state: string;
};
export type GetInitiateWithdraw = {
  id: string;
  createdDatetime: string;
  updatedDatetime: string;
  userId: number;
  vaultId: string;
  chain: string;
  fromAddress: string;
  toAddress: string;
  tokenAddress: string | null;
  assetName: string;
  assetSymbol: string;
  assetDecimals: number;
  transactionHash: string | null;
  amount: string;
  isChainAsset: boolean;
  isComplete: boolean;
};

export type EstimateWithdraw = {
  data: string;
};
