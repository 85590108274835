import {
  EmptyPageDex,
  ErrorPageDex,
} from '../../../research/components/shared/Common';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { useRef } from 'react';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { duration } from 'moment';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import {
  LeaderboardService,
  leaderboardServiceKeys,
} from 'api/services/httpServices/LeaderboardService';
import { desktopColumns, mobileColumns } from './columns';
import { useInView } from 'react-intersection-observer';
import { TableRow } from './TableRow';
import { AlphaCallsParams } from 'api/types/httpsTypes/leaderboard';
import { useAlphaCallsFilters } from './usePageParams';
import { AlphaCallsFilterBar } from './AlphaCallsFilters';
import { useUserState } from 'modules/user/UserContext';
import StarLevelService, {
  starLevelKeys,
} from 'api/services/httpServices/StarLevelService';
import { useSelectedChain } from '../../../components/chains/getChains';

function AlphaCalls({ chain }: { chain: Chains }) {
  const listRef = useRef<HTMLTableElement>(null);
  const selectedChain = useSelectedChain();

  const { filtersState } = useAlphaCallsFilters(selectedChain);
  const { user } = useUserState();
  const {
    data: starLevel,
    isLoading: isLoadingStarLevel,
    isError: isErrorStarLevel,
  } = useQuery({
    queryKey: starLevelKeys.getStarLevel(),
    queryFn: StarLevelService.getStarLevel,
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const params: Partial<AlphaCallsParams> & { chain: Chains; season: string } =
    {
      season: filtersState.season,
      ...(filtersState.address ? { address: filtersState.address } : {}),
      chain,
      ...(filtersState.userName
        ? { userName__ilike: `%${filtersState.userName}%` }
        : {}),
      ...(filtersState.isMaster ? { isMaster: true } : {}),
    };

  const {
    data,
    error,
    isInitialLoading: isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 10, offset: 0 } }) => {
      return LeaderboardService.getAlphaCalls({
        ...params,
        limit: pageParam.limit,
        offset: pageParam.offset,
      });
    },
    queryKey: leaderboardServiceKeys.getAlphaCalls(params),
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit,
      limit: 10,
    }),
    refetchInterval: duration(120, 'seconds').asMilliseconds(),
  });

  const items = data?.pages?.flatMap((page) => page.data) || [];
  const isUserNotAllowed =
    !user || (starLevel && starLevel.mzr_holdings < 250_000);

  const dataLength = items.length;
  const pagination = data?.pages?.[0]?.pagination;

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  const isLargeScreen = useMediaQuery(screenGte.large);

  const columns = isLargeScreen ? desktopColumns() : mobileColumns();

  return (
    <div className="space-y-2">
      <div className="xxs:px-2 lg:px-0">
        <AlphaCallsFilterBar />
      </div>

      {error || isErrorStarLevel ? (
        <ErrorPageDex />
      ) : (
        <div className="overflow-x-auto">
          <div className="w-full  lg:min-w-[1300px] ">
            {!isLargeScreen && <div ref={listRef} />}
            <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
              <Header
                trClassName={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700`}
                columns={columns}
                rowClassName="xxs:p-1"
                className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
              />
              <tbody className="lg:block lg:h-terminal overflow-y-auto">
                {isLoading || (user && isLoadingStarLevel) ? (
                  [...Array(10).keys()]
                    .map((i) => i + 1)
                    .map((index) => (
                      <Table.LoadingRow
                        key={index}
                        elementClassName="xxs:dark:bg-dex-black-700"
                        className={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800`}
                        columns={columns}
                      />
                    ))
                ) : items?.length === 0 ? (
                  <EmptyPageDex
                    height={isLargeScreen ? '100px' : '50vh'}
                    title="No Alpha Calls Found"
                  />
                ) : (
                  <>
                    {items?.map(
                      (item, index) =>
                        pagination && (
                          <TableRow
                            isUserNotAllowed={!!isUserNotAllowed}
                            isLoggedIn={!!user}
                            index={index}
                            item={item}
                            key={item.callTime + '_' + item.chain}
                            columnsDef={columns}
                          />
                        )
                    )}
                    {pagination && pagination.total > dataLength && (
                      <Table.LoadingRow
                        ref={ref}
                        elementClassName="xxs:dark:bg-dex-black-700"
                        className={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800`}
                        columns={columns}
                      />
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default AlphaCalls;
