import {
  EntryKind,
  FormValues,
  isLimitBuyAmount,
  OrderKind,
  isMarketBuyAmount,
  SnipeAmountSchema,
  MarketBuyAmountSchema,
  LimitBuyAmountSchema,
} from './schema';
import { Button } from 'modules/shared-components/button/button';
import {
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { DexTradeService } from 'api/services/httpServices/DexTradeService';
import { ContractInfo } from 'api/types/httpsTypes/contracts';
import { ChainIconStretch } from 'modules/shared-components/asset/chain-icon';
import {
  formatNumberWithSuffix,
  formatTokenAmount,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
import { CreateDexTradeRequest } from 'api/types/httpsTypes/dex-trade';
import Big from 'big.js';
import { ReactNode } from 'react';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrow-right.svg';
import { twMerge } from 'tailwind-merge';
import { percentageToTimesIncrease } from 'modules/utils/number';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import config from 'config';
import { TgConfirmationDialog } from 'modules/telegram/ConfirmationDailog';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { formatApiError } from 'helpers/api/apiErrors';
import {
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import {
  limitValuesToApi,
  marketValuesToApi,
  sharedValuesToApi,
  snipeValuesToApi,
} from './components/templates/SaveAsModal';
import { useHistory } from 'react-router-dom';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import {
  chainAsset,
  chainHasGasLimit,
  chainHasMemPool,
  chainHasTax,
  Chains,
  gasPrioritySuffix,
} from 'api/types/httpsTypes/d-wallets';
import { NotificationDex } from '../components/alerts/notification';
import CustomModal from '../components/modal/CustomModal';

interface Props {
  trade: FormValues;
  contract: ContractInfo;
  handleClose: () => void;
}

export const DataRow = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => (
  <div className="flex justify-between items-center xxs:gap-5">
    <div className="xxs:mb-0 dark:text-dex-white-secondary ">{label}</div>
    <div className="dark:text-dex-white xxs:text-sm truncate">{children}</div>
  </div>
);

export const handleTrade = (
  trade: FormValues,
  mutate: UseMutateFunction<
    CreateDexTradeRequest,
    unknown,
    CreateDexTradeRequest
  >
) => {
  const wallets = trade.wallets || [];

  const batchSize = 5;
  let currentIndex = 0;

  const processNextBatch = () => {
    const batch = wallets.slice(currentIndex, currentIndex + batchSize);

    batch.forEach((wallet) => {
      mutate(mapTradeToApiModel(trade, wallet));
    });

    currentIndex += batchSize;

    if (currentIndex < wallets.length) {
      setTimeout(processNextBatch, 1500);
    }
  };
  processNextBatch();
};

export const TradeConfirmationModal = ({
  trade,
  contract,
  handleClose,
}: Props) => {
  const { addNewMessage } = useSnackBar();
  const history = useHistory();
  const isDesktop = useMediaQuery(screenGte.small);

  const { mutate, isLoading, error } = useMutation({
    mutationFn: DexTradeService.create,
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'New Trade Created',
        message: 'Your trade has been successfully created.',
      });

      if (!isDesktop) {
        return history.push('/dex/trade-monitoring');
      } else {
        return handleClose();
      }
    },
    onError: (err) => {
      if (config.isTelegramBrowser) {
        addNewMessage({
          message: formatApiError(
            err,
            'There was an error while creating your trade. Try again.'
          ),
          title: 'Error',
          type: 'error',
        });
      } else {
        addNewMessage({
          type: 'error',
          title: 'Error',
          message: 'There was an error while creating your trade. Try again.',
        });
      }
    },
  });

  if (config.isTelegramBrowser) {
    return (
      <TgConfirmationDialog
        onClose={handleClose}
        onConfirmed={() => {
          handleTrade(trade, mutate);
        }}
      >
        Do you want to confirm this trade?
      </TgConfirmationDialog>
    );
  }

  return (
    <CustomModal
      title="Confirm"
      size="large"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="xxs:text-3xl font-semibold xxs:leading-5 xxs:mb-4 hidden lg:flex">
          Confirm New Trade
        </CustomModal.Title>
        <CustomModal.Content>
          <TradeSummary contract={contract} trade={trade} />
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
          {contract.tradingInfo.honeypot && (
            <NotificationDex type="error" className="xxs:my-2">
              Careful: this token looks like an honeypot!
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>

      <CustomModal.Footer>
        <PrimaryButton
          type="button"
          loading={isLoading}
          onClick={() => handleTrade(trade, mutate)}
        >
          TRADE
        </PrimaryButton>
        <Button type="button" variant="outline" onClick={handleClose}>
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

function nonSnipeBuyAmountSummary(
  tradeBuyOrder: MarketBuyAmountSchema | LimitBuyAmountSchema,
  previousBuyOrder: MarketBuyAmountSchema | LimitBuyAmountSchema | undefined,
  chain: Chains
) {
  return (
    <>
      <DataRow label="Buy Gas Priority">
        <PositionChange
          before={
            previousBuyOrder &&
            !Big(tradeBuyOrder.maxBuyGasPriority).eq(
              previousBuyOrder.maxBuyGasPriority ?? 0
            ) && (
              <>
                {previousBuyOrder.maxBuyGasPriority} {gasPrioritySuffix(chain)}
              </>
            )
          }
          after={
            <>
              {tradeBuyOrder.maxBuyGasPriority} {gasPrioritySuffix(chain)}
            </>
          }
        />
      </DataRow>
      <DataRow label="Slippage">
        <PositionChange
          before={
            previousBuyOrder &&
            !Big(tradeBuyOrder.slippage).eq(previousBuyOrder.slippage ?? 0) && (
              <>{previousBuyOrder.slippage}%</>
            )
          }
          after={<>{tradeBuyOrder.slippage}%</>}
        />
      </DataRow>

      {chainHasMemPool(chain) && (
        <DataRow label="MEV Protection">
          <PositionChange
            before={
              previousBuyOrder &&
              !(tradeBuyOrder.antiMev === previousBuyOrder.antiMev) && (
                <>{previousBuyOrder.antiMev ? 'Yes' : 'No'}</>
              )
            }
            after={<>{tradeBuyOrder.antiMev ? 'Yes' : 'No'}</>}
          />
        </DataRow>
      )}
    </>
  );
}

function snipeBuyAmountSummary(
  tradeBuyOrder: SnipeAmountSchema,
  previousBuyOrder: SnipeAmountSchema | undefined
) {
  return (
    <>
      <DataRow label="Bribing Fee">
        <PositionChange
          before={
            previousBuyOrder &&
            !Big(tradeBuyOrder.bribe).eq(previousBuyOrder.bribe ?? 0) && (
              <>{previousBuyOrder.bribe} ETH</>
            )
          }
          after={<>{tradeBuyOrder.bribe} ETH</>}
        />
      </DataRow>
      <DataRow label="Auto-retry">
        <PositionChange
          before={
            previousBuyOrder &&
            !(
              tradeBuyOrder.blockOneRetry === previousBuyOrder.blockOneRetry
            ) && <>{previousBuyOrder.blockOneRetry ? 'Yes' : 'No'}</>
          }
          after={<>{tradeBuyOrder.blockOneRetry ? 'Yes' : 'No'}</>}
        />
      </DataRow>
      {tradeBuyOrder.blockOneRetry && (
        <>
          <DataRow label="Retry Bribing Fee">
            <PositionChange
              before={
                previousBuyOrder &&
                !Big(tradeBuyOrder.backupBribe as number).eq(
                  previousBuyOrder.backupBribe === undefined
                    ? 0
                    : (previousBuyOrder.backupBribe as number)
                ) && <>{previousBuyOrder.backupBribe} ETH</>
              }
              after={<>{tradeBuyOrder.backupBribe} ETH</>}
            />
          </DataRow>
          <DataRow label="Slippage">
            <PositionChange
              before={
                previousBuyOrder &&
                !Big(tradeBuyOrder.slippage as number).eq(
                  previousBuyOrder.slippage === undefined
                    ? 0
                    : (previousBuyOrder.slippage as number)
                ) && <>{previousBuyOrder.slippage}%</>
              }
              after={<>{tradeBuyOrder.slippage}%</>}
            />
          </DataRow>
        </>
      )}
    </>
  );
}

export function TradeSummary({
  contract,
  trade,
  previous,
}: {
  contract: ContractInfo;
  trade: FormValues;
  previous?: FormValues;
}) {
  const {
    isLoading,
    data: availableWallets,
    error,
  } = useQuery({
    queryFn: () =>
      MultiChainDWalletService.getWallets(contract.generalInfo.chain.key),
    queryKey: multiChainDWalletServiceKeys.getWallets(
      contract.generalInfo.chain.key
    ),
  });

  if (!trade.buyOrder.entries) {
    return (
      <span className="text-red-500">Something went wrong, try again</span>
    );
  }

  return (
    <div className="flex flex-col xxs:gap-2">
      <DataRow label="Token">
        {contract.generalInfo.name} ({contract.generalInfo.symbol})
      </DataRow>
      <DataRow label="Chain">
        <ChainIconStretch
          imgName={contract.generalInfo.chain.key}
          className="w-6 h-6"
        />{' '}
        {contract.generalInfo.chain.name}
      </DataRow>

      <Divider />
      <DataRow label="Wallet(s)">
        <div className="items-end flex flex-col">
          {trade.wallets?.map((id, index) => {
            const wallet =
              !isLoading && !error && availableWallets
                ? availableWallets?.find((w) => w.id === id)
                : null;

            return (
              <div key={index}>
                {wallet ? (
                  <span>
                    {formatTokenAmount(wallet.chainBalance)}{' '}
                    {chainAsset(wallet.chain)} | {wallet.label}
                  </span>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      </DataRow>

      <Divider />

      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Buy Settings
      </p>
      {isLimitBuyAmount(trade.buyOrder.kind, trade.buyOrder.entries) ? (
        <>
          {trade.buyOrder.entries.orders?.map((order, index) => {
            const previousBuyOrder = previous?.buyOrder;

            const previousOrder =
              !previousBuyOrder ||
              !isLimitBuyAmount(previousBuyOrder.kind, previousBuyOrder.entries)
                ? undefined
                : previousBuyOrder.entries.orders.find(
                    (x) => x.id === order.id
                  );

            return (
              <DataRow label={index === 0 ? `Limit Orders` : ''} key={index}>
                <PositionChange
                  before={
                    previous && !previousOrder ? (
                      <>NONE</>
                    ) : (
                      previousOrder &&
                      !(
                        Big(order.priceDeviationPercentage || 0).eq(
                          previousOrder.priceDeviationPercentage || 0
                        ) && Big(order.amount).eq(previousOrder.amount)
                      ) && (
                        <>
                          ${formatNumberWithSuffix(order.price)} | -
                          {previousOrder.priceDeviationPercentage}% |{' '}
                          {previousOrder.amount} ETH
                        </>
                      )
                    )
                  }
                  after={
                    <>
                      ${formatNumberWithSuffix(order.price)} | -
                      {order.priceDeviationPercentage}% | {order.amount} ETH
                    </>
                  }
                />
              </DataRow>
            );
          })}
        </>
      ) : (
        <DataRow label="Amount">
          <PositionChange
            before={
              previous &&
              !isLimitBuyAmount(
                previous.buyOrder.kind,
                previous.buyOrder.entries
              ) &&
              previous?.buyOrder.entries?.amount &&
              !Big(trade.buyOrder.entries.amount).eq(
                previous.buyOrder.entries.amount
              ) && (
                <>{formatTokenAmount(previous.buyOrder.entries.amount)} ETH</>
              )
            }
            after={
              <> {formatTokenAmount(trade.buyOrder.entries.amount || 0)} ETH</>
            }
          />
        </DataRow>
      )}
      {trade.buyOrder.kind !== OrderKind.Snipe
        ? nonSnipeBuyAmountSummary(
            trade.buyOrder.entries as
              | MarketBuyAmountSchema
              | LimitBuyAmountSchema,
            (previous?.buyOrder?.entries as
              | MarketBuyAmountSchema
              | LimitBuyAmountSchema) || undefined,
            contract.generalInfo.chain.key
          )
        : snipeBuyAmountSummary(
            trade.buyOrder.entries as SnipeAmountSchema,
            (previous?.buyOrder?.entries as SnipeAmountSchema) || undefined
          )}
      <Divider />

      <>
        <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
          Security Settings
        </p>

        {trade.entryKind === EntryKind.Snipe && (
          <>
            <DataRow label="Min-Max Liquidity">
              <PositionChange
                before={
                  previous &&
                  !(
                    Big(trade.safetyMeasures.liquidity.min).eq(
                      previous.safetyMeasures.liquidity.min
                    ) &&
                    Big(trade.safetyMeasures.liquidity.max).eq(
                      previous.safetyMeasures.liquidity.max
                    )
                  ) && (
                    <>
                      $
                      {formatNumberWithSuffix(
                        previous.safetyMeasures.liquidity.min
                      )}{' '}
                      - $
                      {formatNumberWithSuffix(
                        previous.safetyMeasures.liquidity.max
                      )}
                    </>
                  )
                }
                after={
                  Big(trade.safetyMeasures.liquidity.min).gt(0) ||
                  Big(trade.safetyMeasures.liquidity.max).lt(
                    Number.MAX_SAFE_INTEGER
                  ) ? (
                    <>
                      $
                      {formatNumberWithSuffix(
                        trade.safetyMeasures.liquidity.min
                      )}{' '}
                      - $
                      {formatNumberWithSuffix(
                        trade.safetyMeasures.liquidity.max
                      )}
                    </>
                  ) : (
                    'Disabled'
                  )
                }
              />
            </DataRow>
            <DataRow label="Min-Max Market Cap">
              <PositionChange
                before={
                  previous &&
                  !(
                    Big(trade.safetyMeasures.marketCap.min).eq(
                      previous.safetyMeasures.marketCap.min
                    ) &&
                    Big(trade.safetyMeasures.marketCap.max).eq(
                      previous.safetyMeasures.marketCap.max
                    )
                  ) && (
                    <>
                      $
                      {formatNumberWithSuffix(
                        previous.safetyMeasures.marketCap.min
                      )}{' '}
                      - $
                      {formatNumberWithSuffix(
                        previous.safetyMeasures.marketCap.max
                      )}
                    </>
                  )
                }
                after={
                  Big(trade.safetyMeasures.marketCap.min).gt(0) ||
                  Big(trade.safetyMeasures.marketCap.max).lt(
                    Number.MAX_SAFE_INTEGER
                  ) ? (
                    <>
                      $
                      {formatNumberWithSuffix(
                        trade.safetyMeasures.marketCap.min
                      )}{' '}
                      - $
                      {formatNumberWithSuffix(
                        trade.safetyMeasures.marketCap.max
                      )}
                    </>
                  ) : (
                    'Disabled'
                  )
                }
              />
            </DataRow>
            {chainHasTax(contract.generalInfo.chain.key) && (
              <DataRow label="Tax Protection">
                <PositionChange
                  before={
                    previous &&
                    !(
                      Big(trade.safetyMeasures.taxProtection.buy).eq(
                        previous.safetyMeasures.taxProtection.buy
                      ) &&
                      Big(trade.safetyMeasures.taxProtection.sell).eq(
                        previous.safetyMeasures.taxProtection.sell
                      )
                    ) && (
                      <>
                        Buy: {previous.safetyMeasures.taxProtection.buy}% -
                        Sell: {previous.safetyMeasures.taxProtection.sell}%
                      </>
                    )
                  }
                  after={
                    Big(trade.safetyMeasures.taxProtection.buy).lt(100) ||
                    Big(trade.safetyMeasures.taxProtection.sell).lt(100) ? (
                      <>
                        Buy: {trade.safetyMeasures.taxProtection.buy}% - Sell:{' '}
                        {trade.safetyMeasures.taxProtection.sell}%
                      </>
                    ) : (
                      'Disabled'
                    )
                  }
                />
              </DataRow>
            )}
          </>
        )}
        {chainHasGasLimit(contract.generalInfo.chain.key) && (
          <DataRow label="Gas Limit">
            <PositionChange
              before={
                previous &&
                !(
                  Big(trade.safetyMeasures.gasLimit?.toString() ?? 0).eq(
                    previous.safetyMeasures.gasLimit?.toString() ?? 0
                  ) &&
                  Big(trade.safetyMeasures.gasLimit?.toString() ?? 0).eq(
                    previous.safetyMeasures.gasLimit?.toString() ?? 0
                  )
                ) && (
                  <>
                    {formatWithoutDecimals(
                      (previous.safetyMeasures.gasLimit as number) ?? 0
                    )}{' '}
                    wei
                  </>
                )
              }
              after={
                trade.safetyMeasures.gasLimitEnabled ? (
                  <>
                    {formatWithoutDecimals(
                      (trade.safetyMeasures.gasLimit as number) ?? 0
                    )}{' '}
                    wei
                  </>
                ) : (
                  'Disabled'
                )
              }
            />
          </DataRow>
        )}

        <DataRow label="Honeypot protection">
          <PositionChange
            before={
              previous &&
              !(
                trade.safetyMeasures.honeypotProtection ===
                previous.safetyMeasures.honeypotProtection
              ) && (
                <>
                  {previous.safetyMeasures.honeypotProtection
                    ? 'Enabled'
                    : 'Disabled'}
                </>
              )
            }
            after={
              <>
                {trade.safetyMeasures.honeypotProtection
                  ? 'Enabled'
                  : 'Disabled'}
              </>
            }
          />
        </DataRow>
        {!trade.safetyMeasures.autoRetryEnabled ? (
          <DataRow label="Auto-retry">Disabled</DataRow>
        ) : (
          <DataRow label="Auto-retry">Enabled</DataRow>
        )}
        <Divider />
      </>

      <p className="xxs:text-sm xxs:font-semibold dark:text-white xxs:mb-0">
        Sell Settings
      </p>

      {!trade.takeProfit?.length ? (
        <DataRow label="Take Profit">Disabled</DataRow>
      ) : (
        ''
      )}

      {trade.takeProfit.map((takeProfit, index) => {
        const previousTp = previous?.takeProfit.find(
          (x) => x.id === takeProfit.id
        );

        return (
          <DataRow label={index === 0 ? `Take Profit` : ''} key={index}>
            <PositionChange
              before={
                previous && !previousTp ? (
                  <>NONE</>
                ) : (
                  previousTp &&
                  !(
                    Big(takeProfit.threshold).eq(previousTp.threshold) &&
                    Big(takeProfit.weight).eq(previousTp.weight)
                  ) && (
                    <div>
                      +{percentageToTimesIncrease(previousTp.threshold)}X (
                      {previousTp.weight}%)
                    </div>
                  )
                )
              }
              after={
                <div
                  className={
                    (takeProfit.status && takeProfit.status !== 'ACTIVE') ||
                    takeProfit.isFailed
                      ? 'text-yellow-500 line-through'
                      : ''
                  }
                >
                  +{percentageToTimesIncrease(takeProfit.threshold)}X (
                  {takeProfit.weight}%)
                </div>
              }
            />
          </DataRow>
        );
      })}

      {previous?.takeProfit
        .filter((x) => !trade.takeProfit.find((curr) => curr.id === x.id))
        .map((takeProfit, index) => {
          return (
            <DataRow label={index === 0 ? `Removed TP` : ''} key={index}>
              <span className="xxs:text-yellow-500 line-through">
                +{percentageToTimesIncrease(takeProfit.threshold)}X (
                {takeProfit.weight}%)
              </span>
            </DataRow>
          );
        })}

      {!trade.stopLoss ? <DataRow label="Stop Loss">Disabled</DataRow> : ''}
      {(!!trade.stopLoss || !!previous?.stopLoss) && (
        <>
          <DataRow label="Threshold">
            <PositionChange
              before={
                previous &&
                (!Big(trade.stopLoss?.threshold || 0).eq(
                  trade.stopLoss?.threshold || 0
                ) && previous.stopLoss?.threshold ? (
                  <>{previous.stopLoss.threshold}%</>
                ) : (
                  <>--</>
                ))
              }
              after={
                trade.stopLoss?.threshold ? (
                  <>{trade.stopLoss.threshold}%</>
                ) : (
                  <>--</>
                )
              }
            />
          </DataRow>

          {(trade.stopLoss?.deviation || previous?.stopLoss?.deviation) && (
            <DataRow label="Deviation">
              <PositionChange
                before={
                  previous &&
                  (!Big(trade.stopLoss?.deviation || 0).eq(
                    previous.stopLoss?.deviation || 0
                  ) && previous.stopLoss?.deviation ? (
                    <>{previous.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  ))
                }
                after={
                  trade.stopLoss?.deviation ? (
                    <>{trade.stopLoss.deviation}%</>
                  ) : (
                    <>--</>
                  )
                }
              />
            </DataRow>
          )}
        </>
      )}

      {(!!trade.takeProfit?.length ||
        !!previous?.takeProfit?.length ||
        !!trade.stopLoss ||
        !!previous?.stopLoss) && (
        <>
          <DataRow label="Slippage">
            <PositionChange
              before={
                previous &&
                !Big(trade.takeProfitConfig.slippage).eq(
                  previous.takeProfitConfig.slippage
                ) && <>{previous.takeProfitConfig.slippage}%</>
              }
              after={<>{trade.takeProfitConfig.slippage}%</>}
            />
          </DataRow>

          <DataRow label="Gas Priority">
            <PositionChange
              before={
                previous &&
                !Big(trade.takeProfitConfig.maxSellGasPriority).eq(
                  previous.takeProfitConfig.maxSellGasPriority
                ) && (
                  <>
                    {previous.takeProfitConfig.maxSellGasPriority}{' '}
                    {gasPrioritySuffix(previous.chain)}
                  </>
                )
              }
              after={
                <>
                  {trade.takeProfitConfig.maxSellGasPriority}{' '}
                  {gasPrioritySuffix(trade.chain)}
                </>
              }
            />
          </DataRow>

          {chainHasMemPool(contract?.generalInfo.chain.key) && (
            <DataRow label="MEV Protection">
              <PositionChange
                before={
                  previous &&
                  !(
                    trade.takeProfitConfig.antiMev ===
                    previous.takeProfitConfig.antiMev
                  ) && <>{previous.takeProfitConfig.antiMev ? 'Yes' : 'No'}</>
                }
                after={<>{trade.takeProfitConfig.antiMev ? 'Yes' : 'No'}</>}
              />
            </DataRow>
          )}
        </>
      )}
    </div>
  );
}

export function PositionChange({
  before,
  after,
}: {
  before: ReactNode;
  after: ReactNode;
}) {
  return (
    <div
      className={twMerge(
        'ml-auto whitespace-nowrap',
        before && 'xxs:text-yellow-500'
      )}
    >
      {before} {before && <ArrowRight className="align-baseline" />} {after}
    </div>
  );
}

export function Divider() {
  return <div className="h-[1px] dark:bg-dex-black-700 xxs:my-2" />;
}

export function mapTradeToApiModel(
  values: FormValues,
  walletId: string
): CreateDexTradeRequest {
  const buyOrder = values.buyOrder;

  if (isLimitBuyAmount(buyOrder.kind, buyOrder.entries)) {
    return {
      ...sharedValuesToApi(values),
      vaultId: walletId,
      tokenAddress: values.address ?? '',
      isPostLaunchSnipe: values.entryKind === EntryKind.Buy,
      ...limitValuesToApi(
        values.buyOrder.entries as LimitBuyAmountSchema,
        values.chain,
        values.safetyMeasures.autoRetryEnabled
          ? values.safetyMeasures.autoRetry.buy
          : false
      ),
    };
  }

  if (isMarketBuyAmount(buyOrder.kind, buyOrder.entries)) {
    return {
      ...sharedValuesToApi(values),
      vaultId: walletId,
      tokenAddress: values.address ?? '',
      isPostLaunchSnipe: values.entryKind === EntryKind.Buy,
      ...marketValuesToApi(
        values.buyOrder.entries as MarketBuyAmountSchema,
        values.chain
      ),
    };
  } else {
    return {
      ...sharedValuesToApi(values),
      vaultId: walletId,
      tokenAddress: values.address ?? '',
      isPostLaunchSnipe: values.entryKind === EntryKind.Buy,
      ...snipeValuesToApi(
        values.buyOrder.entries as SnipeAmountSchema,
        values.chain
      ),
    };
  }
}
