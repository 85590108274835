import * as yup from 'yup';
import { useQueryParams } from 'modules/routing/useQueryParams';
import { getSeasonForCurrentCalendarWeek } from '../seasons';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useEffect } from 'react';

const schema = (chain: Chains) => ({
  season: yup
    .string()
    .default(getSeasonForCurrentCalendarWeek(chain))
    .required(),
  address: yup.string(),
  userName: yup.string(),
  isMaster: yup.boolean(),
});

type SchemaType = ReturnType<typeof schema>;

// Manually infer the type
export type AlphaCallsFilterState = {
  [K in keyof SchemaType]: SchemaType[K] extends yup.StringSchema<
    infer T,
    any,
    any,
    any
  >
    ? T
    : SchemaType[K] extends yup.BooleanSchema<infer T, any, any, any>
    ? T
    : never;
};

export function usePageParams(chain: Chains) {
  return useQueryParams(schema(chain));
}

export function useAlphaCallsFilters(chain: Chains) {
  const [filtersState, setFilters] = usePageParams(chain);

  useEffect(() => {
    const newSeason = getSeasonForCurrentCalendarWeek(chain);
    if (filtersState.season !== newSeason) {
      setFilters({
        season: getSeasonForCurrentCalendarWeek(chain),
      });
    }
  }, [chain]);

  return { filtersState, setFilters };
}
