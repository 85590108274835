import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Button } from 'modules/shared-components/button/button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  dWalletServiceKeys,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import {
  EditDWalletBody,
  DWalletDetails,
} from 'api/types/httpsTypes/d-wallets';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import CustomModal from '../../components/modal/CustomModal';
import TextInput from '../../components/inputs/text-input/text-input';
import { NotificationDex } from '../../components/alerts/notification';
import { validateLabel } from '../../components/inputs/text-input/text-validation';

export const schema = yup.object({
  label: yup
    .string()
    .typeError('Required')
    .required('Required')
    .test(
      'is-valid-label',
      'Invalid label. Text can contain only letters, spaces, numbers, and -,.. It cannot be longer than 127 characters.',
      (value) => {
        try {
          return validateLabel(value || '');
        } catch {
          return false;
        }
      }
    ),
});

export type FormState = yup.InferType<typeof schema>;

type Props = {
  handleClose: () => void;
  wallet: DWalletDetails;
};

export function EditDWalletModal({ wallet, handleClose }: Props) {
  const queryClient = useQueryClient();
  const { mutate, error, isLoading, data } = useMutation({
    mutationFn: (params: EditDWalletBody) =>
      MultiChainDWalletService.editDWallet(wallet.groupId, params),
    onSuccess: async () => {
      handleClose();
      await queryClient.invalidateQueries(multiChainDWalletServiceKeys.all());
      await queryClient.invalidateQueries(dWalletServiceKeys.all());
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      label: wallet.label,
    },
  });

  return (
    <CustomModal
      title="Edit Wallet"
      showModal
      handleClose={data ? () => {} : handleClose}
    >
      <form
        className="contents"
        onSubmit={(e) => {
          void handleSubmit((state) => {
            mutate(state);
          })(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4 text-left max-w-none">
          <CustomModal.Title className="hidden lg:flex">
            Edit {wallet.label}
          </CustomModal.Title>
          <CustomModal.Content>
            <TextInput
              {...register('label')}
              type="text"
              label="Wallet Label"
              error={errors?.label?.message}
            />
          </CustomModal.Content>

          {error && (
            <NotificationDex
              className="xxs:my-2"
              type="error"
              errorMessage={error}
            >
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton
            loading={isLoading}
            type="submit"
            className="min-w-[80px] xxs:text-base lg:text-xs"
          >
            SAVE
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={handleClose}
          >
            Close
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
