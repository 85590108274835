import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { Marquee } from 'modules/shared-components/marquee';
import { useHistory } from 'react-router-dom';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { PercentageChangeWithMaxValue } from './TelegramCalls/columns';
import { DexCard } from '../../components/cards';
import { useChainPersistedState } from '../../components/chains/getChains';
import { Chains } from 'api/types/httpsTypes/d-wallets';

export function HotContracts() {
  const isDesktop = useMediaQuery(screenGte.large);
  const history = useHistory();
  const { data } = useQuery({
    queryFn: () => ContractService.getHotContracts(),
    queryKey: contractServiceKeys.getHotContracts(),
  });
  const [selectedChain, setSelectedChain] = useChainPersistedState(Chains.Base);
  const SmallScreenMarquee = Marquee;

  return (
    <DexCard className="xxs:flex xxs:flex-row xxs:items-center  xxs:py-1 xxs:px-2 xxs:space-y-0 xxs:gap-3 overflow-hidden rounded-md">
      <div className="xxs:text-xs xxs:font-semibold xxs:text-dex-white-secondary flex-nowrap flex space-x-1">
        <div>Trendy</div>
        <div className="xxs:hidden sm:inline">Tokens</div>
      </div>
      <div className="mr-2 xxs:text-xs xxs:text-dex-white-secondary">|</div>
      <SmallScreenMarquee>
        <div className="flex flex-row items-center xxs:gap-8 xxs:text-sm dark:text-black-50 xxs:px-2">
          {data?.map((contract) => {
            return (
              <BaseButton
                key={contract.address}
                className={twMerge(
                  'xxs:flex xxs:gap-1 xxs:py-1 items-center shrink-0 max-w-[200px] truncate xxs:text-dex-white-secondary hover:text-dex-white'
                )}
                onClick={() => {
                  if (selectedChain !== contract.chain.key) {
                    setSelectedChain(contract.chain.key);
                  }
                  history.push(
                    isDesktop
                      ? `/dex/snipe/new/${contract.chain.key}/${contract.address}`
                      : `/dex/research/${contract.chain.key}/${contract.address}`
                  );
                }}
              >
                <div className="xxs:text-xs">#{contract.position}</div>{' '}
                <TrendingIcon
                  position={contract.position}
                  previousPosition={contract.previousPosition}
                />{' '}
                <TokenIconWithChain
                  hasIcon={contract.hasIcon}
                  className="h-5 w-5"
                  symbol={contract.symbol}
                  chain={contract.chain.key}
                  address={contract.address}
                  tokenName={contract.chain.key}
                />
                <div className="xxs:text-xs">{contract.symbol}</div>
                {contract.priceChange && (
                  <div>
                    {
                      <PercentageChangeWithMaxValue
                        maxValue={999}
                        className="xxs:text-[10px]"
                        change={contract.priceChange}
                      />
                    }
                  </div>
                )}
              </BaseButton>
            );
          })}
          <div className="xxs:px-4 lg:px-10" />
        </div>
      </SmallScreenMarquee>
    </DexCard>
  );
}

function TrendingIcon({
  position,
  previousPosition,
}: {
  position: number;
  previousPosition: number | null;
}) {
  if (!previousPosition || position < previousPosition) {
    return <ChevronDoubleUpIcon className="xxs:text-green-500 w-3.5" />;
  } else if (position > previousPosition) {
    return <ChevronDoubleDownIcon className="xxs:text-red-500 w-3.5" />;
  } else {
    return null;
  }
}
