import { WagmiConfig, configureChains, createConfig, Chain } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import config from 'config';
import { base, bsc } from 'viem/chains';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { MetaMaskConnector } from '@wagmi/connectors/metaMask';

// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

type ExtendedChain = Chain & { key: Chains };

export const EXTENDED_DEPOSIT_CHAINS_CONFIG: ExtendedChain[] = [
  { ...mainnet, key: Chains.Ethereum },
  { ...base, key: Chains.Base },
  { ...bsc, key: Chains.BinanceChain },
];

export const DEPOSIT_CHAINS_CONFIG: Chain[] = [base, mainnet, bsc];

const isExcludedWallet = (provider: any) => {
  const EXCLUDED_WALLETS = [
    'isApexWallet',
    'isAvalanche',
    'isBackpack',
    'isBifrost',
    'isBitKeep',
    'isBitski',
    'isBlockWallet',
    'isBraveWallet',
    'isCoinbaseWallet',
    'isDawn',
    'isDefiant',
    'isEnkrypt',
    'isExodus',
    'isFrame',
    'isFrontier',
    'isGamestop',
    'isHaqqWallet',
    'isHyperPay',
    'isImToken',
    'isKuCoinWallet',
    'isMathWallet',
    'isNovaWallet',
    'isOkxWallet',
    'isOKExWallet',
    'isOneInchAndroidWallet',
    'isOneInchIOSWallet',
    'isOpera',
    'isPhantom',
    'isPortal',
    'isRabby',
    'isRainbow',
    'isStatus',
    'isTalisman',
    'isTally',
    'isTokenPocket',
    'isTokenary',
    'isTTWallet',
    'isXDEFI',
    'isZerion',
    'isHaloWallet',
  ];
  return EXCLUDED_WALLETS.some((flag) => provider[flag] === true);
};

const { chains, publicClient } = configureChains(DEPOSIT_CHAINS_CONFIG, [
  publicProvider(),
]);

type Props = { children: React.ReactNode };

export const wagmiConfig = createConfig({
  autoConnect: false,
  publicClient,
  connectors: () => {
    const connectors = [];

    if (typeof window !== 'undefined' && window.ethereum) {
      if (window.ethereum.providers) {
        const uniqueProviders = Array.from(new Set(window.ethereum.providers));

        // Prioritize MetaMask first
        const metaMaskProvider = uniqueProviders.find(
          (provider: any) =>
            provider.isMetaMask &&
            !provider.isTrust &&
            !isExcludedWallet(provider)
        );

        if (metaMaskProvider) {
          connectors.push(
            new MetaMaskConnector({
              chains,
              options: {
                shimDisconnect: true,
                getProvider: () => metaMaskProvider,
              } as any,
            })
          );
        }

        // Add TrustWallet as an extra injected wallet
        const trustWalletProvider = uniqueProviders.find(
          (provider: any) => provider.isTrust
        );
        if (trustWalletProvider) {
          connectors.push(
            new InjectedConnector({
              chains,
              options: {
                name: 'Trust Wallet',
                shimDisconnect: true,
                getProvider: () => trustWalletProvider as any,
              },
            })
          );
        }
      } else {
        if (
          window.ethereum.isMetaMask &&
          !window.ethereum.isTrust &&
          !isExcludedWallet(window.ethereum)
        ) {
          connectors.push(
            new MetaMaskConnector({
              chains,
              options: { shimDisconnect: true },
            })
          );
        }
        if (window.ethereum.isTrust) {
          connectors.push(
            new InjectedConnector({
              chains,
              options: { name: 'Trust Wallet', shimDisconnect: true },
            })
          );
        }
      }
    }

    connectors.push(
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'Mizar',
          darkMode: true,
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: config.walletConnectProjectId,
          showQrModal: true,
        },
      })
    );

    return connectors;
  },
});

export function DepositEthereumProvider({ children }: Props) {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}
