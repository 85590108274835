import {
  ClosePositionParams,
  CreateDexTradeRequest,
  CreateTemplate,
  DexTradeTemplate,
  GetAllTemplatesResponse,
  GetDexOpenPositionResponse,
  GetDexOpenPositionsParams,
  GetDexOpenPositionsResponse,
  ManualSwapParams,
  OpenPositionListItem,
} from 'api/types/httpsTypes/dex-trade';
import { api } from 'helpers/api/apiCore';
import { toQueryString } from 'modules/routing/query-string';

const SERVICE_KEY = 'dex-trade';

export const dexTradeKeys = {
  all: () => [SERVICE_KEY],
  getPositions: (query: GetDexOpenPositionsParams) => [
    SERVICE_KEY,
    'open-positions',
    query,
  ],
  getPosition: (id: string) => [SERVICE_KEY, id],
  getTemplates: () => [SERVICE_KEY, 'templates'],
};

export const DexTradeService = {
  async getPositions(params: GetDexOpenPositionsParams) {
    const response = await api.get<
      GetDexOpenPositionsResponse,
      GetDexOpenPositionsParams
    >(`/async/api/v2/d-trade/positions?${toQueryString(params)}`);

    return response.data;
  },
  async getPosition(id: string) {
    const response = await api.get<GetDexOpenPositionResponse>(
      `/async/api/v2/d-trade/positions/${id}`
      //`/api/v2/d-trade/positions/${id}`
    );

    return response.data;
  },

  async cancelLimitOrder(id: string) {
    const response = await api.post<{ message: string }>(
      `/async/api/v2/d-trade/orders/${id}/cancel`
      // `/api/v2/d-trade/orders/${id}/cancel`
    );

    return response.data;
  },

  async create(trade: CreateDexTradeRequest) {
    const response = await api.post<{}, CreateDexTradeRequest>(
      `/async/api/v2/d-trade/positions/create`,
      //`/api/v2/d-trade/positions/create`,
      trade
    );

    return response.data;
  },
  async edit(positionId: string, trade: CreateDexTradeRequest) {
    const response = await api.updatePatch<{}, CreateDexTradeRequest>(
      `/async/api/v2/d-trade/positions/${positionId}`,
      // `/api/v2/d-trade/positions/${positionId}`,
      trade
    );

    return response.data;
  },
  async cancel(positionId: string) {
    const response = await api.post<{}, {}>(
      `/async/api/v2/d-trade/positions/${positionId}/cancel`
      // `/api/v2/d-trade/positions/${positionId}/cancel`
    );

    return response.data;
  },

  async reactivate(positionId: string) {
    const response = await api.post<{}, {}>(
      `/async/api/v2/d-trade/positions/${positionId}/reactivate`
      // `/api/v2/d-trade/positions/${positionId}/reactivate`
    );

    return response.data;
  },

  async merge(positionId: string, params: { positionIds: string[] }) {
    const response = await api.post<{}, {}>(
      `/async/api/v2/d-trade/positions/${positionId}/merge`,
      //`/api/v2/d-trade/positions/${positionId}/merge`,
      params
    );

    return response.data;
  },

  async close(positionId: string, params: ClosePositionParams) {
    const response = await api.post<{}, {}>(
      `/async/api/v2/d-trade/positions/${positionId}/close`,
      //`/api/v2/d-trade/positions/${positionId}/close`,
      params
    );

    return response.data;
  },
  async manualSwap(positionId: string, params: ManualSwapParams) {
    const response = await api.post<ManualSwapParams, OpenPositionListItem>(
      `/async/api/v2/d-trade/positions/${positionId}/swap`,
      //`/api/v2/d-trade/positions/${positionId}/swap`,
      params
    );

    return response.data;
  },
  async getTemplates() {
    const response = await api.get<GetAllTemplatesResponse>(
      `/async/api/v2/d-trade/settings-templates`
      // `/api/v2/d-trade/settings-templates`
    );

    return response.data.data;
  },
  async createTemplate(params: CreateTemplate) {
    const response = await api.post<CreateTemplate, DexTradeTemplate>(
      `/async/api/v2/d-trade/settings-templates`,
      //`/api/v2/d-trade/settings-templates`,
      params
    );

    return response.data;
  },
  async updateTemplate(id: string, params: CreateTemplate) {
    const response = await api.update<CreateTemplate, DexTradeTemplate>(
      `/async/api/v2/d-trade/settings-templates/${id}`,
      //`/api/v2/d-trade/settings-templates/${id}`,
      params
    );

    return response.data;
  },
  async deleteTemplate(id: string) {
    const response = await api.delete(
      `/async/api/v2/d-trade/settings-templates/${id}`
      // `/api/v2/d-trade/settings-templates/${id}`
    );

    return response.data;
  },
};
