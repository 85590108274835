import { formatApiError } from 'helpers/api/apiErrors';
import { NotificationDex } from '../../../app/dex/components/alerts/notification';

type Props = Omit<
  React.ComponentProps<typeof NotificationDex>,
  'children' | 'type'
> & {
  error: unknown;
  fallback?: string;
};

export function ApiErrorNotification({ error, fallback, ...props }: Props) {
  if (!error) {
    return <></>;
  }
  return (
    <NotificationDex {...props} type="error">
      {formatApiError(error, fallback)}
    </NotificationDex>
  );
}
