import { useContext } from 'react';
import { WebSocketContext } from '../services/websocket/websocketProvider';

type WebSocketStatus = {
  isConnected: boolean | undefined;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isConnecting: boolean;
};

export function useWebSocketStatus(): WebSocketStatus {
  const wsContext = useContext(WebSocketContext);

  if (!wsContext) {
    return {
      isConnected: false,
      isAuthenticated: false,
      isAuthenticating: false,
      isConnecting: false,
    };
  }

  return {
    isConnecting: wsContext.isConnecting,
    isConnected: wsContext.isConnected,
    isAuthenticated: wsContext.isAuthenticated,
    isAuthenticating: wsContext.isAuthenticating,
  };
}
