import { HotTokensSortKeys } from 'api/types/httpsTypes/contracts';
import { FilterState, useHotTokensSearchFilters } from './usePageParams';
import { useEffect, useState } from 'react';
import { useDebouncedState } from 'utils/useDebounce';
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  LiquidityFilter,
  McRangeFilter,
  VolumeRangeFilter,
} from './ResearchFilters';
import { isNotNil } from 'modules/utils/isNotNil';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { HotTokensPeriods, TokenLaunchPeriods } from '../Wallets/usePageParams';
import { twMerge } from 'tailwind-merge';
import { isValidAddress } from 'helpers/api/web3Utils';
import { PreSetFilters, PreSets } from '../../../bots/volatility/schema';
import {
  SortDropdown,
  SortDropdownProps,
} from '../../../components/dropdown/sort-dropdown';
import {
  ActivteFiltersBar,
  FilterTag,
  SelectFiltersBar,
} from '../../../components/filters';
import Button from 'modules/shared-components/button/button';
import TextInput from '../../../components/inputs/text-input/text-input';
import QuickFilters from '../../../components/filters/quickFilters';
import { useSelectedChain } from '../../../components/chains/getChains';

export const PERIOD_FILTERS = [
  {
    label: '5M',
    value: HotTokensPeriods.FiveMinutes,
  },
  {
    label: '15M',
    value: HotTokensPeriods.FifteenMinutes,
  },
  {
    label: '30M',
    value: HotTokensPeriods.ThirtyMinutes,
  },
  {
    label: '1H',
    value: HotTokensPeriods.OneHour,
  },
  {
    label: '6H',
    value: HotTokensPeriods.SixHours,
  },
  {
    label: '24H',
    value: HotTokensPeriods.TwentyFourHour,
  },
];

export const TOKENS_LAUNCH_PERIOD_FILTERS = [
  {
    label: '> 1 Hour',
    value: TokenLaunchPeriods.Hour,
  },
  {
    label: '> 1 Day',
    value: TokenLaunchPeriods.Day,
  },
  {
    label: '> 1 Week',
    value: TokenLaunchPeriods.Week,
  },
  {
    label: '> 1 Month',
    value: TokenLaunchPeriods.Month,
  },
  {
    label: '> 3 Months',
    value: TokenLaunchPeriods.ThreeMonths,
  },
];

export const SORT_OPTIONS = [
  {
    label: 'Price Change',
    value: `-${HotTokensSortKeys.PriceChange}`,
  },
  {
    label: 'Volatility Index',
    value: `-${HotTokensSortKeys.VolatilityIndex}`,
  },
  {
    label: 'Smart Wallets Flow',
    value: `-${HotTokensSortKeys.SmartFlow}`,
  },
  {
    label: 'Volume',
    value: `-${HotTokensSortKeys.Volume}`,
  },
  {
    label: 'Liquidity',
    value: `-${HotTokensSortKeys.Liquidity}`,
  },
  {
    label: 'Market Cap',
    value: `-${HotTokensSortKeys.MarketCap}`,
  },
];

export const HotTokensFiltersBar = () => {
  const { filtersState: filters, setFilters } = useHotTokensSearchFilters();
  const selectedChain = useSelectedChain();
  const [hotTokenAddress, debouncedHotTokenAddress, setHotTokenAddress] =
    useDebouncedState('', 250);
  const [addressError, setAddressError] = useState(false);
  useEffect(() => {
    if (debouncedHotTokenAddress) {
      setAddressError(!isValidAddress(debouncedHotTokenAddress, selectedChain));
      if (isValidAddress(debouncedHotTokenAddress, selectedChain)) {
        updateFilters({
          hotTokenAddress: debouncedHotTokenAddress,
        });
      }
    }
  }, [debouncedHotTokenAddress]);

  const handleWalletAddressInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setHotTokenAddress(inputValue);
    if (!inputValue) {
      setAddressError(false);
      updateFilters({
        hotTokenAddress: undefined,
      });
    }
  };

  const updateFilters = (update: Partial<FilterState>) => {
    setFilters({ ...filters, ...update });
  };

  const quickFilterMostVolatility = () => {
    return setFilters({
      ...PreSetFilters[PreSets.Volatility],
    } as FilterState);
  };

  const quickFilterMostTraded = () => {
    return setFilters({
      ...PreSetFilters[PreSets.TradingVolume],
    } as FilterState);
  };

  const quickFilterSmartVolume = () => {
    return setFilters({
      ...PreSetFilters[PreSets.SmartMoney],
    } as FilterState);
  };

  const quickFilterMostPriceChange = () => {
    return setFilters({
      ...PreSetFilters[PreSets.Price],
    } as FilterState);
  };

  const quickFilterOptions = [
    {
      label: 'Most Volatile',
      onClick: quickFilterMostVolatility,
      condition: filters.sort === '-volatility_index',
      extraClasses: 'flex',
    },
    {
      label: 'Most Traded',
      onClick: quickFilterMostTraded,
      condition: filters.sort === '-volume',
      extraClasses: 'flex',
    },
    {
      label: 'Smart Wallets Volume',
      onClick: quickFilterSmartVolume,
      condition: filters.sort === '-buy_volume_top',
      extraClasses: 'xxs:hidden lg:flex',
    },
    {
      label: 'Price Increase',
      onClick: quickFilterMostPriceChange,
      condition: filters.sort === '-price_change',
      extraClasses: 'flex',
    },
  ];

  const nOfFiltersActive = [
    filters.poolCreatedAt,
    filters.hotTokenAddress,
    filters.minVolume,
    filters.maxVolume,
    filters.minMc,
    filters.maxMc,
    filters.minLiquidity,
    filters.maxLiquidity,
  ].filter((value) => value !== undefined && value && value !== '').length;

  return (
    <div className="xxs:space-y-2 lg:space-y-1">
      <div className="lg:hidden">
        <PeriodFilterButtons
          value={filters.period}
          onChange={(value) => {
            updateFilters({
              period: value as HotTokensPeriods,
            });
          }}
        />
      </div>
      <SelectFiltersBar
        className="xxs:justify-start space-x-2 "
        sortClass="xxs:w-full"
        icon={
          <div
            className={twMerge(
              'xxs:p-1 flex xxs:space-x-1 items-center',
              nOfFiltersActive
                ? 'xxs:hover:text-dex-white'
                : ' xxs:hover:text-dex-white xxs:text-dex-white-secondary xxs:px-2 '
            )}
          >
            <FunnelIcon aria-hidden="true" width={16} height={16} />
            {nOfFiltersActive ? <div>{nOfFiltersActive}</div> : ''}
          </div>
        }
        isCondensed={true}
        sort={
          <div className="flex gap-x-2 xxs:items-center w-full justify-between">
            <div className="hidden lg:block">
              <PeriodFilterButtons
                value={filters.period}
                onChange={(value) => {
                  updateFilters({
                    period: value as HotTokensPeriods,
                  });
                }}
              />
            </div>
            <div>
              <TextInput
                name="hotTokenAddress"
                error={addressError ? 'Address not valid' : ''}
                value={hotTokenAddress}
                prefix={
                  <MagnifyingGlassIcon className="w-4 xxs:text-dex-white-secondary" />
                }
                onChange={handleWalletAddressInputChange}
                placeholder="Search Address"
              />
            </div>
            <div className="lg:hidden">
              <Sort
                value={filters.sort}
                onChange={(value) => {
                  updateFilters({
                    sort: value,
                  });
                }}
              />
            </div>
          </div>
        }
      >
        <div className="xxs:space-y-2">
          <div className="xxs:hidden lg:block xxs:text-dex-white-secondary">
            Use advanced filters to find the best tokens.
          </div>
          <div>
            <TextInput
              name="hotTokenAddress"
              error={addressError ? 'Address not valid' : ''}
              value={hotTokenAddress}
              prefix={
                <MagnifyingGlassIcon className="w-4 xxs:text-dex-white-secondary" />
              }
              onChange={handleWalletAddressInputChange}
              placeholder="Search Address"
            />
          </div>

          <TokenLaunchPeriodFilterMobile
            value={filters.poolCreatedAt as TokenLaunchPeriods}
            onChange={(value: TokenLaunchPeriods) => {
              if (value === filters.poolCreatedAt) {
                updateFilters({
                  poolCreatedAt: undefined as any,
                });
              } else {
                updateFilters({
                  poolCreatedAt: value,
                });
              }
            }}
          />
          <McRangeFilter />
          <LiquidityFilter />
          <VolumeRangeFilter />
        </div>
      </SelectFiltersBar>
      <QuickFilters filterOptions={quickFilterOptions} />
      <ActivteFiltersBar>
        {filters.poolCreatedAt && (
          <FilterTag
            onRemove={() => {
              return updateFilters({
                poolCreatedAt: undefined as any,
              });
            }}
            label={
              `Age: ${
                TOKENS_LAUNCH_PERIOD_FILTERS.filter(
                  (period) => period.value === filters.poolCreatedAt
                )?.[0]?.label
              }` || ''
            }
          />
        )}
        {filters.hotTokenAddress && (
          <FilterTag
            onRemove={() => {
              setHotTokenAddress('');
              return updateFilters({
                hotTokenAddress: undefined,
              });
            }}
            label={`Token: ${
              filters?.hotTokenAddress ?? hotTokenAddress ?? '?'
            }`}
          />
        )}
        {isNotNil(filters.minVolume) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minVolume: undefined,
              })
            }
            label={`Volume > $${formatNumberWithSuffix(filters.minVolume, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
        {isNotNil(filters.maxVolume) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxVolume: undefined,
              })
            }
            label={`Volume < $${formatNumberWithSuffix(filters.maxVolume, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
        {isNotNil(filters.minMc) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minMc: undefined,
              })
            }
            label={`MC > $${formatNumberWithSuffix(filters.minMc, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
        {isNotNil(filters.maxMc) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxMc: undefined,
              })
            }
            label={`MC < $${formatNumberWithSuffix(filters.maxMc, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
        {isNotNil(filters.minLiquidity) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minLiquidity: undefined,
              })
            }
            label={`Liq. > $${formatNumberWithSuffix(filters.minLiquidity, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
        {isNotNil(filters.maxLiquidity) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxLiquidity: undefined,
              })
            }
            label={`Liq. < $${formatNumberWithSuffix(filters.maxLiquidity, {
              suffixes: allSuffixes,
              precision: 0,
            })}`}
          />
        )}
      </ActivteFiltersBar>
    </div>
  );
};

export function Sort(props: Omit<SortDropdownProps<string>, 'options'>) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:p-2 h-auto"
      {...props}
      options={SORT_OPTIONS}
    />
  );
}

export function PeriodFilterButtons(
  props: Omit<SortDropdownProps<string>, 'options'>
) {
  return (
    <div className="xxs:rounded xxs:grid xxs:grid-cols-6 xxs:divide-x xxs:divide-dex-black-700 border-tailwind xxs:border-solid xxs:border-dex-black-700 ">
      {PERIOD_FILTERS.map((period, id) => {
        return (
          <Button
            size="small"
            variant="dexNeutral"
            className={twMerge(
              'xxs:m-0 xxs:rounded-none xxs:px-2 xxs:hover:bg-dex-black-700',
              id === 0 && 'xxs:rounded-l-sm',
              id === PERIOD_FILTERS.length - 1 && 'xxs:rounded-r-sm',
              period.value === (props.value as HotTokensPeriods)
                ? 'xxs:bg-dex-black-700 xxs:text-dex-white'
                : 'xxs:bg-dex-black-800 xxs:text-dex-white-secondary hover:text-dex-white'
            )}
            key={id}
            type="button"
            onClick={() => props.onChange(period.value)}
          >
            <div className="xxs:text-xs">{period.label}</div>
          </Button>
        );
      })}
    </div>
  );
}

export function PeriodFilter(
  props: Omit<SortDropdownProps<string>, 'options'>
) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:py-2 lg:py-1.5 xxs:px-3 lg:px-2 h-auto"
      {...props}
      options={PERIOD_FILTERS}
      mainLabel="Period"
    />
  );
}

export function TokenLaunchPeriodFilterMobile(
  props: Omit<SortDropdownProps<TokenLaunchPeriods>, 'options'>,
  size?: string
) {
  return (
    <SortDropdown
      size={size}
      className="xxs:py-2 xxs:px-3 h-auto"
      {...props}
      options={TOKENS_LAUNCH_PERIOD_FILTERS}
      mainLabel="Token Age"
    />
  );
}
