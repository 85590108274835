import { Redirect } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import { Chains } from 'api/types/httpsTypes/d-wallets';

const lastVisitedAddressKey = `last-address-trading-terminal`;
export const useLastVisitedAddress = createPersistedState<string>(
  lastVisitedAddressKey
);
const lastVisitedChain = `last-chain-trading-terminal`;
export const useLastVisitedChain =
  createPersistedState<string>(lastVisitedChain);

export const defaultChain = Chains.Base;
export const defaultContract = '0xB1a03EdA10342529bBF8EB700a06C60441fEf25d';

const SnipeContainer = () => {
  const [address] = useLastVisitedAddress(defaultContract);
  const [chain] = useLastVisitedChain(defaultChain);

  return <Redirect to={`/dex/snipe/new/${chain}/${address}`} />;
};

export default SnipeContainer;
