import { FC } from 'react';
import { DepositSteps, useDepositStepper } from './DepositStepper';
import { DepositSelectAsset } from './SelectAsset';
import { StepperContextProvider } from 'modules/shared-components/stepper/StepperContext';
import { DepositContextProvider } from './Context';
import { SelectAmount } from './SelectAmount';
import { ChainGuard } from './ChainGuard';
import { DepositTxPending } from './DepositTxPending';
import {
  chainAsset,
  Chains,
  DWalletDetails,
} from 'api/types/httpsTypes/d-wallets';
import { EXTENDED_DEPOSIT_CHAINS_CONFIG } from './DepositWagmiProvider';
import CustomModal from '../../components/modal/CustomModal';

function ActiveStep() {
  const [step] = useDepositStepper();

  return (
    <>
      {step === DepositSteps.SelectChain && <DepositSelectAsset />}
      {step === DepositSteps.SelectAmount && (
        <ChainGuard>
          <SelectAmount />
        </ChainGuard>
      )}
      {step === DepositSteps.DepositTxPending && <DepositTxPending />}
    </>
  );
}

type Props = {
  handleClose: () => void;
  wallet: DWalletDetails;
  selectedChain: Chains;
};

export const DepositModal: FC<Props> = ({
  handleClose,
  wallet,
  selectedChain,
}: Props) => {
  const mappedChainId = EXTENDED_DEPOSIT_CHAINS_CONFIG.find(
    (c) => (c.key as Chains) === selectedChain
  )?.id;

  return (
    <CustomModal title="Deposit Tokens" showModal handleClose={handleClose}>
      <StepperContextProvider initialStep={DepositSteps.SelectChain}>
        <DepositContextProvider
          initialState={{
            wallet,
            assetSymbol: chainAsset(selectedChain)?.toLowerCase(),
            ...(mappedChainId ? { chainId: mappedChainId } : {}),
          }}
        >
          <ActiveStep />
        </DepositContextProvider>
      </StepperContextProvider>
    </CustomModal>
  );
};
