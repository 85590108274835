import ErrorBoundary from 'components/error-boundaries';
import { WalletMarkets, WalletRowInfo } from 'api/types/httpsTypes/contracts';
import { getMarketCapForWalletShortName } from '../labels';
import Big from 'big.js';
import Tooltip from 'modules/shared-components/tooltip';
import Button from 'modules/shared-components/button/button';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import {
  formatNumberWithSuffix,
  formatUSDAmountWithSign,
  formatWithoutDecimals,
} from 'utils/FormatNumber';
// eslint-disable-next-line import/default
import Chart from 'react-apexcharts';
import GeneralStats from './GeneralStats';
import { ApexOptions } from 'apexcharts';
import Last10Swaps from './swaps/Last10Swaps';

interface Props {
  isLoading: boolean;
  wallet: WalletRowInfo | null;
}

interface ChartProps {
  activeChart: string;
  chart: { y: number; x: string }[];
}

const ChartWalletStats = ({ activeChart, chart }: ChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    yaxis: [
      {
        labels: {
          maxWidth: 330,
          minWidth: 50,
        },
      },
    ],
    xaxis: {
      labels: {
        formatter: (value: string) => {
          return activeChart === 'realizedPnl'
            ? formatUSDAmountWithSign(
                Big(value ?? 0)
                  .div(1000)
                  .toNumber(),
                1
              ) + 'k'
            : activeChart === 'volume'
            ? '$' +
              formatWithoutDecimals(
                Big(value ?? 0)
                  .div(1000)
                  .toNumber()
              ) +
              'k'
            : formatWithoutDecimals(Big(value ?? 0).toNumber());
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {},
      },
    },
    fill: {
      colors: ['#393C43'],
    },
    tooltip: {
      y: {
        formatter: (value: number) => {
          return activeChart === 'realizedPnl'
            ? formatUSDAmountWithSign(value, 1)
            : activeChart === 'volume'
            ? '$' + formatNumberWithSuffix(value)
            : formatWithoutDecimals(value);
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return options ? (
    <Chart
      options={options}
      key={activeChart}
      series={[
        {
          name:
            activeChart === 'realizedPnl'
              ? 'Realized PnL'
              : activeChart === 'volume'
              ? 'Traded volume'
              : 'Tokens',
          data:
            chart.map((data) => {
              return {
                x: data.x,
                y: data.y,
              };
            }) || [],
        },
      ]}
      type="bar"
      className="w-full"
    />
  ) : (
    <></>
  );
};

const categories: {
  key: WalletMarkets;
  label: keyof typeof WalletMarkets;
}[] = [
  { key: WalletMarkets.OneMillion, label: 'OneMillion' },
  { key: WalletMarkets.TenMillions, label: 'TenMillions' },
  { key: WalletMarkets.HundredMillions, label: 'HundredMillions' },
  { key: WalletMarkets.OneBillion, label: 'OneBillion' },
  { key: WalletMarkets.Max, label: 'Max' },
];

const getChartData = (
  wallet: WalletRowInfo | null,
  hook: string
): { y: number; x: string }[] => {
  return wallet
    ? categories.map((category) => ({
        y: Big(
          (wallet[`${hook}${category.key}` as keyof WalletRowInfo] as string) ??
            0
        ).toNumber(),
        x: getMarketCapForWalletShortName(
          WalletMarkets[category.label] as WalletMarkets
        ),
      }))
    : [];
};

export const WalletStats = ({ wallet, isLoading }: Props) => {
  const [activeChart, setActiveChart] = useState('nTokensBought');
  const tabClass =
    'border-none xxs:font-normal xxs:text-left xxs:text-xs xxs:p-1 text-center xxs:rounded hover:bg-black-500 w-full lg:w-auto';

  return (
    <ErrorBoundary>
      <div className="space-y-2 h-full flex flex-col">
        <div className="bg-dex-black-800 xxs:p-4 rounded-md shadow-md space-y-1">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            <Tooltip text="The main wallet trading statistics">
              <div className=" font-bold text-base">30D Main Stats</div>
            </Tooltip>
          </div>
          <GeneralStats wallet={wallet} isLoading={isLoading} />
        </div>
        <div className="bg-dex-black-800 xxs:p-4 rounded-md shadow-md space-y-1">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            <Tooltip text="The main statistics distributed per token, categorized by their fully diluted market capitalization.">
              <div className=" font-bold text-base">
                30D Market Cap Distribution
              </div>
            </Tooltip>
          </div>

          <div className="flex space-x-1 xxs:pb-1 lg:pb-0">
            <Button
              onClick={() => setActiveChart('nTokensBought')}
              className={twMerge(
                tabClass,
                activeChart === 'nTokensBought'
                  ? 'bg-dex-black-600 xxs:text-dex-white '
                  : 'bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white'
              )}
            >
              <div className="xxs:text-xs normal-case">Tokens</div>
            </Button>
            <Button
              onClick={() => setActiveChart('realizedPnl')}
              className={twMerge(
                tabClass,
                activeChart === 'realizedPnl'
                  ? 'bg-dex-black-600 xxs:text-dex-white '
                  : 'bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white'
              )}
            >
              <div className="xxs:text-xs normal-case">Realized PnL</div>
            </Button>
            <Button
              onClick={() => setActiveChart('volume')}
              className={twMerge(
                tabClass,
                activeChart === 'volume'
                  ? 'bg-dex-black-600 xxs:text-dex-white '
                  : 'bg-dex-black-700 hover:bg-dex-black-600 xxs:text-dex-white-secondary hover:text-dex-white'
              )}
            >
              <div className="xxs:text-xs normal-case">Volume</div>
            </Button>
          </div>
          <ChartWalletStats
            activeChart={activeChart}
            chart={getChartData(wallet, activeChart)}
          />
        </div>
        <div className="bg-dex-black-800 xxs:p-4 rounded-md shadow-md space-y-1 flex flex-col grow">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            <Tooltip text="The last swaps executed from this wallet.">
              <div className=" font-bold text-base">Last Swaps</div>
            </Tooltip>
          </div>

          {wallet && wallet.address && (
            <Last10Swaps chain={wallet.chain} makerAddress={wallet.address} />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
