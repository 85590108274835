import {
  ContractInfo,
  ContractInfoV2,
  ContractSearchResponse,
  GetHotTokensParams,
  GetNewCallsParams,
  GetNewPoolsParams,
  GetNewPoolsResponse,
  GetSmartSwapsParams,
  GetSwapsParams,
  GetTelegramChannelDetailsParams,
  GetTelegramChannelDetailsResponse,
  GetTelegramChannelsParams,
  GetTelegramChannelsResponse,
  GetTokenMainStats,
  GetWalletDetailsParams,
  GetWalletDetailsResponse,
  GetWalletsParams,
  GetWalletsResponse,
  HotContractSearchResponse,
  HotTokensRowInfo,
  PreviousSearches,
  SmartSwapsDetails,
  SwapsDetails,
} from 'api/types/httpsTypes/contracts';
import { api } from 'helpers/api/apiCore';
import { toQueryString } from 'modules/routing/query-string';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { HotTokensPeriods } from '../../../app/dex/research/components/Wallets/usePageParams';

const SERVICE_KEY = 'contracts';

export const contractServiceKeys = {
  all: () => [SERVICE_KEY],
  getContract: (chain: Chains, address: string) => [
    SERVICE_KEY,
    chain,
    address,
  ],
  getContractInfo: (chain: Chains, address: string) => [
    SERVICE_KEY,
    chain,
    'contract-info',
    address,
  ],
  search: (q: string) => [SERVICE_KEY, 'search', q],
  getHotContracts: () => [SERVICE_KEY, 'hot-contracts'],
  getFavouriteContracts: () => [SERVICE_KEY, 'favourite-contracts'],
  previousSearches: () => [SERVICE_KEY, 'previous-searches'],
  getNewPools: (params: GetNewPoolsParams) => [
    SERVICE_KEY,
    'new-pools',
    params,
  ],
  getWallets: (params: GetWalletsParams) => [SERVICE_KEY, 'wallets', params],
  getHotTokens: (params: GetHotTokensParams) => [
    SERVICE_KEY,
    'hot-tokens',
    params,
  ],
  getMainTokenStats: (params: { chain: Chains; tokenAddress: string }) => [
    SERVICE_KEY,
    'main-stats',
    params,
  ],
  getSmartSwaps: (params: GetSmartSwapsParams) => [
    SERVICE_KEY,
    'smart-swaps',
    params,
  ],
  getSwapsDetails: (params: GetSwapsParams) => [
    SERVICE_KEY,
    'swaps-details',
    params,
  ],
  getWalletDetails: (params: Partial<GetWalletDetailsParams>) => [
    SERVICE_KEY,
    'wallet-details',
    params,
  ],
  getNewCalls: (params: GetNewCallsParams) => [
    SERVICE_KEY,
    'new-calls',
    params,
  ],
  getTelegramChannels: (params: GetTelegramChannelsParams) => [
    SERVICE_KEY,
    'telegram-channels',
    params,
  ],
  getTelegramChannelDetails: (params: GetTelegramChannelDetailsParams) => [
    SERVICE_KEY,
    'telegram-channel-details',
    params,
  ],
};

export const ContractService = {
  async getContract(chain: Chains, address: string) {
    const response = await api.get<ContractInfo>(
      `/async/api/v2/contract-research/contract-info/chain/${chain}/address/${address}`
      //`/api/v2/contract-research/contract-info/chain/${chain}/address/${address}`
    );

    return response.data;
  },
  async getContractInfo(chain: Chains, address: string) {
    const response = await api.get<ContractInfoV2>(
      `/async/api/v2/contract-research/chain/${chain}/address/${address}`
      //`/api/v2/contract-research/chain/${chain}/address/${address}`
    );

    return response.data;
  },
  async search(params: { text: string; limit: number; offset: number }) {
    const response = await api.get<ContractSearchResponse>(
      `/async/api/v2/contract-research/search?${toQueryString(params)}`
      //`/api/v2/contract-research/search?${toQueryString(params)}`
    );

    return response.data;
  },
  async previousSearches() {
    const response = await api.get<PreviousSearches>(
      `/async/api/v2/contract-research/recent-searches?limit=10`
      //`/api/v2/contract-research/recent-searches?limit=10`
    );

    return response.data.data;
  },
  async getHotContracts() {
    const response = await api.get<HotContractSearchResponse>(
      `/async/api/v2/contract-research/hot-contracts`
      //`/api/v2/contract-research/hot-contracts`
    );

    return response.data.data;
  },
  async getFavouriteContracts() {
    const response = await api.get<ContractSearchResponse>(
      `/async/api/v2/contract-research/favourites?limit=10`
      //`/api/v2/contract-research/favourites?limit=10`
    );

    return response.data.data;
  },
  async getNewPools(params: GetNewPoolsParams) {
    const response = await api.get<GetNewPoolsResponse>(
      `/async/api/v2/contract-research/new-pools?${toQueryString(params)}`
      //`/api/v2/contract-research/new-pools?${toQueryString(params)}`
    );

    return response.data;
  },
  async getWallets(params: GetWalletsParams) {
    const mappedParams = {
      sort: params.sort,
      chain: params.chain,
      period: params.period,
      ...(params.address ? { address: params.address } : {}),
      ...(params.minBalance ? { balance__ge: params.minBalance } : {}),
      ...(params.maxBalance ? { balance__le: params.maxBalance } : {}),
      ...(params.minVolume ? { volume__ge: params.minVolume } : {}),
      ...(params.maxVolume ? { volume__le: params.maxVolume } : {}),
      ...(params.minPnl ? { pnl__ge: params.minPnl } : {}),
      ...(params.maxPnl ? { pnl__le: params.maxPnl } : {}),
      ...(params.minReturns ? { realized_returns__ge: params.minReturns } : {}),
      ...(params.maxReturns ? { realized_returns__le: params.maxReturns } : {}),
      ...(params.tradingStyle ? { label: params.tradingStyle } : {}),
    };

    const response = await api.get<GetWalletsResponse>(
      `/async/api/v2/contract-research/wallets?${toQueryString(mappedParams)}`
      //`/api/v2/contract-research/wallets?${toQueryString(mappedParams)}`
    );

    return response.data;
  },

  async getHotTokens(params: GetHotTokensParams) {
    function getCreationTimestamp(hours: number): string {
      const now = new Date();
      const milliseconds = hours * 60 * 60 * 1000;
      const pastDate = new Date(now.getTime() - milliseconds);
      return pastDate.toISOString();
    }

    const period =
      params.period === HotTokensPeriods.FiveMinutes
        ? 5
        : params.period === HotTokensPeriods.OneHour
        ? 60
        : params.period === HotTokensPeriods.FifteenMinutes
        ? 15
        : params.period === HotTokensPeriods.ThirtyMinutes
        ? 30
        : params.period === HotTokensPeriods.SixHours
        ? 6 * 60
        : 1440;

    const poolCreatedAt = params.poolCreatedAt
      ? getCreationTimestamp(params.poolCreatedAt)
      : undefined;
    const mappedParams = {
      sort: params.sort,
      ...(params.address ? { address: params.address } : {}),
      ...(params.minMc ? { market_cap__ge: params.minMc } : {}),
      ...(params.maxMc ? { market_cap__le: params.maxMc } : {}),
      ...(params.minVolume ? { volume__ge: params.minVolume } : {}),
      ...(params.maxVolume ? { volume__le: params.maxVolume } : {}),
      ...(params.minLiquidity ? { liquidity__ge: params.minLiquidity } : {}),
      ...(params.maxLiquidity ? { liquidity__le: params.maxLiquidity } : {}),
      ...(poolCreatedAt ? { pool_created_at__le: poolCreatedAt } : {}),
    };

    const response = await api.get<HotTokensRowInfo[]>(
      `/async/api/${params.chain}/${period}/pools?${toQueryString(
        mappedParams
      )}`
      //`/api/${params.chain}/${period}/pools?${toQueryString(mappedParams)}`
    );

    return response.data;
  },

  async getMainTokenStats(params: { chain: Chains; tokenAddress: string }) {
    const response = await api.get<GetTokenMainStats>(
      `/async/api/${params.chain}/${params.tokenAddress}/token-stats`
      //`/api/${params.chain}/${params.tokenAddress}/token-stats`
    );

    return response.data;
  },

  async getSmartSwaps(params: GetSmartSwapsParams) {
    function getCreationTimestamp(hours: number): string {
      const now = new Date();
      const milliseconds = hours * 60 * 60 * 1000;
      const pastDate = new Date(now.getTime() - milliseconds);
      return pastDate.toISOString();
    }

    const poolCreatedAt = params.poolCreatedAt
      ? getCreationTimestamp(params.poolCreatedAt)
      : undefined;

    const mappedParams = {
      ...(params.status ? { status: params.status } : {}),
      ...(params.address ? { address: params.address } : {}),
      ...(params.walletAddress ? { walletAddress: params.walletAddress } : {}),
      ...(params.minMc ? { market_cap__ge: params.minMc } : {}),
      ...(params.maxMc ? { market_cap__le: params.maxMc } : {}),
      ...(params.minVolume ? { volume__ge: params.minVolume } : {}),
      ...(params.maxVolume ? { volume__le: params.maxVolume } : {}),
      ...(params.minLiquidity ? { liquidity__ge: params.minLiquidity } : {}),
      ...(params.maxLiquidity ? { liquidity__le: params.maxLiquidity } : {}),
      ...(poolCreatedAt ? { pool_created_at__le: poolCreatedAt } : {}),
    };

    const response = await api.get<SmartSwapsDetails[]>(
      `/async/api/${params.chain}/smart-swaps?${toQueryString(mappedParams)}`
      //`/api/${params.chain}/smart-swaps?${toQueryString(mappedParams)}`
    );

    return response.data;
  },
  async getSwapsDetails(params: GetSwapsParams) {
    const response = await api.get<SwapsDetails[]>(
      `/async/api/v2/contract-research/wallet-details/swaps?${toQueryString(
        params
      )}`
      //`/api/v2/contract-research/wallet-details/swaps?${toQueryString(params)}`
    );

    return response.data;
  },
  async getWalletDetails(params: GetWalletDetailsParams) {
    const response = await api.get<GetWalletDetailsResponse>(
      `/async/api/v2/contract-research/wallet-details?${toQueryString(params)}`
      //`/api/v2/contract-research/wallet-details?${toQueryString(params)}`
    );

    return response.data;
  },

  async updateWalletDetails(params: { address: string; chain: Chains }) {
    const response = await api.post(
      `/async/api/v2/contract-research/wallet-details-update?${toQueryString(
        params
      )}`
      //`/api/v2/contract-research/wallet-details-update?${toQueryString(params)}`
    );

    return response.data;
  },

  async getNewCalls(params: GetNewCallsParams) {
    const response = await api.get<GetNewPoolsResponse>(
      `/async/api/v2/contract-research/new-calls?${toQueryString(params)}`
      //`/api/v2/contract-research/new-calls?${toQueryString(params)}`
    );

    return response.data;
  },
  async getTelegramChannels(params: GetTelegramChannelsParams) {
    const timePeriod = params.timePeriodInDays;

    const mappedParams = {
      limit: params.limit,
      offset: params.offset,
      sort: `${params.sort}_${timePeriod}_day`,
      timePeriodInDays: timePeriod,
      ...(params.minNumberOfCalls
        ? {
            [`total_number_of_calls_${timePeriod}_day__ge`]:
              params.minNumberOfCalls,
          }
        : {}),
      ...(params.maxNumberOfCalls
        ? {
            [`total_number_of_calls_${timePeriod}_day__le`]:
              params.maxNumberOfCalls,
          }
        : {}),
      ...(params.minWinnersVsLosers
        ? { [`hit_rate_${timePeriod}_day__gt`]: params.minWinnersVsLosers }
        : {}),
      ...(params.maxWinnersVsLosers
        ? { [`hit_rate_${timePeriod}_day__le`]: params.maxWinnersVsLosers }
        : {}),
      ...(params.minRugPulls
        ? { [`rug_pull_ratio_${timePeriod}_day__gt`]: params.minRugPulls }
        : {}),
      ...(params.maxRugPulls
        ? { [`rug_pull_ratio_${timePeriod}_day__le`]: params.maxRugPulls }
        : {}),
      ...(params.search
        ? { telegram_trading_channel_name__ilike: params.search + '%' }
        : {}),
      ...(params.traderId ? { id: params.traderId } : {}),
    };

    const response = await api.get<GetTelegramChannelsResponse>(
      `/async/api/v2/contract-research/callers-performance-stats?${toQueryString(
        mappedParams
      )}`
      //`/api/v2/contract-research/callers-performance-stats?${toQueryString(mappedParams)}`
    );

    return response.data;
  },
  async getTelegramChannelDetails(params: GetTelegramChannelDetailsParams) {
    const response = await api.get<GetTelegramChannelDetailsResponse>(
      `/async/api/v2/contract-research/telegram-performance-stats-channel-details?${toQueryString(
        params
      )}`
      //`/api/v2/contract-research/telegram-performance-stats-channel-details?${toQueryString(params)}`
    );

    return response.data;
  },

  async addToFavourites(chain: Chains, address: string) {
    const response = await api.update(
      `/async/api/v2/contract-research/favourites`,
      //`/api/v2/contract-research/favourites`,
      { chain, address }
    );

    return response.data;
  },
  async removeFromFavourites(chain: Chains, address: string) {
    const response = await api.delete(
      `/async/api/v2/contract-research/favourites/chain/${chain}/address/${address}`
      //`/api/v2/contract-research/favourites/chain/${chain}/address/${address}`
    );

    return response.data;
  },
};
