import { Controller, useFormContext } from 'react-hook-form';
import { formatTokenAmount, formatUSDAmount } from 'utils/FormatNumber';
import { useQuery } from '@tanstack/react-query';
import Big from 'big.js';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';

import {
  RowLabel,
  RowLabelledInput,
} from '../../../components/RowLabelledInput';
import { PresetButton } from '../../../components/PresetButton';
import { useState } from 'react';
import Tooltip from 'modules/shared-components/tooltip';
import {
  ArrowTrendingDownIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import GasIcon from '../../../../../assets/img/icons/gas-icon.svg';
import { isNotNil } from 'modules/utils/isNotNil';
import {
  chainAsset,
  chainHasBribe,
  chainHasMemPool,
  chainPriorityGasFeeTitle,
  chainPriorityGasFeeTooltip,
  gasPrioritySuffix,
} from 'api/types/httpsTypes/d-wallets';
import { VolatilityBotFormValues } from '../schema';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';
import { DexCard } from '../../../components/cards';
import { useChainInfo } from 'api/hooks/useChainInfo';

export function BuyAmount() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<VolatilityBotFormValues>();
  const chain = watch('tradingSettings.chain');

  const [openWalletSettingsBox, setOpenWalletSettingsBox] = useState(false);
  const selectedWalletId = watch('tradingSettings.walletId');

  const { data } = useQuery({
    queryFn: () => DWalletService.getWallets(),
    queryKey: dWalletServiceKeys.getWallets(),
  });

  const errorsGas =
    errors?.tradingSettings?.maxBuyGasPriority ||
    errors?.tradingSettings?.antiMev ||
    errors?.tradingSettings?.slippage;
  const selectedWallet = data?.find((x) => x.id === selectedWalletId);
  const buyOrderSettings = watch('tradingSettings');
  const chainPrice = useChainInfo(chain)?.price ?? 0;

  return (
    <MobileExpandableCard
      mobileLabel="Buy Settings"
      renderTrigger={({ open }) => (
        <MobileExpandCardTrigger onClick={open}>
          <div className="flex justify-between items-center text-sm">
            <div className="flex space-x-1 items-center">
              {errors?.tradingSettings?.buyAmount ? (
                <div className="flex space-x-1 items-center">
                  <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-6 h-6" />
                  <div>Missing Information</div>
                </div>
              ) : (
                <>
                  <div className="xxs:text-dex-white-secondary">Buy:</div>
                  <>
                    <div className="xxs:text-dex-white">
                      {buyOrderSettings.buyAmount ? (
                        <>
                          {formatTokenAmount(buyOrderSettings.buyAmount)}{' '}
                          {chainAsset(chain)}
                        </>
                      ) : (
                        <div className="xxs:text-dex-white-secondary">--</div>
                      )}
                    </div>
                    <div className="flex space-x-1 items-center">
                      <div className="xxs:text-dex-white-secondary">|</div>
                      <div className="flex xxs:space-x-1 items-center">
                        <img className="h-4 w-4" alt="gas icon" src={GasIcon} />
                        {buyOrderSettings?.maxBuyGasPriority ? (
                          <div className="xxs:text-dex-white">
                            {buyOrderSettings?.maxBuyGasPriority}{' '}
                            {gasPrioritySuffix(chain)}
                          </div>
                        ) : (
                          <div className="xxs:text-dex-white-secondary">--</div>
                        )}
                      </div>
                      <div className="xxs:text-dex-white-secondary">|</div>
                      <div className="flex xxs:space-x-1 items-center">
                        <ArrowTrendingDownIcon className="h-4 w-4 xxs:text-dex-white-secondary" />
                        <div className="xxs:text-dex-white">
                          {isNotNil(buyOrderSettings?.slippage) ? (
                            <>{buyOrderSettings?.slippage}%</>
                          ) : (
                            <div className="xxs:text-dex-white-secondary">
                              --
                            </div>
                          )}
                        </div>
                      </div>

                      {chainHasMemPool(chain) && <div>|</div>}
                      {chainHasMemPool(chain) && (
                        <div>
                          {buyOrderSettings.antiMev ? (
                            <div className="flex space-x-1 items-center">
                              <div className="xxs:text-dex-white-secondary">
                                MEV
                              </div>
                              <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                            </div>
                          ) : (
                            <div className="flex space-x-1 items-center">
                              <div className="xxs:text-dex-white-secondary">
                                MEV
                              </div>
                              <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
        </MobileExpandCardTrigger>
      )}
    >
      <DexCard size="xs">
        <Controller
          name="tradingSettings.buyAmount"
          defaultValue={'' as any}
          control={control}
          render={({ field, fieldState: { error } }) => {
            const value =
              typeof field.value === 'number'
                ? field.value
                : Number(field.value) || 0;
            return (
              <NumberInput
                {...field}
                label="Buy Amount"
                tooltip={`The amount (in ${chainAsset(
                  chain
                )}) that will be used to execute the buy transaction.`}
                suffix={<>{chainAsset(chain)}</>}
                extraLabel={
                  chainPrice ? (
                    <>
                      ${formatUSDAmount(Big(value).mul(chainPrice)?.toString())}
                    </>
                  ) : undefined
                }
                error={error?.message}
              />
            );
          }}
        />
        <div className="flex xxs:gap-1 mb-1">
          {quickOptions.map((amount) => (
            <PresetButton
              key={amount}
              type="button"
              disabled={!selectedWallet}
              onClick={() => {
                if (selectedWallet?.chainBalance) {
                  setValue(
                    'tradingSettings.buyAmount',
                    Big(selectedWallet.chainBalance)
                      .times(amount)
                      .div(100)
                      .round(4, Big.roundDown)
                      .toNumber(),
                    {
                      shouldDirty: true,
                    }
                  );
                }
              }}
            >
              {amount}%
            </PresetButton>
          ))}
        </div>
        <div>
          <button
            type="button"
            onClick={() => setOpenWalletSettingsBox(!openWalletSettingsBox)}
            className={twMerge(
              ' m-0 p-0 font-normal normal-case justify-start w-full',
              errorsGas ? 'text-red-500' : ''
            )}
          >
            <div className="flex justify-between items-center xxs:text-dex-white-secondary hover:text-dex-white">
              <div className="flex space-x-1 items-center">
                {errorsGas && (
                  <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-4 h-4" />
                )}
                <Tooltip text="Set-up buy gas priority, slippage, and MEV protection.">
                  <div className="text-sm ">
                    Gas, Slippage
                    {chainHasMemPool(chain) && (
                      <span>, and MEV protection</span>
                    )}
                  </div>
                </Tooltip>
              </div>
              <div className="">
                {openWalletSettingsBox ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </div>
            </div>
          </button>
          {openWalletSettingsBox && (
            <>
              <RowLabelledInput
                label={
                  <RowLabel
                    label={chainPriorityGasFeeTitle(
                      watch('tradingSettings.chain')
                    )}
                    tooltip={chainPriorityGasFeeTooltip(
                      watch('tradingSettings.chain')
                    )}
                  />
                }
                className="xxs:py-1"
              >
                <Controller
                  name="tradingSettings.maxBuyGasPriority"
                  control={control}
                  defaultValue={'' as any}
                  render={({ field, fieldState: { error } }) => (
                    <NumberInput
                      containerClassName="xxs:w-[108px]"
                      suffix={<>{gasPrioritySuffix(chain)}</>}
                      value={field.value as number}
                      onChange={(event) => {
                        setValue(
                          'tradingSettings.isMaxBuyGasPriorityEdited',
                          true
                        );

                        field.onChange(event.currentTarget.value);
                      }}
                      error={error?.message}
                      name={''}
                    />
                  )}
                />
              </RowLabelledInput>

              <RowLabelledInput
                label={
                  <RowLabel
                    label="Slippage"
                    tooltip="The slippage threshold for executing the buy transaction."
                  />
                }
                className="xxs:py-1"
              >
                <Controller
                  name={'tradingSettings.slippage'}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <NumberInput
                        {...field}
                        id="tradingSettings.slippage"
                        name="tradingSettings.slippage"
                        containerClassName="xxs:w-[108px]"
                        suffix={<>%</>}
                        error={error?.message}
                      />
                    );
                  }}
                />
              </RowLabelledInput>
              {chainHasMemPool(chain) && (
                <RowLabelledInput
                  label={
                    <RowLabel
                      label="MEV Protection"
                      tooltip="When active, the buy transaction will be executed as a private transaction. This will avoid your transaction to be front-run or sandwiched."
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name="tradingSettings.antiMev"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field }) => (
                      <div className="flex items-center xxs:w-[108px] xxs:gap-2">
                        <Checkbox
                          checked={field.value as boolean}
                          onClick={field.onChange}
                          id="tradingSettings.antiMev"
                        />
                        <Checkbox.Label htmlFor="anti-mev">
                          Enabled
                        </Checkbox.Label>
                      </div>
                    )}
                  />
                </RowLabelledInput>
              )}
              {chainHasBribe(chain) && watch('tradingSettings.antiMev') && (
                <RowLabelledInput
                  label={
                    <RowLabel
                      label="Bribe Amount"
                      tooltip="Bribe is used to make transaction faster when using anti-mev."
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name="tradingSettings.bribe"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field, fieldState: { error } }) => (
                      <NumberInput
                        containerClassName="xxs:w-[108px]"
                        suffix={<>{gasPrioritySuffix(chain)}</>}
                        value={field.value as number}
                        onChange={(event) => {
                          field.onChange(event.currentTarget.value);
                        }}
                        error={error?.message}
                        name={''}
                      />
                    )}
                  />
                </RowLabelledInput>
              )}
            </>
          )}
        </div>
      </DexCard>
    </MobileExpandableCard>
  );
}

const quickOptions = [1, 3, 5, 10];
