import ErrorBoundary from 'components/error-boundaries';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  chainName,
  Chains,
  dexName,
  Protocols,
} from 'api/types/httpsTypes/d-wallets';
import {
  ChainsIcon,
  ExchangeIconStretch,
} from 'modules/shared-components/exchange/exchange-icon';
import Tooltip from 'modules/shared-components/tooltip';

type TokenIconProps = {
  className?: string;
  address: string;
  symbol?: string | undefined;
  chain: Chains | undefined;
  protocol?: Protocols | undefined;
  tokenName: string | undefined;
  size?: 'lg' | 'sm';
  hasIcon?: boolean;
};
export function TokenIconWithChain({
  address,
  symbol,
  chain,
  protocol,
  size,
  tokenName,
  hasIcon = true, // default to true
  ...rest
}: TokenIconProps & React.ComponentProps<'img'>) {
  const [imageExists, setImageExists] = useState(false);

  const checkImage = (url: string) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const baseUrl = 'https://cdnassets.mizar.com/blockchains/';
  const chainPath =
    chain === Chains.Ethereum
      ? 'ethereum'
      : chain === Chains.Base
      ? 'base'
      : chain === Chains.BinanceChain
      ? 'bsc'
      : 'solana';

  const imgUrl = `${baseUrl}${chainPath}/assets/${address}/logo.png`;

  useEffect(() => {
    if (!hasIcon) {
      setImageExists(false);
      return;
    }

    void checkImage(imgUrl).then((exists) => {
      setImageExists(Boolean(exists));
    });
  }, [address, chain, hasIcon]);

  const text = `${symbol ?? tokenName ?? '?'}${
    protocol ? ` on ${dexName(protocol)} | ` : ' | '
  }${chainName(chain ?? Chains.Ethereum)}`;

  return (
    <ErrorBoundary>
      <Tooltip text={text}>
        <div className="relative ">
          {!imageExists ? (
            <div
              className={twMerge(
                'xxs:rounded-full dark:bg-dex-black-700 xxs:border-solid xxs:border dark:border-dex-black-600 p-[3px] flex items-center justify-center text-[14px] leading-none',
                rest.className
              )}
            >
              {!symbol ? '?' : symbol?.trim()?.[0]}
            </div>
          ) : (
            <img
              {...rest}
              alt={`token logo ${address}`}
              className={twMerge(
                'xxs:rounded-full dark:bg-dex-black-700 xxs:border-solid xxs:border dark:border-dex-black-600 p-[1px] ',
                rest.className
              )}
              src={imgUrl}
            />
          )}
          {protocol && (
            <div
              className={twMerge(
                size === 'lg' ? '-top-1 -right-1' : '-top-0.5 -right-0.5',
                'absolute xxs:rounded-full xxs:flex xxs:justify-center xxs:items-center dark:bg-dex-black-600 p-[1px]'
              )}
            >
              <ExchangeIconStretch
                imgName={protocol}
                className={twMerge(size === 'lg' ? 'w-4 h-4' : 'w-3 h-3 ')}
              />
            </div>
          )}
          {chain && (
            <div
              className={twMerge(
                size === 'lg' ? '-bottom-1 -right-1' : '-bottom-0.5 -right-0.5',
                'absolute xxs:flex xxs:justify-center xxs:items-center'
              )}
            >
              <ChainsIcon
                imgName={chain}
                className={twMerge(size === 'lg' ? 'w-4 h-4' : 'w-3 h-3 ')}
              />
            </div>
          )}
        </div>
      </Tooltip>
    </ErrorBoundary>
  );
}
