import * as yup from 'yup';
import { useQueryParams } from 'modules/routing/useQueryParams';
import { SwapsStatus } from 'api/types/httpsTypes/contracts';

const PAGE_SIZE = 20;
const schema = {
  limit: yup.number().default(PAGE_SIZE).required(),
  offset: yup.number().default(0).required(),
  sort: yup.string().default('-last_swap').required(),
  search: yup.string(),
  status: yup.string().oneOf([SwapsStatus.Open, SwapsStatus.Closed]),
};

export type WalletDetailsFilterState = yup.TypeFromShape<typeof schema, any>;

export function usePageParams() {
  return useQueryParams(schema);
}

export function useWalletDetailsSearchFilters() {
  const [filtersState, setFilters] = usePageParams();

  const goToPage = (page: number) => {
    setFilters({
      ...filtersState,
      offset: (page - 1) * filtersState.limit,
    });
  };

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.sort?.includes(key)) {
      if (filtersState.sort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFilters({
      ...filtersState,
      offset: 0,
      sort: newSortKey || key,
    });
  };

  return { filtersState, setFilters, goToPage, setSort };
}
