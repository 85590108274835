import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import {
  RowLabel,
  RowLabelledCheckBox,
} from '../../../components/RowLabelledInput';
import { useState } from 'react';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { WalletCopyTradingSettings } from './WalletCopyTradingSettings';
import { TelegramBotSettingsForm } from '../CreateBot';
import {  chainHasGasLimit, chainHasMemPool, chainHasTax } from 'api/types/httpsTypes/d-wallets';
import Tooltip from 'modules/shared-components/tooltip';
import Checkbox from '../../../components/inputs/Checkbox';
import NumberInput from '../../../components/inputs/number-input';
import { DexCard } from '../../../components/cards';

export function SafetyMeasures({
  isWalletCopyTrading,
}: {
  isWalletCopyTrading: boolean;
}) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [openBoxLiquidity, setOpenBoxLiquidity] = useState(false);
  const [openBoxMarketCap, setOpenBoxMarketCap] = useState(false);
  const [openBoxTax, setOpenBoxTax] = useState(false);
  const [openBoxAutoRetry, setOpenBoxAutoRetry] = useState(false);
  const [openBoxGasLimit, setOpenBoxGasLimit] = useState(false);
  const chain = watch('tradingSettings.chain');

  const takeProfitActive = watch('tradingSettings.takeProfit')?.length > 0;
  const stopLossActive = watch('tradingSettings.stopLoss');
  return (
    <MobileExpandableCard
      mobileLabel="Security Settings"
      trigger={
        <MobileExpandCardTrigger>
          {errors?.tradingSettings?.safetyMeasures ? (
            <div className="flex space-x-1 items-center text-sm xxs:text-red-500">
              <ExclamationCircleIcon className=" xxs:w-6 h-6" />
              <div>Missing Information</div>
            </div>
          ) : (
            <div className="flex space-x-1 items-center text-sm xxs:text-dex-white-secondary">
              <div className="">Security:</div>
              <div className="flex space-x-1 items-center font-semibold">
                <div>Liq.</div>
                {watch('tradingSettings.safetyMeasures.liquidityRange') ? (
                  <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                ) : (
                  <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                )}
              </div>
              <div className="flex space-x-1 items-center font-semibold">
                <div>MC</div>
                {watch('tradingSettings.safetyMeasures.marketCapRange') ? (
                  <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                ) : (
                  <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                )}
              </div>
              {chainHasTax(chain) && (
                <div className="flex space-x-1 items-center font-semibold">
                  <div>Tax</div>
                  {watch(
                    'tradingSettings.safetyMeasures.taxProtectionEnabled'
                  ) ? (
                    <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                  ) : (
                    <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                  )}
                </div>
              )}
              <div className="flex space-x-1 items-center font-semibold">
                <div>Honey-pot</div>
                {watch('tradingSettings.safetyMeasures.honeypotProtection') ? (
                  <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                ) : (
                  <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                )}
              </div>
            </div>
          )}
        </MobileExpandCardTrigger>
      }
    >
      <div className="xxs:space-y-2 flex grow flex-col">
        <div className="hidden lg:block">
          {isWalletCopyTrading ? (
            <WalletCopyTradingSettings />
          ) : (
            <TelegramBotSettingsForm />
          )}
        </div>
        <DexCard className="grow" size="xs">
          <div className="hidden lg:block font-bold text-sm xxs:text-dex-white-secondary">
            Safety Measures
          </div>
          <fieldset className="space-y-1">
            <div>
              <RowLabelledCheckBox
                label={
                  <RowLabel
                    label="Liquidity Range"
                    isError={
                      errors?.tradingSettings?.safetyMeasures?.liquidity &&
                      watch('tradingSettings.safetyMeasures.liquidityRange') &&
                      ('min' in
                        errors.tradingSettings.safetyMeasures.liquidity ||
                        'max' in
                          errors.tradingSettings.safetyMeasures.liquidity)
                    }
                    tooltip="The minimum and maximum liquidity thresholds. Transactions will be blocked if the liquidity pool size falls outside this defined range."
                  />
                }
                openBox={openBoxLiquidity}
                setOpenBox={
                  watch('tradingSettings.safetyMeasures.liquidityRange')
                    ? () => setOpenBoxLiquidity(!openBoxLiquidity)
                    : undefined
                }
                className="xxs:py-1"
              >
                <Controller
                  name={'tradingSettings.safetyMeasures.liquidityRange'}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={
                          field.value === undefined
                            ? false
                            : (field.value as boolean)
                        }
                        onClick={field.onChange}
                        onChange={() => {
                          if (!field.value) {
                            setValue(
                              'tradingSettings.safetyMeasures.liquidity.min',
                              10000
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.liquidity.max',
                              250000
                            );
                            setOpenBoxLiquidity(true);
                          } else {
                            setValue(
                              'tradingSettings.safetyMeasures.liquidity.min',
                              0
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.liquidity.max',
                              Number.MAX_SAFE_INTEGER
                            );
                            setOpenBoxLiquidity(false);
                          }
                        }}
                        id="tradingSettings.safetyMeasures.liquidityRange"
                      />
                    );
                  }}
                />
              </RowLabelledCheckBox>

              {openBoxLiquidity && (
                <div className="flex xxs:space-x-2 items-center">
                  <Controller
                    name="tradingSettings.safetyMeasures.liquidity.min"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          label="Min"
                          inputSize="small"
                          prefix="$"
                          error={error?.message}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="tradingSettings.safetyMeasures.liquidity.max"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          label="Max"
                          inputSize="small"
                          prefix="$"
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </div>

            <div>
              <RowLabelledCheckBox
                label={
                  <RowLabel
                    label="Market Cap Range"
                    isError={
                      errors?.tradingSettings?.safetyMeasures?.marketCap &&
                      watch('tradingSettings.safetyMeasures.marketCapRange') &&
                      ('min' in
                        errors.tradingSettings.safetyMeasures.marketCap ||
                        'max' in
                          errors.tradingSettings.safetyMeasures.marketCap)
                    }
                    tooltip="The minimum and maximum fully diluted Market Cap (MC) thresholds. Transactions will be blocked if the MC size falls outside this defined range."
                  />
                }
                openBox={openBoxMarketCap}
                setOpenBox={
                  watch('tradingSettings.safetyMeasures.marketCapRange')
                    ? () => setOpenBoxMarketCap(!openBoxMarketCap)
                    : undefined
                }
                className="xxs:py-1"
              >
                <Controller
                  name={'tradingSettings.safetyMeasures.marketCapRange'}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={field.value}
                        onClick={field.onChange}
                        onChange={() => {
                          if (!field.value) {
                            setValue(
                              'tradingSettings.safetyMeasures.marketCap.min',
                              25000
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.marketCap.max',
                              1000000
                            );
                            setOpenBoxMarketCap(true);
                          } else {
                            setOpenBoxMarketCap(false);
                            setValue(
                              'tradingSettings.safetyMeasures.marketCap.min',
                              0
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.marketCap.max',
                              Number.MAX_SAFE_INTEGER
                            );
                          }
                        }}
                        id="tradingSettings.safetyMeasures.marketCapRange"
                      />
                    );
                  }}
                />
              </RowLabelledCheckBox>

              {openBoxMarketCap && (
                <div className="flex xxs:space-x-2 items-center">
                  <Controller
                    name="tradingSettings.safetyMeasures.marketCap.min"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          label="Min"
                          inputSize="small"
                          prefix="$"
                          error={error?.message}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="tradingSettings.safetyMeasures.marketCap.max"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          label="Max"
                          inputSize="small"
                          prefix="$"
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </div>

            {chainHasTax(chain) && (
              <div>
                <RowLabelledCheckBox
                  label={
                    <RowLabel
                      label="Tax Protection"
                      isError={
                        errors?.tradingSettings?.safetyMeasures
                          ?.taxProtection &&
                        watch(
                          'tradingSettings.safetyMeasures.taxProtectionEnabled'
                        ) &&
                        ('buy' in
                          errors.tradingSettings.safetyMeasures.taxProtection ||
                          'sell' in
                            errors.tradingSettings.safetyMeasures.taxProtection)
                      }
                      tooltip="Should a token's buy or sell tax exceed your limit, the bot blocks the snipe."
                    />
                  }
                  openBox={openBoxTax}
                  setOpenBox={
                    watch('tradingSettings.safetyMeasures.taxProtectionEnabled')
                      ? () => setOpenBoxTax(!openBoxTax)
                      : undefined
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name={'tradingSettings.safetyMeasures.taxProtectionEnabled'}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value}
                          onClick={field.onChange}
                          onChange={() => {
                            if (!field.value) {
                              setValue(
                                'tradingSettings.safetyMeasures.taxProtection.buy',
                                15
                              );
                              setValue(
                                'tradingSettings.safetyMeasures.taxProtection.sell',
                                15
                              );
                              setOpenBoxTax(true);
                            } else {
                              setOpenBoxTax(false);
                              setValue(
                                'tradingSettings.safetyMeasures.taxProtection.buy',
                                100
                              );
                              setValue(
                                'tradingSettings.safetyMeasures.taxProtection.sell',
                                100
                              );
                            }
                          }}
                          id="tradingSettings.safetyMeasures.taxProtectionEnabled"
                        />
                      );
                    }}
                  />
                </RowLabelledCheckBox>

                {openBoxTax && (
                  <div className="flex xxs:space-x-2 items-center">
                    <Controller
                      name="tradingSettings.safetyMeasures.taxProtection.buy"
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            {...field}
                            label="Max Buy Tax"
                            inputSize="small"
                            suffix="%"
                            error={error?.message}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="tradingSettings.safetyMeasures.taxProtection.sell"
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            {...field}
                            label="Max Sell Tax"
                            inputSize="small"
                            suffix="%"
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {chainHasGasLimit(chain) && (
              <div>
                <RowLabelledCheckBox
                  label={
                    <RowLabel
                      label="Gas Limit"
                      isError={
                        !!errors?.tradingSettings?.safetyMeasures?.gasLimit
                      }
                      tooltip="The maximum amount of gas that can be paid into a single buy or sell transaction."
                    />
                  }
                  openBox={openBoxGasLimit}
                  setOpenBox={
                    watch('tradingSettings.safetyMeasures.gasLimitEnabled')
                      ? () => setOpenBoxGasLimit(!openBoxGasLimit)
                      : undefined
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name={'tradingSettings.safetyMeasures.gasLimitEnabled'}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Checkbox
                          checked={field.value}
                          onClick={field.onChange}
                          onChange={() => {
                            if (!field.value) {
                              setValue(
                                'tradingSettings.safetyMeasures.gasLimit',
                                300000
                              );
                              setOpenBoxGasLimit(true);
                            } else {
                              setOpenBoxGasLimit(false);
                              setValue(
                                'tradingSettings.safetyMeasures.gasLimit',
                                null as any
                              );
                            }
                          }}
                          id="tradingSettings.safetyMeasures.taxProtectionEnabled"
                        />
                      );
                    }}
                  />
                </RowLabelledCheckBox>
                {openBoxGasLimit && (
                  <div>
                    <Controller
                      name="tradingSettings.safetyMeasures.gasLimit"
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            {...field}
                            label="Gas Limit"
                            value={field.value as unknown as number}
                            inputSize="small"
                            suffix="wei"
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            <RowLabelledCheckBox
              label={
                <RowLabel
                  label="Anti-honeypot Protection"
                  tooltip="If the token you're sniping is a honeypot, then the transaction will be blocked and your funds protected."
                />
              }
              className="xxs:py-1"
            >
              <Controller
                name="tradingSettings.safetyMeasures.honeypotProtection"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    id="anti-honeypot"
                  />
                )}
              />
            </RowLabelledCheckBox>
            <div>
              <RowLabelledCheckBox
                label={
                  <RowLabel
                    label="Auto-retry"
                    tooltip="Enable automatic retries for buy limit orders, take profit, and stop loss transactions. If any of these transactions fail, the bot will automatically attempt to execute the transaction again, retrying once."
                  />
                }
                openBox={openBoxAutoRetry}
                setOpenBox={
                  watch('tradingSettings.safetyMeasures.autoRetryEnabled')
                    ? () => setOpenBoxAutoRetry(!openBoxAutoRetry)
                    : undefined
                }
                className="xxs:py-1"
              >
                <Controller
                  name={'tradingSettings.safetyMeasures.autoRetryEnabled'}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={
                          field.value === undefined
                            ? false
                            : (field.value as boolean)
                        }
                        onClick={field.onChange}
                        onChange={() => {
                          if (!field.value) {
                            setOpenBoxAutoRetry(true);
                            setValue(
                              'tradingSettings.safetyMeasures.autoRetry.takeProfit',
                              takeProfitActive
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.autoRetry.stopLoss',
                              !!stopLossActive
                            );
                          } else {
                            setValue(
                              'tradingSettings.safetyMeasures.autoRetry.takeProfit',
                              false
                            );
                            setValue(
                              'tradingSettings.safetyMeasures.autoRetry.stopLoss',
                              false
                            );
                            setOpenBoxAutoRetry(false);
                          }
                        }}
                        id="tradingSettings.safetyMeasures.autoRetryEnabled"
                      />
                    );
                  }}
                />
              </RowLabelledCheckBox>
              {openBoxAutoRetry && (
                <div className="flex flex-col space-y-1">
                  <Controller
                    name="tradingSettings.safetyMeasures.autoRetry.takeProfit"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className="flex space-x-1 items-center justify-end">
                          <Checkbox
                            className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                            disabled={!takeProfitActive}
                            checked={
                              field.value === undefined
                                ? false
                                : (field.value as boolean)
                            }
                            onClick={field.onChange}
                            id="botSettings.entries.autoRetry.takeProfit"
                          />
                          <Tooltip text="Enable this option to automatically retry the take profit transaction one more time if the swap fails.">
                            <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                              Take Profit Order
                            </div>
                          </Tooltip>
                        </div>
                      );
                    }}
                  />
                  <Controller
                    name="tradingSettings.safetyMeasures.autoRetry.stopLoss"
                    defaultValue={'' as any}
                    control={control}
                    render={({ field }) => {
                      return (
                        <div className="flex space-x-1 items-center justify-end">
                          <Checkbox
                            className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                            disabled={!stopLossActive}
                            checked={
                              field.value === undefined
                                ? false
                                : (field.value as boolean)
                            }
                            onClick={field.onChange}
                            id="botSettings.entries.autoRetry.stopLoss"
                          />
                          <Tooltip text="Enable this option to automatically retry the stop loss transaction one more time if the swap fails.">
                            <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                              Stop Loss Order
                            </div>
                          </Tooltip>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>

            {chainHasMemPool(chain) && (
              <RowLabelledCheckBox
                label={
                  <RowLabel
                    label="Anti-rug Protection"
                    tooltip="The anti-rug protection is applied to all your trades and trades by default. The anti-rug engine scans the mem-pool and monitor all the pending transactions. If any malicious pending transaction is detected, then the anti-rug mechanism is triggered. Read the docs for more info."
                  />
                }
                className="xxs:py-1"
              >
                <Checkbox disabled id="anti-rug" checked />
              </RowLabelledCheckBox>
            )}
          </fieldset>
        </DexCard>
      </div>
    </MobileExpandableCard>
  );
}
