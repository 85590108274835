import { useContext, useEffect, useState, useMemo } from 'react';
import { WebSocketContext } from '../services/websocket/websocketProvider';

interface Subscription {
  channel: string;
  data: any;
}

export const useWebsocket = (subscription: Subscription) => {
  const wsContext = useContext(WebSocketContext);
  const [message, setMessage] = useState<any>(null);

  const isValidSubscription = useMemo(() => {
    return (
      subscription?.channel &&
      subscription?.data &&
      Object.keys(subscription.data).length > 0
    );
  }, [subscription]);

  const stableSubscription = useMemo(
    () => subscription,
    [subscription.channel, JSON.stringify(subscription.data)]
  );

  useEffect(() => {
    // ✅ Don't subscribe if WebSocket is not connected
    if (!wsContext?.isConnected) {
      console.warn(
        '⚠️ WebSocket is not connected. Subscription delayed:',
        stableSubscription
      );
      return;
    }

    if (!isValidSubscription) {
      console.warn(
        '⚠️ useWebSocket: Invalid subscription. Skipping subscription.'
      );
      return;
    }

    wsContext.subscribe(stableSubscription);

    return () => {
      wsContext.unsubscribe(stableSubscription);
    };
  }, [stableSubscription, isValidSubscription, wsContext?.isConnected]);

  useEffect(() => {
    if (!wsContext || !isValidSubscription || !wsContext.isConnected) return;

    const handleMessage = (msg: any) => {
      if (msg.channel === stableSubscription.channel) {
        setMessage(msg);
      }
    };

    wsContext.addMessageListener(stableSubscription.channel, handleMessage);

    return () => {
      wsContext.removeMessageListener(
        stableSubscription.channel,
        handleMessage
      );
    };
  }, [
    wsContext,
    stableSubscription.channel,
    isValidSubscription,
    wsContext?.isConnected,
  ]);

  return useMemo(() => message, [message]);
};
