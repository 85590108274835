import { desktopColumns, mobileColumns, SwapsModalProps } from '../columns';
import { GetSwapsParams } from 'api/types/httpsTypes/contracts';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { EmptyPageDex, ErrorPageDex } from '../../../shared/Common';
import ErrorBoundary from 'components/error-boundaries';
import { SwapsDetailsTable } from './SwapsDetailsTable';
import CustomModal from '../../../../../components/modal/CustomModal';

const SwapsModal = ({
  fromDatetime,
  tradedTokenId,
  makerAddress,
  handleClose,
  chain,
}: SwapsModalProps & { handleClose: () => void }) => {
  const isLargeScreen = useMediaQuery(screenGte.large);

  const params: GetSwapsParams = {
    fromDatetime,
    tradedTokenId,
    makerAddress,
    chain,
  };
  const columns = isLargeScreen ? desktopColumns : mobileColumns;
  const { data, error, isLoading } = useQuery({
    queryKey: contractServiceKeys.getSwapsDetails(params),
    queryFn: () => ContractService.getSwapsDetails(params),
    keepPreviousData: true,
  });

  return (
    <CustomModal
      title="Swaps Details"
      size="xLarge"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex xxs:text-lg">
          Swaps Details
        </CustomModal.Title>
        <CustomModal.Content>
          <ErrorBoundary>
            <div className="space-y-1">
              {isLoading && (
                <div className="flex">
                  <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
                    <Header
                      trClassName="xxs:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
                      columns={columns}
                      className="xxs:dark:bg-dex-black-800"
                    />
                    <tbody>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
                        <Table.LoadingRow
                          className=" xxs:dark:bg-dex-black-800"
                          elementClassName="xxs:dark:bg-dex-black-700"
                          columns={columns}
                          key={key}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {error && <ErrorPageDex />}
              {data?.length === 0 && <EmptyPageDex title="No Swaps Found" />}
              {data && data?.length !== 0 && !error && (
                <SwapsDetailsTable data={data} />
              )}
            </div>
          </ErrorBoundary>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
};

export default SwapsModal;
