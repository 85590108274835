import { FC } from 'react';
import {
  chainAsset,
  chainName,
  Chains,
  DWalletDetails,
} from 'api/types/httpsTypes/d-wallets';
import CustomModal from '../../components/modal/CustomModal';
import { SortDropdown } from '../../components/dropdown/sort-dropdown';
import { AssetIconNew } from 'modules/shared-components/asset/asset-icon';
import TextInput from '../../components/inputs/text-input/text-input';
import { CopyContent } from 'modules/shared-components/tooltip/copy-content';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { NotificationDex } from '../../components/alerts/notification';

type Props = {
  handleClose: () => void;
  wallet: DWalletDetails;
};

export const DepositModalSolana: FC<Props> = ({
  handleClose,
  wallet,
}: Props) => {
  return (
    <CustomModal title="Deposit SOL" showModal handleClose={handleClose}>
      <>
        <CustomModal.Body>
          <CustomModal.Title className="hidden lg:flex">
            Deposit SOL
          </CustomModal.Title>

          <CustomModal.Content className="min-h-[300px]">
            <div className="mb-2 ">
              Send SOL to your Solana wallet and start to trade.
            </div>
            <div className="flex flex-col xxs:gap-4">
              <SortDropdown
                mainLabel="Chain"
                options={[
                  {
                    value: chainAsset(Chains.Solana).toLowerCase(),
                    label: chainName(Chains.Solana),
                    icon: (
                      <AssetIconNew imgName={Chains.Solana.toLowerCase()} />
                    ),
                  },
                ]}
                disabled={true}
                value={'sol'}
                onChange={() => {}}
              />

              <SortDropdown
                mainLabel="Asset"
                options={[
                  {
                    value: chainAsset(Chains.Solana).toLowerCase(),
                    label: chainAsset(Chains.Solana),
                    icon: (
                      <AssetIconNew imgName={Chains.Solana.toLowerCase()} />
                    ),
                  },
                ]}
                disabled={true}
                value={'sol'}
                onChange={() => {}}
              />

              <TextInput
                name="walletAddress"
                label="Wallet Address"
                value={wallet?.address || ''}
                readOnly
                suffix={
                  <CopyContent text={wallet?.address}>
                    <IconButton disabled={!wallet?.address}>
                      <DocumentDuplicateIcon width={24} height={24} />
                    </IconButton>
                  </CopyContent>
                }
              />
            </div>

            <NotificationDex type="warning" className="xxs:mt-2 xxs:mx-0">
              Make sure you send your SOL from a Solana wallet.
            </NotificationDex>
          </CustomModal.Content>
        </CustomModal.Body>
      </>
    </CustomModal>
  );
};
