import { FC, Suspense } from 'react';
import { MetaInfo } from '../../MetaInfo';
import { useTheme } from 'modules/media/theme';
import SnackBarContextProvider from './SnackBar/context';
import SnackBar from './SnackBar';
import TopNavbar from './TopBar';
import Footer from './Footer';
import classNames from 'classnames';
import config from 'config';
import { twMerge } from 'tailwind-merge';
import { useConnectionStatus } from 'modules/routing/ConnectionStatusProvider';
import { NotificationDex } from '../../app/dex/components/alerts/notification';

const VerticalLayout: FC<{}> = ({ children }) => {
  const loading = () => <div className="" />;
  const theme = useTheme();
  const { isOnline } = useConnectionStatus();

  return (
    <SnackBarContextProvider>
      <SnackBar />
      <MetaInfo />
      <div
        className="tailwind"
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={classNames(theme, 'xxs:flex-grow')}>
          {!config.isTelegramBrowser && <TopNavbar />}

          <div
            className={twMerge(
              'custom-scrollbar',
              'relative lg:pb-4 xxs:px-0 sm:px-4 xl:px-4 mx-auto xxs:flex-grow',
              config.isTelegramBrowser
                ? 'xxs:pt-0'
                : 'xxs:pt-[64px] xxs:pb-[64px] lg:pt-[74px] '
            )}
          >
            {!isOnline && (
              <NotificationDex type="error">You're offline...</NotificationDex>
            )}
            <Suspense fallback={loading()}>{children}</Suspense>
          </div>
        </div>
        <div className="wrapper xxs:hidden lg:block">
          <Footer />
        </div>
      </div>
    </SnackBarContextProvider>
  );
};
export default VerticalLayout;
