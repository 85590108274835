import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useToggle } from 'modules/utils/useToggle';
import { ChannelsModal } from './ChannelsModal';
import { duration } from 'moment';
import {
  TelegramChannelsKeys,
  TelegramChannelsService,
} from 'api/services/httpServices/TelegramChannelsService';
import { useQuery } from '@tanstack/react-query';
import { FilterTag } from 'modules/shared-components/filters';
import Tooltip from 'modules/shared-components/tooltip';
import { CooldownPeriod } from './CooldownPeriod';
import { CallsFilter } from './CallsFilter';
import { twMerge } from 'tailwind-merge';

export function ChannelsSection() {
  const [isOpen, { open, close }] = useToggle();
  const { control } = useFormContext<FormValues>();
  const { data: channelsList } = useQuery({
    queryFn: () => TelegramChannelsService.getAllChannels(),
    queryKey: TelegramChannelsKeys.getAllChannels(),
    staleTime: duration('1', 'hour').asMilliseconds(),
    cacheTime: duration('1', 'hour').asMilliseconds(),
  });

  return (
    <>
      <div className="space-y-1">
        <div className="flex">
          <Tooltip text="Select traders for copy-trading and automatically trade a token when it's called.">
            <div className="xxs:text-dex-white-secondary hover:text-dex-white xxs:text-sm">
              Traders
            </div>
          </Tooltip>
        </div>

        <Controller
          control={control}
          name="botSettings.entries.channels"
          render={({
            field: { value, onChange, ref, onBlur },
            fieldState: { error },
          }) => {
            return (
              <>
                <div
                  ref={ref}
                  className={twMerge(
                    'text-white-900 dark:text-black-50 xxs:text-base xxs:border xxs:border-solid  xxs:rounded-md xxs:px-3 xxs:py-2 xxs:flex xxs:gap-1 flex-wrap',
                    error
                      ? 'xxs:border-red-500'
                      : 'xxs:border-dex-black-700 xxs:bg-dex-black-800'
                  )}
                >
                  {value?.length ? (
                    <span>{value.length} selected | </span>
                  ) : (
                    <span className="mr-1">Select traders </span>
                  )}

                  {value?.map((channelId) => (
                    <ChannelFilterTag
                      name={
                        channelsList?.data.find(
                          (channel) => channel.channelId === channelId
                        )?.channelName || ''
                      }
                      key={channelId}
                      onRemove={() =>
                        onChange(value.filter((id) => id !== channelId))
                      }
                    />
                  ))}
                  <IconButton
                    className="xxs:bg-dex-black-700 hover:bg-dex-black-600 rounded xxs:p-1"
                    onClick={() => open()}
                  >
                    <PlusIcon className="w-4 h-4 " />
                  </IconButton>
                </div>
                {error && (
                  <div className="flex space-x-1 items-center">
                    <ExclamationCircleIcon className="xxs:text-red-500 w-4 h-4" />
                    <p className="text-red-500 mb-0">{error.message}</p>
                  </div>
                )}
                {isOpen && (
                  <ChannelsModal
                    value={value || []}
                    onChange={(val) => {
                      onChange(val);
                    }}
                    close={() => {
                      close();
                      onBlur();
                    }}
                  />
                )}
              </>
            );
          }}
        />
      </div>
      <div className="lg:hidden space-y-2">
        <div className="flex space-x-1 items-center">
          <div className="xxs:text-sm xxs:text-dex-white-secondary">
            Advanced Settings
          </div>
        </div>
        <div className="flex flex-col xxs:gap-1">
          <CooldownPeriod />
          <CallsFilter />
        </div>
      </div>
    </>
  );
}

export function ChannelFilterTag({
  name,
  onRemove,
}: {
  name: string;
  onRemove: () => void;
}) {
  return (
    <FilterTag
      label={name}
      onRemove={onRemove}
      className="xxs:text-dex-white-secondary hover:text-dex-white xxs:mr-0 dark:bg-dex-black-700 hover:bg-dex-black-600"
    />
  );
}
