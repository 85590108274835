import { PaginatedList } from './common';
import { Chains, Protocols } from 'api/types/httpsTypes/d-wallets';

export enum LimitOrderSide {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum LimitOrderOperator {
  LessThan = 'LESSER_EQUAL',
  GreaterThan = 'GREATER_EQUAL',
}

export type CreateTemplate = Omit<
  DexTradeTemplate,
  'id' | 'createdAt' | 'updatedAt'
>;
export type UpdateTemplate = CreateTemplate;

export type GetAllTemplatesResponse = PaginatedList<DexTradeTemplate>;

export interface DexTradeTemplate {
  id: string;
  name: string;
  isDefault: boolean;
  chain: Chains;
  position: TemplateSettings;
  isMizarTemplate?: boolean;
  createdAt?: string;
  updatedAt?: string;
  wallets: string[] | [];
}

export type TemplateSettings = {
  buyAmountInWei: string | null;
  bribe?: string;
  backupBribe?: string;
  blockOneRetry?: boolean;
  antiMev?: boolean;
  gasLimit?: number;
  limitOrders?: LimitOrdersTemplateSettings[];
  maxSlippage: string;
  maxPriorityFeePerGasWei: string;
  minLiquidityInUsd: number;
  maxLiquidityInUsd: number;
  minMarketcapInUsd: number;
  maxMarketcapInUsd: number;
  maxBuyTax: number;
  maxSellTax: number;
  honeypotProtection: boolean;
  chain: Chains;
  takeProfits: TakeProfitTemplateSettings[];
  trailingStopLoss: StopLossTemplateSettings | null;
};

export type StopLossTemplateSettings = {
  initialStopLossPercentage: string;
  amountToSellPercentage: string;
  trailingDeviationPercentage: string | null;
  maxPriorityFeePerGasWei: string;
  maxSlippage: string;
  antiMev: boolean;
  retry: boolean;
};
export type TakeProfitTemplateSettings = {
  priceIncreasePercentage: string;
  amountToSellPercentage: string;
  maxPriorityFeePerGasWei: string;
  maxSlippage: string;
  antiMev: boolean;
  retry: boolean;
};
export type LimitOrdersTemplateSettings = {
  priceDeviationPercentage: number;
  amount: number;
  retry: boolean;
  antiMev: boolean;
  maxPriorityFeePerGasWei: string;
  bribe?: string;
  maxSlippage: string;
  expirationInHours: number;
  side: LimitOrderSide;
  comparisonOperator: LimitOrderOperator;
};

export interface CancelLimitOrderRequest {
  subscriptionId: number;
  symbolId: number;
  positionId: string;
  orderId: string;
}

export interface CreateDexTradeRequest {
  tokenAddress: string;
  antiMev?: boolean;
  blockOneRetry?: boolean;
  bribe?: string;
  backupBribe?: string;
  vaultId: string;
  buyAmountInWei: string;
  maxSlippage?: string;
  maxPriorityFeePerGasWei?: string;
  minLiquidityInUsd: number;
  maxLiquidityInUsd: number;
  minMarketcapInUsd: number;
  maxMarketcapInUsd: number;
  maxBuyTax: number;
  maxSellTax: number;
  honeypotProtection: boolean;
  chain: string;
  takeProfits?: TakeProfitInput[] | [];
  limitOrders?: LimitOrdersInput[] | [];
  trailingStopLoss?: StopLossInput | null;
  isPostLaunchSnipe: boolean;
}

export type StopLossInput = {
  id?: string;
  retry: boolean;
  initialStopLossPercentage: string;
  amountToSellPercentage: string;
  trailingDeviationPercentage: string;
  maxPriorityFeePerGasWei: string;
  maxSlippage: string;
  antiMev: boolean;
};

export type TakeProfitInput = {
  id?: string;
  priceIncreasePercentage: string;
  amountToSellPercentage: string;
  maxPriorityFeePerGasWei: string;
  maxSlippage: string;
  retry: boolean;
  antiMev: boolean;
};
export type LimitOrdersInput = {
  id?: string;
  priceDeviationPercentage: number;
  price?: number;
  amount: number;
  retry: boolean;
  antiMev: boolean;
  maxPriorityFeePerGasWei: string;
  maxSlippage: string;
  expirationInHours: number;
  side: LimitOrderSide.Buy | LimitOrderSide.Sell;
  comparisonOperator:
    | LimitOrderOperator.LessThan
    | LimitOrderOperator.GreaterThan;
};
export type EditDexTradeRequest = CreateDexTradeRequest;

export type GetDexOpenPositionsParams = {
  limit?: number;
  offset?: number;
  status?: DexTradeStatus[];
  chain?: Chains;
  tokenAddress?: string;
  vaultId?: string[];
  sort?: string;
  id?: string;
  botId?: string;
};
export type ClosePositionParams = {
  maxSlippage: string;
  maxPriorityFeePerGasWei: string;
};
export type ManualSwapParams = {
  side: SwapSide;
  type: 'market' | 'limit';
  price?: string;
  expiration?: number;
  amount: string;
  maxSlippage: number;
  maxPriorityFeePerGasWei: number;
  antiMev?: boolean;
};

export type GetDexOpenPositionsResponse = PaginatedList<OpenPositionListItem>;
export type GetDexOpenPositionResponse = OpenPositionListItem;

export type OpenPositionListItem = {
  vaultId: string;
  hasIcon: boolean;
  chain: Chains;
  bribe: string;
  copyTradingBotId: string | undefined;
  volatilityBotId: string | undefined;
  effectiveBribeInEth: string;
  priceImpact: number | null;
  sellTax: number | null;
  blockOneRetry: boolean;
  backupBribe: string;
  tokenAddress: string;
  buyAmountInWei: string;
  antiMev?: boolean;
  maxSlippage: string;
  gasLimit?: number;
  maxPriorityFeePerGasWei: string;
  minLiquidityInUsd: string;
  maxLiquidityInUsd: string;
  minMarketcapInUsd: string;
  maxMarketcapInUsd: string;
  maxBuyTax: string;
  maxSellTax: string;
  honeypotProtection: boolean;
  id: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  status: DexTradeStatus;
  transactions: DexTransaction[];
  vaultAddress: string;
  tokenSymbol: string;
  tokenName: string;
  // Sniped
  poolAddress: string | null;
  protocol: {
    id: string;
    chain: Chains;
    protocol: Protocols;
    name: string;
  } | null;
  quoteAsset: {
    id: string;
    address: string | null;
    chain: string;
    name: string;
    symbol: string;
    decimals: number;
  } | null;
  snipeEventId: string | null;
  snipeEvent: SnipeEvent | null;
  totalPaidGasFeesInEth: number;
  // currentValueInEth: string | null;
  // currentValueInUsd: string | null;
  // currentTokenUnitPriceInUsd?: string;
  currentTokenPriceInUsd: string | null;
  averageBuyPriceInUsd: string | null;
  averageSellPriceInUsd: string | null;
  // profitAndLoss?: ProfitAndLoss;
  // holdingTime?: HoldingTime;
  unrealisedProfitAndLoss: ProfitAndLoss | null;
  netUnrealisedProfitAndLoss: ProfitAndLoss | null;
  realisedProfitAndLoss: ProfitAndLoss | null;
  totalProfitAndLoss: ProfitAndLoss | null;
  holdingTimeSeconds: string | null;
  takeProfits: TakeProfitEntry[];
  limitOrders: LimitOrder[];
  isPostLaunchSnipe: boolean;
  telegramSniperBotId: string | null;
  telegramSnipingCallEventId: string | null;
  telegramSnipingCallEvent: SnipeCallEvent | null;
  trailingStopLoss: StopLossEntry | null;
  totalBuyAmountInEth: string | null;
  totalSellAmountInEth: string | null;
  totalAmountTokenBought: string | null;
  totalAmountTokenSold: string | null;
  totalAmountTokenRemaining: string | null;
  totalAmountTokenRemainingInWei: string | null;
  tokenDecimals: number;
};

export type LimitOrder = {
  priceDeviationPercentage: string;
  remainingBeforeExpirationInSeconds: string | undefined;
  retry: boolean;
  amount: string;
  slippage?: number;
  amountOut: string | null;
  amountIn: string | null;
  maxPriorityFeePerGasWei: string;
  bribe: string;
  maxSlippage: string;
  antiMev: boolean;
  expirationInHours: string;
  side: LimitOrderSide;
  comparisonOperator: LimitOrderOperator;
  id: string;
  price: string;
  priceInUsd: number | null;
  marketCap: number | null;
  positionId: string;
  createdAt: string;
  updatedAt: string;
  status: LimitOrderStatus;
  transactionId: string | null;
};

export type SnipeCallEvent = {
  chain: string;
  contractTelegramLink: string;
  createdAt: string;
  id: string;
  isPostLaunchSnipe: boolean;
  messageDate: string;
  messageId: number;
  poolAddress: string;
  protocol: string;
  telegramTradingChannelId: string;
  telegramTradingChannelName: string;
  telegramTradingChannelPostLink: string;
  tokenAddress: string;
  updatedAt: string;
  trailingStopLoss: StopLossEntry | null;
};

export type StopLossEntry = {
  id: string;
  amountOut: string | null;
  amountIn: string | null;
  antiMev: boolean;
  retry: boolean;
  initialStopLossPercentage: string;
  amountToSellPercentage: string;
  stopLossTriggerPrice: string;
  stopLossTriggerDeviation: string;
  trailingDeviationPercentage: string;
  status: StopLossStatus;
  maxPriorityFeePerGasWei: string;
  bribe: string;
  maxSlippage: string;
  createdAt: string;
  updatedAt: string;
  priceInUsd: number | null;
  marketCap: number | null;
  transactionId: string | null;
};

export type TakeProfitEntry = {
  id: string;
  priceInUsd: number | null;
  marketCap: number | null;
  amountOut: string | null;
  amountIn: string | null;
  retry: boolean;
  priceIncreasePercentage: string;
  amountToSellPercentage: string;
  maxPriorityFeePerGasWei: string;
  bribe: string;
  maxSlippage: string;
  createdAt: string;
  updatedAt: string;
  antiMev: boolean;
  status: TakeProfitStatus;
  transactionId: string | null;
};

export type TakeProfitStatus = 'ACTIVE' | 'TRIGGERED' | 'CANCELLED' | 'DELETED';
export type LimitOrderStatus =
  | 'ACTIVE'
  | 'TRIGGERED'
  | 'CANCELLED'
  | 'DELETED'
  | 'CANCELLED_BY_RUG_PULL'
  | 'EXPIRED';
export type StopLossStatus = 'ACTIVE' | 'TRIGGERED' | 'CANCELLED' | 'DELETED';

type SwapSide = 'BUY' | 'SELL';
export type DexTransaction = {
  id: string;
  positionId: string;
  userActionId: null | string;
  userId: number;
  vaultId: string;
  vaultAddress: string;
  clientTransactionId: string;
  status: DexOrderStatus;
  side: SwapSide;
  chain: Chains;
  chainId: number;
  protocol: string;
  type: string;
  amountIn: string;
  amountInTokenAddress: string;
  amountInTokenDecimals: number;
  amountOutMin: string;
  amountOutMax: string;
  amountOutTokenAddress: string;
  amountOutTokenDecimals: number;
  poolAddress: string;
  tokenTaxRate: string;
  tokenTotalSupply: string;
  tokenDecimals: number;
  createdAt: string;
  updatedAt: string;
  transactionHash: string | null;
  transactionSentAt: string | null;
  transactionSentAtBlockNo: number | null;
  transactionSentWithNonce: null | number;
  blockHash: null | string;
  blockNumber: number | null;
  blockDatetime: string | null;
  numBlockConfirmations: number;
  gasUsed: number | null;
  effectiveGasPrice: number | null;
  failureReason: null | string;
  priceEthInUsd: string | null;
  amountOut: string | null;
  cancelTransactions: any[]; // Assuming it's an array of some type
  // amountInInUsd: string;
  priceInUsd: string | null;
  transactionValueInUsd: string | null;
  transactionValueInEth: string | null;
  marketCap: string | null;
  taxInEth: string | null;
  gasFee: {
    amountWei: string;
    amountUsd: string;
  } | null;
};

type SnipeEvent = {
  id: string;
  txHash: string;
  blockNumber: number;
  protocol: string;
  chain: string;
  eventName: string;
  eventIngestedAt: string;
  eventEmittedAt: string;
  contractAddress: string;
  poolAddress: string;
  priceInUsd: string;
  totalLiquidityInUsd: string;
  totalSupply: string;
  taxBuy: string;
  taxSell: string;
  maxBuyInWei: number | null;
  honeypot: boolean;
  maxBuy: null | string;
  maxSell: null | string;
  maxWallet: string | null;
  buyGasEstimate: number;
  sellGasEstimate: null | number;
  marketCap: string | null;
  tokenDecimals: number;
};

type ProfitAndLoss = {
  pnlAmountInEth: string;
  valueInEth: string;
  pnlPct: string;
};

export function isOrderWithRetryFailing(status: DexOrderStatus) {
  return (
    status === DexOrderStatus.confirmedExecutionRevertedInProgress ||
    status === DexOrderStatus.timedOut ||
    status === DexOrderStatus.failedToBuild ||
    status === DexOrderStatus.failedToSend ||
    status === DexOrderStatus.failedToSnipe ||
    status === DexOrderStatus.confirmedExecutionReverted
  );
}

export enum DexOrderStatus {
  new = 'NEW',
  confirmedSuccessInProgress = 'CONFIRMED_SUCCESS_IN_PROGRESS',
  confirmedExecutionRevertedInProgress = 'CONFIRMED_EXECUTION_REVERTED_IN_PROGRESS',
  confirmedCancelledInProgress = 'CONFIRMED_CANCELLED_IN_PROGRESS',
  confirmedCancelled = 'CONFIRMED_CANCELLED',
  confirmedSuccess = 'CONFIRMED_SUCCESS',
  timedOut = 'TIMED_OUT',
  failedToSnipe = 'FAILED_TO_SNIPE',
  failedToSend = 'FAILED_TO_SEND',
  pending = 'PENDING',
  signed = 'SIGNED',
  confirmedExecutionReverted = 'CONFIRMED_EXECUTION_REVERTED',
  failedToBuild = 'FAILED_TO_BUILD',
  stuckInMempool = 'STUCK_IN_MEMPOOL',
}

export const isPositionExecuted = (status: DexTradeStatus) => {
  return (
    status !== DexTradeStatus.new &&
    status !== DexTradeStatus.cancelledByUserSettings &&
    status !== DexTradeStatus.failedToSnipe &&
    status !== DexTradeStatus.snipeInProgress
  );
};

export enum DexTradeStatus {
  new = 'NEW',
  snipeInProgress = 'SNIPE_IN_PROGRESS',
  sniped = 'SNIPED',
  failedToSnipe = 'FAILED_TO_SNIPE',
  closed = 'CLOSED',
  closedInProgress = 'CLOSED_IN_PROGRESS',
  closedByRugPullDetection = 'CLOSED_BY_RUG_PULL_DETECTION',
  closedByRugPullDetectionInProgress = 'CLOSED_BY_RUG_PULL_DETECTION_IN_PROGRESS',
  closedByUserInProgress = 'CLOSED_BY_USER_IN_PROGRESS',
  closedByUser = 'CLOSED_BY_USER',
  cancelledByUserInProgress = 'CANCELLED_BY_USER_IN_PROGRESS',
  cancelledByUser = 'CANCELLED_BY_USER',
  cancelledInProgress = 'CANCELLED_IN_PROGRESS',
  cancelled = 'CANCELLED',
  cancelledByUserSettings = 'CANCELLED_BY_USER_SETTINGS',
  merged = 'MERGED',
}

type SnipePositionStatusType = {
  canSell: boolean;
  canBuy: boolean;
  canEdit: boolean;
  canCancel: boolean;
  canReactivate: boolean;
  canMerge: boolean;
  canShare: boolean;
  canDuplicate: boolean;
};

export const SnipePositionStatus: Record<
  DexTradeStatus,
  SnipePositionStatusType
> = {
  [DexTradeStatus.new]: {
    canSell: false,
    canBuy: false,
    canMerge: false,
    canEdit: true,
    canReactivate: false,
    canCancel: true,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.snipeInProgress]: {
    canSell: false,
    canMerge: false,
    canBuy: false,
    canEdit: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.merged]: {
    canSell: false,
    canMerge: false,
    canBuy: false,
    canEdit: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: false,
  },
  [DexTradeStatus.sniped]: {
    canSell: true,
    canBuy: true,
    canEdit: true,
    canCancel: true,
    canMerge: true,
    canReactivate: false,
    canShare: true,
    canDuplicate: true,
  },
  [DexTradeStatus.failedToSnipe]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.closed]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: true,
    canCancel: false,
    canShare: true,
    canDuplicate: true,
  },
  [DexTradeStatus.closedInProgress]: {
    canSell: false,
    canBuy: false,
    canMerge: false,
    canEdit: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.closedByRugPullDetection]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.closedByRugPullDetectionInProgress]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.closedByUserInProgress]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.closedByUser]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: true,
    canCancel: false,
    canShare: true,
    canDuplicate: true,
  },
  [DexTradeStatus.cancelledByUserInProgress]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canMerge: false,
    canReactivate: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },

  [DexTradeStatus.cancelledByUser]: {
    canSell: false,
    canBuy: false,
    canMerge: false,
    canEdit: false,
    canReactivate: true,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.cancelledInProgress]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canReactivate: false,
    canMerge: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.cancelled]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canReactivate: true,
    canCancel: false,
    canMerge: false,
    canShare: false,
    canDuplicate: true,
  },
  [DexTradeStatus.cancelledByUserSettings]: {
    canSell: false,
    canBuy: false,
    canEdit: false,
    canReactivate: false,
    canMerge: false,
    canCancel: false,
    canShare: false,
    canDuplicate: true,
  },
};
