import { Button } from 'modules/shared-components/button/button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  dWalletServiceKeys,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import { DWalletDetails } from 'api/types/httpsTypes/d-wallets';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { useHistory } from 'react-router-dom';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { usePageParams } from '../WalletDetails/usePageParams';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';
import TextInput from '../../components/inputs/text-input/text-input';
import { Tabs } from '../../components/layout/SIDEBAR_CONST';

function getSchema(wallet: DWalletDetails) {
  return yup.object({
    label: yup
      .string()
      .required('Label is required')
      .test(
        'name-type',
        'Label does not match wallet label',
        (value) => wallet.label === value
      ),
  });
}

type FormValues = yup.InferType<ReturnType<typeof getSchema>>;

export function DeleteDWalletModal({
  wallet,
  handleClose,
}: {
  wallet: DWalletDetails;
  handleClose: () => void;
}) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: yupResolver(getSchema(wallet)),
  });
  const [, setAddress] = usePageParams();
  const isDesktop = useMediaQuery(screenGte.small);
  const { addNewMessage } = useSnackBar();
  const { mutate, error, isLoading } = useMutation({
    mutationFn: MultiChainDWalletService.delete,
    onSuccess: async () => {
      await queryClient.invalidateQueries(multiChainDWalletServiceKeys.all());
      await queryClient.invalidateQueries(dWalletServiceKeys.all());

      if (isDesktop) {
        setAddress({});
      } else {
        history.push(`/dex/wallets/${Tabs.Portfolio}`);
      }

      handleClose();
      addNewMessage({
        type: 'success',
        title: 'Success',
        message: 'Wallet has been deleted.',
      });
    },
  });

  const onSubmit = handleSubmit(() => {
    mutate(wallet.groupId);
  });

  return (
    <CustomModal title="Delete Wallet" showModal handleClose={handleClose}>
      <form
        className="contents"
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Delete your Wallet
          </CustomModal.Title>

          <CustomModal.Content>
            <NotificationDex type="warning" className="xxs:my-2 xxs:mx-0 ">
              This action cannot be undone.
            </NotificationDex>
            <NotificationDex type="error" className="xxs:my-2 xxs:mx-0">
              Mizar does not have access to your private keys and cannot recover
              them if they are lost. Please ensure you store them securely
              before proceeding.
            </NotificationDex>

            <p className="xxs:mb-4 xxs:text-dex-white-secondary">
              For your safety, type your label:
            </p>

            <div className="flex flex-col xxs:gap-4">
              <TextInput
                autoComplete="off"
                {...register('label')}
                error={formState.errors.label?.message}
              />
            </div>

            <div className="xxs:text-xs">Label: {wallet.label}</div>

            {error && (
              <NotificationDex
                className="xxs:my-2 xxs:mx-0"
                type="error"
                errorMessage={error}
              >
                An error occurred.
              </NotificationDex>
            )}
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            type="submit"
            loading={isLoading}
          >
            DELETE
          </PrimaryButton>
          <Button
            type="button"
            variant="dexNeutral"
            className="xxs:text-base lg:text-xs"
            onClick={() => handleClose?.()}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
