import Big from 'big.js';
import ErrorBoundary from 'components/error-boundaries';
import { formatWithoutDecimals, formatWithPrecision } from 'utils/FormatNumber';
import moment from 'moment';
import Tooltip from 'modules/shared-components/tooltip';
import { WalletRowInfo } from 'api/types/httpsTypes/contracts';
import { twMerge } from 'tailwind-merge';
import ProgressBar from 'modules/shared-components/data-display/ProgressBar';
import { TokenLabels } from '../labels';
import { WalletPeriods } from '../usePageParams';
import { PercentageChange } from '../../TelegramCalls/columns';
import { NotificationDex } from '../../../../components/alerts/notification';
import { PerformanceStyle } from '../../../../components/alerts/performanceStyle';

interface MainListProps {
  title: string;
  value?: string | number;
  prefix?: string;
  suffix?: string;
  tooltip?: string;
  isLoading?: boolean;
}

const MainListComponent = ({
  title,
  prefix,
  value,
  suffix,
  tooltip,
  isLoading,
}: MainListProps) => {
  return (
    <div className="xxs:px-2">
      {isLoading ? (
        <div className="h-2 w-16 rounded-xl xxs:bg-dex-black-700 animate-pulse xxs:my-3" />
      ) : (
        <div className="flex justify-between">
          <div className="text-xs">
            {title} <Tooltip.Info text={tooltip} />
          </div>

          <div className="text-xs font-medium">
            {prefix}
            {value}
            {suffix}
          </div>
        </div>
      )}
    </div>
  );
};

export const mainPerformanceMapping = (
  wallet: WalletRowInfo | null
): MainListProps[] => {
  return [
    {
      title: 'Avg. Holding Time',
      value: formatWithPrecision(
        2,
        Big(wallet?.avgHoldingTime || 0)
          .div(3600)
          .toNumber()
      ),
      suffix: ' hr',
      tooltip: 'The average time swaps were open.',
    },
  ];
};

interface Props {
  wallet: WalletRowInfo | null;
  isLoading?: boolean;
}

const GeneralStats = ({ wallet, isLoading }: Props) => {
  const mainClass =
    'flex justify-center items-center border-bottom-new border-b-1 xxs:border-b-1 border-dex-black-700 xxs:py-2';

  const titles = [1, 2, 3];

  if (isLoading) {
    return (
      <ErrorBoundary>
        {titles.map((title, index) => {
          return (
            <div className={mainClass} key={index + '_title'}>
              <div className="flex flex-col justify-center items-center space-y-2 xxs:py-2">
                <div className="h-3 w-28 rounded-xl xxs:bg-dex-black-700 animate-pulse" />

                <div className="h-2 w-16 rounded-xl xxs:bg-dex-black-700 animate-pulse" />
              </div>
            </div>
          );
        })}
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <MainListComponent
            title=""
            prefix=""
            suffix=""
            isLoading={true}
            key={index}
          />
        ))}
      </ErrorBoundary>
    );
  }

  const volumeVsPnlRatio = Big(wallet?.pnl ?? 0)
    .div(wallet?.volume ?? 1)
    .mul(100)
    .toNumber();

  return (
    <ErrorBoundary>
      <div className=" ">
        <div className={mainClass}>
          <div className="text-center">
            {wallet?.pnl ? (
              <div className="text-lg font-medium flex space-x-1 items-center">
                <div className="">
                  <PerformanceStyle
                    className="xxs:text-lg"
                    pnl={wallet?.pnl ?? 0}
                  />
                </div>
                {wallet?.returns && (
                  <PercentageChange
                    className="xxs:text-xs xxs:pt-1"
                    change={Big(wallet?.returns ?? 0).mul(100)}
                  />
                )}
              </div>
            ) : (
              <div className="text-lg font-medium">--</div>
            )}
            <Tooltip text="The total realized PnL in the last 30 days plus the current unrealized PnL.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Total PnL
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={mainClass}>
          <div className="text-center">
            {wallet?.volume ? (
              <div className="text-lg font-medium">
                ${formatWithPrecision(2, Big(wallet?.volume || 0).toNumber())}
              </div>
            ) : (
              <div className="text-lg font-medium">--</div>
            )}
            <Tooltip text="The total traded volume in the last 30 days.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Total Volume
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={mainClass}>
          <div className="text-center">
            <Tooltip
              text={
                volumeVsPnlRatio < 1.25
                  ? 'A PnL-to-volume ratio below 1.25% indicates that the PnL is significantly affected by trading fees.'
                  : volumeVsPnlRatio < 2
                  ? 'A PnL-to-volume ratio between 1.25% and 2.0% is acceptable, but still on the lower end, as trading fees may still significantly affect the PnL.'
                  : 'A PnL-to-volume ratio above 2.0% is considered very good and healthy.'
              }
            >
              <div className="">
                <NotificationDex
                  isDotHidden={true}
                  type={
                    volumeVsPnlRatio < 1.25
                      ? 'error'
                      : volumeVsPnlRatio < 2
                      ? 'warning'
                      : 'success'
                  }
                  className="text-lg font-medium xxs:p-1 xxs:py-0.5"
                >
                  {formatWithPrecision(2, volumeVsPnlRatio)}%
                </NotificationDex>
              </div>
            </Tooltip>

            <Tooltip text="The ratio between the total PnL and the total volume in the last 30 days.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                PnL / Volume
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="xxs:space-y-2 xxs:pt-2">
          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The PnL realized from closed swaps.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Realized PnL
              </div>
            </Tooltip>

            <div className="flex space-x-1 items-center">
              <div className="text-xs  font-medium">
                <PerformanceStyle
                  pnl={wallet?.realizedPnl?.toString() ?? '0'}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The unrealized PnL from open swaps.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Unrealized PnL
              </div>
            </Tooltip>

            <div className="flex space-x-1 items-center">
              <div className="text-xs  font-medium">
                <PerformanceStyle
                  pnl={wallet?.unrealizedPnl?.toString() ?? '0'}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The total number of swaps executed + the current open swaps.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Total Swaps
              </div>
            </Tooltip>

            <div className="flex space-x-1 items-center">
              <div className="text-xs  font-medium">{wallet?.totalSwaps}</div>
            </div>
          </div>
          <div className="flex justify-between xxs:px-2 ">
            <Tooltip text="The total number of buys and sells.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Buys Vs. Sells
              </div>
            </Tooltip>

            <div className="flex space-x-1 items-center">
              <div className="">
                <BuySellBar
                  buys={wallet?.totalBuys}
                  sells={wallet?.totalSells}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between xxs:px-2 ">
            <Tooltip text="The total number of wins and losses.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Wins Vs. Losses
              </div>
            </Tooltip>

            <div className="flex space-x-1 items-center">
              <div className="">
                <BuySellBar
                  t1={'Wins'}
                  t2={'Losses'}
                  c1={'bg-green-500'}
                  c2={'xxs:bg-red-500'}
                  buys={wallet?.nOfWins}
                  sells={
                    wallet && wallet?.uniqueTokens && wallet?.nOfWins
                      ? wallet.uniqueTokens - wallet.nOfWins
                      : 0
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The date time of the last executed swap.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Last Swap
              </div>
            </Tooltip>

            <div className="text-xs font-medium">
              {wallet?.lastSwap ? (
                moment(wallet?.lastSwap).format('DD/MM/YY HH:mm:ss')
              ) : (
                <div className="text-xs font-medium">--</div>
              )}
            </div>
          </div>
          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The amount of unique tokens bought and sold, and the type (snipe, early ape, trade).">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Unique Tokens
              </div>
            </Tooltip>
            <div className="flex space-x-1 items-center">
              <div className="text-xs font-medium">{wallet?.uniqueTokens}</div>
              <div>|</div>
              {wallet && TokenLabels(wallet, WalletPeriods.Thirty)}
            </div>
          </div>
          <div className="flex justify-between xxs:px-2">
            <Tooltip text="The amount of scams bought and sold.">
              <div className="text-xs font-medium xxs:text-dex-white-secondary hover:text-dex-white">
                Scams
              </div>
            </Tooltip>

            <div className="flex space-x-2 items-center">
              <div>
                {' '}
                🚨{' '}
                {formatWithoutDecimals(Big(wallet?.honeypots ?? 0).toNumber())}
              </div>
              <div className="bg-red-600 text-white xxs:px-0.5 rounded text-[10px] xxs:mb-0.5">
                {Big(wallet?.honeypots ?? 0)
                  .div(wallet?.uniqueTokens ?? 1)
                  .mul(100)
                  .toFixed(0)}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const BuySellBar = ({
  buys,
  sells,
  c1,
  c2,
  t1,
  t2,
}: {
  buys: number | undefined;
  sells: number | undefined;
  c1?: string;
  c2?: string;
  t1?: string;
  t2?: string;
}) => {
  const b = buys ?? 0;
  const s = sells ?? 0;
  const total = +b + s;
  const ratio = total === 0 ? 0 : (b / total) * 100;

  return total === 0 ? (
    <></>
  ) : (
    <div className="lg:space-y-1 flex lg:flex-col justify-end space-x-1 lg:space-x-0 space-y-0 w-[100px]">
      <div className="w-full lg:w-auto">
        <ProgressBar
          color={c1 ?? 'bg-blue-500'}
          fillPercentage={ratio}
          className={twMerge('xxs:h-[5px]', c2)}
        />
        <div className="flex items-center justify-between ">
          <div className="text-[10px]">
            {formatWithoutDecimals(Big(b).toNumber())} {t1 ?? 'buys'}
          </div>
          <div className="text-[10px]">
            {formatWithoutDecimals(Big(s).toNumber())} {t2 ?? 'sells'}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GeneralStats;
