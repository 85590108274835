import {
  chainAsset,
  Chains,
  DWalletDetails,
  TokenBalance,
} from 'api/types/httpsTypes/d-wallets';
import { useState } from 'react';
import {
  ResponsiveMenu,
  ResponsiveMenuItem,
} from '../../components/dropdown/menu/ResponsiveMenu';
import { twMerge } from 'tailwind-merge';
import {
  ArrowDownTrayIcon,
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
  ArrowUpTrayIcon,
  CogIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { BridgeModal } from '../BridgeModal/BridgeModal';
import { WithdrawModal } from '../WithdrawModal/WithdrawModal';
import { DeleteDWalletModal } from '../DeleteDWalletModal/DeleteDWalletModal';
import { EditDWalletModal } from '../EditWalletModal/EditVaultModal';
import { DepositEthereumProvider } from '../DepositModal/DepositWagmiProvider';
import { DepositModal } from '../DepositModal';
import { useToggle } from 'modules/utils/useToggle';
import { usePageParams } from './usePageParams';
import { useQuery } from '@tanstack/react-query';
import {
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { formatTokenAmount, formatWithoutDecimals } from 'utils/FormatNumber';
import Big from 'big.js';
import { CreateDWalletModal } from '../CreateWallet/CreateDWalletModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { tokenColumn } from './tokenColumns';
import Table, { Body } from 'modules/shared-components/data-display/table';
import Button from 'modules/shared-components/button/button';
import { DepositModalSolana } from '../DepositModal/depositModalSolana';

export type Action = 'withdraw' | 'delete' | 'edit' | 'deposit' | 'bridge';

export function DWalletActions({
  wallet,
  className,
  chain,
}: {
  wallet: DWalletDetails;
  className?: string;
  chain: Chains;
}) {
  const [activeAction, setActiveAction] = useState<Action>();

  const handleClose = () => setActiveAction(undefined);

  return (
    <div
      className="xxs:flex xxs:gap-2 justify-end z-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <ResponsiveMenu
        dropdownPlacement="bottom-end"
        color="dexNeutral"
        className={twMerge('xxs:p-1', className)}
        label="Wallet Actions"
      >
        <ResponsiveMenuItem onClick={() => setActiveAction('deposit')}>
          <ArrowDownTrayIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Deposit</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('bridge')}>
          <ArrowsRightLeftIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Bridge</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('withdraw')}>
          <ArrowUpTrayIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Send</span>
        </ResponsiveMenuItem>

        <ResponsiveMenuItem onClick={() => setActiveAction('edit')}>
          <CogIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Edit</span>
        </ResponsiveMenuItem>
        <ResponsiveMenuItem
          onClick={() => {
            setActiveAction('delete');
          }}
        >
          <TrashIcon className="lg:w-4 xxs:w-5 xxs:h-5 lg:h-4" />{' '}
          <span className="xxs:text-base lg:text-xs">Delete</span>
        </ResponsiveMenuItem>
      </ResponsiveMenu>

      {activeAction === 'bridge' && (
        <BridgeModal chain={chain} handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'withdraw' && (
        <WithdrawModal
          chain={chain}
          handleClose={handleClose}
          wallet={wallet}
        />
      )}
      {activeAction === 'delete' && (
        <DeleteDWalletModal handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'edit' && (
        <EditDWalletModal handleClose={handleClose} wallet={wallet} />
      )}
      {activeAction === 'deposit' &&
        (chain !== Chains.Solana ? (
          <DepositEthereumProvider>
            <DepositModal
              selectedChain={chain}
              handleClose={handleClose}
              wallet={wallet}
            />
          </DepositEthereumProvider>
        ) : (
          <DepositModalSolana wallet={wallet} handleClose={handleClose} />
        ))}
    </div>
  );
}

export function MobileDWalletSelector({
  onCreated,
  selectedChain,
  isError,
}: {
  onCreated: () => void;
  selectedChain: Chains;
  isError?: boolean;
}) {
  const [isCreateOpen, { open: openCreateModal, close: closeCreateModal }] =
    useToggle();
  const [{ selectionDefaultOpen }, setPage] = usePageParams();
  const { data } = useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(selectedChain),
    queryKey: multiChainDWalletServiceKeys.getWallets(selectedChain),
  });

  return (
    <>
      {data?.length === 1 && (
        <IconButton variant="dexNeutral" onClick={openCreateModal}>
          <PlusIcon className="w-6" />
        </IconButton>
      )}
      {data?.length !== 1 && !isError && (
        <ResponsiveMenu
          dropdownPlacement="bottom-end"
          color="dexNeutral"
          className="xxs:p-1"
          label="My Wallets"
          defaultOpen={selectionDefaultOpen}
          trigger={({ open }) => (
            <IconButton variant="dexNeutral" onClick={open}>
              <ArrowsUpDownIcon className="w-6" />
            </IconButton>
          )}
        >
          <div className="xxs:p-2">
            <Button
              onClick={openCreateModal}
              variant="primary"
              className="w-full"
            >
              ADD NEW WALLET
            </Button>
          </div>
          {data?.map((option) => (
            <ResponsiveMenuItem
              key={option.id}
              onClick={() => {
                setPage({
                  walletAddress: option.address,
                });
              }}
            >
              <div className="flex w-full justify-between">
                <div className="max-w-[200px] truncate">{option.label}</div>
                <div className="xxs:text-dex-white">
                  {formatTokenAmount(
                    Big(option.totalEthBalance ?? 0).toNumber()
                  )}{' '}
                  {chainAsset(option.chain)} | $
                  {formatWithoutDecimals(
                    Big(option.totalUsdBalance ?? 0).toNumber()
                  )}
                </div>
              </div>
            </ResponsiveMenuItem>
          ))}
        </ResponsiveMenu>
      )}

      {isCreateOpen && (
        <CreateDWalletModal
          onCreated={(id) => {
            setPage({ walletId: id });
            onCreated();
          }}
          selectedChain={selectedChain}
          handleClose={() => {
            closeCreateModal();
          }}
        />
      )}
    </>
  );
}

export const BalancesLoading = () => {
  const isLargeScreen = useMediaQuery(screenGte.large);
  const column = tokenColumn(Chains.Ethereum);

  return (
    <div className="xxs:bg-dex-black-800 flex flex-col h-[550px] animate-pulse xxs:p-2">
      <div className="flex flex-col">
        <div className="flex justify-between xxs:text-2xl md:text-3xl font-medium text-black-800 dark:text-white-200 md:mb-3">
          <div className="h-4 rounded-full xxs:bg-dex-black-700  xxs:w-24 md:w-96 mb-0" />
          <div className="flex xxs:space-y-2">
            <div className="xxs:block md:hidden h-4 rounded-md xxs:bg-dex-black-700 xxs:w-8 ml-auto" />
            <div className="xxs:block md:hidden h-4  rounded-md xxs:bg-dex-black-700  xxs:w-8 ml-auto" />
          </div>
        </div>
        <div className="text-xl lg:text-3xl font-medium text-black-800 dark:text-white-200 xxs:mb-2.5 ">
          <div className="h-4 xxs:bg-dex-black-700 rounded-full w-48 mb-0" />
        </div>
        <div className="text-3xl lg:text-3xl font-medium text-black-800 dark:text-white-200 xxs:mb-2">
          <div className="h-6 rounded-full xxs:bg-dex-black-700 xxs:w-full md:w-24 mb-0" />
        </div>
        <div className="xxs:flex md:hidden justify-between text-2xl lg:text-3xl font-medium xxs:mb-2 text-black-800 dark:text-white-200">
          <div className="h-6 rounded-full xxs:bg-dex-black-700 xxs:w-24 md:w-96 mb-0" />
        </div>
      </div>
      <Table className="">
        <Table.Header
          trClassName=" xxs:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
          columns={column.filter((x) => isLargeScreen || x.showOnMobile)}
          {...(!isLargeScreen && {
            className: 'bg-transparent xxs:p-0',
            rowClassName: 'px-0 font-normal',
          })}
        />
        <Body<any>
          columns={column.filter((x) => isLargeScreen || x.showOnMobile)}
          data={undefined}
          {...(isLargeScreen
            ? {
                elementClassName: 'xxs:dark:bg-dex-black-700',
                className: 'md:dark:bg-dex-black-800 px-0 ',
              }
            : {
                className: 'xxs:dark:bg-transparent',
                cellClassName: 'px-0',
              })}
        />
      </Table>
    </div>
  );
};
export const MapBalances = (
  wallet: DWalletDetails,
  chainPrice: string,
  chain?: Chains
): TokenBalance[] => {
  const chainType = chain ?? Chains.Ethereum;

  const chainBalance = Big(wallet.chainBalance).gt(0)
    ? [
        {
          address: '',
          chain: chain ?? Chains.Ethereum,
          amount: +wallet.chainBalance,
          symbol: chainAsset(chainType).toUpperCase(),
          decimals: 18,
          name:
            chainType === Chains.Ethereum || chainType === Chains.Base
              ? 'Ether'
              : chainType === Chains.BinanceChain
              ? 'BNB'
              : 'SOL',
          price: chainPrice.toString(),
          hasIcon: true,
        },
      ]
    : [];
  return wallet.tokenBalances ? [...chainBalance, ...wallet.tokenBalances] : [];
};
