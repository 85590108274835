import {
  deleteUserCookies,
  getLocalRefreshToken,
  updateLocalAccessToken,
} from 'modules/user/userCookies';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export const REFRESH_TOKEN_URL = '/api/refresh-token';
let refreshTimeoutId: ReturnType<typeof setTimeout> | null = null;
let isRefreshing = false;

export function getAccessTokenRefreshDelay(
  token: string,
  offsetSeconds = 5
): number {
  try {
    const decoded = jwtDecode<{ exp: number }>(token);
    const currentTime = Date.now() / 1000;
    const expiresIn = decoded.exp - currentTime;

    return (expiresIn - offsetSeconds) * 1000; // return in milliseconds
  } catch (err) {
    console.warn('Failed to decode access token:', err);
    return 0; // force immediate refresh
  }
}

export async function performRefresh(onTokenRefreshed?: () => void) {
  if (isRefreshing) return;

  const refreshToken = getLocalRefreshToken();

  if (!refreshToken) {
    console.log('Logging out, token not found while performing silent refresh');
    deleteUserCookies();
    window.location.href = '/login';
    return;
  }

  try {
    isRefreshing = true;

    const response = await axios
      .create({ headers: { Authorization: `Bearer ${refreshToken}` } })
      .post(REFRESH_TOKEN_URL);

    const newAccessToken = response.data.access_token;

    updateLocalAccessToken(newAccessToken);
    axios.defaults.headers.Authorization = `Bearer ${newAccessToken}`;

    if (onTokenRefreshed) {
      onTokenRefreshed();
    }

    scheduleSilentRefresh(newAccessToken, onTokenRefreshed);
  } catch (err: any) {
    const status = err?.response?.status;

    if (status === 401) {
      console.log('refresh failed with 401 – logging out');
      deleteUserCookies();
      window.location.href = '/login';
    } else if (status === 429) {
      console.warn(`refresh failed with status ${status} – retrying in 1s...`);
      setTimeout(() => {
        void performRefresh(onTokenRefreshed);
      }, 1000); // retry after 1 second
    } else {
      console.warn(
        `refresh failed with status ${status} – retrying in 500ms...`
      );
      setTimeout(() => {
        void performRefresh(onTokenRefreshed);
      }, 500); // retry after 0.5 second
    }
  } finally {
    isRefreshing = false;
  }
}

function scheduleSilentRefresh(
  accessToken: string,
  onTokenRefreshed?: () => void
) {
  if (!accessToken) return;

  const refreshTime = getAccessTokenRefreshDelay(accessToken, 5);

  if (refreshTime <= 0) {
    void performRefresh(onTokenRefreshed);
    return;
  }

  if (refreshTimeoutId) {
    clearTimeout(refreshTimeoutId);
  }

  refreshTimeoutId = setTimeout(() => {
    void performRefresh(onTokenRefreshed);
  }, refreshTime);
}

export function startSilentRefresh(
  accessToken: string,
  onTokenRefreshed?: () => void
) {
  scheduleSilentRefresh(accessToken, onTokenRefreshed);
}

export function stopSilentRefresh() {
  if (refreshTimeoutId) {
    clearTimeout(refreshTimeoutId);
    refreshTimeoutId = null;
  }
}
