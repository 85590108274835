import { useQueryParams } from 'modules/routing/useQueryParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { isEmpty, omit } from 'lodash/fp';
import { toQueryObject } from 'modules/routing/query-string';
import {
  WalletKeys,
  WalletsTableSortKeys,
} from 'api/types/httpsTypes/contracts';
import { isValidAddress } from 'helpers/api/web3Utils';

export enum WalletPeriods {
  Seven = '7',
  Thirty = '30',
  Ninety = '90',
}

export enum TokenLaunchPeriods {
  Hour = 1,
  Day = 24,
  Week = 7 * 24,
  Month = 30 * 24,
  ThreeMonths = 90 * 24,
}

export enum HotTokensPeriods {
  FiveMinutes = '5m',
  FifteenMinutes = '15m',
  ThirtyMinutes = '30m',
  OneHour = '1h',
  SixHours = '6h',
  TwentyFourHour = '24h',
}

const schema = {
  sort: yup.string().default(`-${WalletsTableSortKeys.PnL}`).required(),
  period: yup
    .string()
    .oneOf([WalletPeriods.Seven, WalletPeriods.Thirty, WalletPeriods.Ninety])
    .required()
    .default(WalletPeriods.Thirty),
  walletAddress: yup.string(),
  minBalance: yup.number(),
  maxBalance: yup.number(),
  minVolume: yup.number(),
  maxVolume: yup.number(),
  minPnl: yup.number(),
  maxPnl: yup.number(),
  minReturns: yup.number(),
  maxReturns: yup.number(),
  tradingStyle: yup
    .string()
    .oneOf([
      WalletKeys.EarlyApe,
      WalletKeys.Trader,
      WalletKeys.TopSniper,
      WalletKeys.TopCopy,
    ]),
};

export function usePageParams() {
  return useQueryParams(schema);
}

export type FilterState = yup.TypeFromShape<typeof schema, any>;

export const CACHE_KEY_WALLETS = 'walletsFilters-v4';

export function useWalletsSearchFilters() {
  const [filtersState, setFilters] = usePageParams();

  const setFiltersWithCache = useFiltersWithCache(
    setFilters,
    {
      period: WalletPeriods.Thirty,
      sort: `-${WalletsTableSortKeys.PnL}`,
      tradingStyle: WalletKeys.TopCopy,
    },
    (queryObj) => isEmpty(omit(['searchDefaultOpen'], queryObj))
  );

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.sort?.includes(key)) {
      if (filtersState.sort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFiltersWithCache({
      ...filtersState,
      sort: newSortKey || key,
    });
  };

  return { filtersState, setFilters: setFiltersWithCache, setSort };
}

function useFiltersWithCache(
  setFilters: (filters: FilterState) => void,
  defaults: Partial<FilterState> = {},
  shouldApplyCache: (filters: FilterState) => boolean = () => true
) {
  const location = useLocation();

  useEffect(() => {
    if (
      shouldApplyCache(toQueryObject(location.search) as unknown as FilterState)
    ) {
      const fromCache = window.localStorage.getItem(CACHE_KEY_WALLETS);

      setFilters(fromCache ? { ...JSON.parse(fromCache) } : defaults);
    }
  }, [location.search]);

  return function cacheAndSetFilters(filters: FilterState) {
    window.localStorage.setItem(CACHE_KEY_WALLETS, JSON.stringify(filters));
    setFilters(filters);
  };
}
