import {
  SwapsStatus,
  WalletsSwapsDetailsTableSortKeys,
} from 'api/types/httpsTypes/contracts';
import { useWalletDetailsSearchFilters } from './usePageParams';
import { useEffect } from 'react';
import { useDebouncedState } from 'utils/useDebounce';

import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { WalletDetailsFilterState } from './usePageParams';
import {
  SortDropdown,
  SortDropdownProps,
} from '../../../../components/dropdown/sort-dropdown';
import {
  ActivteFiltersBar,
  FilterTag,
  SelectFiltersBar,
} from '../../../../components/filters';
import TextInput from '../../../../components/inputs/text-input/text-input';

export const SORT_OPTIONS = [
  {
    label: 'Total PnL',
    value: `-${WalletsSwapsDetailsTableSortKeys.Pnl}`,
  },

  {
    label: 'Last Swap',
    value: `-${WalletsSwapsDetailsTableSortKeys.LastSwap}`,
  },
];

export const STATUS_OPTIONS = [
  {
    value: SwapsStatus.Open,
    label: 'Open',
  },
  {
    value: SwapsStatus.Closed,
    label: 'Closed',
  },
];

export const AggregatedSwapsFiltersBar = () => {
  const { filtersState: filters, setFilters } = useWalletDetailsSearchFilters();

  const [address, debouncedAddress, setAddress] = useDebouncedState('', 250);

  useEffect(() => {
    if (debouncedAddress) {
      updateFilters({
        search: debouncedAddress,
      });
    }
  }, [debouncedAddress]);

  const handleWalletAddressInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setAddress(inputValue);
    if (!inputValue) {
      updateFilters({
        search: undefined,
      });
    }
  };

  const updateFilters = (update: Partial<WalletDetailsFilterState>) => {
    setFilters({ ...filters, ...update });
  };

  return (
    <div className="xxs:mx-1 space-y-1 lg:mx-0">
      <SelectFiltersBar
        className="xxs:justify-start space-x-2"
        icon={
          <div className="xxs:p-1">
            <FunnelIcon aria-hidden="true" width={16} height={16} />
          </div>
        }
        sort={
          <div className="flex gap-x-2">
            <div className="lg:hidden">
              <Sort
                value={filters.sort}
                onChange={(value) => {
                  updateFilters({
                    sort: value,
                  });
                }}
              />
            </div>

            <StatusFilter
              value={filters.status ?? ''}
              onChange={(value) => {
                updateFilters({
                  status: value as SwapsStatus,
                });
              }}
            />
          </div>
        }
      >
        <div className="xxs:space-y-2">
          <div>
            <TextInput
              name="WalletAddress"
              value={address}
              inputSize="xs"
              prefix={
                <MagnifyingGlassIcon className="w-4 xxs:text-dex-white-secondary xxs:my-1" />
              }
              onChange={handleWalletAddressInputChange}
              placeholder="Contract Address"
            />
          </div>
        </div>
      </SelectFiltersBar>

      <ActivteFiltersBar>
        {filters.status && (
          <FilterTag
            onRemove={() => {
              return updateFilters({
                status: undefined,
              });
            }}
            label={
              STATUS_OPTIONS.find((opt) => opt.value === filters.status)
                ?.label || ''
            }
          />
        )}
        {filters.search && (
          <FilterTag
            onRemove={() => {
              setAddress('');
              return updateFilters({
                search: undefined,
              });
            }}
            label={address !== '' ? address : filters.search ?? ''}
          />
        )}
      </ActivteFiltersBar>
    </div>
  );
};

export function Sort(props: Omit<SortDropdownProps<string>, 'options'>) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:py-1.5 xxs:px-2 h-auto"
      {...props}
      options={SORT_OPTIONS}
    />
  );
}

export function StatusFilter(
  props: Omit<SortDropdownProps<string>, 'options'>
) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:py-1 xxs:px-2 h-auto"
      {...props}
      options={STATUS_OPTIONS}
      mainLabel="Status"
    />
  );
}
