import { api } from 'helpers/api/apiCore';
import {
  FeesManager,
  FeesPlan,
  FeesPlanCharge,
  PlanLimitReachedProps,
} from 'api/types/httpsTypes/fee-manager-new';
import { toQueryString } from 'modules/routing/query-string';

interface FeesProps {
  month: number;
  year: number;
}

const moduleKey = 'fee-manager';
export const feesInfoKeys = {
  getFeesInfo: (props: FeesProps) => [moduleKey, 'feesInfo', props],
  getPlanInfo: () => [moduleKey, 'c-plan'],
  getPlanLimitsReached: () => [moduleKey, 'c-plan-limits-reached'],
  setNewPlan: () => [moduleKey, 'set-c-plan'],
  getPlanCharge: (params: { next_plan_id: string }) => [
    moduleKey,
    'get-c-plan-charge',
    params,
  ],
  getAll: () => [moduleKey],
};

export const FeeService = {
  getFeesInfo: async ({ month, year }: FeesProps) => {
    return api.get<FeesManager>(
      `/api/v2/settings/fee-manager?month=${month}&year=${year}`
    );
  },

  getPlanInfo: async () => {
    const result = await api.get<FeesPlan>(`/api/v2/settings/c-plan`);
    return result.data;
  },
  getPlanLimitsReached: async () => {
    const result = await api.get<PlanLimitReachedProps>(
      `/api/v2/settings/plan-limit-reached`
    );
    return result.data;
  },

  setNewPlan: async (params: { new_plan_id: string }) => {
    const result = await api.post('/api/v2/settings/c-plan', params);
    return result.data;
  },

  getPlanCharge: async (params: { next_plan_id: string }) => {
    const response = await api.get<FeesPlanCharge>(
      `/api/v2/settings/c-plan-pro-rata-charge?${toQueryString(params)}`
    );

    return response.data;
  },
};
