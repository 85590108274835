import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ChartBarSquareIcon,
  ExclamationCircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import Button from 'modules/shared-components/button/button';
import {
  BotType,
  BotWhitelistedTokens,
  GetDexBotsParams,
} from 'api/types/httpsTypes/dex-bot';
import Header from 'modules/shared-components/data-display/table/header';
import Table from 'modules/shared-components/data-display/table';
import Tooltip from 'modules/shared-components/tooltip';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { formatUUID } from 'utils/uuid';
import { CopyText } from 'modules/shared-components/copy';
import { twMerge } from 'tailwind-merge';
import { chainAsset, chainName, Chains } from 'api/types/httpsTypes/d-wallets';
import {
  ResponsiveTableColumn,
  TableColumn,
} from 'modules/shared-components/data-display/table/types';
import ErrorBoundary from 'components/error-boundaries';
import {
  dexBotKeys,
  DexBotService,
} from 'api/services/httpServices/DexBotService';
import { useState } from 'react';
import CustomModal from '../../../components/modal/CustomModal';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { MaxFundsNeeded } from '../../volatility/sections/VolatilitySettings';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { isValidAddress } from 'helpers/api/web3Utils';
import {
  isTokenSuspicious,
  isTokenSuspiciousCheck,
} from '../../../research/components/Wallets/labels';
import {
  GetHotTokensParams,
  HotTokensSortKeys,
} from 'api/types/httpsTypes/contracts';
import { HotTokensPeriods } from '../../../research/components/Wallets/usePageParams';
import { BotsSortKeys } from '../usePageParams';
import { LoaderDex } from '../../../components/alerts/Loader';
import { useUserState } from 'modules/user/UserContext';
import StarLevelService, {
  starLevelKeys,
} from 'api/services/httpServices/StarLevelService';
import { PerformanceStyle } from '../../../components/alerts/performanceStyle';

type Props = {
  bots: BotWhitelistedTokens[];
  handleClose: () => void;
  address: string;
  chain: Chains;
  symbol: string;
  isBotsLoading: boolean;
};

export function thDynamicClass(s: string) {
  return s === 'BOT' ? 'col-span-2' : '';
}

const RemoveTokenButton = ({
  botId,
  chain,
  address,
  bots,
  setBots,
}: {
  botId: string;
  chain: Chains;
  address: string;
  bots: BotWhitelistedTokens[];
  setBots: (arg0: [] | BotWhitelistedTokens[]) => void;
}) => {
  const { addNewMessage } = useSnackBar();

  const { mutate, isLoading } = useMutation({
    mutationFn: DexBotService.removeTokenFromVolatilityBot,
    onSuccess: () => {
      if (botId) {
        setBots(bots.filter((bot) => bot.botId !== botId));
      }
    },
    onError: () => {
      addNewMessage({
        type: 'error',
        title: 'Error',
        message: 'Some error occurred. Try again.',
      });
    },
  });

  return (
    <Button
      className="xxs:p-1"
      size="small"
      loading={isLoading}
      variant="errorDex"
      type="button"
      onClick={() => {
        mutate({ botId, chain, address });
      }}
    >
      <div className="flex gap-x-1 items-center">
        <TrashIcon className="w-4 h-4" />
      </div>
    </Button>
  );
};
const cols = ({
  address,
  chain,
  bots,
  setBots,
}: {
  address: string;
  chain: Chains;
  bots: BotWhitelistedTokens[];
  setBots: (arg0: [] | BotWhitelistedTokens[]) => void;
}): TableColumn<BotWhitelistedTokens>[] => {
  return [
    {
      label: (
        <Tooltip text="The name of the bot.">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            Bot
          </div>
        </Tooltip>
      ),
      key: 'BOT',
      component: ({ data }) => (
        <div className="flex items-center xxs:gap-x-1">
          <div className="flex">
            <div
              className={twMerge(
                'text-white xxs:text-xs rounded xxs:p-0.5 ',
                data.status === 'ACTIVE'
                  ? 'bg-green-600 text-green-500'
                  : data.status === 'PAUSED'
                  ? 'bg-yellow-600 text-yellow-500'
                  : 'bg-red-600 text-red-500'
              )}
            >
              <div className="flex space-x-1 items-center">
                {data.status === 'ACTIVE' ? (
                  <PlayCircleIcon className="w-4 h-4 " />
                ) : (
                  <PauseCircleIcon className="w-4 h-4 " />
                )}
              </div>
            </div>
          </div>

          <Tooltip text={data.name}>
            <div className="max-w-[220px] xxs:text-xs overflow-hidden truncate">
              <div>{data.name}</div>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      label: (
        <Tooltip text="The id of the bot.">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            Id
          </div>
        </Tooltip>
      ),
      key: 'ID',
      component: ({ data }) => (
        <CopyText textToCopy={data.botId}>
          <BaseButton
            type="button"
            className="xxs:text-dex-white-secondary hover:text-dex-white"
          >
            <div className="xxs:text-xs">{formatUUID(data.botId)}</div>
          </BaseButton>
        </CopyText>
      ),
    },

    {
      label: (
        <Tooltip text="Remove the token from the bot.">
          <div className="xxs:text-dex-white-secondary hover:text-dex-white">
            Action
          </div>
        </Tooltip>
      ),
      key: 'ACTION',
      component: ({ data }) => (
        <RemoveTokenButton
          setBots={setBots}
          bots={bots}
          botId={data.botId}
          chain={chain}
          address={address}
        />
      ),
    },
  ];
};

const BotsRow = ({
  data,
  columnsDef,
}: {
  columnsDef: ResponsiveTableColumn<BotWhitelistedTokens>[];
  data: BotWhitelistedTokens;
}) => {
  return (
    <tr className="border-t border-dex-black-700 lg:dark:bg-dex-black-800 cursor-pointer items-center hover:dark:lg:bg-dex-black-700 lg:grid lg:grid-cols-4">
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return;
          return (
            <Table.Td className={thDynamicClass(column.key)} key={column.key}>
              <Column data={data} />
            </Table.Td>
          );
        })}
      </ErrorBoundary>
    </tr>
  );
};

const ManageTokensModal = ({
  bots,
  handleClose,
  address,
  chain,
  symbol,
  isBotsLoading,
}: Props) => {
  const queryClient = useQueryClient();
  return (
    <CustomModal
      showModal
      title={`Add/Remove ${symbol} from volatility bot`}
      handleClose={() => {
        void queryClient.invalidateQueries(dexBotKeys.all());
        handleClose();
      }}
    >
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex">
          Add/Remove {symbol} from Bots
        </CustomModal.Title>
        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div className="xxs:space-y-4">
              <div>
                Add/Remove {symbol} from your bots. Open positions are still
                managed even if the token is removed.
              </div>
            </div>
            <ManageTokensBody
              isBotsLoading={isBotsLoading}
              bots={bots}
              chain={chain}
              address={address}
              symbol={symbol}
            />
          </div>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
};

export const ManageTokensBody = ({
  bots: b,
  isBotsLoading,
  address,
  chain,
  symbol,
}: Omit<Props, 'handleClose'>) => {
  const { addNewMessage } = useSnackBar();
  const [addToBotId, setAddToBotId] = useState<string | undefined>();
  const [bots, setBots] = useState<BotWhitelistedTokens[] | []>(b);

  const { user } = useUserState();
  const { data: starLevel } = useQuery({
    queryKey: starLevelKeys.getStarLevel(),
    queryFn: StarLevelService.getStarLevel,
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const maxNOfBots = starLevel
    ? starLevel.mzr_holdings < 1_000_000
      ? 20
      : starLevel.mzr_holdings < 4_000_000
      ? 50
      : 100
    : 20;

  const { mutate: addToken } = useMutation({
    mutationFn: DexBotService.addTokenToVolatilityBot,
    onSuccess: () => {
      const filteredBot = allBots?.filter(
        (allBot) => allBot.id === addToBotId
      )?.[0];

      if (filteredBot) {
        setBots((prevBots) => [
          ...prevBots.filter((bot) => bot.botId !== filteredBot.id),
          {
            botId: filteredBot.id,
            name: filteredBot.name,
            status: filteredBot.status,
          },
        ]);
      }
    },
    onError: () => {
      addNewMessage({
        type: 'error',
        title: 'Error',
        message: 'Some error occurred. Try again.',
      });
    },
  });

  const columns = cols({ address, chain, bots, setBots });

  const params1: GetHotTokensParams = {
    sort: HotTokensSortKeys.TotalBuyTop,
    address,
    chain,
    period: HotTokensPeriods.TwentyFourHour,
  };

  const { data: contractData } = useQuery({
    queryKey: contractServiceKeys.getHotTokens(params1),
    queryFn: () => ContractService.getHotTokens(params1),
    keepPreviousData: true,
    enabled: isValidAddress(address || '', chain),
  });

  const params: GetDexBotsParams = {
    status: ['ACTIVE', 'PAUSED'],
    sort: `-${BotsSortKeys.Name}`,
    limit: 50,
    offset: 0,
    botType: BotType.Volatility,
    chain,
  };

  const { data: page, isLoading } = useQuery({
    queryKey: dexBotKeys.getBots(params),
    queryFn: () => DexBotService.getBots(params),
    keepPreviousData: true,
  });

  const botIds = bots.map((bot) => bot.botId);

  const allBots = page?.data ? page.data : [];

  const filteredAllBots = allBots.filter(
    (allBot) => !botIds.includes(allBot.id)
  );

  return !isBotsLoading ? (
    <div className="xxs:space-y-2">
      {contractData?.[0] && isTokenSuspiciousCheck(contractData?.[0]) && (
        <div className=" xxs:p-2 rounded xxs:bg-red-600 text-red-500">
          <div>Warning: the wallets trading this token look suspicious!</div>
          <ul className="xxs:mb-0 xxs:pl-4">
            {isTokenSuspicious(contractData?.[0])?.map((item, index) => (
              <li className="" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
      {bots.length > 0 && (
        <div className="xxs:space-y-2">
          <div>Currently whitelisted on:</div>
          <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
            <Header
              trClassName="lg:grid lg:grid-cols-4 lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
              columns={columns}
              thDynamicClass={thDynamicClass}
              rowClassName="xxs:p-1"
              className="lg:dark:bg-dex-black-700 xxs:text-xs lg:grid"
            />
            <tbody>
              {bots?.map((item, rowId) => (
                <BotsRow key={rowId} data={item} columnsDef={columns} />
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {filteredAllBots?.length >= 1 ? (
        <div className="xxs:space-y-2">
          <div>Add {symbol} to:</div>
          <div className="max-h-[300px] overflow-y-scroll">
            {filteredAllBots?.map((bot, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    setAddToBotId(bot.id);
                    addToken({ botId: bot.id, chain, address });
                  }}
                  className=" xxs:p-2 w-full xxs:text-xs flex lg:hover:bg-dex-black-700 justify-between xxs:gap-2 items-center xxs:text-dex-white-secondary"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center xxs:gap-x-1">
                      {bot.insufficientBalance && (
                        <Tooltip text="Your wallet balance is running low! Please top up soon to prevent your bot from being automatically paused.">
                          <div className="flex justify-center items-center">
                            <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-5 xxs:h-5" />
                          </div>
                        </Tooltip>
                      )}
                      <div className="flex">
                        <div
                          className={twMerge(
                            'text-dex-white xxs:text-xs rounded xxs:p-0.5 ',
                            bot.status === 'ACTIVE'
                              ? 'bg-green-600 text-green-500'
                              : bot.status === 'PAUSED'
                              ? 'bg-yellow-600 text-yellow-500'
                              : 'bg-red-600 text-red-500'
                          )}
                        >
                          <div className="flex space-x-1 items-center">
                            {bot.status === 'ACTIVE' ? (
                              <PlayCircleIcon className="w-3 h-3 " />
                            ) : (
                              <PauseCircleIcon className="w-3 h-3 " />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="relative">
                        <ChainsIcon imgName={bot.chain} />
                      </div>
                      <Tooltip text={bot.name}>
                        <div className="max-w-[220px] overflow-hidden truncate xxs:text-dex-white">
                          <div>{bot.name}</div>
                        </div>
                      </Tooltip>
                    </div>

                    <div className="flex space-x-1 items-center">
                      <div className="text-xs">
                        Max:{' '}
                        {formatNumberWithSuffix(
                          MaxFundsNeeded({
                            am:
                              parseFloat(bot.position.buyAmountInWei ?? 0) ?? 0,
                            dcaO:
                              bot.position.limitOrders?.map((order) => {
                                return {
                                  amount: parseFloat(order.amount ?? 0),
                                  slippage: order.slippage ?? 0,
                                  priceDeviationPercentage: parseFloat(
                                    order.priceDeviationPercentage ?? 0
                                  ),
                                };
                              }) ?? undefined,
                            ad: bot.addresses?.map((a) => {
                              return {
                                address: a,
                              };
                            }),
                            isDca: bot.position.limitOrders?.length > 0,
                            isFromWei: true,
                            chain: bot.chain,
                          })
                        )}{' '}
                        {chainAsset(bot.chain)}
                      </div>

                      <div
                        className={twMerge(
                          'flex space-x-0.5 items-center',
                          bot?.addresses &&
                            bot.addresses.length >= 20 &&
                            'text-red-500'
                        )}
                      >
                        <ChartBarSquareIcon className="h-4 w-4" />
                        <div className="">{bot.addresses?.length ?? 0}</div>
                        {bot?.addresses &&
                          bot.addresses.length >= maxNOfBots && (
                            <div>Max tokens reached</div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 items-center ">
                    <div className="flex items-end flex-col xxs:text-dex-white">
                      {bot.analytics?.pnlDollar ? (
                        <div>
                          <PerformanceStyle
                            className="xxs:text-base"
                            pnl={bot.analytics.pnlDollar}
                          />
                        </div>
                      ) : (
                        <div className="text-base">--</div>
                      )}

                      <div className="xxs:text-xs xxs:font-normal text-center">
                        {bot.numActivePositions}/{bot.addresses?.length}
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      ) : isLoading ? (
        <LoaderDex />
      ) : bots?.length === 0 ? (
        <div className="text-red-500">
          No bot on {chainName(chain)} has been found.
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div></div>
  );
};
export default ManageTokensModal;
