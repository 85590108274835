import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'modules/shared-components/button/button';
import {
  DexTradeService,
  dexTradeKeys,
} from 'api/services/httpServices/DexTradeService';
import { DexTradeTemplate } from 'api/types/httpsTypes/dex-trade';
import { useLastUsedTemplate } from './TemplatesBar';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import CustomModal from '../../../components/modal/CustomModal';
import { NotificationDex } from '../../../components/alerts/notification';

type Props = {
  handleClose: () => void;
  settings: DexTradeTemplate;
};

export function DeleteTemplateModal({ settings, handleClose }: Props) {
  const [, setActive] = useLastUsedTemplate();
  const { addNewMessage } = useSnackBar();

  const queryClient = useQueryClient();

  const { mutate, error, isLoading } = useMutation({
    mutationFn: () => DexTradeService.deleteTemplate(settings.id),
    onSuccess: async () => {
      setActive(null as any);
      await queryClient.invalidateQueries(dexTradeKeys.getTemplates());
      handleClose();
      addNewMessage({
        message: 'Template deleted',
        title: 'Success',
        type: 'success',
      });
    },
  });

  return (
    <CustomModal title="Delete Settings" showModal handleClose={handleClose}>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Delete Settings
        </CustomModal.Title>
        <CustomModal.Content>
          This operation cannot be undone.
          {error && (
            <NotificationDex type="error" errorMessage={error}>
              An error occurred.
            </NotificationDex>
          )}
        </CustomModal.Content>
      </CustomModal.Body>

      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          loading={isLoading}
          onClick={() => {
            mutate();
          }}
        >
          Delete
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
}
