import {
  EmptyPageDex,
  ErrorPageDex,
} from '../../../research/components/shared/Common';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useQuery } from '@tanstack/react-query';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import {
  LeaderboardService,
  leaderboardServiceKeys,
} from 'api/services/httpServices/LeaderboardService';
import { AlphaCallsParams } from 'api/types/httpsTypes/leaderboard';
import { useUserState } from 'modules/user/UserContext';
import StarLevelService, {
  starLevelKeys,
} from 'api/services/httpServices/StarLevelService';
import { TableRow } from '../alphaCalls/TableRow';
import {
  top10CallsDesktopColumns,
  top10CallsMobileColumns,
} from './top10CallsColumns';
import { useUser } from 'modules/user/user';

function Top10Calls({
  defaultParams,
}: {
  defaultParams: Partial<AlphaCallsParams> & { chain: Chains; season: string };
}) {
  const { user } = useUserState();
  const {
    data: starLevel,
    isLoading: isLoadingStarLevel,
    isError: isErrorStarLevel,
  } = useQuery({
    queryKey: starLevelKeys.getStarLevel(),
    queryFn: StarLevelService.getStarLevel,
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: leaderboardServiceKeys.getAlphaCalls(defaultParams),
    queryFn: () => LeaderboardService.getAlphaCalls(defaultParams),
  });

  const userId = useUser()?.id;
  const items = data?.data;

  //28803 is KOL id doing video
  const isUserNotAllowed =
    !user ||
    (starLevel && starLevel.mzr_holdings < 250_000 && userId !== 28803);

  const pagination = data?.pagination;

  const isLargeScreen = useMediaQuery(screenGte.large);

  const columns = isLargeScreen
    ? top10CallsDesktopColumns(defaultParams.chain)
    : top10CallsMobileColumns(defaultParams.chain);

  return (
    <div className="space-y-2">
      {error || isErrorStarLevel ? (
        <ErrorPageDex />
      ) : (
        <div className="overflow-x-auto">
          <div className="w-full  lg:min-w-[1300px] ">
            <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
              <Header
                trClassName={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700`}
                columns={columns}
                rowClassName="xxs:p-1"
                className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
              />
              <tbody className="lg:block lg:h-terminal overflow-y-auto">
                {isLoading || (user && isLoadingStarLevel) ? (
                  [...Array(10).keys()]
                    .map((i) => i + 1)
                    .map((index) => (
                      <Table.LoadingRow
                        key={index}
                        elementClassName="xxs:dark:bg-dex-black-700"
                        className={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800`}
                        columns={columns}
                      />
                    ))
                ) : items?.length === 0 ? (
                  <EmptyPageDex
                    height={isLargeScreen ? '100px' : '50vh'}
                    title="No Alpha Calls Found"
                  />
                ) : (
                  <>
                    {items?.map(
                      (item, index) =>
                        pagination && (
                          <TableRow
                            isUserNotAllowed={!!isUserNotAllowed}
                            isLoggedIn={!!user}
                            index={index}
                            item={item}
                            key={item.callTime + '_' + item.chain}
                            columnsDef={columns}
                          />
                        )
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Top10Calls;
