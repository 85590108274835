import {
  chainAsset,
  chainGasFeeLimits,
  chainMinOrderAmountLimits,
  Chains,
  gasPrioritySuffix,
} from 'api/types/httpsTypes/d-wallets';
import * as yup from 'yup';

export const gasMinMaxTest = function (
  this: yup.TestContext,
  chain: Chains,
  value: number
) {
  const { min, max } = chainGasFeeLimits(chain);
  if (value < min) {
    return this.createError({
      message: `Min is ${min} ${gasPrioritySuffix(chain)}.`,
    });
  }
  if (value > max) {
    return this.createError({
      message: `Max is ${max} ${gasPrioritySuffix(chain)}.`,
    });
  }
  return true;
};

export const amountMinMaxTest = function (
  this: yup.TestContext,
  chain: Chains,
  value: number
) {
  const { min, max } = chainMinOrderAmountLimits(chain);
  if (value < min) {
    return this.createError({
      message: `Min is ${min} ${chainAsset(chain)}.`,
    });
  }
  if (value > max) {
    return this.createError({
      message: `Max is ${max} ${chainAsset(chain)}.`,
    });
  }
  return true;
};
