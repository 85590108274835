import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { useDebouncedState } from 'utils/useDebounce';
import { duration } from 'moment';
import { useRef } from 'react';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { FullPageModal } from 'modules/shared-components/modal/FullPage';
import { ContractSearchResultItem } from './Search';
import { useToggle } from 'modules/utils/useToggle';
import { useHistory } from 'react-router-dom';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { LoaderDex } from '../../components/alerts/Loader';
import TextInput from '../../components/inputs/text-input/text-input';

type SearchProps = {
  handleClose: () => void;
  onSelect?: (chain: Chains, address: string) => void;
};

export function FavouritesSearchModal({ handleClose, onSelect }: SearchProps) {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, debouncedQuery, setQuery] = useDebouncedState('', 125);
  const {
    data: options,
    error,
    isLoading,
  } = useQuery({
    queryFn: () => ContractService.getFavouriteContracts(),
    queryKey: contractServiceKeys.getFavouriteContracts(),
    staleTime: duration('1', 'hour').asMilliseconds(),
    cacheTime: duration('1', 'hour').asMilliseconds(),
  });

  return (
    <FullPageModal
      showModal
      handleClose={handleClose}
      className="dark:bg-dex-black-800 xxs:text-dex-white-secondary h-screen lg:h-[80vh] border-bottom-new border-b-1 xxs:border-b-1 xxs:border-dex-black-600"
      backdropClassName="dark:bg-black-900/70"
      initialFocus={inputRef}
    >
      <div className="xxs:mt-4 xxs:mb-2 xxs:px-2 lg:px-8 sticky top-2">
        <IconButton
          onClick={handleClose}
          className="z-50 ml-auto xxs:rounded xxs:text-dex-white-secondary hover:text-dex-white xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:p-1 "
        >
          <XMarkIcon className="w-6" />
        </IconButton>
      </div>
      <div className="lg:px-8 ">
        <div className="max-w-[620px] mx-auto">
          <div className="xxs:p-3 lg:px-0 lg:p-2 flex xxs:gap-2 xxs:sticky top-0 items-center z-50 mb-2 lg:mb-0">
            <TextInput
              name="search"
              prefix={<MagnifyingGlassIcon className="w-4" />}
              ref={inputRef}
              suffix={isLoading ? <LoaderDex /> : undefined}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              className="outline-none shadow-none"
              autoComplete="off"
              placeholder="Symbol, name or address"
            />
          </div>
          {error && (
            <div className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500">
              Could not load options
            </div>
          )}
          {!!options?.length && (
            <div className="xxs:text-xs xxs:mb-1 xxs:ml-3 lg:ml-0 flex items-center xxs:gap-1">
              Your Favourite Tokens
            </div>
          )}

          {debouncedQuery.length > 0 && options?.length === 0 && (
            <div className="xxs:py-1 xxs:mb-3 xxs:pl-3">No results found</div>
          )}
          <div className="xxs:divide-x-0 xxs:divide-y xxs:divide-solid xxs:divide-black-100 dark:divide-black-700 border-0">
            {options
              ?.filter(
                (option) =>
                  !debouncedQuery ||
                  option.address.toLowerCase() ===
                    debouncedQuery.toLowerCase() ||
                  option.name
                    .toLowerCase()
                    .includes(debouncedQuery.trim().toLowerCase()) ||
                  option.symbol
                    .toLowerCase()
                    .includes(debouncedQuery.trim().toLowerCase())
              )
              .map((option) => (
                <ContractSearchResultItem
                  key={option.address}
                  option={option}
                  onSelect={() => {
                    if (onSelect) {
                      onSelect(
                        option.chain?.key ?? Chains.Ethereum,
                        option.address
                      );
                    } else {
                      history.push(
                        `/dex/research/${
                          option.chain?.key ?? Chains.Ethereum
                        }/${option.address}`
                      );
                    }
                    handleClose();
                  }}
                />
              ))}
            {options?.length === 0 && (
              <div className="xxs:px-3">
                <p className="xxs:text-sm">Your favourite list is empty!</p>
                <p className="xxs:text-sm xxs:mb-0">
                  Start building your favourite list by adding contract or
                  symbol.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </FullPageModal>
  );
}

export function Favourites({
  children,
  onSelect,
}: {
  children: (props: { open: () => void }) => JSX.Element;
  onSelect?: (chain: Chains, address: string) => void;
}) {
  const [isOpen, { close, open }] = useToggle();

  return (
    <>
      {children({ open })}
      {isOpen && (
        <FavouritesSearchModal handleClose={close} onSelect={onSelect} />
      )}
    </>
  );
}
