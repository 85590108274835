import { formatEther, formatGwei, parseEther, parseGwei } from 'viem';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import Big from 'big.js';
import { Chains } from 'api/types/httpsTypes/d-wallets';

/**
 * Converts an amount to the appropriate blockchain unit.
 * - Uses `parseEther` (ETH → Wei) for EVM-compatible chains.
 * - Uses `parseGwei` (Gwei → Wei) for EVM gas fees.
 * - Uses `LAMPORTS_PER_SOL` (SOL → Lamports) for Solana.
 *
 * @param amount - The amount to convert.
 * @param chain - The blockchain type (EVM or Solana).
 * @param unit - Defines if we are converting from "ether" or "gwei" (only for EVM).
 * @returns The converted amount as `bigint`.
 */

export function parseAmount(
  amount: number | string | null | undefined,
  chain: Chains | undefined,
  unit: 'etherToWei' | 'gweiToWei' = 'etherToWei'
): number {
  if (!chain) {
    return 0;
  }

  if (amount === null || amount === undefined) {
    return 0;
  }

  const amountStr = amount.toString().trim();
  if (amountStr === '' || isNaN(Number(amountStr))) {
    return 0;
  }

  try {
    const amountBig = Big(amountStr.toString().trim());

    if (amountBig.lt(0)) {
      return 0;
    }

    if (chain !== Chains.Solana) {
      switch (unit) {
        case 'etherToWei':
          return Number(parseEther(amountBig.toString())); // ✅ Convert ETH → Wei
        case 'gweiToWei':
          return Number(parseGwei(amountBig.toString())); // ✅ Convert Gwei → Wei
        default:
          return 0;
      }
    }

    return Number(amountBig.times(LAMPORTS_PER_SOL)); // ✅ Convert SOL → Lamports
  } catch {
    return 0;
  }
}

export function fromAmount(
  amount: string | number | null | undefined,
  chain: Chains | undefined,
  unit: 'weiToEther' | 'weiToGwei' = 'weiToEther'
): number {
  if (!chain) {
    return 0;
  }
  if (amount === null || amount === undefined) {
    return 0;
  }

  const amountStr = amount.toString().trim();
  if (amountStr === '' || isNaN(Number(amountStr))) {
    return 0;
  }

  try {
    const amountBig = Big(amountStr.toString().trim());

    if (amountBig.lt(0)) {
      return 0;
    }

    if (chain !== Chains.Solana) {
      switch (unit) {
        case 'weiToEther':
          return Number(formatEther(BigInt(amountBig.toString()))); // ✅ Convert Wei → ETH
        case 'weiToGwei':
          return Number(formatGwei(BigInt(amountBig.toString()))); // ✅ Convert Wei → Gwei
        default:
          return 0;
      }
    }

    return Number(amountBig.div(LAMPORTS_PER_SOL)); // ✅ Convert Lamports → SOL
  } catch {
    return 0;
  }
}
