import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDebouncedState } from 'utils/useDebounce';
import { isValidAddress } from 'helpers/api/web3Utils';
import { LoadingComponent } from '../../side-panels/LoadingComponent';
import { Chains, DWalletDetails } from 'api/types/httpsTypes/d-wallets';
import { WalletComponent } from '../../side-panels/WalletsPanel';
import { chainName } from 'api/types/httpsTypes/d-wallets';
import { FormValues } from './schema';
import TextInput from '../../components/inputs/text-input/text-input';

interface SelectWalletContentProps {
  wallets: DWalletDetails[] | undefined;
  isLoading: boolean;
  isError: boolean;
  setIsWalletSet: (arg0: boolean) => void;
  chain: Chains;
}

const SelectWalletContent = ({
  wallets,
  isLoading,
  isError,
  setIsWalletSet,
  chain,
}: SelectWalletContentProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<FormValues>();

  const [invalidAddress, setInvalidAddress] = useState(false);
  const [walletAddress, debouncedWalletAddress, setWalletAddress] =
    useDebouncedState('', 250);
  const handleStrategyIdInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setWalletAddress(inputValue);
  };

  useEffect(() => {
    if (debouncedWalletAddress) {
      if (isValidAddress(debouncedWalletAddress, chain)) {
        setValue('toAddress', debouncedWalletAddress);
        setIsWalletSet(true);
        setInvalidAddress(false);
      } else {
        setValue('toAddress', undefined as any);
        setIsWalletSet(false);
        setInvalidAddress(true);
      }
    } else {
      setIsWalletSet(false);
      setInvalidAddress(false);
    }
  }, [debouncedWalletAddress]);

  return (
    <div className="space-y-2">
      <p className="xxs:mb-4 xxs:text-xs xxs:text-dex-white-secondary">
        Send your funds to another wallet on the {chainName(chain)} chain.
      </p>
      <div className="">
        <TextInput
          value={walletAddress}
          placeholder="Enter public address"
          {...register('toAddress')}
          error={errors?.toAddress?.message || ''}
          onChange={(e) => {
            handleStrategyIdInputChange(e);
          }}
        />
      </div>
      {invalidAddress ? (
        <div className="text-red-500 xxs:text-xs">Invalid Address</div>
      ) : (
        <div className="space-y-2 xxs:text-dex-white-secondary xxs:text-xs">
          <div className="xxs:text-xs">Send to other Mizar wallets</div>
          <div className="divide-x-0 divide-solid divide-dex-black-700 divide-y max-h-[155px] overflow-y-scroll ">
            {isLoading ? (
              <LoadingComponent />
            ) : isError ? (
              <div className="text-red-500">
                An error occurred while loading your wallets
              </div>
            ) : wallets?.length === 0 ? (
              <div className="">No other Mizar wallets found</div>
            ) : (
              wallets?.map((wallet: DWalletDetails) => {
                return (
                  <button
                    type="button"
                    onClick={() => {
                      setValue('toAddress', wallet.address);
                      setIsWalletSet(true);
                    }}
                    key={wallet.label}
                    className="hover:text-dex-white hover:bg-dex-black-700 m-0 xxs:p-2 w-full xxs:text-xs flex xxs:gap-2 items-center"
                  >
                    <WalletComponent
                      selectedChain={wallet.chain}
                      hideDeposit={true}
                      wallet={wallet}
                    />
                  </button>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectWalletContent;
