import { Controller, useFormContext } from 'react-hook-form';
import { formatTokenAmount, formatUSDAmount } from 'utils/FormatNumber';
import { BotKind, FormValues } from '../schema';
import { useQuery } from '@tanstack/react-query';
import Big from 'big.js';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';

import {
  RowLabel,
  RowLabelledCheckBox,
  RowLabelledInput,
} from '../../../components/RowLabelledInput';
import { PresetButton } from '../../../components/PresetButton';
import { useEffect, useState } from 'react';
import Tooltip from 'modules/shared-components/tooltip';
import {
  ArrowTrendingDownIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import GasIcon from '../../../../../assets/img/icons/gas-icon.svg';
import { isNotNil } from 'modules/utils/isNotNil';
import {
  chainAsset,
  chainDefaultBackupBribeInBaseAsset,
  chainDefaultBribeInBaseAsset,
  chainHasBribe,
  chainHasMemPool,
  chainPriorityGasFeeTitle,
  chainPriorityGasFeeTooltip,
  gasPrioritySuffix,
} from 'api/types/httpsTypes/d-wallets';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';
import { DexCard } from '../../../components/cards';
import { fromAmount } from '../../../components/chains/units';
import { useChainInfo } from 'api/hooks/useChainInfo';

export function BuyAmount() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>();

  const chain = watch('tradingSettings.chain');
  const [openBoxTradedTokens, setOpenBoxTradedTokens] = useState(false);
  const [openBoxNotTradedTokens, setOpenBoxNotTradedTokens] = useState(false);
  const [openWalletSettingsBox, setOpenWalletSettingsBox] = useState(false);
  const selectedWalletId = watch('tradingSettings.walletId');

  const { data } = useQuery({
    queryFn: () => DWalletService.getWallets(),
    queryKey: dWalletServiceKeys.getWallets(),
  });
  const selectedWallet = data?.find((x) => x.id === selectedWalletId);
  const isWalletCopyTrading = watch('type') === BotKind.WalletCopyTrading;

  const isErrorSnipeLaunchedTokens =
    errors &&
    (errors.tradingSettings?.maxBuyGasPriority !== undefined ||
      errors.tradingSettings?.slippage !== undefined ||
      errors.tradingSettings?.antiMev !== undefined);

  const chainPrice = useChainInfo(chain)?.price ?? 0;

  const isErrorSnipeNotLaunchedTokens =
    watch('tradingSettings.notLaunchedFilter') &&
    errors &&
    ((errors.tradingSettings &&
      'bribe' in errors.tradingSettings &&
      errors.tradingSettings.bribe !== undefined) ||
      (watch('tradingSettings.blockOneRetry') &&
        ((errors.tradingSettings &&
          'backupBribe' in errors.tradingSettings &&
          errors.tradingSettings.backupBribe !== undefined) ||
          (errors.tradingSettings &&
            'slippage' in errors.tradingSettings &&
            errors.tradingSettings.slippage !== undefined))));

  useEffect(() => {
    if (!isWalletCopyTrading) {
      setValue(
        'tradingSettings.oneFilterSelected',
        watch('tradingSettings.notLaunchedFilter') ||
          watch('tradingSettings.launchedFilter')
      );
    }
  }, [
    watch('tradingSettings.notLaunchedFilter') ||
      watch('tradingSettings.launchedFilter'),
  ]);

  const buyOrderSettings = watch('tradingSettings');

  return (
    <MobileExpandableCard
      mobileLabel="Buy Settings"
      renderTrigger={({ open }) => (
        <MobileExpandCardTrigger onClick={open}>
          <div className="flex justify-between items-center text-sm">
            <div className="flex space-x-1 items-center">
              {errors?.tradingSettings?.buyAmount ||
              isErrorSnipeLaunchedTokens ||
              isErrorSnipeNotLaunchedTokens ||
              (!isWalletCopyTrading &&
                !watch('tradingSettings.oneFilterSelected')) ? (
                <div className="flex space-x-1 items-center xxs:text-red-500 ">
                  <ExclamationCircleIcon className="xxs:w-6 h-6" />
                  <div>Missing Information</div>
                </div>
              ) : (
                <>
                  <div className="xxs:text-dex-white-secondary">Buy:</div>
                  {isWalletCopyTrading ? (
                    <>
                      <div className="xxs:text-dex-white-white">
                        {buyOrderSettings.buyAmount ? (
                          <div className="xxs:text-dex-white">
                            {formatTokenAmount(buyOrderSettings.buyAmount)}{' '}
                            {chainAsset(chain)}
                          </div>
                        ) : (
                          <div className="xxs:text-dex-white-secondary">--</div>
                        )}
                      </div>
                      <div className="flex space-x-1 items-center">
                        <div className="xxs:text-dex-white-secondary">|</div>
                        <div className="flex xxs:space-x-1 items-center">
                          <img
                            className="h-4 w-4"
                            alt="gas icon"
                            src={GasIcon}
                          />
                          {buyOrderSettings?.maxBuyGasPriority ? (
                            <div className="xxs:text-dex-white">
                              {buyOrderSettings?.maxBuyGasPriority}{' '}
                              {gasPrioritySuffix(chain)}
                            </div>
                          ) : (
                            <div className="xxs:text-dex-white-secondary">
                              --
                            </div>
                          )}
                        </div>
                        <div className="xxs:text-dex-white-secondary">|</div>
                        <div className="flex xxs:space-x-1 items-center">
                          <ArrowTrendingDownIcon className="h-4 w-4 xxs:text-dex-white-secondary" />
                          <div>
                            {isNotNil(buyOrderSettings?.slippage) ? (
                              <div className="xxs:text-dex-white">
                                {buyOrderSettings?.slippage}%
                              </div>
                            ) : (
                              <div className="xxs:text-dex-white-secondary">
                                --
                              </div>
                            )}
                          </div>
                        </div>

                        {chainHasMemPool(chain) && (
                          <div className="xxs:text-dex-white-secondary">|</div>
                        )}
                        {chainHasMemPool(chain) && (
                          <div>
                            {buyOrderSettings.antiMev ? (
                              <div className="flex space-x-1 items-center">
                                <div className="xxs:text-dex-white-secondary">
                                  MEV
                                </div>
                                <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                              </div>
                            ) : (
                              <div className="flex space-x-1 items-center">
                                <div className="xxs:text-dex-white-secondary">
                                  MEV
                                </div>
                                <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="">
                        {buyOrderSettings.buyAmount ? (
                          <div className="xxs:text-dex-white">
                            {formatTokenAmount(buyOrderSettings.buyAmount)}{' '}
                            {chainAsset(chain)}
                          </div>
                        ) : (
                          <div className="xxs:text-dex-white-secondary">--</div>
                        )}
                      </div>
                      <div className="xxs:text-dex-white-secondary">|</div>
                      <div className="flex space-x-1 items-center">
                        <div className="xxs:text-dex-white-secondary">
                          Launched
                        </div>
                        {watch('tradingSettings.launchedFilter') ? (
                          <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                        ) : (
                          <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                        )}
                      </div>
                      <div>|</div>
                      <div className="flex space-x-1 items-center ">
                        <div className="xxs:text-dex-white-secondary">
                          Not Launched
                        </div>
                        {watch('tradingSettings.notLaunchedFilter') ? (
                          <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                        ) : (
                          <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </MobileExpandCardTrigger>
      )}
    >
      <DexCard size="xs">
        <Controller
          name="tradingSettings.buyAmount"
          defaultValue={'' as any}
          control={control}
          render={({ field, fieldState: { error } }) => {
            const value =
              typeof field.value === 'number'
                ? field.value
                : Number(field.value) || 0;

            return (
              <NumberInput
                {...field}
                label="Buy Amount"
                tooltip={`The amount (in ${chainAsset(
                  chain
                )}) that will be used to executed the buy transaction.`}
                suffix={<>{chainAsset(chain)}</>}
                extraLabel={
                  chainPrice ? (
                    <>
                      $
                      {formatUSDAmount(
                        Big(value ?? 0)
                          .mul(chainPrice)
                          ?.toString()
                      )}
                    </>
                  ) : undefined
                }
                error={error?.message}
              />
            );
          }}
        />
        <div className="flex xxs:gap-1 mb-1">
          {quickOptions.map((amount) => (
            <PresetButton
              key={amount}
              type="button"
              disabled={!selectedWallet}
              onClick={() => {
                if (selectedWallet?.chainBalance) {
                  setValue(
                    'tradingSettings.buyAmount',
                    Big(selectedWallet.chainBalance)
                      .times(amount)
                      .div(100)
                      .round(4, Big.roundDown)
                      .toNumber(),
                    {
                      shouldDirty: true,
                    }
                  );
                }
              }}
            >
              {amount}%
            </PresetButton>
          ))}
        </div>
        {isWalletCopyTrading ? (
          <div>
            <button
              type="button"
              onClick={() => setOpenWalletSettingsBox(!openWalletSettingsBox)}
              className={twMerge(
                ' m-0 p-0 font-normal normal-case justify-start w-full',
                isErrorSnipeLaunchedTokens ? 'text-red-500' : ''
              )}
            >
              <div className="flex justify-between items-center">
                <div className="xxs:text-dex-white secondary hover:text-dex-white flex space-x-1 items-center  xxs:text-dex-white-secondary hover:text-dex-white">
                  {isErrorSnipeLaunchedTokens && (
                    <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-4 h-4" />
                  )}
                  <Tooltip text="Set-up buy gas priority, slippage, and MEV protection.">
                    <div className="text-sm">
                      Gas, Slippage
                      {chainHasMemPool(chain) && (
                        <span>, and MEV protection</span>
                      )}
                    </div>
                  </Tooltip>
                </div>
                <div className="xxs:text-dex-white secondary hover:text-dex-white">
                  {openWalletSettingsBox ? (
                    <ChevronUpIcon className="h-4 w-4" />
                  ) : (
                    <ChevronDownIcon className="h-4 w-4" />
                  )}
                </div>
              </div>
            </button>
            {openWalletSettingsBox && (
              <>
                <RowLabelledInput
                  label={
                    <RowLabel
                      label={chainPriorityGasFeeTitle(
                        watch('tradingSettings.chain')
                      )}
                      tooltip={chainPriorityGasFeeTooltip(
                        watch('tradingSettings.chain')
                      )}
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name="tradingSettings.maxBuyGasPriority"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field, fieldState: { error } }) => (
                      <NumberInput
                        containerClassName="xxs:w-[108px]"
                        suffix={<>{gasPrioritySuffix(chain)}</>}
                        value={field.value as number}
                        onChange={(event) => {
                          setValue(
                            'tradingSettings.isMaxBuyGasPriorityEdited',
                            true
                          );

                          field.onChange(event.currentTarget.value);
                        }}
                        error={error?.message}
                        name={''}
                      />
                    )}
                  />
                </RowLabelledInput>

                <RowLabelledInput
                  label={
                    <RowLabel
                      label="Slippage"
                      tooltip="The slippage threshold for executing the buy transaction."
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name={'tradingSettings.slippage'}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          id="tradingSettings.slippage"
                          name="tradingSettings.slippage"
                          containerClassName="xxs:w-[108px]"
                          suffix={<>%</>}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </RowLabelledInput>
                {chainHasMemPool(chain) && (
                  <RowLabelledInput
                    label={
                      <RowLabel
                        label="MEV Protection"
                        tooltip="When active, the buy transaction will be executed as a private transaction. This will avoid your transaction to be front-run or sandwiched."
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name="tradingSettings.antiMev"
                      control={control}
                      defaultValue={'' as any}
                      render={({ field }) => (
                        <div className="flex items-center xxs:w-[108px] xxs:gap-2">
                          <Checkbox
                            checked={field.value as boolean}
                            onClick={field.onChange}
                            id="tradingSettings.antiMev"
                          />
                          <Checkbox.Label htmlFor="anti-mev">
                            Enabled
                          </Checkbox.Label>
                        </div>
                      )}
                    />
                  </RowLabelledInput>
                )}
                {chainHasBribe(chain) && watch('tradingSettings.antiMev') && (
                  <RowLabelledInput
                    label={
                      <RowLabel
                        label="Bribe Amount"
                        tooltip="Bribe is used to make transaction faster when using anti-mev."
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name="tradingSettings.bribe"
                      control={control}
                      defaultValue={'' as any}
                      render={({ field, fieldState: { error } }) => (
                        <NumberInput
                          containerClassName="xxs:w-[108px]"
                          suffix={<>{gasPrioritySuffix(chain)}</>}
                          value={field.value as number}
                          onChange={(event) => {
                            field.onChange(event.currentTarget.value);
                          }}
                          error={error?.message}
                          name={''}
                        />
                      )}
                    />
                  </RowLabelledInput>
                )}
              </>
            )}
          </div>
        ) : !chainHasMemPool(chain) ? (
          <>
            <RowLabelledCheckBox
              label={
                <RowLabel
                  label="Trade Launched Tokens"
                  tooltip="Enable it to allow the bot to trade tokens that are already launched."
                />
              }
              className="xxs:py-1"
            >
              <Controller
                name="tradingSettings.launchedFilter"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setValue('tradingSettings.launchedFilter', true);
                        setOpenBoxTradedTokens(true);
                      } else {
                        setValue('tradingSettings.launchedFilter', false);
                        setOpenBoxTradedTokens(false);
                      }
                      setOpenWalletSettingsBox(!openWalletSettingsBox);
                    }}
                    id="tradingSettings.launchedFilter"
                  />
                )}
              />
            </RowLabelledCheckBox>
            <RowLabelledCheckBox
              label={
                <RowLabel
                  label="Trade not Launched Tokens"
                  tooltip="Enable it to allow the bot to snipe not launched tokens at block-0 as soon as they're launched."
                />
              }
              className="xxs:py-1"
            >
              <Controller
                name="tradingSettings.notLaunchedFilter"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={field.onChange}
                    id="tradingSettings.notLaunchedFilter"
                  />
                )}
              />
            </RowLabelledCheckBox>
            {!watch('tradingSettings.oneFilterSelected') && (
              <p className="text-red-500 mb-0">
                One of the two token type filter has to be selected.
              </p>
            )}
            <div>
              <button
                type="button"
                onClick={() => setOpenWalletSettingsBox(!openWalletSettingsBox)}
                className={twMerge(
                  ' m-0 p-0 font-normal normal-case justify-start w-full',
                  isErrorSnipeLaunchedTokens ? 'text-red-500' : ''
                )}
              >
                <div className="flex justify-between items-center">
                  <div className="flex space-x-1 items-center">
                    {isErrorSnipeLaunchedTokens && (
                      <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-4 h-4" />
                    )}
                    <div className="text-sm">Gas, Slippage</div>
                    <Tooltip.Info text="Set-up buy gas priority and slippage." />
                  </div>
                  <div className="text-white">
                    {openWalletSettingsBox ? (
                      <ChevronUpIcon className="h-5 w-5" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" />
                    )}
                  </div>
                </div>
              </button>
              {openWalletSettingsBox && (
                <>
                  <RowLabelledInput
                    label={
                      <RowLabel
                        label={chainPriorityGasFeeTitle(
                          watch('tradingSettings.chain')
                        )}
                        tooltip={chainPriorityGasFeeTooltip(
                          watch('tradingSettings.chain')
                        )}
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name="tradingSettings.maxBuyGasPriority"
                      control={control}
                      defaultValue={'' as any}
                      render={({ field, fieldState: { error } }) => (
                        <NumberInput
                          containerClassName="xxs:w-[108px]"
                          suffix={<>{gasPrioritySuffix(chain)}</>}
                          value={field.value as number}
                          onChange={(event) => {
                            setValue(
                              'tradingSettings.isMaxBuyGasPriorityEdited',
                              true
                            );
                            field.onChange(event.currentTarget.value);
                          }}
                          error={error?.message}
                          name={''}
                        />
                      )}
                    />
                  </RowLabelledInput>

                  <RowLabelledInput
                    label={
                      <RowLabel
                        label="Slippage"
                        tooltip="The slippage threshold for executing the buy transaction."
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name={'tradingSettings.slippage'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <NumberInput
                            {...field}
                            id="tradingSettings.slippage"
                            name="tradingSettings.slippage"
                            containerClassName="xxs:w-[108px]"
                            suffix={<>%</>}
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </RowLabelledInput>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <RowLabelledCheckBox
              label={
                <div className="flex space-x-1 items-center ">
                  <RowLabel
                    label="Trade Launched Tokens"
                    isError={
                      watch('tradingSettings.launchedFilter') &&
                      isErrorSnipeLaunchedTokens
                    }
                    tooltip="Enable it to allow the bot to trade tokens that are already launched."
                  />
                </div>
              }
              className="xxs:py-1"
              openBox={openBoxTradedTokens}
              setOpenBox={
                watch('tradingSettings.launchedFilter')
                  ? () => setOpenBoxTradedTokens(!openBoxTradedTokens)
                  : undefined
              }
            >
              <Controller
                name="tradingSettings.launchedFilter"
                control={control}
                defaultValue={'' as any}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setValue('tradingSettings.launchedFilter', true);
                        setValue('tradingSettings.maxBuyGasPriority', 10);
                        setOpenBoxTradedTokens(true);
                      } else {
                        setValue('tradingSettings.launchedFilter', false);
                        setValue('tradingSettings.maxBuyGasPriority', 0);
                        setOpenBoxTradedTokens(false);
                      }
                      setOpenWalletSettingsBox(!openWalletSettingsBox);
                    }}
                    id="tradingSettings.launchedFilter"
                  />
                )}
              />
            </RowLabelledCheckBox>
            {openBoxTradedTokens && (
              <div className="border-tailwind xxs:border-dex-black-700 xxs:px-2 xxs:py-1 xxs:rounded">
                <RowLabelledInput
                  label={
                    <RowLabel
                      label={chainPriorityGasFeeTitle(
                        watch('tradingSettings.chain')
                      )}
                      tooltip={chainPriorityGasFeeTooltip(
                        watch('tradingSettings.chain')
                      )}
                    />
                  }
                >
                  <Controller
                    name="tradingSettings.maxBuyGasPriority"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field, fieldState: { error } }) => (
                      <NumberInput
                        containerClassName="xxs:w-[108px]"
                        suffix={<>{gasPrioritySuffix(chain)}</>}
                        value={field.value as number}
                        onChange={(event) => {
                          setValue(
                            'tradingSettings.isMaxBuyGasPriorityEdited',
                            true
                          );
                          field.onChange(event.currentTarget.value);
                        }}
                        error={error?.message}
                        name={''}
                      />
                    )}
                  />
                </RowLabelledInput>
                <RowLabelledInput
                  label={
                    <RowLabel
                      label="Slippage"
                      tooltip="The slippage threshold for executing your buy transaction. In case of limit orders, the same slippage is applied to each limit order step."
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name={'tradingSettings.slippage'}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          id="tradingSettings.slippage"
                          {...field}
                          containerClassName="xxs:w-[108px]"
                          suffix={<>%</>}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </RowLabelledInput>
                <RowLabelledInput
                  label={
                    <RowLabel
                      label="MEV Protection"
                      tooltip="Enable it to allow the bot to trade tokens that are already launched."
                    />
                  }
                  className="xxs:py-1"
                >
                  <Controller
                    name="tradingSettings.antiMev"
                    control={control}
                    defaultValue={'' as any}
                    render={({ field }) => (
                      <div className="flex items-center xxs:w-[108px] xxs:gap-2">
                        <Checkbox
                          checked={field.value as boolean}
                          onClick={field.onChange}
                          id="tradingSettings.antiMev"
                        />
                        <Checkbox.Label htmlFor="anti-mev">
                          Enabled
                        </Checkbox.Label>
                      </div>
                    )}
                  />
                </RowLabelledInput>
              </div>
            )}
            <RowLabelledCheckBox
              label={
                <RowLabel
                  label="Trade not Launched Tokens"
                  isError={isErrorSnipeNotLaunchedTokens}
                  tooltip="Enable it to allow the bot to snipe not launched tokens at block-0 as soon as they're launched."
                />
              }
              openBox={openBoxNotTradedTokens}
              setOpenBox={
                watch('tradingSettings.notLaunchedFilter')
                  ? () => setOpenBoxNotTradedTokens(!openBoxNotTradedTokens)
                  : undefined
              }
              className="xxs:py-1"
            >
              <Controller
                name="tradingSettings.notLaunchedFilter"
                control={control}
                defaultValue={'' as any}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        setValue('tradingSettings.notLaunchedFilter', true);
                        setValue(
                          'tradingSettings.bribe',
                          fromAmount(
                            chainDefaultBribeInBaseAsset(chain),
                            chain,
                            'weiToEther'
                          )
                        );
                        setOpenBoxNotTradedTokens(true);
                      } else {
                        setValue('tradingSettings.notLaunchedFilter', false);
                        setValue('tradingSettings.bribe', 0);
                        setOpenBoxNotTradedTokens(false);
                      }
                      setOpenWalletSettingsBox(!openWalletSettingsBox);
                    }}
                    id="tradingSettings.notLaunchedFilter"
                  />
                )}
              />
            </RowLabelledCheckBox>
            {openBoxNotTradedTokens && (
              <>
                <div className="border-tailwind xxs:border-dex-black-700 xxs:px-2 xxs:rounded">
                  <RowLabelledInput
                    label={
                      <RowLabel
                        label="Bribing Fee"
                        tooltip="The amount of gas fee as a tip you will pay to the miner to get ahead of other snipers and be the first to buy. In case the block-0 transaction fails, you won't lose your tip."
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name="tradingSettings.bribe"
                      control={control}
                      defaultValue={'' as any}
                      render={({ field, fieldState: { error } }) => (
                        <NumberInput
                          {...field}
                          containerClassName="xxs:w-[108px]"
                          id="buyOrder.entries.bribe"
                          suffix={<>{chainAsset(chain)}</>}
                          value={field.value as number}
                          error={error?.message}
                        />
                      )}
                    />
                  </RowLabelledInput>
                  <RowLabelledInput
                    label={
                      <RowLabel
                        label="Auto Retry"
                        tooltip="When enabled, the bot will automatically retry to snipe at block-1, in case the block-0 snipe fails."
                      />
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name={'tradingSettings.blockOneRetry'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className="flex items-center xxs:w-[108px] xxs:gap-2">
                            <Checkbox
                              checked={
                                field.value === undefined
                                  ? false
                                  : (field.value as boolean)
                              }
                              onClick={field.onChange}
                              onChange={(checked) => {
                                if (checked) {
                                  setValue(
                                    'tradingSettings.backupBribe',
                                    fromAmount(
                                      chainDefaultBackupBribeInBaseAsset(chain),
                                      chain,
                                      'weiToEther'
                                    )
                                  );
                                } else {
                                  setValue('tradingSettings.backupBribe', 0);
                                }
                              }}
                              id="tradingSettings.blockOneRetry"
                            />
                            <Checkbox.Label htmlFor="anti-mev">
                              Enabled
                            </Checkbox.Label>
                          </div>
                        );
                      }}
                    />
                  </RowLabelledInput>
                  {watch('tradingSettings.blockOneRetry') && (
                    <div className="flex xxs:gap-2 sm:p-2 xxs:p-2 border-tailwind xxs:border-dex-black-700 justify-between">
                      <Controller
                        name="tradingSettings.backupBribe"
                        control={control}
                        defaultValue={'' as any}
                        render={({ field, fieldState: { error } }) => (
                          <NumberInput
                            suffix={<>{chainAsset(chain)}</>}
                            value={field.value as number}
                            tooltip="The bribing fee that will be used when re-trying to snipe at block-1."
                            label="Backup Bribing Fee"
                            onChange={(event) =>
                              field.onChange(event.currentTarget.value)
                            }
                            error={error?.message}
                            name={''}
                          />
                        )}
                      />

                      <Controller
                        name={'tradingSettings.slippage'}
                        defaultValue={'' as any}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <NumberInput
                              suffix={<>%</>}
                              label="Slippage"
                              tooltip="The slippage that will applied when retry the snipe at block-1"
                              {...field}
                              error={error?.message}
                              name={''}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {!watch('tradingSettings.oneFilterSelected') && (
              <p className="text-red-500 mb-0">
                One of the two token type filter has to be selected.
              </p>
            )}
          </>
        )}
      </DexCard>
    </MobileExpandableCard>
  );
}

const quickOptions = [1, 3, 5, 10];
