import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import {
  ArrowTopRightOnSquareIcon,
  ChartBarSquareIcon,
  CheckIcon,
  ClockIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import {
  LeaderboardService,
  leaderboardServiceKeys,
} from 'api/services/httpServices/LeaderboardService';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { formatWithPrecision } from 'utils/FormatNumber';
import { twMerge } from 'tailwind-merge';
import Tooltip from 'modules/shared-components/tooltip';
import Button from 'modules/shared-components/button/button';
import { useToggle } from 'modules/utils/useToggle';
import {
  AlphaLeaderboardParams,
  PrizeLevel,
  UserResults,
} from 'api/types/httpsTypes/leaderboard';
import Header from 'modules/shared-components/data-display/table/header';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import { thDynamicClass } from '../../../research/components/HotTokens';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { desktopColumns, mobileColumns } from './columns';
import { PrizesModal } from './PrizeModal';
import { SelectSeasonDropdown } from '../alphaCalls/AlphaCallsFilters';
import { useEffect, useState } from 'react';
import { getSeasonForCurrentCalendarWeek } from '../seasons';
import Big from 'big.js';
import { DurationCounter } from '../../../research/components/TelegramCalls/columns';
import { NotificationDex } from '../../../components/alerts/notification';
import {
  StatsCard,
  StatsCardTitle,
  StatsCardValue,
} from '../../../research/components/StatsCard';
import {
  EmptyPageDex,
  ErrorPageDex,
} from '../../../research/components/shared/Common';

import Top10Calls from './top10Calls';

import { PerformanceStyle } from '../../../components/alerts/performanceStyle';

function getRemainingWeekStart() {
  const now = new Date(); // Current date and time
  const currentDay = now.getUTCDay(); // Day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)

  // Calculate the UTC timestamp for the end of the week (Sunday 23:59:59 UTC)
  const daysUntilSunday = 7 - (currentDay === 0 ? 7 : currentDay); // Days until Sunday
  const endOfWeek = new Date(now);
  endOfWeek.setUTCDate(now.getUTCDate() + daysUntilSunday);
  endOfWeek.setUTCHours(23, 59, 59, 999); // Set to 23:59:59 UTC

  return {
    start: now.toISOString(), // Current time as the "start"
    finishDate: endOfWeek.toISOString(), // End of the week as the "finishDate"
  };
}

const getCurrentPrize = (prizes: PrizeLevel[], value: number) => {
  // Find the lower bound: the highest eth less than or equal to the value
  if (value < prizes[0].minPnlQuote) {
    return { minPnlQuote: 0, prize: 0, index: -1 };
  }
  const lower = prizes.reduce(
    (prev, curr, currIndex) =>
      curr.minPnlQuote <= value
        ? { minPnlQuote: curr.minPnlQuote, prize: curr.prize, index: currIndex }
        : prev,
    { minPnlQuote: 0, prize: 0, index: -1 }
  );

  return { prize: lower.prize, index: lower.index };
};

const MAX_OFFSET = 2;
const AlphaScore = ({
  chain,
  prizes,
}: {
  chain: Chains;
  prizes: PrizeLevel[] | undefined;
}) => {
  const [isOpen, { open, close }] = useToggle();
  const isLargeScreen = useMediaQuery(screenGte.large);

  const [params, setParams] = useState<AlphaLeaderboardParams>({
    season: getSeasonForCurrentCalendarWeek(chain),
    chain,
  });

  const updateParams = (update: Partial<AlphaLeaderboardParams>) => {
    setParams({ ...params, ...update });
  };

  useEffect(() => {
    updateParams({ season: getSeasonForCurrentCalendarWeek(chain), chain });
  }, [chain]);

  const { data, isLoading, error } = useQuery({
    queryKey: leaderboardServiceKeys.getLeaderboard(params),
    queryFn: () => LeaderboardService.getLeaderboard(params),
    keepPreviousData: true,
  });

  const calculateWidth = (v: string, currentPrizeIndex: number) => {
    const maxLength = prizes ? prizes?.length - 1 ?? 0 : 0;
    const index =
      currentPrizeIndex >= maxLength - MAX_OFFSET
        ? maxLength
        : currentPrizeIndex + MAX_OFFSET;
    const max = prizes ? prizes?.[index].minPnlQuote : 1;

    if (+v < 0) {
      return '0%';
    }
    if (+v > max) {
      return '100%';
    }
    return `${(+v / max) * 100}%`;
  };

  if (error) {
    return <ErrorPageDex />;
  }
  const { start, finishDate } = getRemainingWeekStart();
  const currentPrize =
    data && prizes
      ? getCurrentPrize(prizes, Big(data.realizedPnlQuote ?? 0).toNumber())
          .prize
      : undefined;

  const currentPrizeIndex =
    data && prizes
      ? getCurrentPrize(prizes, Big(data.realizedPnlQuote ?? 0).toNumber())
          .index
      : 0;

  const statsData = [
    {
      key: 'maxWeeklyPrize',
      title: 'Weekly Prize',
      tooltip: 'The maximum prize awarded during this season.',
      value:
        data && currentPrize ? (
          <span>
            {formatWithPrecision(2, currentPrize)} {chainAsset(chain)}
          </span>
        ) : (
          '--'
        ),
    },
    {
      key: 'totalProfitLoss',
      title: 'Total Profit & Loss',
      tooltip:
        'The net profit and loss realized by the Mizar community through the Alpha program over the course of the season.',
      value: data ? (
        <span>
          <PerformanceStyle
            asset={chainAsset(chain)}
            className="xxs:text-lg xxs:font-semibold"
            pnl={Big(data.realizedPnlQuote ?? 0).toString()}
          />
        </span>
      ) : (
        '--'
      ),
    },
    {
      key: 'numberOfCalls',
      title: 'Number of Calls',
      tooltip: 'The total number of unique calls during the season.',
      value: data ? data.numberOfUniqueCalls : '--',
    },
    {
      key: 'numberOfCallers',
      title: 'Number of Callers',
      tooltip: 'The total number of unique callers during the season.',
      value: data ? data.totalCallers : '--',
    },
  ];

  return (
    <div className=" text-white xxs:p-2 lg:p-6 rounded-lg w-full mx-auto xxs:space-y-6 lg:space-y-10">
      <div className="lg:flex lg:justify-between lg:items-center">
        <div className="lg:flex xxs:space-y-2 lg:space-y-0 lg:space-x-2 lg:items-center">
          <div className="flex xxs:space-x-2 xxs:justify-between lg:justify-start items-center">
            <div className="text-xl font-bold ">PnL and Prizes</div>
            <SelectSeasonDropdown
              value={params.season}
              onChange={(e) => updateParams({ season: e })}
            />
          </div>

          {getSeasonForCurrentCalendarWeek(chain) === params.season ? (
            <NotificationDex type="info">
              <div className="flex items-center xxs:gap-1 text-sm">
                <ClockIcon className="w-5 h-5" />
                <div>Ends in:</div>
                <DurationCounter
                  maxBlocks={3}
                  start={start}
                  finishDate={finishDate}
                />
              </div>
            </NotificationDex>
          ) : (
            <NotificationDex type="info">FINISHED</NotificationDex>
          )}
        </div>
        <div className="hidden lg:flex space-x-2 items-center">
          <a
            target="_blank"
            rel="noreferrer"
            className="xxs:text-dex-white-secondary hover:text-dex-white flex space-x-1 items-center"
            href="https://discord.com/invite/gM4mAYMeWG"
          >
            <div>Share Alpha Now</div>
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </a>
          <Button
            className="xxs:p-2"
            variant="dexNeutral"
            onClick={open}
            disabled={!prizes}
            type="button"
          >
            CHECK PROGRAM
          </Button>
        </div>
      </div>

      {isOpen && prizes && <PrizesModal prizes={prizes} onClose={close} />}

      <div className="w-full lg:hidden xxs:space-y-2">
        {statsData.map((stat) => (
          <StatsCard key={stat.key} className="xxs:rounded-md ">
            <StatsCardValue className="xxs:text-lg xxs:font-semibold xxs:bg-transparent">
              <div className="">{stat.value}</div>
            </StatsCardValue>
            <StatsCardTitle>
              <div className="flex space-x-1 items-center xxs:text-dex-white-secondary hover:text-dex-white">
                <Tooltip text={stat.tooltip}>
                  <div>{stat.title}</div>
                </Tooltip>
              </div>
            </StatsCardTitle>
          </StatsCard>
        ))}
      </div>

      <div className="hidden lg:flex xxs:space-x-16 items-center ">
        <ChainsIcon className="w-12 h-12" imgName={chain} />

        {statsData.map(({ key, title, tooltip, value }) => (
          <Tooltip key={key} text={tooltip}>
            <div className="xxs:space-y-2">
              <div className="text-sm text-dex-white-secondary hover:text-dex-white">
                {title}
              </div>
              <div className="text-xl font-bold">{value}</div>
            </div>
          </Tooltip>
        ))}
      </div>

      <div className="">
        <div className="relative w-full h-2 bg-dex-black-600 rounded-full mb-2">
          <div
            className={twMerge(
              'absolute h-full  rounded-full',
              currentPrize
                ? 'bg-gradient-to-r from-green-500 to-red-500'
                : 'bg-red-500'
            )}
            style={{
              width:
                data && prizes
                  ? calculateWidth(data.realizedPnlQuote, currentPrizeIndex)
                  : 0,
            }}
          />
        </div>
        <div className="flex justify-between text-xs text-dex-white-secondary">
          <div className="xxs:space-y-4 flex flex-col">
            <ChartBarSquareIcon className="w-4 h-4 " />
            <TrophyIcon className="w-4 h-4 " />
          </div>

          <div className="relative w-full ">
            {data &&
              prizes &&
              prizes
                ?.slice(0, currentPrizeIndex + MAX_OFFSET + 1)
                .map((label, index) => {
                  const prizesLength =
                    prizes?.slice(0, currentPrizeIndex + MAX_OFFSET + 1)
                      .length - 1;
                  const lastMinPnlQuote =
                    prizes[currentPrizeIndex + MAX_OFFSET]?.minPnlQuote ?? 1;

                  return (
                    <Tooltip
                      key={index}
                      text={`By reaching a total realized PnL of ${
                        prizes[index].minPnlQuote
                      } ${chainAsset(
                        chain
                      )}, the prize pool will be ${formatWithPrecision(
                        2,
                        Big(prizes[index].prize.toLocaleString()).toNumber()
                      )} ${chainAsset(chain)}.`}
                    >
                      <div
                        className={twMerge(
                          'absolute flex flex-col text-dex-white-secondary hover:text-dex-white',
                          index !== prizesLength ? ' items-start' : 'items-end',
                          index < currentPrizeIndex + 1 &&
                            'text-green-500 hover:text-green-400'
                        )}
                        style={{
                          ...(index !== prizesLength
                            ? {
                                left: `${Big(label.minPnlQuote ?? 0)
                                  .div(lastMinPnlQuote)
                                  .mul(100)
                                  .toString()}%`,
                              }
                            : {}),
                          ...(index === prizesLength ? { right: '0' } : {}),
                          ...(index !== prizesLength
                            ? { transform: 'translateX(-50%)' }
                            : {}),
                        }}
                      >
                        <p className="text-xs text-nowrap flex xxs:space-x-0.5 items-center">
                          <div>{prizes[index].minPnlQuote} </div>
                          <div>{chainAsset(chain)}</div>
                          {index < currentPrizeIndex + 1 && (
                            <CheckIcon className="h-4 w-4" />
                          )}
                        </p>
                        <p className="text-xs text-nowrap">
                          {formatWithPrecision(
                            2,
                            Big(prizes[index].prize.toLocaleString()).toNumber()
                          )}{' '}
                          {chainAsset(chain)}
                        </p>
                      </div>
                    </Tooltip>
                  );
                })}
          </div>
        </div>
      </div>
      {!isLoading && !data?.totalCallers ? (
        <EmptyPageDex
          height={isLargeScreen ? '100px' : '50vh'}
          title="There are no calls for this season."
          subTitle={
            <div className="xxs:space-y-1">
              <p>
                It seems there are no alpha calls available for this season yet.
                Please wait a few hours and check back to discover the next gem!
              </p>
            </div>
          }
        />
      ) : (
        <>
          <div className="xxs:space-y-2">
            <div className="text-xl font-bold ">Top-5 Callers</div>
            <AlphaWinners
              chain={chain}
              isLoading={isLoading}
              leaderboard={data?.usersResults}
            />
          </div>
          <div className="xxs:space-y-2">
            <div className="text-xl font-bold ">Top-10 Tokens</div>
            <Top10Calls
              defaultParams={{
                ...params,
                isTopTen: true,
              }}
            />{' '}
          </div>
        </>
      )}
    </div>
  );
};

interface AlphaWinnersProps {
  chain: Chains;
  isLoading: boolean;
  leaderboard: UserResults[] | undefined;
}

function AlphaWinners({ chain, leaderboard, isLoading }: AlphaWinnersProps) {
  const isLargeScreen = useMediaQuery(screenGte.large);
  const columns = isLargeScreen ? desktopColumns(chain) : mobileColumns(chain);

  return (
    <div>
      <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
        <Header
          trClassName="items-center lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
          columns={columns}
          rowClassName="xxs:p-1"
          className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
        />
        <tbody className="lg:lg:block overflow-y-auto">
          {isLoading
            ? [...Array(5).keys()]
                .map((i) => i + 1)
                .map((index) => (
                  <Table.LoadingRow
                    thDynamicClass={thDynamicClass}
                    key={index}
                    elementClassName="xxs:dark:bg-dex-black-700"
                    className="lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800"
                    columns={columns}
                  />
                ))
            : leaderboard &&
              leaderboard?.map((item, index) => (
                <tr
                  key={index}
                  className={twMerge(
                    'border-t border-dex-black-700 items-center lg:grid lg:grid-cols-9',
                    'lg:dark:bg-dex-black-800'
                  )}
                >
                  <ErrorBoundary>
                    {columns.map((column) => {
                      const Column = column.component;
                      if (!Column) return;
                      return (
                        <Table.Td key={column.key}>
                          <Column data={item} />
                        </Table.Td>
                      );
                    })}
                  </ErrorBoundary>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
}

function Leaderboard({
  chain,
  prizes,
}: {
  chain: Chains;
  prizes: PrizeLevel[] | undefined;
}) {
  return (
    <div className="xxs:space-y-4">
      <AlphaScore prizes={prizes} chain={chain} />
    </div>
  );
}

export default Leaderboard;
