const isProd = process.env.REACT_APP_DOMAIN === 'mizar.com';

const contracts = isProd
  ? {
      staking: '0x2ccce38b80326950a6c9561c10973f496a649616' as const,
      mzrToken: '0xbbea044f9e7c0520195e49ad1e561572e7e1b948' as const,
    }
  : {
      staking: '0x014136dB6eF9554B0e451b434AaAeBddC9FeaA1e' as const,
      mzrToken: '0xdb0dbB35996cC2b2E024BaD7A9f39d9AdDdaCACD' as const,
    };

const getWebSocketUrl = () => {
  if (process.env.REACT_APP_DOMAIN === 'mizar.com') {
    return 'wss://ws.mizar.com';
  } else if (process.env.REACT_APP_DOMAIN === 'mizar-test.com') {
    return 'wss://ws.mizar-test.com';
  } else {
    return 'wss://ws.mizar-test.com';
  }
};

const config = {
  WEBSOCKET_URL: getWebSocketUrl(),
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  VERSION: process.env.REACT_APP_VERSION,
  ENCRYPTION_SEED: process.env.REACT_APP_ENCRYPTION_SEED
    ? process.env.REACT_APP_ENCRYPTION_SEED
    : '111-ASD-ASD-ASD-123',
  APPLICATION_DOMAIN: process.env.REACT_APP_DOMAIN
    ? process.env.REACT_APP_DOMAIN
    : 'mizar-test.com',
  IS_PROD: isProd,
  IS_E2E: process.env.REACT_APP_IS_E2E === 'true',
  featureFlags: [
    'friendsBreakdown',
    'smartTradeAddFunds',
    'dex-withdraw',
  ] as const,
  exchangesWithPassphrases: [
    'coinbasepro',
    'hyperliquid',
    'kucoin_futures',
    'kucoin',
    'bitget',
    'okx',
  ],
  hasExchangeRequiresPassphrase(exchangeName: string) {
    return this.exchangesWithPassphrases.some(
      (exchange) => exchange === exchangeName
    );
  },
  network: isProd ? 'arbitrum' : 'arbitrumGoerli',
  contracts,
  adminIds: [1, 2, 3, 614],
  walletConnectProjectId: '580d5cfb0975e5a0c82bc685ca1e6158',
  isTelegramBrowser: !!window.Telegram?.WebApp,
  // !!window.Telegram?.WebApp,
  isLocalTgDev: window.location.host.includes('ngrok'),
  // 6386136400
  telegramBotId: isProd ? 6542678169 : 6770268560,
  isMaintenance: process.env.REACT_APP_MAINTENANCE_MODE
    ? process.env.REACT_APP_MAINTENANCE_MODE
    : 'false',
};

export default config;
