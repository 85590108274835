import {
  PriceQuoteResponse,
  PriceTopNavbarResponse,
} from 'api/types/httpsTypes/pricing';
import { api } from 'helpers/api/apiCore';

const SERVICE_KEY = 'pricing';

export const priceServiceKeys = {
  getMzrPrice: () => [SERVICE_KEY, 'MZR'],
  getCryptoPrice: () => [SERVICE_KEY, 'eth-btc'],
  getGasAmount: () => [SERVICE_KEY, 'gas-amount'],
};

export const PriceService = {
  getMzrPrice: async () => {
    const response = await api.get<PriceQuoteResponse>(
      '/api/v2/token/price-quote'
    );
    return response.data.price;
  },
  getCryptoPrice: async () => {
    const response = await api.get<PriceTopNavbarResponse>(
      '/dashboard/api/price-top-navbar'
    );
    return response.data;
  },
  getGasAmount: () => {
    /*  const response = await api.get<number>('/dashboard/api/gas-amount');*/

    return 158969;
  },
};
