import { WebSocketContext } from 'api/services/websocket/websocketProvider';
import { useContext, useEffect } from 'react';
import { useSnackBar } from 'modules/layouts/SnackBar/context';

const WebsocketErrorsListener: React.FC = () => {
  const wsContext = useContext(WebSocketContext);
  const { addNewMessage } = useSnackBar();

  useEffect(() => {
    if (!wsContext) return;

    const handleGlobalMessage = (msg: any) => {
      if (msg?.type === 'too_many_connections') {
        addNewMessage({
          type: 'error',
          title: 'Too many tabs open!',
          message:
            'Please close any extra tabs and reload this page to restore live data updates.',
        });
      }
    };

    wsContext.addGlobalMessageListener(handleGlobalMessage);

    return () => {
      wsContext.removeGlobalMessageListener(handleGlobalMessage);
    };
  }, [wsContext, addNewMessage]);

  return null;
};

export default WebsocketErrorsListener;
