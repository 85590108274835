import config from '../../../config';
import { useParams } from 'react-router-dom';
import PageTemplate from '../components/layout';
import {
  ALPHA_LEADERBOARD_SIDEBAR,
  MAIN_INFO,
  Tabs,
} from '../components/layout/SIDEBAR_CONST';
import AlphaCalls from './pages/alphaCalls/alphaCalls';
import Leaderboard from './pages/leaderboard/leaderboard';
import { Banner } from './banner';
import History from './pages/history/history';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { EmptyPageDex } from '../research/components/shared/Common';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useQuery } from '@tanstack/react-query';
import {
  LeaderboardService,
  leaderboardServiceKeys,
} from 'api/services/httpServices/LeaderboardService';
import { useSelectedChain } from '../components/chains/getChains';


export default function AlphaProgram() {
  const { tab } = useParams<{ tab: Tabs }>();
  const selectedChain = useSelectedChain();
  const isLargeScreen = useMediaQuery(screenGte.large);
  const prizesParams = {
    chain: selectedChain,
  };

  const { data: prizes } = useQuery({
    queryKey: leaderboardServiceKeys.getPrizeStructure(prizesParams),
    queryFn: () => LeaderboardService.getPrizeStructure(prizesParams),
    enabled:
      selectedChain === Chains.Base || selectedChain === Chains.BinanceChain,
  });
  return (
    <PageTemplate
      className="xxs:px-1"
      mainInfo={MAIN_INFO.leaderboard.filter((info) => info.activeTab === tab)}
      sidebar={ALPHA_LEADERBOARD_SIDEBAR}
      pages={[
        ...(config.isTelegramBrowser
          ? [
              {
                href: '/dex/navigation',
                name: 'Navigation',
              },
            ]
          : []),

        {
          href: `/dex/leaderboard/${tab}`,
          name:
            tab === Tabs.AlphaCalls
              ? 'Alpha Calls'
              : tab === Tabs.Wallets
              ? 'Wallets'
              : tab === Tabs.OpenTrades
              ? 'Open Trades'
              : tab === Tabs.History
              ? 'History'
              : 'Tutorials',
        },
      ]}
    >
      <div className="xxs:space-y-2 xxs:pt-1">
        <Banner chain={selectedChain} prizes={prizes} />
        {selectedChain === Chains.Base ||
        selectedChain === Chains.BinanceChain ? (
          <div className="xxs:space-y-2">
            <div>
              {tab === Tabs.AlphaCalls && <AlphaCalls chain={selectedChain} />}
            </div>
            <div>
              {tab === Tabs.Leaderboard && (
                <Leaderboard prizes={prizes} chain={selectedChain} />
              )}
            </div>
            <div>
              {tab === Tabs.History && <History chain={selectedChain} />}
            </div>
            <div className="xxs:mx-4">
              <div className="xxs:text-dex-white-secondary rounded xxs:py-4">
                <div className="xxs:space-y-1">
                  <div className="font-bold xxs:text-[10px]">Disclaimer:</div>
                  <div className=" xxs:text-[10px]">
                    The Mizar Alpha program does not constitute financial
                    advice, either from Mizar, its employees, or its users. Each
                    interaction with the program serves solely an informational
                    purpose. Users are required to conduct their own due
                    diligence prior to making any investment or trading
                    decisions regarding the tokens referenced.
                  </div>
                  <div className=" xxs:text-[10px]">
                    Furthermore, users acknowledge and accept the inherent risks
                    associated with trading cryptocurrencies, including, but not
                    limited to, the possibility of the token being part of a
                    scam or rug-pull, or its value declining to zero. Users must
                    exercise caution and never invest more than they can afford
                    to lose entirely.
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyPageDex
            height={isLargeScreen ? '100px' : '50vh'}
            title="Mizar Alpha Program is not available for the selected chain yet."
            subTitle={
              <div className="xxs:space-y-1">
                <div>
                  The Mizar Alpha Program was launched a few months ago and is
                  currently available only on the BASE chain.
                </div>

                <div>
                  If you're interested in extending the Alpha Program to more
                  chains, please reach out to us on Discord!
                </div>
              </div>
            }
          />
        )}
      </div>
    </PageTemplate>
  );
}
