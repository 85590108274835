import createPersistedState from 'use-persisted-state';
import { History } from 'history';
import { Tabs } from '../layout/SIDEBAR_CONST';
import { Chains, defaultContracts } from 'api/types/httpsTypes/d-wallets';

export const useChainPersistedState =
  createPersistedState<Chains>('d-mizar-chain');

export const useSelectedChain = () => {
  const [selectedChain] = useChainPersistedState(Chains.Base);

  return selectedChain;
};
export const handleChainChange = ({
  chainValue,
  setSelectedChain,
  history,
  setAddress,
  setChain,
}: {
  chainValue: Chains;
  setSelectedChain: (arg0: Chains | ((prev: Chains) => Chains)) => void;
  history: History;
  setAddress: (arg0: string) => void;
  setChain: (arg0: Chains) => void;
}) => {
  const location = history.location.pathname;

  setSelectedChain(() => chainValue);

  if (chainValue) {
    const defaultContract = defaultContracts(chainValue);
    setChain(chainValue);
    setAddress(defaultContract);

    if (location.startsWith('/dex/snipe/new/')) {
      setTimeout(() => {
        history.push(`/dex/snipe/new/${chainValue}/${defaultContract}`);
      }, 100);
    } else if (
      location.startsWith('/dex/research/') &&
      !Object.values(Tabs).some((tab) => location.includes(tab))
    ) {
      setTimeout(() => {
        history.push(`/dex/research/${chainValue}/${defaultContract}`);
      }, 100);
    }
  }
};
