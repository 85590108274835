import { Chains } from 'api/types/httpsTypes/d-wallets';
import { chainAsset, minimumSafeAmount } from 'api/types/httpsTypes/d-wallets';
export const WarningLowBalanceMessage = ({ chain }: { chain: Chains }) => {
  const chainType = chain ?? Chains.Ethereum;

  return (
    <>
      <span className="hidden lg:inline-block">
        Warning! Your wallet balance is below {minimumSafeAmount(chainType)}{' '}
        {chainAsset(chainType)}. This could cause unexpected errors while
        managing your positions. Deposit {chainAsset(chainType)} to prevent
        future issues.
      </span>
      <span className="lg:hidden">
        Warning! Wallet balance below {minimumSafeAmount(chainType)}{' '}
        {chainAsset(chainType)} could cause unexpected errors. Deposit{' '}
        {chainAsset(chainType)}.
      </span>
    </>
  );
};
