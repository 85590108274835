import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'modules/shared-components/button/button';
import {
  FormValues,
  LimitBuyAmountSchema,
  isLimitBuyAmount,
  isMarketBuyAmount,
  MarketBuyAmountSchema,
  SnipeAmountSchema,
} from '../../schema';
import {
  DexTradeService,
  dexTradeKeys,
} from 'api/services/httpServices/DexTradeService';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  LimitOrderOperator,
  LimitOrderSide,
  TemplateSettings,
} from 'api/types/httpsTypes/dex-trade';
import { parseEther, parseGwei } from 'viem';
import { percentToRatio } from 'utils/number';
import { useLastUsedTemplate } from './TemplatesBar';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { yupTemplateName } from './RenameTemplateModal';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import {  chainHasBribe, chainHasMemPool, Chains } from 'api/types/httpsTypes/d-wallets';
import CustomModal from '../../../components/modal/CustomModal';
import { NotificationDex } from '../../../components/alerts/notification';
import TextInput from '../../../components/inputs/text-input/text-input';
import { parseAmount } from '../../../components/chains/units';

type Props = {
  handleClose: () => void;
  settings: FormValues;
};

export const schema = yup.object({
  name: yupTemplateName,
});

export type CreateTemplateFormState = yup.InferType<typeof schema>;

export function SaveTemplateAsModal({ settings, handleClose }: Props) {
  const [, setActive] = useLastUsedTemplate();
  const { addNewMessage } = useSnackBar();

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateTemplateFormState>({
    resolver: yupResolver(schema),
  });

  const { mutate, error, isLoading } = useMutation({
    mutationFn: DexTradeService.createTemplate,
    onSuccess: async (values) => {
      setActive(values.id);
      await queryClient.invalidateQueries(dexTradeKeys.getTemplates());
      handleClose();
      addNewMessage({
        message: 'Template created successfully',
        title: 'Success',
        type: 'success',
      });
    },
  });

  const onSubmit = handleSubmit(({ name }) => {
    mutate({
      isDefault: false,
      name,
      position: mapformValuesToTemplate(settings),
      chain: settings.chain,
      wallets: settings.wallets,
    });
  });

  return (
    <CustomModal title="New Settings" showModal handleClose={handleClose}>
      <form
        id="create-template"
        className="contents"
        onSubmit={(e) => {
          e?.stopPropagation();

          void onSubmit(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            New Settings
          </CustomModal.Title>
          <CustomModal.Content>
            <TextInput
              {...register('name')}
              type="text"
              label="Give a name to your new settings"
              form="create-template"
              error={errors?.name?.message}
            />
            {error && (
              <NotificationDex type="error" errorMessage={error}>
                An error occurred.
              </NotificationDex>
            )}
          </CustomModal.Content>
        </CustomModal.Body>

        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            form="create-template"
            type="submit"
            loading={isLoading}
          >
            Save
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}

export function limitValuesToApi(
  entries: LimitBuyAmountSchema,
  chain: Chains,
  retry: boolean
) {
  return {
    buyAmountInWei: '0',
    antiMev: chainHasMemPool(chain) ? entries.antiMev : false,
    maxPriorityFeePerGasWei: '0',
    maxSlippage: '0',
    ...(entries.antiMev && chainHasBribe(chain)
      ? {
          bribe: parseAmount(
            entries.bribe.toString() ?? '0',
            chain,
            'etherToWei'
          ).toString(),
        }
      : {}),
    limitOrders: entries.orders?.map(
      ({ id, priceDeviationPercentage, amount, price }) => ({
        id,
        priceDeviationPercentage: -percentToRatio(
          priceDeviationPercentage || 0
        ),
        retry,
        ...(price ? { price } : {}),
        amount: +parseAmount(amount.toString(), chain, 'etherToWei').toString(),
        maxSlippage: percentToRatio(entries.slippage ?? 0).toString(),
        antiMev: chainHasMemPool(chain) ? entries.antiMev ?? false : false,
        maxPriorityFeePerGasWei: parseAmount(
          entries.maxBuyGasPriority.toString(),
          chain,
          'gweiToWei'
        ).toString(),
        ...(entries.antiMev && chainHasBribe(chain)
          ? {
              bribe: parseAmount(
                entries.bribe.toString() ?? '0',
                chain,
                'etherToWei'
              ).toString(),
            }
          : {}),
        side: LimitOrderSide.Buy,
        comparisonOperator: LimitOrderOperator.LessThan,
        expirationInHours: entries.expirationInHours,
      })
    ),
  };
}

export function marketValuesToApi(
  entries: MarketBuyAmountSchema,
  chain: Chains
) {
  return {
    buyAmountInWei: parseAmount(
      entries.amount.toString() ?? '0',
      chain,
      'etherToWei'
    ).toString(),
    antiMev: chainHasMemPool(chain) ? entries.antiMev : false,
    maxPriorityFeePerGasWei: parseAmount(
      entries.maxBuyGasPriority?.toString() ?? '0',
      chain,
      'gweiToWei'
    ).toString(),
    ...(entries.antiMev && chainHasBribe(chain)
      ? {
          bribe: parseAmount(
            entries.bribe.toString() ?? '0',
            chain,
            'etherToWei'
          ).toString(),
        }
      : {}),
    maxSlippage: percentToRatio(entries.slippage ?? 0).toString(),
  };
}

export function snipeValuesToApi(entries: SnipeAmountSchema, chain: Chains) {
  return {
    buyAmountInWei: parseAmount(
      entries.amount.toString() ?? '0',
      chain,
      'etherToWei'
    ).toString(),
    bribe: parseAmount(
      entries.bribe.toString() ?? '0',
      chain,
      'etherToWei'
    ).toString(),
    maxSlippage:
      entries.blockOneRetry && entries.slippage
        ? percentToRatio(entries.slippage as number).toString()
        : '0',
    maxPriorityFeePerGasWei: '0',
    blockOneRetry: entries.blockOneRetry,
    ...(entries.blockOneRetry
      ? {
          backupBribe: parseAmount(
            entries.backupBribe?.toString() ?? '0',
            chain,
            'etherToWei'
          ).toString(),
        }
      : {}),
  };
}

export function sharedValuesToApi(values: FormValues) {
  return {
    chain: values.chain,
    honeypotProtection: values.safetyMeasures.honeypotProtection,
    maxBuyTax: percentToRatio(values.safetyMeasures.taxProtection.buy || 0),
    maxSellTax: percentToRatio(values.safetyMeasures.taxProtection.sell || 0),
    ...(values.safetyMeasures.gasLimitEnabled
      ? {
          gasLimit: values.safetyMeasures.gasLimit as number,
        }
      : {}),
    maxLiquidityInUsd: values.safetyMeasures.liquidity.max || 0,
    minLiquidityInUsd: values.safetyMeasures.liquidity.min || 0,
    maxMarketcapInUsd: values.safetyMeasures.marketCap.max || 0,
    minMarketcapInUsd: values.safetyMeasures.marketCap.min || 0,
    vaultId: values.wallets && values.wallets[0],
    takeProfits: values.takeProfit
      ? values.takeProfit.map(({ id, threshold, weight }) => ({
          id,
          antiMev: chainHasMemPool(values.chain)
            ? values.takeProfitConfig.antiMev
            : false,
          retry: values.safetyMeasures.autoRetryEnabled
            ? values.safetyMeasures.autoRetry.takeProfit
            : false,
          priceIncreasePercentage: percentToRatio(threshold).toString(),
          amountToSellPercentage: percentToRatio(weight).toString(),
          maxPriorityFeePerGasWei: parseAmount(
            values.takeProfitConfig.maxSellGasPriority.toString(),
            values.chain,
            'gweiToWei'
          ).toString(),
          ...(values.takeProfitConfig.antiMev && chainHasBribe(values.chain)
            ? {
                bribe: parseAmount(
                  values.takeProfitConfig.bribe.toString() ?? '0',
                  values.chain,
                  'etherToWei'
                ).toString(),
              }
            : {}),
          maxSlippage: percentToRatio(
            values.takeProfitConfig.slippage ?? 0
          ).toString(),
        }))
      : [],
    trailingStopLoss: values.stopLoss
      ? {
          amountToSellPercentage: '1',
          retry: values.safetyMeasures.autoRetryEnabled
            ? values.safetyMeasures.autoRetry.stopLoss
            : false,
          antiMev: chainHasMemPool(values.chain)
            ? values.takeProfitConfig.antiMev
            : false,
          initialStopLossPercentage: percentToRatio(
            -values.stopLoss.threshold
          ).toString(),
          maxPriorityFeePerGasWei: parseAmount(
            values.takeProfitConfig.maxSellGasPriority.toString(),
            values.chain,
            'gweiToWei'
          ).toString(),
          ...(values.takeProfitConfig.antiMev && chainHasBribe(values.chain)
            ? {
                bribe: parseAmount(
                  values.takeProfitConfig.bribe.toString() ?? '0',
                  values.chain,
                  'etherToWei'
                ).toString(),
              }
            : {}),
          maxSlippage: percentToRatio(
            values.takeProfitConfig.slippage ?? 0
          ).toString(),
          trailingDeviationPercentage: percentToRatio(
            values.stopLoss.deviation || 100
          ).toString(),
        }
      : null,
  };
}

export function mapformValuesToTemplate(values: FormValues): TemplateSettings {
  const buyOrder = values.buyOrder;

  if (isLimitBuyAmount(buyOrder.kind, buyOrder.entries)) {
    return {
      ...sharedValuesToApi(values),
      ...limitValuesToApi(
        values.buyOrder.entries as LimitBuyAmountSchema,
        values.chain,
        values.safetyMeasures.autoRetryEnabled
          ? values.safetyMeasures.autoRetry.buy
          : false
      ),
    };
  }

  if (isMarketBuyAmount(buyOrder.kind, buyOrder.entries)) {
    return {
      ...sharedValuesToApi(values),
      ...marketValuesToApi(
        values.buyOrder.entries as MarketBuyAmountSchema,
        values.chain
      ),
    };
  } else {
    return {
      ...sharedValuesToApi(values),
      ...snipeValuesToApi(
        values.buyOrder.entries as SnipeAmountSchema,
        values.chain
      ),
    };
  }
}
