import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { WalletSwapsDetails } from 'api/types/httpsTypes/contracts';
import { FC } from 'react';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import SwapsModal from './swaps/SwapsModal';
import { useToggle } from 'modules/utils/useToggle';

interface Props {
  columnsDef: ResponsiveTableColumn<WalletSwapsDetails>[];
  data: WalletSwapsDetails;
  isFirst?: boolean;
}

export const TableRow: FC<Props> = ({ data, columnsDef }) => {
  const [isOpen, { open, close }] = useToggle();

  return (
    <tr
      onClick={open}
      className="border-t border-dex-black-700 xxs:dark:bg-dex-black-800 cursor-pointer items-center hover:dark:lg:bg-dex-black-700 xl:grid xl:grid-cols-7"
    >
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return;
          return (
            <Table.Td key={column.key}>
              <Column data={data} />
            </Table.Td>
          );
        })}
        {isOpen && (
          <SwapsModal
            chain={data.chain}
            fromDatetime={data.firstBuyTimestamp}
            makerAddress={data.makerAddress}
            handleClose={close}
            tradedTokenId={data.tradedTokenId}
          />
        )}
      </ErrorBoundary>
    </tr>
  );
};
