import Routes from './routes/Routes';
import './assets/scss/Saas.scss';
import './assets/css/tailwind.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from './config';
import * as moment from 'moment';
import 'moment/min/locales';
import RedirectContextProvider from 'modules/routing/RedirectContext';
import SidebarContextProvider from 'modules/shared-components/layout/Sidebar/context';
import DexSwitchProvider from 'modules/DexSwitch/DexSwitchContext';
import { WebAppProvider } from 'modules/telegram/WebAppProvider';
import { WebSocketProvider } from 'api/services/websocket/websocketProvider';
import { UserContextProvider } from 'modules/user/UserContext';
import { ConnectionStatusProvider } from 'modules/routing/ConnectionStatusProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const locale = window.navigator.language;
  moment.locale(locale);
  return (
    <WebAppProvider>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID!}>
          <RedirectContextProvider>
            <SidebarContextProvider>
              <DexSwitchProvider>
                <UserContextProvider>
                  <ConnectionStatusProvider>
                    <WebSocketProvider>
                      <Routes />
                    </WebSocketProvider>
                  </ConnectionStatusProvider>
                </UserContextProvider>
              </DexSwitchProvider>
            </SidebarContextProvider>
          </RedirectContextProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </WebAppProvider>
  );
};

export default App;
