import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import { usePageParams } from './usePageParams';
import { useQuery } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { NotificationDex } from '../../components/alerts/notification';
import WalletHeader from './WalletHeader';
import Portfolio from './Portfolio';
import { BalancesLoading, MobileDWalletSelector } from './components';
import { useSelectedChain } from '../../components/chains/getChains';

export function WalletDetails({ isDashboard }: { isDashboard?: boolean }) {
  const [{ walletAddress }] = usePageParams();
  const walletChain = useSelectedChain();

  const {
    isLoading,
    data: wallet,
    error,
  } = useQuery({
    queryFn: () =>
      DWalletService.getWalletWithAddress(walletChain, walletAddress as string),
    queryKey: dWalletServiceKeys.getWalletWithAddress(
      walletChain,
      walletAddress as string
    ),
    enabled: !!(walletChain && walletAddress),
    keepPreviousData: true,
  });

  if (isLoading) {
    return <BalancesLoading />;
  }

  if (error || !wallet) {
    return (
      <div className="xxs:space-y-2  ">
        <div className="flex justify-end xxs:px-2">
          <MobileDWalletSelector
            onCreated={() => {}}
            selectedChain={walletChain}
          />
        </div>

        <NotificationDex
          className="xxs:mx-2 lg:mx-0"
          errorMessage={error}
          type="error"
        >
          Wallet not found.
        </NotificationDex>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        !isDashboard
          ? 'xxs:p-2 lg:p-0'
          : 'h-[700px] bg-dex-black-800 xxs:p-2 rounded '
      )}
    >
      <WalletHeader wallet={wallet} />
      <Portfolio wallet={wallet} walletChain={walletChain} />
    </div>
  );
}
