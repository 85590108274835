/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import {
  PoolRowInfo,
  PoolsTableSortKeys,
} from 'api/types/httpsTypes/contracts';
import {
  ClockIcon,
  ChartBarSquareIcon,
  FireIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/outline';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import Tooltip from 'modules/shared-components/tooltip';
import { useUserState } from 'modules/user/UserContext';
import { AddToFavouritesButton } from '../../../components';
import { toPercentageChange } from 'modules/utils/number';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import {
  DurationCounter,
  PercentageChangeWithMaxValue,
  SecurityChecks,
  SecurityChecksMobile,
} from '../TelegramCalls/columns';
import { Link } from 'react-router-dom';
import Button from 'modules/shared-components/button/button';
import dexScreenerImg from '../../../../../assets/img/brands/dexscreener.svg';
import { ReactComponent as TwitterIcon } from '../../../../../assets/img/brands/twitter.svg';
import Tag from '../../../components/alerts/Tags';
import ManageTokensModal from '../../../bots/dashboard/whitelistedTokens/ManageTokensModal';
import { useToggle } from 'modules/utils/useToggle';
import { useQuery } from '@tanstack/react-query';
import {
  dexBotKeys,
  DexBotService,
} from 'api/services/httpServices/DexBotService';

import { GetWhitelistedTokensParams } from 'api/types/httpsTypes/dex-bot';
import AddNewTokenModal from '../../../bots/dashboard/whitelistedTokens/AddNewTokenModal';
import React from 'react';
import { useSelectedChain } from '../../../components/chains/getChains';

export const desktopColumns: TableColumn<PoolRowInfo>[] = [
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The Token address, chain, and DEX.">
          <div>Token</div>
        </Tooltip>
      </div>
    ),
    key: 'PAIR',
    component: ({ data }) => (
      <span
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="flex items-center xxs:gap-2">
          <div className="relative xxs:hidden md:block">
            <TokenIconWithChain
              hasIcon={data.hasIcon}
              tokenName={data.token0Symbol}
              chain={data.chain?.key ?? Chains.Ethereum}
              protocol={data.dex?.key}
              symbol={data.token0Symbol}
              className="w-6 h-6"
              address={data.tokenAddress}
            />
          </div>
          <div className="max-w-[120px] overflow-hidden truncate">
            <span>
              <span className="font-bold text-sm">{data.token0Symbol}</span>/
              <span className="xxs:text-dex-white-secondary xxs:text-xs">
                {data.token1Symbol}
              </span>
            </span>
            <div>
              <EthAddress
                className="text-xs"
                address={data.tokenAddress}
                precision={3}
                kind="token"
                chain={data.chain?.key ?? Chains.Ethereum}
              />
            </div>
          </div>
        </div>
      </span>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The time at which the token was listed.">
          <div>Listed Since</div>
        </Tooltip>
      </div>
    ),
    key: 'LISTED SINCE',
    sortableKey: PoolsTableSortKeys.CreatedAt,
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="flex items-center xxs:gap-1 text-xs">
          <ClockIcon className="w-5 h-5" />
          <DurationCounter start={data.creationDatetime} />
        </div>
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current price and fully diluted market cap.">
          <div>Price/MC</div>
        </Tooltip>
      </div>
    ),
    key: 'PRICE/MC',
    sortableKey: PoolsTableSortKeys.Marketcap,
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        {data.price && (
          <div>
            <span className="xxs:text-dex-white-secondary text-xs">P: </span>
            <span className="text-xs">
              ${formatNumberWithSuffix(data.price)}
            </span>{' '}
            {data.currentPrice &&
              data.listingPrice &&
              data.status !== 'RUG_PULL' && (
                <PercentageChangeWithMaxValue
                  maxValue={999}
                  className="xxs:text-xs"
                  change={toPercentageChange(
                    data.listingPrice,
                    data.currentPrice
                  )}
                />
              )}
          </div>
        )}
        {data.marketcap && (
          <div>
            <span className="xxs:text-dex-white-secondary text-xs">MC: </span>
            <span className="text-xs">
              ${formatNumberWithSuffix(data.marketcap, { precision: 1 })}
            </span>
          </div>
        )}
        {!data.price && !data.marketcap && '--'}
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The total liquidity (as sum of token and quote asset in USD) at launch and now, on the major pool.">
          <div>Liquidity</div>
        </Tooltip>
      </div>
    ),
    key: 'TOTAL LIQUIDITY',
    sortableKey: PoolsTableSortKeys.Liquidity,
    component: ({ data }) => {
      return (
        <div
          className={
            data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
          }
        >
          {data.totalLiquidityUsd ? (
            <div>
              <div className="flex space-x-1 items-center">
                <div className="xxs:text-dex-white-secondary text-xs">
                  <Tooltip text="Liquidity at launch">
                    <PlayCircleIcon className="h-4 w-4" />
                  </Tooltip>
                </div>
                {data.totalLiquidityUsdAtLaunch ? (
                  <div className="text-xs">
                    $
                    {formatNumberWithSuffix(data.totalLiquidityUsdAtLaunch, {
                      precision: 1,
                    })}
                  </div>
                ) : (
                  <div>--</div>
                )}
              </div>
              <div className="flex space-x-1 items-center">
                <div className="xxs:text-dex-white-secondary">
                  <Tooltip text="Liquidity now">
                    <ClockIcon className="h-4 w-4" />
                  </Tooltip>
                </div>
                <div className="text-xs">
                  $
                  {formatNumberWithSuffix(data.totalLiquidityUsd, {
                    precision: 1,
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div>--</div>
          )}
        </div>
      );
    },
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current pool liquidity expressed in quote asset.">
          <div>Pool Amount</div>
        </Tooltip>
      </div>
    ),
    key: 'POOL AMOUNT',
    component: ({ data }) =>
      data.ethLiquidity ? (
        <div
          className={
            data.status === 'RUG_PULL'
              ? 'xxs:text-dex-white-secondary text-xs'
              : 'text-xs'
          }
        >
          {formatNumberWithSuffix(data.ethLiquidity, { precision: 1 })}{' '}
          {chainAsset(data.chain.key)}
        </div>
      ) : (
        <>--</>
      ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="Important security checks such as honeypot, low liquidity, and others.">
          <div>Security</div>
        </Tooltip>
      </div>
    ),
    key: 'SECURITY',
    component: ({ data }) => <SecurityChecks data={data} />,
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        <Tooltip text="The current status of the token, which could be launched, not launched, or rug-pull in the event of a scam.">
          <div>Status</div>
        </Tooltip>
      </div>
    ),
    key: 'STATUS',
    component: ({ data }) => (
      <div className="text-xs">
        {data.status === 'SNIPEABLE' ? (
          <Tag condensed type="info">
            Not Launched
          </Tag>
        ) : data.status === 'NOT_SNIPEABLE' ? (
          <Tag condensed type="info">
            Launched
          </Tag>
        ) : (
          <Tag condensed type="error">
            Rug-pull
          </Tag>
        )}
      </div>
    ),
  },
  {
    label: (
      <div className="xxs:text-dex-white-secondary hover:text-dex-white">
        Actions
      </div>
    ),
    key: 'ACTIONS',
    component: ({ data }) => (
      <Actions
        tokenAddress={data.tokenAddress}
        chain={data.chain.key}
        symbol={data.token0Symbol}
        poolAddress={data.poolAddress}
      />
    ),
  },
];

export const mobileColumns: TableColumn<PoolRowInfo>[] = [
  {
    key: 'PAIR',
    component: ({ data }) => (
      <div
        className={
          data.status === 'RUG_PULL' ? 'xxs:text-dex-white-secondary' : ''
        }
      >
        <div className="flex justify-between items-center ">
          <div className="flex items-center xxs:gap-2">
            <div className="relative">
              <TokenIconWithChain
                hasIcon={data.hasIcon}
                tokenName={data.token0Symbol}
                chain={data.chain?.key ?? Chains.Ethereum}
                protocol={data.dex?.key}
                symbol={data.token0Symbol}
                className="w-6 h-6"
                address={data.tokenAddress}
              />
            </div>
            <div className="max-w-[120px] overflow-hidden truncate">
              <div>
                <span className="font-bold text-sm">{data.token0Symbol}</span>/
                <span className="xxs:text-dex-white-secondary xxs:text-xs">
                  {data.token1Symbol}
                </span>
              </div>
              <div>
                <EthAddress
                  className="text-xs"
                  address={data.tokenAddress}
                  precision={3}
                  kind="token"
                  chain={data.chain?.key ?? Chains.Ethereum}
                />
              </div>
            </div>
          </div>
          <div className="justify-end flex flex-col items-end">
            {data.price ? (
              <div className="flex space-x-1 items-end">
                <div className="font-bold text-lg">
                  $
                  {formatNumberWithSuffix(data.price, {
                    precision: 1,
                  })}
                </div>
                {data.marketcap && (
                  <div className="text-xs pb-[3px]">
                    MC: $
                    {formatNumberWithSuffix(data.marketcap, { precision: 1 })}
                  </div>
                )}
              </div>
            ) : (
              <>--</>
            )}
            <div className="flex space-x-1 items-center">
              {data.totalLiquidityUsd ? (
                <div className="flex space-x-1 items-end">
                  <div className="text-xs">
                    Pool: $
                    {formatNumberWithSuffix(data.totalLiquidityUsd, {
                      precision: 1,
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              <SecurityChecksMobile data={data} />
              <div className="text-xs">
                {data.status === 'SNIPEABLE' ? (
                  <Tag condensed type="info">
                    Not Launched
                  </Tag>
                ) : data.status === 'NOT_SNIPEABLE' ? (
                  <Tag condensed type="info">
                    Launched
                  </Tag>
                ) : (
                  <Tag condensed type="error" className="text-white">
                    Rug-pull
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
];

interface Props {
  tokenAddress: string;
  close: () => void;
  symbol: string;
}

export function WhitelistButton({ tokenAddress, close, symbol }: Props) {
  const selectedChain = useSelectedChain();
  const params: GetWhitelistedTokensParams = {
    limit: 1,
    search: tokenAddress,
    offset: 0,
    withAnalytics: true,
    chain: selectedChain,
  };

  const { data: page } = useQuery({
    queryKey: dexBotKeys.getWhitelistedTokens(params),
    queryFn: () => DexBotService.getWhitelistedTokens(params),
    keepPreviousData: true,
  });

  if (!page) {
    return <></>;
  }
  if (page.data.length === 0) {
    return <AddNewTokenModal tA={tokenAddress} handleClose={close} />;
  } else {
    const bots = page ? page.data[0]?.bots : undefined;
    return bots ? (
      <ManageTokensModal
        isBotsLoading={false}
        symbol={symbol}
        address={tokenAddress}
        chain={selectedChain}
        handleClose={close}
        bots={bots}
      />
    ) : (
      <></>
    );
  }
}

export function Actions({
  tokenAddress,
  chain,
  symbol,
  poolAddress,
}: {
  tokenAddress: string;
  symbol: string;
  chain: Chains;
  poolAddress: string;
}) {
  const { user } = useUserState();
  const [openModal, { open, close }] = useToggle();

  return (
    <div
      className="flex items-center xxs:gap-1"
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        to={`/dex/snipe/new/${chain}/${tokenAddress}`}
        as={Link}
        type="button"
        variant="primary"
        className="xxs:text-dex-white hover:text-dex-white xxs:p-1"
      >
        <FireIcon className="xxs:w-5 xxs:h-5" />
      </Button>
      {user && (
        <Button
          type="button"
          onClick={open}
          className="xxs:p-1"
          variant="dexNeutral"
        >
          <ChartBarSquareIcon className="xxs:w-5 xxs:h-5" />
        </Button>
      )}
      {user && (
        <AddToFavouritesButton
          className="xxs:py-1 items-center flex justify-center xxs:px-1.5 hidden lg:block xxs:bg-dex-black-700 hover:xxs:bg-dex-black-600 xxs:rounded hover:text-dex-white xxs:text-dex-white-secondary"
          contractAddress={tokenAddress}
          chain={chain}
        />
      )}

      <a
        rel="noopener noreferrer"
        className="xxs:py-1 xxs:px-1.5 hidden lg:block xxs:bg-dex-black-700 hover:xxs:bg-dex-black-600 xxs:rounded hover:text-dex-white xxs:text-dex-white-secondary"
        target="_blank"
        href={`https://twitter.com/search?q=$${tokenAddress}`}
      >
        <TwitterIcon className="w-4 h-4 xxs:bg-black dark:bg-white rounded-full p-[3px] dark:fill-black fill-white" />
      </a>

      <a
        rel="noopener noreferrer"
        target="_blank"
        className="xxs:py-1 xxs:px-1.5 hidden lg:block xxs:bg-dex-black-700 hover:xxs:bg-dex-black-600 xxs:rounded hover:text-dex-white xxs:text-dex-white-secondary"
        href={`https://dexscreener.com/${
          chain === Chains.Ethereum
            ? 'ethereum'
            : chain === Chains.Solana
            ? 'solana'
            : chain
        }/${poolAddress}`}
      >
        <img src={dexScreenerImg} alt="dexscreener logo" className="w-4 h-4" />
      </a>
      {openModal && (
        <WhitelistButton
          close={close}
          symbol={symbol}
          tokenAddress={tokenAddress}
        />
      )}
    </div>
  );
}
