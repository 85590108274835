import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DexBotService,
  dexBotKeys,
} from 'api/services/httpServices/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import Button from 'modules/shared-components/button/button';
import { BotType } from 'api/types/httpsTypes/dex-bot';
import CustomModal from '../modal/CustomModal';
import { NotificationDex } from '../alerts/notification';

type Props = {
  handleClose: () => void;
  id: string;
  botType: BotType;
};

export function PauseDexBotModal({ handleClose, id, botType }: Props) {
  const { addNewMessage } = useSnackBar();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: () => DexBotService.pauseBot(id, botType),
    onSuccess: async () => {
      await queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        message: 'Bot paused successfully',
        title: 'Success',
        type: 'success',
      });
      handleClose();
    },
  });

  return (
    <CustomModal title="Pause bot" handleClose={handleClose} showModal>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Pause Bot
        </CustomModal.Title>

        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div>
              <p>By pausing this bot, you will:</p>
              <ul>
                <li>Stop opening new trades</li>
              </ul>
              <p>
                The current open trades will still be managed (Take Profit,
                Anti-rug and more)
              </p>
            </div>
          </div>
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          loading={isLoading}
          className="xxs:text-base lg:text-xs"
          onClick={() => {
            mutate();
          }}
        >
          Pause
        </PrimaryButton>
        <Button
          type="button"
          className="xxs:text-base lg:text-xs"
          variant="dexNeutral"
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
}
