import { useState, useEffect } from 'react';
import { useWebsocket } from 'api/hooks/useWebsocket';
import {
  DexTradeStatus,
  OpenPositionListItem,
} from 'api/types/httpsTypes/dex-trade';
import { useUser } from 'modules/user/user';
import { Chains } from 'api/types/httpsTypes/d-wallets';

interface PositionUpdateMessage {
  u: number;
  f: DexTradeStatus;
  p: OpenPositionListItem;
}

export const usePositionUpdate = (
  chain: Chains
): PositionUpdateMessage | null => {
  const [position, setPosition] = useState<PositionUpdateMessage | null>(null);
  const user = useUser();

  const message = useWebsocket({
    channel: 'position_update',
    data: { positionType: 'open' },
  });

  useEffect(() => {
    if (!message || !message.data) return;

    try {
      const parsedMessage: PositionUpdateMessage = message.data;
      if (parsedMessage.p.chain !== chain) {
        return;
      }
      if (parsedMessage.u !== user?.id) {
        console.error('❌ Unauthorized message.');
        return;
      }
      setPosition(parsedMessage);
    } catch (error) {
      console.error('❌ Error parsing position update message:', error);
    }
  }, [message]);

  return position;
};
