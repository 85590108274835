import { usePositionUpdate } from 'api/hooks/usePositionUpdate';
import { useEffect } from 'react';
import { formatTokenAmount } from 'utils/FormatNumber';
import { chainAsset, chainName } from 'api/types/httpsTypes/d-wallets';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { DexTradeStatus } from 'api/types/httpsTypes/dex-trade';
import { fromAmount } from '../chains/units';
import { useSelectedChain } from '../chains/getChains';

export enum UpdateActions {
  Open = 'open',
  Close = 'close',
  Cancel = 'cancel',
  FailedToSnipe = 'failedToSnipe',
}

function updateMapping(
  fromStatus: DexTradeStatus,
  toStatus: DexTradeStatus
): UpdateActions | null {
  if (
    fromStatus === DexTradeStatus.new &&
    toStatus === DexTradeStatus.snipeInProgress
  ) {
    return UpdateActions.Open;
  } else if (
    fromStatus === DexTradeStatus.sniped &&
    toStatus === DexTradeStatus.closedInProgress
  ) {
    return UpdateActions.Close;
  } else if (
    fromStatus === DexTradeStatus.sniped &&
    toStatus === DexTradeStatus.cancelledInProgress
  ) {
    return UpdateActions.Cancel;
  } else if (
    (fromStatus === DexTradeStatus.new ||
      fromStatus === DexTradeStatus.snipeInProgress) &&
    toStatus === DexTradeStatus.failedToSnipe
  ) {
    return UpdateActions.FailedToSnipe;
  } else return null;
}

const PositionUpdateListener: React.FC = () => {
  const selectedChain = useSelectedChain();

  const updatedPosition = usePositionUpdate(selectedChain);
  const { addNewMessage } = useSnackBar(); // ✅ Get alert function
  useEffect(() => {
    if (updatedPosition) {
      const { f, p } = updatedPosition;
      const symbol = p.tokenSymbol;
      const chain = p.chain;
      const toStatus = p.status;
      const fromStatus = f;

      if (updateMapping(fromStatus, toStatus) === UpdateActions.Open) {
        const amount = fromAmount(p?.buyAmountInWei ?? 0, chain, 'weiToEther');

        addNewMessage({
          type: 'success',
          title: 'New Trade',
          message: `Bought ${formatTokenAmount(amount)} ${chainAsset(
            chain
          )} of ${symbol} on ${chainName(chain)}`,
        });
      } else if (
        updateMapping(fromStatus, toStatus) === UpdateActions.FailedToSnipe
      ) {
        addNewMessage({
          type: 'error',
          title: 'Trade Cancelled',
          message: `${symbol} trade buy failed.`,
        });
      } else if (updateMapping(fromStatus, toStatus) === UpdateActions.Close) {
        const profit = Number(p?.totalProfitAndLoss?.pnlAmountInEth ?? 0);
        const isProfit = +profit > 0;

        addNewMessage({
          type: isProfit ? 'success' : 'error',
          title: 'Trade Closed',
          message: `${symbol} trade closed on ${chainName(chain)} with ${
            isProfit ? '+' : ''
          }${formatTokenAmount(profit)} ${chainAsset(chain)} ${
            isProfit ? 'profit' : 'loss'
          } `,
        });
      } else if (updateMapping(fromStatus, toStatus) === UpdateActions.Cancel) {
        addNewMessage({
          type: 'success',
          title: 'Trade Cancelled',
          message: `${symbol} trade has been cancelled.`,
        });
      }
    }
  }, [updatedPosition]);

  return null;
};

export default PositionUpdateListener;
