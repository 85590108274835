import { BaseButton } from 'modules/shared-components/button/base-button';
import { twMerge } from 'tailwind-merge';

export function PresetButton(props: React.ComponentProps<typeof BaseButton>) {
  return (
    <BaseButton
      type="button"
      {...props}
      className={twMerge(
        'xxs:rounded-lg xxs:text-center xxs:bg-white-300 hover:bg-white-600 dark:text-black-50 dark:hover:bg-black-600 xxs:font-semibold xxs:text-lg dark:bg-black-700 xxs:p-4 flex flex-col items-center justify-center',
        props.className
      )}
    />
  );
}
export function PresetButtonTitle(props: React.ComponentProps<'p'>) {
  return <div {...props} className={twMerge('xxs:mb-0', props.className)} />;
}
export function PresetButtonDescription(props: React.ComponentProps<'p'>) {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:mb-0 xxs:text-sm xxs:font-normal',
        props.className
      )}
    />
  );
}
