import { mobileColumns } from './columns';
import { GetSwapsParams } from 'api/types/httpsTypes/contracts';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import ErrorBoundary from 'components/error-boundaries';
import { SwapsDetailsTable } from './SwapsDetailsTable';
import { Chains } from 'api/types/httpsTypes/d-wallets';

const Last10Swaps = ({
  makerAddress,
  chain,
}: {
  makerAddress: string;
  chain: Chains;
}) => {
  const params: GetSwapsParams = {
    makerAddress,
    chain,
  };
  const columns = mobileColumns;

  const { data, error, isLoading } = useQuery({
    queryKey: contractServiceKeys.getSwapsDetails(params),
    queryFn: () => ContractService.getSwapsDetails(params),
    keepPreviousData: true,
    enabled: !!makerAddress,
  });

  return makerAddress ? (
    <ErrorBoundary>
      <div className="space-y-1 max-h-[500px] overflow-y-scroll">
        {isLoading && (
          <div className="flex">
            <Table className="min-w-full [&_td:first-of-type:not(.details)]:pl-0 [&_th:first-of-type]:pl-0">
              <Header columns={columns} className="xxs:dark:bg-dex-black-800" />
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => (
                  <Table.LoadingRow
                    elementClassName="xxs:dark:bg-dex-black-700"
                    columns={columns}
                    key={key}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {error && (
          <div className="text-red-500">Error while loading swaps...</div>
        )}
        {data?.length === 0 && (
          <div className="text-red-500">No swaps founds...</div>
        )}
        {data && data?.length !== 0 && !error && (
          <SwapsDetailsTable isLast10Swaps={true} data={data} />
        )}
      </div>
    </ErrorBoundary>
  ) : (
    <></>
  );
};

export default Last10Swaps;
