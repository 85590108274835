import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { onFulfilled, onRejected } from './axios-interceptor';
import { UserV1 } from 'api/types/httpsTypes/user';

export const transactionId = `${Date.now()}-${Math.random().toString(36)}`;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['X-Original-Forwarded-For'] =
  window.location.host.includes('localhost') ||
  window.location.host.includes('ngrok')
    ? '0.0.0.0'
    : undefined;
axios.defaults.headers.common['X-Transaction-Id'] = transactionId;

if (process.env.REACT_APP_CLOUDFLARE_E2E_SERVICE_TOKEN) {
  axios.defaults.headers.common['CF-Access-Client-Id'] =
    process.env.REACT_APP_CLOUDFLARE_E2E_SERVICE_TOKEN;
  axios.defaults.headers.common['CF-Access-Client-Secret'] =
    process.env.REACT_APP_CLOUDFLARE_E2E_SERVICE_TOKEN_SECRET;
}
/*
axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);*/
axios.interceptors.response.use(onFulfilled, onRejected);

type ParamsObject = Record<string, unknown>;

export class APICore {
  /**
   * Fetches data from given url
   */
  get<Response = unknown, Query extends ParamsObject = {}>(
    url: string,
    params?: Query
  ) {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : '';
      response = axios.get<Response>(`${url}?${queryString}`, params);
    } else {
      response = axios.get<Response>(`${url}`, params);
    }
    return response;
  }

  /**
   * post given data to url
   */
  create = <Response = any>(url: string, data: FormData) => {
    const formData = new FormData();
    if (data && data instanceof FormData) {
      for (const value of data.entries()) {
        if (
          value[1] === 'undefined' ||
          value[1] === 'null' ||
          value[1] === undefined ||
          value[1] === null
        ) {
          // formData.append(value[0], null)
          continue;
        }
        formData.append(value[0], value[1]);
      }
    }

    return axios.post<Response>(url, data instanceof FormData ? formData : {});
  };

  post = <Body extends ParamsObject, Response = any>(
    url: string,
    data?: Body
  ) => {
    const isWrong = (value: unknown) =>
      value === 'undefined' ||
      value === 'null' ||
      value === undefined ||
      value === null;
    const payload = Object.fromEntries(
      Object.entries(data ?? {}).filter(([, value]) => !isWrong(value))
    );

    return axios.post<Response>(url, payload);
  };

  /**
   * Updates patch data
   */
  updatePatch = <Body, Response = unknown>(url: string, data: Body) => {
    return axios.patch<Response>(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatchJson = <Body, Response = unknown>(url: string, data: Body) => {
    return axios.patch<Response>(url, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  /**
   * Updates data
   */
  update = <Body extends {}, Response = any>(url: string, data: Body) => {
    return axios.put<Response>(url, data);
  };

  /**
   * Deletes data
   */
  delete = <Response = unknown>(url: string) => {
    return axios.delete<Response>(url);
  };
}

export function hasActiveJWT(user: UserV1) {
  const decoded = jwtDecode<{ exp: number }>(user.access_token);
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    const refreshDecoded = jwtDecode<{ exp: number }>(user.refresh_token);
    if (refreshDecoded.exp < currentTime) {
      console.warn('refresh token expired');
      return false;
    }
  }
  return true;
}

export const api = new APICore();
