import { FC } from 'react';
import CustomModal from '../../../components/modal/CustomModal';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import Header from 'modules/shared-components/data-display/table/header';
import Table from 'modules/shared-components/data-display/table';
import ErrorBoundary from 'components/error-boundaries';
import Tooltip from 'modules/shared-components/tooltip';
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import goldImg from 'assets/img/trader-levels/gold.png';
import silverImg from 'assets/img/trader-levels/silver.png';
import bronzeImg from 'assets/img/trader-levels/bronze.png';
import fourthImg from 'assets/img/trader-levels/wood.png';
import { formatWithPrecision } from 'utils/FormatNumber';
import { PrizeLevel } from 'api/types/httpsTypes/leaderboard';
import { useSelectedChain } from '../../../components/chains/getChains';

interface Props {
  onClose: () => void;
  prizes: PrizeLevel[];
}

export const desktopColumns = (chain: Chains): TableColumn<PrizeLevel>[] => {
  return [
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The league of the Alpha Program is determined by the total profit realized during the season by the Mizar community on the alpha calls.">
            <div>League</div>
          </Tooltip>
        </div>
      ),
      key: 'LEAGUE',
      component: ({ data }) => <span>{data.level}</span>,
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit range from the Mizar community based on the alpha calls during the season.">
            <div>Realized PnL</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL',
      component: ({ data }) => (
        <span>
          {`>`} {data.minPnlQuote} {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The weekly prize pool to be distributed among winners.">
            <div> Prize</div>
          </Tooltip>
        </div>
      ),
      key: 'PRIZE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, data.prize)} {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Prize for the first position—awarded to the caller whose alpha call generated the highest realized profit for the Mizar community.">
            <div>
              <img alt="gold" className="h-[25px]" src={goldImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'GOLD_PRIZE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, data.first)} {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Prize for the second position—awarded to the caller whose alpha call generated the second-highest realized profit for the Mizar community.">
            <div>
              <img alt="silver" className="h-[25px]" src={silverImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'SILVER_PRIZE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, data.second)} {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Prize for the third position—awarded to the caller whose alpha call generated the third-highest realized profit for the Mizar community.">
            <div>
              <img alt="bronze" className="h-[25px]" src={bronzeImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'BRONZE_PRIZE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, data.third)} {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Prize for the fourth position—awarded to the caller whose alpha call generated the fourth-highest realized profit for the Mizar community.">
            <div>
              <img alt="fourth" className="h-[25px]" src={fourthImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'FOURTH_PRIZE',
      component: ({ data }) => (
        <span>
          {data.fourth !== null
            ? `${formatWithPrecision(2, data.fourth)} ${chainAsset(chain)}`
            : '--'}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="Prize for the fifth position—awarded to the caller whose alpha call generated the fifth-highest realized profit for the Mizar community.">
            <div>
              <img alt="fifth" className="h-[25px]" src={fourthImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'FIFTH_PRIZE',
      component: ({ data }) => (
        <span>
          {data.fifth !== null
            ? `${formatWithPrecision(2, data.fifth)} ${chainAsset(chain)}`
            : '--'}
        </span>
      ),
    },
  ];
};

export const PrizesModal: FC<Props> = ({ onClose, prizes }) => {
  const selectedChain = useSelectedChain();
  const columns = desktopColumns(selectedChain);

  return (
    <CustomModal
      size="xxLarge"
      title="Alpha Program Prizes"
      showModal
      handleClose={onClose}
    >
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex">
          Alpha Program Prizes
        </CustomModal.Title>
        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div>
              <p>
                Join the{' '}
                <a
                  className="xxs:text-dex-white hover:text-dex-white"
                  href="https://discord.com/invite/gM4mAYMeWG"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mizar Alpha Program
                </a>{' '}
                and compete{' '}
                <b>
                  to win up to{' '}
                  {prizes
                    ? formatWithPrecision(
                        1,
                        prizes[prizes.length - 1]?.prize ?? 0
                      )
                    : '--'}{' '}
                  {chainAsset(selectedChain)} every week
                </b>
                , shared among the top 5 winners!
              </p>
              <p className="font-bold">How Does It Work?</p>
              <p>
                To participate, simply join our{' '}
                <a
                  className="xxs:text-dex-white hover:text-dex-white"
                  href="https://discord.com/invite/gM4mAYMeWG"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  official Discord channel
                </a>
                , and start calling alpha using the command <code>/alpha</code>{' '}
                followed by a token contract. It's as simple as that!
              </p>
              <p className="font-bold">Key Rules:</p>
              <ul>
                <li>
                  Participants can make up to 10 alpha calls in the official{' '}
                  <a
                    className="xxs:text-dex-white-secondary hover:text-dex-white"
                    href="https://discord.com/invite/gM4mAYMeWG"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mizar Discord channel
                  </a>
                  . First come, first served.
                </li>
                <li>
                  Callers are ranked based on the total profit realized by the
                  Mizar community from their calls, starting from the moment
                  they are made.
                </li>
                <li>
                  Rewards are tied to overall {chainAsset(selectedChain)} profit
                  generated by the community over the season.
                </li>
                <li>
                  Weekly Seasons: Each season begins on Monday at 00:00:00 UTC,
                  with prizes distributed at the end of the week.
                </li>
                <li>
                  The top 10 tokens each week, based on generated profit, will
                  be ranked. The trader who originally called these tokens
                  retains the exclusive right to call them again the following
                  week—even if others have called them during the week.
                </li>
              </ul>
            </div>
            <a
              className="xxs:text-dex-white hover:text-dex-white block xxs:pb-2"
              href="https://docs.mizar.com/sniper-bot-manual/alpha-program"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex xxs:space-x-1 items-center">
                <div>Read Full Program</div>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </div>
            </a>
            <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
              <Header
                trClassName="items-center lg:grid lg:grid-cols-8 lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
                columns={columns}
                rowClassName="xxs:p-1"
                className="lg:dark:bg-dex-black-800 xxs:text-xs lg:grid"
              />
              <tbody className="lg:lg:block overflow-y-auto">
                {prizes?.map((item, index) => (
                  <tr
                    key={index}
                    className="border-t border-dex-black-700 lg:dark:bg-dex-black-800 items-center lg:grid lg:grid-cols-8"
                  >
                    <ErrorBoundary>
                      {columns.map((column) => {
                        const Column = column.component;
                        if (!Column) return;
                        return (
                          <Table.Td key={column.key}>
                            <Column data={item} />
                          </Table.Td>
                        );
                      })}
                    </ErrorBoundary>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
};
