import { useRef, useState, useCallback } from 'react';
import config from '../../../config';

export interface Subscription {
  channel: string;
  data: any;
  auth?: boolean;
}

interface WebSocketServerReturn {
  subscribe: (subscription: Subscription) => void;
  unsubscribe: (subscription: Subscription) => void;
  addGlobalMessageListener: (callback: (msg: any) => void) => void;
  removeGlobalMessageListener: (callback: (msg: any) => void) => void;
  isConnected: boolean | undefined;
  isConnecting: boolean;
  getSocket?: () => WebSocket | null;
  connect: () => void;
  disconnect: () => void;
  waitForSocketOpen: () => Promise<void>;
}

const WEBSOCKET_URL = config.WEBSOCKET_URL;

// 🔹 Generate or retrieve a persistent session token (for rate limiting)
const getSessionToken = () => {
  const existingToken = localStorage.getItem('websocketSessionToken');
  if (existingToken) return existingToken;

  const randomToken = crypto.randomUUID(); // Secure random token
  localStorage.setItem('websocketSessionToken', randomToken);
  return randomToken;
};

export const useWebSocketServer = (): WebSocketServerReturn => {
  const [isConnected, setIsConnected] = useState<boolean | undefined>(
    undefined
  );
  const [isConnecting, setIsConnecting] = useState(false);

  const socketRef = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);

  const MAX_RETRIES = 10; // Stop after 10 failed attempts
  const BASE_DELAY = 1000; // 1 sec
  const MAX_RECONNECT_DELAY = 5 * 60 * 1000; // 5 minutes

  const sendMessage = (message: any) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
    } else {
      console.warn('⚠️ WebSocket not connected. Message not sent:', message);
    }
  };

  const connectWebSocket = useCallback(() => {
    if (
      socketRef.current &&
      socketRef.current.readyState !== WebSocket.CLOSED
    ) {
      socketRef.current.close(); // Close existing connection first
    }

    const socket = new WebSocket(`${WEBSOCKET_URL}?token=${getSessionToken()}`);
    socketRef.current = socket;

    setIsConnecting(true);

    socket.onopen = () => {
      setIsConnected(true);
      setIsConnecting(false);
    };

    socket.onclose = () => {
      setIsConnected(false);
      setIsConnecting(false);

      if (reconnectAttempts.current >= MAX_RETRIES) {
        console.log('🚫 Max retries reached. Stopping reconnection attempts.');
        return; // Stop retrying
      }

      // Reconnect with exponential backoff
      const delay = Math.min(
        BASE_DELAY * Math.pow(2, reconnectAttempts.current),
        MAX_RECONNECT_DELAY
      );
      reconnectAttempts.current += 1;

      setTimeout(() => {
        console.log(`🔄 Reconnecting WebSocket in ${delay / 1000} sec...`);
        connectWebSocket();
      }, delay);
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);

        const isGlobalMessage = !message.channel && message.type;

        if (isGlobalMessage) {
          globalListeners.current.forEach((fn) => fn(message));
        }
      } catch (err) {
        console.error('❌ Failed to parse WebSocket message:', err);
      }
    };

    socket.onerror = (error) => {
      console.error('❌ WebSocket Error:', error);
      if (socket.readyState !== WebSocket.CLOSED) {
        socket.close(); // Trigger onclose logic
      }
    };
  }, []);

  const subscribe = (subscription: Subscription) => {
    sendMessage({
      action: 'subscribe',
      channel: subscription.channel,
      data: subscription.data,
    });
  };

  const unsubscribe = (subscription: Subscription) => {
    sendMessage({
      action: 'unsubscribe',
      channel: subscription.channel,
      data: subscription.data,
    });
  };

  const globalListeners = useRef<((msg: any) => void)[]>([]);

  const addGlobalMessageListener = (callback: (msg: any) => void) => {
    globalListeners.current.push(callback);
  };

  const removeGlobalMessageListener = (callback: (msg: any) => void) => {
    globalListeners.current = globalListeners.current.filter(
      (fn) => fn !== callback
    );
  };

  const waitForSocketOpen = (): Promise<void> => {
    return new Promise((resolve) => {
      const socket = socketRef.current;

      if (socket?.readyState === WebSocket.OPEN) {
        return resolve();
      }

      const interval = setInterval(() => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          clearInterval(interval);
          resolve();
        }
      }, 50); // check every 50ms
    });
  };

  const disconnect = () => {
    reconnectAttempts.current = 0;

    if (socketRef.current) {
      console.log('🔌 Disconnecting WebSocket manually...');
      socketRef.current.onopen = null;
      socketRef.current.onclose = null;
      socketRef.current.onerror = null;
      socketRef.current.onmessage = null;
      socketRef.current.close();
      socketRef.current = null;
    }

    setIsConnected(false);
    setIsConnecting(false);
  };


  return {
    subscribe,
    unsubscribe,
    isConnected,
    getSocket: () => socketRef.current,
    addGlobalMessageListener,
    removeGlobalMessageListener,
    connect: connectWebSocket,
    disconnect,
    isConnecting,
    waitForSocketOpen,
  };
};
