import { Chains } from 'api/types/httpsTypes/d-wallets';
import { PreSetFilters, PreSets } from '../schema';
import {
  PresetButton,
  PresetButtonDescription,
  PresetButtonTitle,
} from '../../../components/PresetButton';

import {
  GetHotTokensParams,
  HotTokensRowInfo,
  HotTokensSortKeys,
} from 'api/types/httpsTypes/contracts';
import { useQuery } from '@tanstack/react-query';
import { ContractService } from 'api/services/httpServices/ContractService';
import React, { useState } from 'react';
import { NotificationDex } from '../../../components/alerts/notification';
import CustomModal from '../../../components/modal/CustomModal';
import { LoaderDex } from '../../../components/alerts/Loader';
import {
  RowLabel,
  RowLabelledCheckBox,
} from '../../../components/RowLabelledInput';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Checkbox from '../../../components/inputs/Checkbox';
import NumberInput from '../../../components/inputs/number-input';
import * as yup from 'yup';
import {
  HotTokensPeriods,
  TokenLaunchPeriods,
} from '../../../research/components/Wallets/usePageParams';
import {
  PeriodFilterButtons,
  Sort,
  TokenLaunchPeriodFilterMobile,
} from '../../../research/components/HotTokens/HotTokensFilters';
import { yupResolver } from '@hookform/resolvers/yup';
import { calculateWordWidth } from 'modules/shared-components/input/number-input/calculateLengthPixel';
import Button from 'modules/shared-components/button/button';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Tooltip from 'modules/shared-components/tooltip';
import { TagButton } from '../../../trade/components/templates/TemplatesBar';
import createPersistedState from 'use-persisted-state';
import { LeaderboardService } from 'api/services/httpServices/LeaderboardService';
import { getSeasonForCurrentCalendarWeek } from '../../../alpha-leaderboard/pages/seasons';
import {
  AlphaCallsParams,
  AlphaCallsResponse,
} from 'api/types/httpsTypes/leaderboard';
import StarLevelService, {
  starLevelKeys,
} from 'api/services/httpServices/StarLevelService';
import { useUserState } from 'modules/user/UserContext';

type Props = {
  handleClose: () => void;
  chain: Chains;
  replace: (arg0: { address: string }[]) => void;
};

interface FilterValidAddressProps {
  suspiciousBuy: number;
  rugAddictedBuy: number;
  scammerBuy: number;
  freshBuy: number;
  totalBuyTop: number;
  totalBuy: number;
  tokenAddress: string;
}

const filterValidAddresses = ({
  data,
  slice,
}: {
  data: FilterValidAddressProps[];
  slice: number;
}) => {
  const MINIMUM_ALERT_TRIGGER = 0.2;

  return data
    .filter((row) => {
      const denominator = row.totalBuy ?? 1;
      return (
        row.totalBuyTop > 10 &&
        (row.suspiciousBuy / denominator || 0) < MINIMUM_ALERT_TRIGGER &&
        (row.rugAddictedBuy / denominator || 0) < MINIMUM_ALERT_TRIGGER &&
        (row.scammerBuy / denominator || 0) < MINIMUM_ALERT_TRIGGER &&
        (row.freshBuy / denominator || 0) < MINIMUM_ALERT_TRIGGER
      );
    })
    .slice(0, slice)
    .map((row) => ({ address: row.tokenAddress }));
};

function usePreSet(
  preSet:
    | PreSets
    | 'top-10-alpha'
    | 'last-10-alpha'
    | Partial<TokensFormValues>
    | undefined,
  handleClose: () => void,
  chain: Chains,
  replace: (arg0: { address: string }[]) => void
) {
  const isAlphaPreset = preSet === 'last-10-alpha' || preSet === 'top-10-alpha';

  const alphaParams: Partial<AlphaCallsParams> & {
    chain: Chains;
    season: string;
  } = {
    season: getSeasonForCurrentCalendarWeek(chain) ?? '1',
    chain: chain ?? Chains.Base,
    ...(preSet === 'last-10-alpha' ? { limit: 10 } : {}),
    ...(preSet === 'last-10-alpha' ? { offset: 0 } : {}),
    ...(preSet === 'last-10-alpha' ? { isMaster: true } : {}),
    ...(preSet === 'top-10-alpha' ? { isTopTen: true } : {}),
  };

  const tokenParams: GetHotTokensParams | null =
    !isAlphaPreset && preSet
      ? typeof preSet === 'string' && preSet in PreSetFilters
        ? { ...PreSetFilters[preSet], chain }
        : {
            sort:
              (preSet as Partial<TokensFormValues>).sort ??
              `-${HotTokensSortKeys.TotalBuyTop}`,
            period:
              (preSet as Partial<TokensFormValues>).period ||
              HotTokensPeriods.SixHours,
            ...((preSet as Partial<TokensFormValues>).poolCreatedAt !==
            undefined
              ? {
                  poolCreatedAt: (preSet as Partial<TokensFormValues>)
                    .poolCreatedAt,
                }
              : {}),
            ...((preSet as Partial<TokensFormValues>).liquidity?.min !==
            undefined
              ? {
                  minLiquidity: (preSet as Partial<TokensFormValues>).liquidity
                    ?.min,
                }
              : {}),
            ...((preSet as Partial<TokensFormValues>).liquidity?.max !==
            undefined
              ? {
                  maxLiquidity: (preSet as Partial<TokensFormValues>).liquidity
                    ?.max,
                }
              : {}),
            ...((preSet as Partial<TokensFormValues>).volume?.min !== undefined
              ? { minVolume: (preSet as Partial<TokensFormValues>).volume?.min }
              : {}),
            ...((preSet as Partial<TokensFormValues>).volume?.max !== undefined
              ? { maxVolume: (preSet as Partial<TokensFormValues>).volume?.max }
              : {}),
            ...((preSet as Partial<TokensFormValues>).marketCap?.min !==
            undefined
              ? { minMc: (preSet as Partial<TokensFormValues>).marketCap?.min }
              : {}),
            ...((preSet as Partial<TokensFormValues>).marketCap?.max !==
            undefined
              ? { maxMc: (preSet as Partial<TokensFormValues>).marketCap?.max }
              : {}),
            chain,
          }
      : null;

  const { isLoading: alphaLoading, isError: alphaError } = useQuery({
    queryKey: ['getAlphaCalls', alphaParams],
    queryFn: () => LeaderboardService.getAlphaCalls(alphaParams),
    enabled: !!(preSet && isAlphaPreset), // Ensures this query only runs when `isAlphaPreset` is true
    onSuccess: (data: AlphaCallsResponse) => {
      if (!data?.data) return;
      replace(
        filterValidAddresses({
          data: data.data.map((d) => ({
            suspiciousBuy: d.hotTokenStats.suspiciousBuy,
            rugAddictedBuy: d.hotTokenStats.rugAddictedBuy,
            scammerBuy: d.hotTokenStats.scammerBuy,
            freshBuy: d.hotTokenStats.freshBuy,
            totalBuyTop: d.hotTokenStats.totalBuyTop,
            totalBuy: d.hotTokenStats.totalBuy,
            tokenAddress: d.address,
          })),
          slice: 10,
        })
      );
      handleClose();
    },
  });

  const { isLoading: tokenLoading, isError: tokenError } = useQuery({
    queryKey: [
      'getHotTokens',
      tokenParams ?? { ...PreSetFilters[PreSets.SmartMoney], chain },
    ],
    queryFn: () =>
      ContractService.getHotTokens(
        tokenParams ?? { ...PreSetFilters[PreSets.SmartMoney], chain }
      ),
    enabled: !!(preSet && !isAlphaPreset), // Ensures this query only runs when `isAlphaPreset` is false
    onSuccess: (data: HotTokensRowInfo[]) => {
      if (!Array.isArray(data)) return;
      replace(
        filterValidAddresses({
          data: data.map((d) => ({
            suspiciousBuy: d.suspiciousBuy,
            rugAddictedBuy: d.rugAddictedBuy,
            scammerBuy: d.scammerBuy,
            freshBuy: d.freshBuy,
            totalBuyTop: d.totalBuyTop,
            totalBuy: d.totalBuy,
            tokenAddress: d.tokenAddress,
          })),
          slice: (preSet as Partial<TokensFormValues>).nOfTokens ?? 10,
        })
      );
      handleClose();
    },
  });

  return {
    isLoading: preSet ? (isAlphaPreset ? alphaLoading : tokenLoading) : false,
    isError: isAlphaPreset ? alphaError : tokenError,
  };
}

const sets = [
  {
    preSet: PreSets.Volatility,
    title: '🎢 Volatility',
    description: 'Hot tokens ranked by 24h volatility index',
  },
  {
    preSet: PreSets.TradingVolume,
    title: '📊 Trading Volume',
    description: 'Hot tokens ranked by 24h trading volume',
  },
  {
    preSet: PreSets.SmartMoney,
    title: '🧠 Smart Money',
    description: 'Hot tokens ranked by 24h smart money net inflow',
  },
  {
    preSet: PreSets.Price,
    title: '💸 Price Change',
    description: 'Tokens ranked by 24h largest price increase',
  },
];

export function PreSetModal({ handleClose, chain, replace }: Props) {
  const { user } = useUserState();
  const { data: starLevel } = useQuery({
    queryKey: starLevelKeys.getStarLevel(),
    queryFn: StarLevelService.getStarLevel,
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const isUserNotAllowed =
    !user || (starLevel && starLevel.mzr_holdings < 1_000_000);

  const [preSet, setPreSet] = useState<
    | PreSets
    | 'top-10-alpha'
    | 'last-10-alpha'
    | Partial<TokensFormValues>
    | undefined
  >();
  const [custom, setCustom] = useState(false);
  const { isError, isLoading } = usePreSet(preSet, handleClose, chain, replace);
  const [top10Error, setTop10Error] = useState(false);
  const [last10Error, setLast10Error] = useState(false);
  const [customError, setCustomError] = useState(false);

  return (
    <CustomModal
      title="Tokens Smart Pre-Sets"
      handleClose={handleClose}
      showModal
    >
      {!custom ? (
        <CustomModal.Body className="xxs:p-4 h-full lg:min-h-[500px]">
          <CustomModal.Title className="hidden lg:flex">
            Tokens Smart Pre-Sets
          </CustomModal.Title>
          <CustomModal.Content>
            <p className="xxs:text-sm">
              Whitelist your tokens using Mizar smart pre-sets
            </p>
            {(top10Error || last10Error || customError) && (
              <NotificationDex errorMessage={isError} type="error">
                Upgrade to Hyper Giant to enable special pre-sets
              </NotificationDex>
            )}
            {isError && (
              <NotificationDex errorMessage={isError} type="error">
                An error occurred, try again.
              </NotificationDex>
            )}
            <div className="flex flex-col xxs:space-y-2 xxs:mb-4 w-full">
              <PresetButton
                key="top-10-alpha"
                className="xxs:py-2"
                error={top10Error}
                onClick={() => {
                  if (!isUserNotAllowed) {
                    setPreSet('top-10-alpha');
                    setTop10Error(false); // Reset error if allowed
                  } else {
                    setTop10Error(true); // Show error only on click
                  }
                }}
              >
                <div className="xxs:space-y-1">
                  <PresetButtonDescription>
                    🏆️ Top-10 Alpha Calls{' '}
                    <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded d-inline">
                      NEW
                    </div>
                  </PresetButtonDescription>
                  <PresetButtonTitle>
                    The top-10 alpha tokens ranked by PnL
                  </PresetButtonTitle>
                </div>
              </PresetButton>
              <PresetButton
                key="last-10-alpha"
                className="xxs:py-2"
                error={last10Error}
                onClick={() => {
                  if (!isUserNotAllowed) {
                    setPreSet('last-10-alpha');
                    setLast10Error(false); // Reset error if allowed
                  } else {
                    setLast10Error(true); // Show error only on click
                  }
                }}
              >
                <div className="xxs:space-y-1">
                  <PresetButtonDescription>
                    📞 Last Alpha Calls{' '}
                    <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded d-inline">
                      NEW
                    </div>
                  </PresetButtonDescription>
                  <PresetButtonTitle>The last 10 alpha calls</PresetButtonTitle>
                </div>
              </PresetButton>
              {sets.map((set) => {
                return (
                  <PresetButton
                    key={set.preSet}
                    className="xxs:py-2"
                    onClick={() => setPreSet(set.preSet)}
                  >
                    {isLoading && set.preSet === preSet ? (
                      <div className="flex justify-center">
                        <LoaderDex className="xxs:my-4" />
                      </div>
                    ) : (
                      <div className=" xxs:space-y-1">
                        <PresetButtonDescription>
                          {set.title}
                        </PresetButtonDescription>
                        <PresetButtonTitle>{set.description}</PresetButtonTitle>
                      </div>
                    )}
                  </PresetButton>
                );
              })}

              <PresetButton
                key="custom"
                className="xxs:py-2"
                error={customError}
                onClick={() => {
                  if (!isUserNotAllowed) {
                    setCustom(true);
                    setCustomError(false); // Reset error if allowed
                  } else {
                    setCustomError(true); // Show error only on click
                  }
                }}
              >
                <div className="xxs:space-y-1">
                  <PresetButtonDescription>
                    🛠️ Custom{' '}
                    <div className="xxs:bg-green-600 xxs:text-green-500 xxs:p-1 rounded d-inline">
                      NEW
                    </div>
                  </PresetButtonDescription>
                  <PresetButtonTitle>Customize your pre-set</PresetButtonTitle>
                </div>
              </PresetButton>
            </div>
          </CustomModal.Content>
        </CustomModal.Body>
      ) : (
        <CustomComponent
          setPreSet={setPreSet}
          isLoading={isLoading}
          setCustom={setCustom}
        />
      )}
    </CustomModal>
  );
}

const tokensSchema = yup.object({
  sort: yup.string().default(`-${HotTokensSortKeys.TotalBuyTop}`).required(),
  poolCreatedAt: yup
    .number()
    .oneOf(Object.values(TokenLaunchPeriods) as number[]),
  period: yup
    .string()
    .oneOf(Object.values(HotTokensPeriods))
    .required()
    .default(HotTokensPeriods.OneHour),
  marketCapRange: yup.boolean().default(false),
  liquidityRange: yup.boolean().default(true),
  volumeRange: yup.boolean().default(true),
  nOfTokens: yup
    .number()
    .default(10)
    .typeError('Required')
    .min(1, 'Min 1 token')
    .max(100, 'Max 100 tokens')
    .required('Required'),
  liquidity: yup
    .object({
      min: yup.number().min(0, 'Minimum is $0'),
      max: yup.number().min(0, 'Minimum is $0'),
    })
    .required(),
  volume: yup
    .object({
      min: yup.number().min(0, 'Minimum is $0'),
      max: yup.number().min(0, 'Minimum is $0'),
    })
    .required(),
  marketCap: yup
    .object({
      min: yup.number().min(0, 'Minimum is $0'),
      max: yup.number().min(0, 'Minimum is $0'),
    })
    .required(),
});

export type TokensFormValues = yup.InferType<typeof tokensSchema>;

interface RangeFilterProps {
  name: 'liquidity' | 'marketCap' | 'volume';
  label: string;
  tooltip: string;
}

const RangeFilter: React.FC<RangeFilterProps> = ({ name, label, tooltip }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<TokensFormValues>();

  const isOpen = !!watch(`${name}Range`);

  return (
    <div className="xxs:space-y-2 ">
      <RowLabelledCheckBox
        label={
          <RowLabel
            label={label}
            inputSize="xs"
            isError={
              !!(
                errors?.[name] &&
                isOpen &&
                ((errors[name] as any)?.min || (errors[name] as any)?.max)
              )
            }
            tooltip={tooltip}
          />
        }
        openBox={isOpen}
        setOpenBox={
          isOpen ? () => setValue(`${name}Range`, !isOpen) : undefined
        }
        className="xxs:py-1"
      >
        <Controller
          name={`${name}Range`}
          control={control}
          render={({ field }) => (
            <Checkbox
              className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
              checked={!!field.value}
              onClick={field.onChange}
              onChange={(e) => {
                const isEnabled = e.target.checked;
                setValue(`${name}Range`, isEnabled);
                setValue(`${name}.min`, isEnabled ? 10000 : undefined);
                setValue(`${name}.max`, isEnabled ? 250000 : undefined);
              }}
              id={`${name}Range`}
            />
          )}
        />
      </RowLabelledCheckBox>
      {isOpen && (
        <div className="flex space-x-1 items-center">
          <Controller
            name={`${name}.min`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                suffix={<>Min</>}
                className="text-right"
                hideErrorMessage={true}
                extraStyle={{
                  paddingRight: `${calculateWordWidth('Min', 12) + 20}px`,
                }}
                prefix={<>$</>}
                error={error?.message}
              />
            )}
          />
          <Controller
            name={`${name}.max`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                suffix={<>Max</>}
                className="text-right"
                extraStyle={{
                  paddingRight: `${calculateWordWidth('Max', 12) + 20}px`,
                }}
                prefix={<>$</>}
                hideErrorMessage={true}
                error={error?.message}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export const useWhiteListedTokensPresetPersistedState = createPersistedState<
  Partial<TokensFormValues>
>('whitelisted-tokens-customized-preset');

const CUSTOMIZED_PRESET_DEFAULT = {
  marketCapRange: false,
  liquidityRange: true,
  nOfTokens: 10,
  volumeRange: true,
  poolCreatedAt: PreSetFilters[PreSets.SmartMoney].poolCreatedAt,
  sort: `-${HotTokensSortKeys.SmartFlow}`,
  period: PreSetFilters[PreSets.SmartMoney].period,
  liquidity: {
    min: PreSetFilters[PreSets.SmartMoney].minLiquidity,
    max: PreSetFilters[PreSets.SmartMoney].maxLiquidity,
  },
  volume: {
    min: PreSetFilters[PreSets.SmartMoney].minVolume,
  },
};
const CustomComponent = ({
  setCustom,
  isLoading,
  setPreSet,
}: {
  setCustom: (arg0: boolean) => void;
  isLoading: boolean;
  setPreSet: (arg0: Partial<TokensFormValues>) => void;
}) => {
  const [presetDefault, setPresetDefault] =
    useWhiteListedTokensPresetPersistedState(CUSTOMIZED_PRESET_DEFAULT);

  const tokenForm = useForm<TokensFormValues>({
    resolver: yupResolver(tokensSchema),
    defaultValues: presetDefault,
  });

  const { watch, setValue, handleSubmit, control } = tokenForm;

  return (
    <FormProvider {...tokenForm}>
      <form
        className="flex flex-col grow"
        onSubmit={(e) => {
          e.preventDefault(); // ✅ Prevent default behavior
          e.stopPropagation(); // ✅ Prevent event bubbling

          void handleSubmit((values) => {
            setPreSet(values);
          })(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4 min-h-[500px]">
          <CustomModal.Title className="hidden lg:flex">
            <div className="">Tokens Smart Pre-Sets</div>
            <div className="flex">
              <Tooltip text="Save current settings to reused it later.">
                <TagButton
                  className="xxs:h-full xxs:text-dex-white-secondary hover:text-dex-white"
                  onClick={(e) => {
                    e.preventDefault(); // ✅ Prevent default behavior
                    e.stopPropagation(); // ✅ Prevent event bubbling

                    void handleSubmit((values) => {
                      setPresetDefault(values);
                    })(); // Call handleSubmit to validate the form before saving
                  }}
                >
                  <DocumentArrowDownIcon className="xxs:w-4 xxs:h-4" />
                </TagButton>
              </Tooltip>
            </div>
          </CustomModal.Title>

          <CustomModal.Content>
            <div className="xxs:space-y-2 ">
              <div className="xxs:text-xs">N. of tokens</div>
              <Controller
                name={'nOfTokens'}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    {...field}
                    hideErrorMessage={true}
                    error={error?.message}
                  />
                )}
              />
              <div className="xxs:text-xs">Timeframe</div>
              <PeriodFilterButtons
                value={watch('period')}
                onChange={(value) => {
                  setValue('period', value as HotTokensPeriods);
                }}
              />
              <div className="xxs:text-xs">Sort Tokens</div>
              <Sort
                value={watch('sort')}
                onChange={(value) => setValue('sort', value)}
              />
              <div className="xxs:text-xs">Token Launch</div>
              <TokenLaunchPeriodFilterMobile
                size="xs"
                value={watch('poolCreatedAt') as TokenLaunchPeriods}
                onChange={(value: TokenLaunchPeriods) => {
                  if (value === watch('poolCreatedAt')) {
                    setValue('poolCreatedAt', undefined as any);
                  } else {
                    setValue('poolCreatedAt', value);
                  }
                }}
              />
              <RangeFilter
                name="liquidity"
                label="Liquidity Range"
                tooltip="The minimum and maximum liquidity thresholds."
              />
              <RangeFilter
                name="volume"
                label="Volume Range"
                tooltip="The minimum and maximum volume thresholds."
              />
              <RangeFilter
                name="marketCap"
                label="Market Cap Range"
                tooltip="The minimum and maximum market cap thresholds."
              />
            </div>
          </CustomModal.Content>
        </CustomModal.Body>
        <CustomModal.Footer>
          <Button
            type="submit"
            className="xxs:text-base lg:text-xs"
            variant="primary"
            loading={isLoading}
          >
            save
          </Button>
          <Button
            className="xxs:text-base lg:text-xs"
            onClick={() => setCustom(false)}
            variant="dexNeutral"
          >
            Back
          </Button>
        </CustomModal.Footer>
      </form>
    </FormProvider>
  );
};
