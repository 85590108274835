import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useWebSocketServer, Subscription } from './websocketServer';
import { getLocalAccessToken } from 'modules/user/userCookies';

export interface WebSocketContextType {
  subscribe: (subscription: Subscription) => void;
  unsubscribe: (subscription: Subscription) => void;
  isConnected: boolean | undefined;
  isAuthenticated: boolean;
  addMessageListener: (channel: string, callback: (msg: any) => void) => void;
  removeMessageListener: (
    channel: string,
    callback: (msg: any) => void
  ) => void;
  activeSubscriptions: Set<string>;
  sendAuth: () => void;
  connect: () => void;
  disconnect: () => void;
  isConnecting: boolean;
  isAuthenticating: boolean;
  addGlobalMessageListener: (callback: (msg: any) => void) => void;
  removeGlobalMessageListener: (callback: (msg: any) => void) => void;
  waitForSocketOpen: () => Promise<void>;
}

export const WebSocketContext = createContext<WebSocketContextType | null>(
  null
);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    subscribe,
    unsubscribe,
    isConnected,
    getSocket,
    addGlobalMessageListener,
    removeGlobalMessageListener,
    connect,
    disconnect,
    isConnecting,
    waitForSocketOpen,
  } = useWebSocketServer();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const listeners = useRef<Map<string, Set<(msg: any) => void>>>(new Map());
  const activeSubscriptions = useRef<Map<string, number>>(new Map());

  const sendAuth = useCallback(() => {
    const socket = getSocket?.();
    if (!socket) {
      console.warn('❌ WebSocket not found');
      return;
    }
    const token = getLocalAccessToken(); // token stored in cookies/localStorage
    setIsAuthenticating(true);
    if (token && socket?.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          action: 'authenticate',
          channel: 'auth',
          data: { token },
        })
      );
    } else {
      console.warn(
        '⚠️ Cannot send WebSocket auth - token missing or socket not open'
      );
    }
  }, [getSocket]);

  useEffect(() => {
    const socket = getSocket?.();
    if (!socket) {
      console.warn('❌ WebSocket not found');
      return;
    }

    const handleMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      if (data.type === 'authenticated') {
        setIsAuthenticated(true);
      } else if (data.type === 'auth_failed') {
        console.warn('❌ WebSocket authentication failed');
        setIsAuthenticated(false);
      }

      setIsAuthenticating(false);

      if (!data.channel) return;

      listeners.current
        .get(data.channel)
        ?.forEach((callback) => callback(data));
    };

    socket.addEventListener('message', handleMessage);
    return () => {
      socket.removeEventListener('message', handleMessage);
    };
  }, [getSocket]);

  const addMessageListener = (
    channel: string,
    callback: (msg: any) => void
  ) => {
    if (!listeners.current.has(channel)) {
      listeners.current.set(channel, new Set());
    }
    listeners.current.get(channel)?.add(callback);
  };

  const removeMessageListener = (
    channel: string,
    callback: (msg: any) => void
  ) => {
    listeners.current.get(channel)?.delete(callback);
    if (listeners.current.get(channel)?.size === 0) {
      listeners.current.delete(channel);
    }
  };

  const handleSubscribe = (subscription: Subscription) => {
    if (!isConnected) return;

    if (subscription.auth && !isAuthenticated) {
      console.warn(
        `⚠️ Cannot subscribe to ${subscription.channel} - Not authenticated`
      );
      return;
    }

    const key = `${subscription.channel}:${JSON.stringify(subscription.data)}`;
    const count = activeSubscriptions.current.get(key) || 0;

    if (count === 0) {
      if (!isConnected) {
        console.warn(
          `⚠️ WebSocket not connected. Delaying subscription to ${subscription.channel}`
        );
        return;
      }

      subscribe(subscription);
    }

    activeSubscriptions.current.set(key, count + 1);
  };

  const handleUnsubscribe = (subscription: Subscription) => {
    if (!isConnected) return;

    const key = `${subscription.channel}:${JSON.stringify(subscription.data)}`;
    const count = activeSubscriptions.current.get(key) || 0;

    if (count <= 1) {
      unsubscribe(subscription);
      activeSubscriptions.current.delete(key);
    } else {
      activeSubscriptions.current.set(key, count - 1);
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        subscribe: handleSubscribe,
        unsubscribe: handleUnsubscribe,
        isConnected,
        isAuthenticated,
        addMessageListener,
        removeMessageListener,
        activeSubscriptions: new Set(activeSubscriptions.current.keys()),
        sendAuth,
        addGlobalMessageListener,
        removeGlobalMessageListener,
        connect,
        disconnect,
        isConnecting,
        isAuthenticating,
        waitForSocketOpen,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
