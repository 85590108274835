import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormValues } from '../schema';
import {
  ArrowTrendingDownIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  MinusIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { last, sum } from 'lodash';
import { StopLoss } from './StopLoss';
import { SellSettings } from './SellSettings';
import Tooltip from 'modules/shared-components/tooltip';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import GasIcon from '../../../../../assets/img/icons/gas-icon.svg';
import { isNotNil } from 'modules/utils/isNotNil';
import {  chainHasMemPool, gasPrioritySuffix } from 'api/types/httpsTypes/d-wallets';
import { VolatilityBotFormValues } from '../../volatility/schema';
import NumberInput from '../../../components/inputs/number-input';
import { DexCard } from '../../../components/cards';
import Checkbox from '../../../components/inputs/Checkbox';

export function TakeProfit() {
  type CombinedFormValues = VolatilityBotFormValues | FormValues;

  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<CombinedFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tradingSettings.takeProfit',
    keyName: 'key',
  });

  const sl = watch('tradingSettings.stopLoss');
  const isAutoSell = fields.length > 0 || !!sl;
  const priorityFee = watch(
    'tradingSettings.takeProfitConfig.maxSellGasPriority'
  );
  const slippage = watch('tradingSettings.takeProfitConfig.slippage');
  const sellAntiMev = watch('tradingSettings.takeProfitConfig.antiMev');
  const chain = watch('tradingSettings.chain');

  return (
    <MobileExpandableCard
      mobileLabel="Sell Settings"
      renderTrigger={({ open }) => (
        <>
          <MobileExpandCardTrigger onClick={open}>
            <div className="flex space-x-1 items-center text-sm xxs:text-dex-white-secondary">
              {errors?.tradingSettings?.takeProfitConfig ||
              errors.tradingSettings?.stopLoss ||
              errors.tradingSettings?.takeProfit ? (
                <div className="flex space-x-1 items-center xxs:text-red-500">
                  <ExclamationCircleIcon className=" xxs:w-6 h-6" />
                  <div>Missing Information</div>
                </div>
              ) : (
                <>
                  <div>Sell:</div>
                  <div className=" flex space-x-1 items-center">
                    {fields.length > 0 ? (
                      <div className="flex space-x-1 items-center">
                        <div>TP</div>
                        <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                      </div>
                    ) : (
                      <div className="flex space-x-1 items-center">
                        <div>TP</div>
                        <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                      </div>
                    )}
                    <div>|</div>
                    {sl ? (
                      <div className="flex space-x-1 items-center">
                        <div>SL</div>
                        <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                      </div>
                    ) : (
                      <div className="flex space-x-1 items-center">
                        <div>SL</div>
                        <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                      </div>
                    )}
                  </div>

                  {isAutoSell && (
                    <div className="flex space-x-1 items-center">
                      <div>|</div>
                      <div>
                        <div className="flex xxs:space-x-1 items-center">
                          <img
                            className="h-4 w-4"
                            alt="gas icon"
                            src={GasIcon}
                          />
                          {priorityFee ? (
                            <div className="xxs:text-dex-white">
                              {priorityFee} {gasPrioritySuffix(chain)}
                            </div>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                      <div>|</div>

                      <div className="flex xxs:space-x-1 items-center">
                        <ArrowTrendingDownIcon className="h-4 w-4" />
                        <div className="xxs:text-dex-white">
                          {isNotNil(slippage) ? <>{slippage}%</> : '--'}
                        </div>
                      </div>

                      {chainHasMemPool(chain) && <div>|</div>}
                      {chainHasMemPool(chain) &&
                        (sellAntiMev ? (
                          <div className="flex space-x-1 items-center font-semibold">
                            <div>MEV</div>
                            <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                          </div>
                        ) : (
                          <div className="flex space-x-1 items-center font-semibold">
                            <div>MEV</div>
                            <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                          </div>
                        ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </MobileExpandCardTrigger>
        </>
      )}
    >
      <DexCard className="grow" size="xs">
        <div className="flex">
          <Tooltip text="Auto-sell your tokens when they hit the take profit price threshold. Set-up multiple take profit levels.">
            <div>
              <div className="flex items-center xxs:gap-1">
                <Checkbox
                  className="lg:w-4 lg:h-4 xxs:w-5 xxs:h-5"
                  checked={fields.length > 0}
                  disabled={fields.some(
                    (field) => field.status && field.status !== 'ACTIVE'
                  )}
                  onChange={(e) => {
                    const checked = !e.currentTarget.checked;
                    if (
                      checked &&
                      (watch('botSettings.entries.autoRetryEnabled') ||
                        watch(
                          'tradingSettings.safetyMeasures.autoRetryEnabled'
                        ))
                    ) {
                      setValue(
                        'tradingSettings.safetyMeasures.autoRetry.takeProfit',
                        false
                      );
                      setValue(
                        'botSettings.entries.autoRetry.takeProfit',
                        false
                      );
                    }
                    if (!checked) {
                      append({
                        threshold: 100,
                        weight: 100,
                      });
                    } else {
                      remove();
                    }
                  }}
                />
                <div className="xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                  Take Profit
                </div>
              </div>
            </div>
          </Tooltip>
        </div>

        {fields.length ? (
          <div>
            <div className="grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_36px] xxs:gap-1 xxs:text-xs dark:text-black-50">
              <Tooltip text="The threshold at which the position will be sold, based on the deviation from the average buy price.">
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  Target
                </div>
              </Tooltip>
              <Tooltip text="The portion of the position to be sold when the target is reached. For instance, if you bought 100 PEPE coins and specified a 50% Take Profit weight, 50 PEPE coins will be sold when the target is hit.">
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  Weight
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-col xxs:gap-1">
              {fields.map((row, index) => (
                <div
                  className="grid grid-cols-[minmax(0,1fr)_minmax(0,1fr)_30px] xxs:gap-1"
                  key={row.key}
                >
                  <Controller
                    name={`tradingSettings.takeProfit.${index}.threshold`}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          prefix={'+'}
                          suffix={<>%</>}
                          inputSize="small"
                          hideErrorMessage={true}
                          disabled={row.status && row.status !== 'ACTIVE'}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                  <Controller
                    name={`tradingSettings.takeProfit.${index}.weight`}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          {...field}
                          hideErrorMessage={true}
                          suffix={<>%</>}
                          inputSize="small"
                          disabled={row.status && row.status !== 'ACTIVE'}
                          error={error?.message}
                        />
                      );
                    }}
                  />

                  {index === 0 ? (
                    <IconButton
                      className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
                      type="button"
                      onClick={() =>
                        append({
                          threshold: +(last(fields)?.threshold || 0) + 100,
                          weight: 100 - sum(fields.map((x) => x.weight)),
                        })
                      }
                    >
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </IconButton>
                  ) : (
                    <IconButton
                      className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
                      type="button"
                      disabled={row.status && row.status !== 'ACTIVE'}
                      onClick={() => remove(index)}
                    >
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
            {errors.tradingSettings?.takeProfit?.message && (
              <div className="text-red-500">
                {errors.tradingSettings?.takeProfit.message}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className="py-2">
          <StopLoss />
        </div>
        {isAutoSell ? <SellSettings /> : ''}
      </DexCard>
    </MobileExpandableCard>
  );
}
