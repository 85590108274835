import { api } from 'helpers/api/apiCore';
import {
  GetWalletBalanceResponse,
  GetWalletsResponse,
} from '../../types/httpsTypes/balance';
import { GetWalletTransactionsResponse } from '../../types/httpsTypes/transactions';
import { toQueryString } from 'modules/routing/query-string';

export const SERVICE_KEY = 'wallet';

export const walletKeys = {
  getWalletBalance: () => [SERVICE_KEY, 'balance'],
  getAssetTransactions: ({
    limit,
    offset,
  }: {
    limit: number;
    offset: number;
  }) => [SERVICE_KEY, 'transactions', limit, offset],
  getWallets: () => [SERVICE_KEY, 'account', 'wallets'],
};

export const WalletService = {
  getWallets: async () => {
    const response = await api.get<GetWalletsResponse>(
      '/api/v2/account/crypto/wallets'
    );
    return response.data.data;
  },
  getWalletBalance: async () => {
    const response = await api.get<GetWalletBalanceResponse>(
      `/api/v2/account/credits/balance`
    );
    return response.data;
  },
  getAssetTransactions: async (values: { limit: number; offset: number }) => {
    const response = await api.get<GetWalletTransactionsResponse>(
      `/api/v2/account/credits/transactions?${toQueryString(values)}`
    );
    return response.data;
  },
};

export default WalletService;
