import { Button } from 'modules/shared-components/button/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DexBotService,
  dexBotKeys,
} from 'api/services/httpServices/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { BotSummary, formValuesToDexBot } from '../BotConfirmationModal';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { useHistory } from 'react-router-dom';
import { VolatilityBotFormValues } from '../schema';
import { NotificationDex } from '../../../components/alerts/notification';
import CustomModal from '../../../components/modal/CustomModal';
import { Tabs } from '../../../components/layout/SIDEBAR_CONST';

type Props = {
  id: string;
  bot: VolatilityBotFormValues;
  previous: VolatilityBotFormValues;
  handleClose: () => void;
};

export const EditBotConfirmationModal = ({
  id,
  bot,
  previous,
  handleClose,
}: Props) => {
  const queryClient = useQueryClient();
  const { addNewMessage } = useSnackBar();

  const history = useHistory();

  const { mutate, isLoading, error } = useMutation({
    mutationFn: () =>
      DexBotService.editVolatilityBot(id, formValuesToDexBot(bot)),
    onSuccess: () => {
      void queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        message: 'Bot edited successfully',
        title: 'Success',
        type: 'success',
      });
      history.push(`/dex/bots/${Tabs.VolatilityBot}`);
    },
  });

  return (
    <CustomModal
      title="Edit Bot"
      size="large"
      showModal
      handleClose={handleClose}
    >
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Edit{' '}
          <span className="max-w-[200px] lg:max-w-[500px] truncate">
            {previous.name}
          </span>
        </CustomModal.Title>
        <CustomModal.Content>
          <BotSummary bot={bot} previous={previous} />
          <NotificationDex type="info" className="xxs:my-2">
            Changes will be applied to all new snipes
          </NotificationDex>
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
        </CustomModal.Content>
      </CustomModal.Body>

      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          type="button"
          loading={isLoading}
          onClick={() => mutate()}
        >
          save
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};
