import without from 'lodash/without';

import { PoolStatus } from 'api/types/httpsTypes/contracts';
import { PoolFilterState, usePoolSearchFilters } from '../../usePageParams';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { useToggle } from 'modules/utils/useToggle';
import { LiquidityFilterModal } from './LiquidityFilterModal';
import { isNotNil } from 'modules/utils/isNotNil';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import { MarketcapFilterModal } from './MarketcapFilterModal';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import {
  SortDropdown,
  SortDropdownProps,
} from '../../../components/dropdown/sort-dropdown';
import {
  ActivteFiltersBar,
  FilterTag,
  SelectFiltersBar,
} from '../../../components/filters';
import { FilterButton } from '../../../components/filters/filterButton';
import { twMerge } from 'tailwind-merge';

const STATUS_OPTIONS = [
  {
    value: 'SNIPEABLE' as PoolStatus,
    label: 'Not Launched',
    icon: null,
  },
  {
    value: 'NOT_SNIPEABLE' as PoolStatus,
    label: 'Launched',
    icon: null,
  },
  {
    value: 'RUG_PULL' as PoolStatus,
    label: 'Rug-pull',
    icon: null,
  },
];

export const PoolFiltersBar = () => {
  const { filtersState: filters, setFilters } = usePoolSearchFilters();

  const nOfFiltersActive = [
    filters.status?.length,
    filters.minMarketcap,
    filters.maxMarketcap,
    filters.minLiquidity,
    filters.maxLiquidity,
  ].filter((value) => value !== undefined && value).length;

  const updateFilters = (update: Partial<PoolFilterState>) => {
    setFilters({ ...filters, offset: 0, ...update });
  };
  const isLargeScreen = useMediaQuery(screenGte.large);
  return (
    <div className="space-y-1">
      <SelectFiltersBar
        icon={
          <div
            className={twMerge(
              'xxs:p-1 flex xxs:space-x-1 items-center',
              nOfFiltersActive
                ? 'xxs:hover:text-dex-white'
                : ' xxs:hover:text-dex-white xxs:text-dex-white-secondary'
            )}
          >
            <FunnelIcon aria-hidden="true" width={16} height={16} />
            {nOfFiltersActive ? <div>{nOfFiltersActive}</div> : ''}
          </div>
        }
        sort={
          !isLargeScreen && (
            <PoolSort
              value={filters.sort}
              onChange={(value) => {
                updateFilters({
                  sort: value,
                });
              }}
            />
          )
        }
      >
        <SortDropdown
          className="xxs:bg-dex-black-800"
          value={
            filters?.status?.[0]
              ? (filters.status[0] as PoolStatus)
              : (undefined as unknown as any)
          }
          onChange={(value: PoolStatus) => {
            if (value === filters.status?.[0]) {
              updateFilters({
                status: undefined,
              });
            } else {
              updateFilters({
                status: [value],
              });
            }
          }}
          options={STATUS_OPTIONS}
          mainLabel="Status"
        />

        <LiquidityRangeFilter />
        <MarketcapRangeFilter />
      </SelectFiltersBar>
      <ActivteFiltersBar>
        {filters.status?.map((status) => (
          <FilterTag
            key={status}
            onRemove={() => {
              return updateFilters({
                status: without(filters.status, status),
              });
            }}
            label={
              STATUS_OPTIONS.find((el) => el.value === status)?.label || ''
            }
          />
        ))}

        {isNotNil(filters.minLiquidity) ? (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minLiquidity: undefined,
              })
            }
            label={`Liquidity > $${formatNumberWithSuffix(
              filters.minLiquidity,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}`}
          />
        ) : null}
        {isNotNil(filters.maxLiquidity) ? (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxLiquidity: undefined,
              })
            }
            label={`Liquidity < $${formatNumberWithSuffix(
              filters.maxLiquidity,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}`}
          />
        ) : null}
        {isNotNil(filters.minMarketcap) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                minMarketcap: undefined,
              })
            }
            label={`Marketcap > $${formatNumberWithSuffix(
              filters.minMarketcap,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}`}
          />
        )}
        {isNotNil(filters.maxMarketcap) && (
          <FilterTag
            onRemove={() =>
              updateFilters({
                maxMarketcap: undefined,
              })
            }
            label={`Marketcap < $${formatNumberWithSuffix(
              filters.maxMarketcap,
              {
                suffixes: allSuffixes,
                precision: 0,
              }
            )}`}
          />
        )}
      </ActivteFiltersBar>
    </div>
  );
};

function LiquidityRangeFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = usePoolSearchFilters();

  return (
    <>
      <FilterButton label="Liquidity" onClick={() => open()}>
        {!!(filtersState.minLiquidity || filtersState.maxLiquidity) && (
          <FunnelIcon className="w-3 h-3 xxs:text-dex-white-secondary" />
        )}
      </FilterButton>
      {isOpen && <LiquidityFilterModal handleClose={close} />}
    </>
  );
}

function MarketcapRangeFilter() {
  const [isOpen, { open, close }] = useToggle();
  const { filtersState } = usePoolSearchFilters();

  return (
    <>
      <FilterButton label="Marketcap" onClick={() => open()}>
        {!!(filtersState.minMarketcap || filtersState.maxMarketcap) && (
          <FunnelIcon className="w-3 h-3 xxs:text-dex-white-secondary" />
        )}
      </FilterButton>
      {isOpen && <MarketcapFilterModal handleClose={close} />}
    </>
  );
}

export enum PoolsTableSortKeys {
  Liquidity = 'total_liquidity_in_usd',
  Marketcap = 'token_marketcap',
  CreatedAt = 'created_at',
}

export const SORT_OPTIONS = [
  {
    label: 'Newest',
    value: `-${PoolsTableSortKeys.CreatedAt}`,
  },
  {
    label: 'Marketcap',
    value: `-${PoolsTableSortKeys.Marketcap}`,
  },
  {
    label: 'Liquidity',
    value: `-${PoolsTableSortKeys.Liquidity}`,
  },
];

export function PoolSort(props: Omit<SortDropdownProps<string>, 'options'>) {
  return (
    <SortDropdown
      size="xs"
      className="xxs:py-1.5 xxs:px-2 h-auto"
      {...props}
      options={SORT_OPTIONS}
    />
  );
}
