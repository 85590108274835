import { isAddress } from 'ethereum-address';
import { PublicKey } from '@solana/web3.js';
import { Chains } from 'api/types/httpsTypes/d-wallets';

export function isValidAddress(address, chain) {
  if (
    chain === Chains.Ethereum ||
    chain === Chains.Base ||
    chain === Chains.BinanceChain
  ) {
    return isAddress(address);
  } else {
    try {
      const publicKey = new PublicKey(address);
      return publicKey.toBytes().length === 32;
    } catch (error) {
      return false;
    }
  }
}
