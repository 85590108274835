import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import {
  DWalletService,
  dWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';

import { formatTokenAmount } from 'utils/FormatNumber';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import Big from 'big.js';
import {
  chainAsset,
  chainName,
  Chains,
  gasPrioritySuffix,
} from 'api/types/httpsTypes/d-wallets';
import { FormValues } from './schema';
import Button from 'modules/shared-components/button/button';
import { chainsOptions } from '../WithdrawModal/SendFundsSettings';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import NumberInput from '../../components/inputs/number-input';
import { SortDropdown } from '../../components/dropdown/sort-dropdown';
import { useChainInfo } from 'api/hooks/useChainInfo';
import { fromAmount } from '../../components/chains/units';
import { NotificationDex } from '../../components/alerts/notification';
import { useGetWallets } from '../WalletDetails/mobileWrapper';

const BridgeFundsContent = ({ groupId }: { groupId: string }) => {
  const { control, watch, setValue, trigger } = useFormContext<FormValues>();

  const inputValues = watch();
  const address = inputValues.address;
  const srcChain = inputValues.srcChain;
  const dstChain = inputValues.dstChain;

  const { data: wallets } = useGetWallets(dstChain, true);

  const isDstWalletAvailable = !groupId
    ? dstChain !== Chains.Solana
    : wallets?.some((wallet) => wallet.groupId === groupId) || false;

  const { isLoading: myWalletIsLoading, data: myWallet } = useQuery({
    queryFn: () => DWalletService.getWalletWithAddress(srcChain, address),
    queryKey: dWalletServiceKeys.getWalletWithAddress(srcChain, address),
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data?.chainBalance) {
        const roundedMaxBalance = Big(data.chainBalance).toFixed(
          18,
          Big.roundDown
        );
        setValue('maxBalance', roundedMaxBalance);
      }
    },
  });

  const srcChainTokenAmount =
    inputValues.srcChainTokenAmount === ''
      ? '0'
      : inputValues.srcChainTokenAmount ?? 0;

  const {
    data: bridgeEstimation,
    error: bridgeEstimationError,
    isLoading: loadingBridgeEstimation,
  } = useQuery({
    queryFn: () => DWalletService.estimateBridge(inputValues),
    queryKey: dWalletServiceKeys.estimateBridge(inputValues),
    enabled: Big(srcChainTokenAmount).gt(0),
  });

  const chainInfo = useChainInfo(srcChain);

  return (
    <div className="xxs:space-y-4">
      <p className="xxs:mb-4 xxs:text-dex-white-secondary">
        Bridge your funds to another chain.
      </p>
      <div className="xxs:text-white-secondary xxs:bg-dex-black-700 rounded xxs:p-2">
        <div className="flex items-center w-full">
          <div className="space-y-1 w-full">
            {myWallet && (
              <div className="flex space-x-1 items-center">
                <div className="max-w-[330px] truncate">
                  <span className="font-bold">Wallet:</span> {myWallet.label}
                </div>
                <ChainsIcon imgName={srcChain} />
                <div>|</div>
                {myWalletIsLoading ? (
                  <div className="font-bold">--</div>
                ) : (
                  <div className="font-bold">
                    {formatTokenAmount(myWallet.chainBalance)}{' '}
                    {chainAsset(srcChain)}
                  </div>
                )}
              </div>
            )}
            <div>
              Address:{' '}
              <EthAddress
                address={address || ''}
                kind="account"
                chain={srcChain as Chains}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-2 items-center">
        <div className="w-2/5">
          <Controller
            name="srcChain"
            control={control}
            render={({ field }) => (
              <SortDropdown
                mainLabel="From Chain"
                disabled={true}
                {...field}
                options={chainsOptions()}
                onChange={(option) => {
                  field.onChange(option);
                }}
              />
            )}
          />
        </div>

        <div className="flex justify-center items-center w-1/5 ">
          <ArrowRightIcon className="h-5 w-5" />
        </div>

        <div className="w-2/5">
          <Controller
            name="dstChain"
            control={control}
            render={({ field }) => (
              <SortDropdown
                mainLabel="To Chain"
                {...field}
                options={chainsOptions()}
                onChange={(option) => {
                  field.onChange(option);
                }}
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="srcChainTokenAmount"
        render={({ field, fieldState: { error: errore } }) => (
          <NumberInput
            label={'Amount'}
            type="number"
            {...field}
            onChange={(e) => {
              const val = e?.currentTarget?.value;
              setValue('srcChainTokenAmount', val as unknown as string);
              trigger()
                .then(() => {})
                .catch((er) => {
                  console.error('Error', er);
                });
            }}
            extraLabel={
              <Button
                type="button"
                onClick={() => {
                  const roundedValue = Big(myWallet?.chainBalance ?? 0).toFixed(
                    18,
                    Big.roundDown
                  );
                  setValue('srcChainTokenAmount', roundedValue);

                  trigger()
                    .then(() => {})
                    .catch((e) => {
                      console.error('Error', e);
                    });
                }}
                className="m-0 text-dex-white-secondary hover:text-dex-white p-0 normal-case bg-transparent"
              >
                Max
              </Button>
            }
            suffix={
              <span className="max-w-[100px] truncate">
                {chainAsset(srcChain)}
              </span>
            }
            error={errore?.message}
          />
        )}
      />

      {Big(srcChainTokenAmount ?? 0).gt(0) && loadingBridgeEstimation ? (
        <div className="text-xs">Estimating bridged amount...</div>
      ) : (
        bridgeEstimation && (
          <div className="text-xs">
            You'll receive{' '}
            {formatTokenAmount(
              fromAmount(
                Big(bridgeEstimation.dstAmount || 0).toNumber(),
                dstChain,
                'weiToEther'
              )
            )}{' '}
            {chainAsset(dstChain)} on {chainName(dstChain)}
          </div>
        )
      )}

      {bridgeEstimationError ? (
        <NotificationDex
          type="error"
          errorMessage={bridgeEstimationError}
          className="text-red-500"
        >
          This transaction will most likely fail. Review your settings. Error:{' '}
        </NotificationDex>
      ) : (
        chainInfo &&
        bridgeEstimation && (
          <div className="dark:bg-dex-black-600 xxs:px-2 xxs:py-1 ml-auto lg:mt-auto flex space-x-1">
            <div>Estimated Gas Fee:</div>
            <div className="xxs:font-semibold">
              {formatTokenAmount(
                Number(
                  Big(
                    fromAmount(
                      Big(bridgeEstimation.gasAmount ?? 0)
                        .plus(bridgeEstimation.feeAmount ?? 0)
                        .toNumber() ?? 0,
                      srcChain,
                      'weiToGwei'
                    )
                  )
                )
              )}{' '}
              {gasPrioritySuffix(srcChain)} ($
              {Big(
                fromAmount(
                  Big(bridgeEstimation.gasAmount ?? 0)
                    .plus(bridgeEstimation.feeAmount ?? 0)
                    .toNumber() ?? 0,
                  srcChain,
                  'weiToEther'
                )
              )
                .times(chainInfo?.price ?? 0)
                .toFixed(2)}
              )
            </div>
          </div>
        )
      )}
      {!isDstWalletAvailable && (
        <NotificationDex type="error" className="mt-2">
          Unable to bridge from this wallet. A matching wallet on the
          destination chain could not be found.
        </NotificationDex>
      )}
    </div>
  );
};
export default BridgeFundsContent;
