import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Big from 'big.js';

interface Props extends HTMLAttributes<HTMLDivElement> {
  fillPercentage: number;
  color?: string;
  className?: string;
}

const ProgressBar = ({ fillPercentage, className, color }: Props) => {
  return (
    <div
      className={twMerge(
        'relative xxs:h-2 xxs:rounded-full xxs:overflow-hidden xxs:bg-black-200',
        className
      )}
    >
      <div
        className={twMerge(
          color ? color : 'xxs:bg-blue-500',
          'absolute top-0 left-0 xxs:h-full'
        )}
        style={{
          width: `${Big(fillPercentage).gt(100) ? '100' : fillPercentage}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
