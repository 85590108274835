import {
  chainBuyBribe,
  chainHasGasLimit,
  chainMaxBuyGasPriority,
  chainMaxSellGasPriority,
  Chains,
  chainSellBribe,
} from 'api/types/httpsTypes/d-wallets';
import { fromAmount } from '../../components/chains/units';

export const TG_BOT_DEFAULT_SETTINGS = (chain: Chains) =>
  (() => {
    return {
      launchedFilter: true,
      chain,
      notLaunchedFilter: false,
      antiMev: false,
      bribe: 0,
      backupBribe: 0,
      blockOneRetry: false,
      maxBuyGasPriority: chainMaxBuyGasPriority(chain),
      slippage: 15,
      safetyMeasures: {
        autoRetryEnabled: false,
        autoRetry: {
          buy: false,
          takeProfit: false,
          stopLoss: false,
        },
        liquidityRange: false,
        marketCapRange: false,
        taxProtectionEnabled: false,
        gasLimit: chainHasGasLimit(chain) ? 400000 : undefined,
        gasLimitEnabled: chainHasGasLimit(chain),

        liquidity: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER,
        },
        marketCap: {
          min: 0,
          max: Number.MAX_SAFE_INTEGER,
        },
        taxProtection: {
          buy: 0,
          sell: 0,
        },
        honeypotProtection: true,
      },

      takeProfit: [
        {
          threshold: 50,
          weight: 50,
        },
        {
          threshold: 150,
          weight: 50,
        },
      ],
      takeProfitConfig: {
        slippage: 15,
        maxSellGasPriority: chainMaxSellGasPriority(chain),
        bribe: fromAmount(
          chainSellBribe(chain).toString(),
          chain,
          'weiToEther'
        ),
        antiMev: false,
      },
      stopLoss: null,
    };
  })();

export const BOT_DEFAULT_SETTINGS = (chain: Chains) => {
  return {
    maxBuyGasPriority: chainMaxBuyGasPriority(chain),
    bribe: fromAmount(chainBuyBribe(chain).toString(), chain, 'weiToEther'),
    antiMev: false,
    chain,
    slippage: 10,
    safetyMeasures: {
      autoRetryEnabled: false,
      autoRetry: {
        buy: false,
        takeProfit: false,
        stopLoss: false,
      },
      liquidityRange: false,
      marketCapRange: false,
      taxProtectionEnabled: false,
      gasLimit: chainHasGasLimit(chain) ? 400000 : undefined,
      gasLimitEnabled: chainHasGasLimit(chain),
      liquidity: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      marketCap: {
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
      },
      taxProtection: {
        buy: 0,
        sell: 0,
      },
      honeypotProtection: true,
    },

    takeProfit: [
      {
        threshold: 15,
        weight: 100,
      },
    ],
    takeProfitConfig: {
      slippage: 10,
      maxSellGasPriority: chainMaxSellGasPriority(chain),
      bribe: fromAmount(chainSellBribe(chain).toString(), chain, 'weiToEther'),
      antiMev: false,
    },
    stopLoss: null,
  };
};
