import { Button } from 'modules/shared-components/button/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  DWalletService,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import { Chains, DWalletDetails } from 'api/types/httpsTypes/d-wallets';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useUser } from 'modules/user/user';
import config from '../../../../config';
import { formatApiError } from 'helpers/api/apiErrors';
import UserService, {
  userServiceKeys,
} from 'api/services/httpServices/UserService';
import { useHapticFeedback } from 'modules/telegram/core/useHapticFeedback';
import SelectWalletContent from './SelectWalletContent';
import SendFundsContent from './SendFundsSettings';
import TxInfo from './SendFundTxInfo';
import { FormValues, getSchema } from './schema';
import { Enable2FA, ValidateWith2Fa } from './ValidateWith2Fa';
import { valueAsSafeNumber } from 'utils/FormatNumber';
import CustomModal from '../../components/modal/CustomModal';
import { NotificationDex } from '../../components/alerts/notification';

export function WithdrawModal({
  wallet,
  handleClose,
  chain,
}: {
  wallet: DWalletDetails;
  handleClose: () => void;
  chain: Chains;
}) {
  const user = useUser();
  const isTelegramOnlyUser = user?.email.endsWith('telegram.mizar.com');
  const schema = getSchema();
  const [isWalletSet, setIsWalletSet] = useState(false);
  const [enable2FA, setEnable2FA] = useState(false);
  const hapticFeedback = useHapticFeedback();
  const { data: otpConfig } = useQuery({
    queryFn: () => UserService.getOtpConfig(),
    queryKey: userServiceKeys.getOtpConfig(),
    enabled: !isTelegramOnlyUser,
  });

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      fromAddress: wallet.address,
      chain,
    },
  });

  const { handleSubmit, watch, setValue, trigger } = form;

  const {
    isLoading: walletsLoading,
    data: myWallets,
    error: errorWallets,
  } = useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(chain),
    queryKey: multiChainDWalletServiceKeys.getWallets(chain),
  });

  const wallets = myWallets?.filter((w) => w.id !== wallet.id);

  const { addNewMessage } = useSnackBar();

  const { mutate: requestOtp, isLoading: isLoadingRequestOtp } = useMutation({
    mutationFn: DWalletService.initiateWithdraw,
    onSuccess: (data) => {
      setValue('transferId', data ? data.id : (undefined as any));
      if (data.transactionHash) {
        setValue('transactionHash', data.transactionHash);
      }
    },
    onError: (err: string) => {
      if (config.isTelegramBrowser) {
        addNewMessage({
          message: formatApiError(err, 'There was an error. Try again.'),
          title: 'Error',
          type: 'error',
        });
      } else {
        addNewMessage({
          type: 'error',
          title: 'Error',
          message: `An Error Occurred: ${err ?? 'Unknown'}`,
        });
      }
    },
  });

  const { mutate, error, isLoading } = useMutation({
    mutationFn: DWalletService.completeWithdraw,
    onError: (err: string) => {
      addNewMessage({
        type: 'error',
        title: 'Error',
        message: `An Error Occurred: ${err ?? 'Unknown'}`,
      });
    },
    onSuccess: (data) => {
      setValue('transactionHash', data.transactionHash);
    },
  });

  const onSubmit = handleSubmit((value) => {
    mutate({
      params: {
        transfer_id: inputValues.transferId as string,
        chain: value.chain?.toLowerCase(),
        from_address: value.fromAddress,
        to_address: value.toAddress ?? '',
        ...(value.tokenAddress === 'eth' ||
        value.tokenAddress === 'bnb' ||
        inputValues.tokenAddress === 'sol'
          ? {}
          : { token_address: value.tokenAddress }),
        amount: valueAsSafeNumber(
          value.amount?.toString()
        )?.toString() as string,
        otp: value.otp as string,
      },
    });
  });

  const inputValues = watch();
  const transferId = inputValues.transferId;
  const transactionHash = inputValues.transactionHash;

  return (
    <CustomModal title="Send Tokens" showModal handleClose={handleClose}>
      <form
        className="contents"
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <FormProvider {...form}>
          <CustomModal.Body className="xxs:p-4 min-h-[350px]">
            <CustomModal.Title className="hidden lg:flex">
              Send Tokens
            </CustomModal.Title>

            <CustomModal.Content>
              {isTelegramOnlyUser && (
                <>
                  {!isWalletSet ? (
                    <SelectWalletContent
                      chain={chain}
                      wallets={wallets}
                      setIsWalletSet={setIsWalletSet}
                      isError={!!errorWallets}
                      isLoading={walletsLoading}
                    />
                  ) : !transactionHash ? (
                    <SendFundsContent
                      walletAddress={wallet.address}
                      setIsWalletSet={setIsWalletSet}
                    />
                  ) : (
                    <TxInfo chain={chain} />
                  )}
                </>
              )}

              {!isTelegramOnlyUser && (
                <>
                  {!isWalletSet ? (
                    <SelectWalletContent
                      chain={chain}
                      wallets={wallets}
                      setIsWalletSet={setIsWalletSet}
                      isError={!!errorWallets}
                      isLoading={walletsLoading}
                    />
                  ) : !transferId && !enable2FA ? (
                    <SendFundsContent
                      walletAddress={wallet.address}
                      setIsWalletSet={setIsWalletSet}
                    />
                  ) : enable2FA ? (
                    <Enable2FA />
                  ) : transferId && !transactionHash ? (
                    <ValidateWith2Fa
                      otpConfig={otpConfig}
                      error={error as string}
                    />
                  ) : transactionHash ? (
                    <TxInfo chain={chain} />
                  ) : (
                    <div className="text-red-500">Something went wrong</div>
                  )}
                </>
              )}

              {error && (
                <NotificationDex type="error" errorMessage={error}>
                  An error occurred.
                </NotificationDex>
              )}
            </CustomModal.Content>
          </CustomModal.Body>

          <CustomModal.Footer>
            {isTelegramOnlyUser && isWalletSet && !transactionHash && (
              <PrimaryButton
                type="button"
                className="xxs:text-base lg:text-xs"
                variant="primary"
                onClick={() => {
                  trigger()
                    .then((value) => {
                      if (value) {
                        requestOtp({
                          params: {
                            chain: inputValues.chain?.toLowerCase(),
                            from_address: inputValues.fromAddress,
                            to_address: inputValues.toAddress ?? '',
                            ...(inputValues.tokenAddress === 'eth' ||
                            inputValues.tokenAddress === 'bnb' ||
                            inputValues.tokenAddress === 'sol'
                              ? {}
                              : { token_address: inputValues.tokenAddress }),
                            amount: valueAsSafeNumber(
                              inputValues.amount?.toString()
                            )?.toString() as string,
                          },
                        });
                      }
                    })
                    .catch((e) => {
                      console.error('Error', e);
                      hapticFeedback.notification('error');
                    });
                }}
                loading={isLoadingRequestOtp}
              >
                SEND
              </PrimaryButton>
            )}

            {!isTelegramOnlyUser && (
              <>
                {isWalletSet &&
                  !otpConfig?.enabled &&
                  !transferId &&
                  !enable2FA && (
                    <PrimaryButton
                      className="xxs:text-base lg:text-xs"
                      type="button"
                      variant="primary"
                      onClick={() => {
                        trigger()
                          .then((value) => {
                            if (value) {
                              setEnable2FA(true);
                            }
                          })
                          .catch((e) => {
                            console.error('Error', e);
                            hapticFeedback.notification('error');
                          });
                      }}
                    >
                      SEND
                    </PrimaryButton>
                  )}
                {isWalletSet &&
                  !otpConfig?.enabled &&
                  !transferId &&
                  enable2FA && (
                    <Button
                      className="xxs:text-base lg:text-xs"
                      as={Link}
                      type="button"
                      variant="primary"
                      to="/dashboard/settings/Account"
                    >
                      SETUP 2FA
                    </Button>
                  )}
                {isWalletSet && otpConfig?.enabled && !transferId && (
                  <PrimaryButton
                    type="button"
                    className="xxs:text-base lg:text-xs"
                    variant="primary"
                    onClick={() => {
                      trigger()
                        .then((value) => {
                          if (value) {
                            requestOtp({
                              params: {
                                chain: inputValues.chain?.toLowerCase(),
                                from_address: inputValues.fromAddress,
                                to_address: inputValues.toAddress ?? '',
                                ...(inputValues.tokenAddress === 'eth' ||
                                inputValues.tokenAddress === 'bnb' ||
                                inputValues.tokenAddress === 'sol'
                                  ? {}
                                  : {
                                      token_address: inputValues.tokenAddress,
                                    }),
                                amount: valueAsSafeNumber(
                                  inputValues.amount?.toString()
                                )?.toString() as string,
                              },
                            });
                          }
                        })
                        .catch((e) => {
                          console.error('Error', e);
                          hapticFeedback.notification('error');
                        });
                    }}
                    loading={isLoadingRequestOtp}
                  >
                    SEND
                  </PrimaryButton>
                )}
                {isWalletSet &&
                  transferId &&
                  otpConfig?.enabled &&
                  !transactionHash && (
                    <PrimaryButton
                      className="xxs:text-base lg:text-xs"
                      variant="primary"
                      type="submit"
                      loading={isLoading}
                    >
                      SEND
                    </PrimaryButton>
                  )}
              </>
            )}
            <Button
              className="xxs:text-base lg:text-xs"
              type="button"
              variant="dexNeutral"
              onClick={() => handleClose?.()}
            >
              {transactionHash ? 'Close' : 'Cancel'}
            </Button>
          </CustomModal.Footer>
        </FormProvider>
      </form>
    </CustomModal>
  );
}
