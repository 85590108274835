import { useEffect } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Button } from 'modules/shared-components/button/button';
import { AssetIconNew } from 'modules/shared-components/asset/asset-icon';
import { CopyContent } from 'modules/shared-components/tooltip/copy-content';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { WalletIcon } from 'modules/ethereum/components/WalletIcon';
import { useAccount, useConnect, useNetwork } from 'wagmi';
import { DepositSteps, useDepositStepper } from './DepositStepper';
import { useDepositState } from './Context';
import config from 'config';
import { EXTENDED_DEPOSIT_CHAINS_CONFIG } from './DepositWagmiProvider';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { base, bsc } from 'wagmi/chains';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import { NotificationDex } from '../../components/alerts/notification';
import CustomModal from '../../components/modal/CustomModal';
import TextInput from '../../components/inputs/text-input/text-input';
import { SortDropdown } from '../../components/dropdown/sort-dropdown';

export const chainsOptions = EXTENDED_DEPOSIT_CHAINS_CONFIG.map((chain) => {
  return {
    value: chain.id,
    label: chain.name,
    icon: <ChainsIcon imgName={chain.key} />,
  };
});

export const DepositSelectAsset = () => {
  const [{ wallet, assetSymbol, chainId }, setState] = useDepositState();

  return (
    <>
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex">
          Deposit Tokens
        </CustomModal.Title>

        <CustomModal.Content className="min-h-[300px]">
          <div className="mb-2 ">
            Send{' '}
            {chainAsset(
              chainId === bsc.id ? Chains.BinanceChain : Chains.Ethereum
            )}{' '}
            to your{' '}
            {
              chainsOptions.filter((option) => option.value === chainId)?.[0]
                ?.label
            }{' '}
            wallet and start to trade.
          </div>
          <div className="flex flex-col xxs:gap-4">
            <SortDropdown<number>
              mainLabel="Chain"
              options={chainsOptions}
              value={chainId ?? base.id}
              disabled={true}
              onChange={() => {}}
            />

            <SortDropdown
              mainLabel="Asset"
              options={[
                {
                  value:
                    chainId === bsc.id
                      ? chainAsset(Chains.BinanceChain).toLowerCase()
                      : chainAsset(Chains.Ethereum).toLowerCase(),
                  label:
                    chainId === bsc.id
                      ? chainAsset(Chains.BinanceChain)
                      : chainAsset(Chains.Ethereum),
                  icon: (
                    <AssetIconNew
                      imgName={
                        chainId === bsc.id
                          ? chainAsset(Chains.BinanceChain).toLowerCase()
                          : chainAsset(Chains.Ethereum).toLowerCase()
                      }
                    />
                  ),
                },
              ]}
              disabled={true}
              value={assetSymbol || ''}
              onChange={() => {
                setState((state) => ({
                  ...state,
                }));
              }}
            />

            <TextInput
              name="walletAddress"
              label="Wallet Address"
              value={wallet?.address || ''}
              readOnly
              suffix={
                <CopyContent text={wallet?.address}>
                  <IconButton disabled={!wallet?.address}>
                    <DocumentDuplicateIcon width={24} height={24} />
                  </IconButton>
                </CopyContent>
              }
            />
          </div>
          {config.isTelegramBrowser ? null : (
            <>
              <div className="xxs:my-2 flex justify-center text-base">or</div>
              <DepositOptions />
            </>
          )}
          <NotificationDex type="warning" className="xxs:mt-2 xxs:mx-0">
            Make sure you send your funds from a{' '}
            {
              chainsOptions.filter((option) => option.value === chainId)?.[0]
                ?.label
            }{' '}
            wallet.
          </NotificationDex>
        </CustomModal.Content>
      </CustomModal.Body>
    </>
  );
};

function DepositOptions() {
  const { connect, connectors, pendingConnector, isLoading, isSuccess } =
    useConnect();
  const { chain } = useNetwork();
  const [, setStep] = useDepositStepper();
  const { connector: selectedConnector } = useAccount();

  useEffect(() => {
    if (isSuccess) {
      setStep(DepositSteps.SelectAmount);
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex flex-col xxs:gap-2">
        {connectors.map((connector) => {
          return (
            <Button
              loading={isLoading && connector.id === pendingConnector?.id}
              variant="dexNeutral"
              disabled={
                !connector.ready ||
                (isLoading && connector.id !== pendingConnector?.id)
              }
              key={connector.id}
              onClick={() => {
                if (selectedConnector?.id === connector.id) {
                  setStep(DepositSteps.SelectAmount);
                } else {
                  connect({ connector, chainId: chain?.id });
                }
              }}
              className="justify-start"
            >
              <div className="xxs:w-full items-center flex xxs:gap-4 normal-case">
                <WalletIcon name={connector.name} width={24} height={24} />
                Deposit with {connector.name}
              </div>
            </Button>
          );
        })}
      </div>
    </>
  );
}
