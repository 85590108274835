import React from 'react';
import { screenGte, useMediaQuery } from './use-media-query';
import Button from 'modules/shared-components/button/button';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

export const MobileOnly = ({ children }: Props) => {
  const isDesktop = useMediaQuery(screenGte.large);

  return !isDesktop ? (
    <>{children}</>
  ) : (
    <div className="flex flex-col justify-center items-center">
      This page cannot be accessed from a desktop.
      <Button variant="primary" as={Link} to="/" className="w-max">
        Go to main page
      </Button>
    </div>
  );
};
