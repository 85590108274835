import {
  ArrowsRightLeftIcon,
  AtSymbolIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  CreditCardIcon,
  FireIcon,
  LightBulbIcon,
  PhoneIcon,
  Square3Stack3DIcon,
  TrophyIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { Chains } from 'api/types/httpsTypes/d-wallets';

export enum Tabs {
  HotTokens = 'hot-tokens',
  Performance = 'performance',
  History = 'history',
  OpenTrades = 'open-trades',
  SmartSwaps = 'smart-swaps',
  Wallets = 'wallets',
  NewPools = 'new-pools',
  TelegramCalls = 'telegram-calls',
  TelegramChannels = 'telegram-channels',
  VolatilityBot = 'volatility-bot',
  WalletCopyTrading = 'wallet-copy-trading',
  TgCopyTrading = 'telegram-copy-trading',
  AlphaCalls = 'alpha-calls',
  Leaderboard = 'leaderboard',
  Portfolio = 'portfolio',
  Swaps = 'swaps',
}

export type SidebarItemProps = {
  label: string;
  icon: React.ReactNode;
  to: string;
};

export type SidebarConfigProps = {
  items: SidebarItemProps[] | [];
};

export const RESEARCH_SIDEBAR = (chain: Chains): SidebarConfigProps => {
  return {
    items: [
      {
        label: 'Hot Tokens',
        icon: <FireIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/research/${Tabs.HotTokens}`,
      },
      {
        label: 'Smart Swaps',
        icon: <LightBulbIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/research/${Tabs.SmartSwaps}`,
      },
      {
        label: 'Smart Wallets',
        icon: <WalletIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/research/${Tabs.Wallets}`,
      },
      ...(chain === Chains.Solana
        ? []
        : [
            {
              label: 'New Pools',
              icon: (
                <Square3Stack3DIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />
              ),
              to: `/dex/research/${Tabs.NewPools}`,
            },
          ]),
      ...(chain === Chains.Solana
        ? []
        : [
            {
              label: 'Telegram Calls',
              icon: <PhoneIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
              to: `/dex/research/${Tabs.TelegramCalls}`,
            },
          ]),
      ...(chain === Chains.Solana
        ? []
        : [
            {
              label: 'Telegram Channels',
              icon: <AtSymbolIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
              to: `/dex/research/${Tabs.TelegramChannels}`,
            },
          ]),
    ],
  };
};

export const BOT_SIDEBAR = (chain: Chains): SidebarConfigProps => {
  return {
    items: [
      {
        label: 'Volatility Bots',
        icon: <ChartBarSquareIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/bots/${Tabs.VolatilityBot}`,
      },
      {
        label: 'Wallet Copy Trading',
        icon: <CreditCardIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/bots/${Tabs.WalletCopyTrading}`,
      },
      ...(chain === Chains.Solana
        ? []
        : [
            {
              label: 'TG Copy Trading',
              icon: <PhoneIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
              to: `/dex/bots/${Tabs.TgCopyTrading}`,
            },
          ]),
    ],
  };
};

export const WALLET_MOBILE_SIDEBAR: (
  walletAddress?: string
) => SidebarConfigProps = (walletAddress?: string) => {
  return {
    items: [
      {
        label: 'Portfolio',
        icon: <WalletIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/wallets/${Tabs.Portfolio}${
          walletAddress ? `?walletAddress=${walletAddress}` : ''
        }`,
      },
      {
        label: 'Performance',
        icon: <ChartBarSquareIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/wallets/${Tabs.Performance}${
          walletAddress ? `?walletAddress=${walletAddress}` : ''
        }`,
      },
      {
        label: 'Swaps',
        icon: <ArrowsRightLeftIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
        to: `/dex/wallets/${Tabs.Swaps}${
          walletAddress ? `?walletAddress=${walletAddress}` : ''
        }`,
      },
    ],
  };
};
export const DASHBOARD_SIDEBAR: SidebarConfigProps = {
  items: [
    {
      label: 'Performance',
      icon: <ChartBarSquareIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/dashboard/${Tabs.Performance}`,
    },
    {
      label: 'Open Trades',
      icon: <FireIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/dashboard/${Tabs.OpenTrades}`,
    },
    {
      label: 'History',
      icon: <ChartBarIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/dashboard/${Tabs.History}`,
    },
    {
      label: 'Wallets',
      icon: <WalletIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/dashboard/${Tabs.Wallets}`,
    },
  ],
};

export const ALPHA_LEADERBOARD_SIDEBAR: SidebarConfigProps = {
  items: [
    {
      label: 'Leaderboard',
      icon: <TrophyIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/alpha-program/${Tabs.Leaderboard}`,
    },
    {
      label: 'Alpha Calls',
      icon: <PhoneIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/alpha-program/${Tabs.AlphaCalls}`,
    },
    {
      label: 'History',
      icon: <ChartBarIcon className="xxs:w-8 xxs:h-8 lg:w-5 lg:h-5" />,
      to: `/dex/alpha-program/${Tabs.History}`,
    },
  ],
};

export const MAIN_INFO = {
  leaderboard: [
    {
      activeTab: Tabs.Leaderboard,
      name: 'Alpha Leaderboard',
      icon: <TrophyIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The Mizar Leaderboard ranks the top-5 traders based on profits
          generated from their alpha calls in the Mizar community,and the best
          10 tokens called. <br />
          <br />
          Whether you’re aiming to learn from the top traders or earn up to
          $5,000 each week for sharing alpha, the Mizar Leaderboard is your
          go-to place for on-chain trading.
        </p>
      ),
      tutorials: [
        {
          title: 'Check the Program',
          link: 'https://docs.mizar.com/sniper-bot-manual/alpha-program',
        },
      ],
    },
    {
      activeTab: Tabs.History,
      name: 'History',
      icon: <ChartBarIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The Mizar History provides a detailed overview of all seasons,
          showcasing the total generated profits and highlighting the top 3
          traders based on the profits earned from their alpha calls within the
          Mizar community. <br />
          <br />
          Whether you’re looking to learn from top traders or aiming to earn up
          to $5,000 each week by sharing your alpha, the Mizar Leaderboard is
          your ultimate destination for on-chain trading insights and rewards.
        </p>
      ),
      tutorials: [
        {
          title: 'Check the Program',
          link: 'https://docs.mizar.com/sniper-bot-manual/alpha-program',
        },
      ],
    },
    {
      activeTab: Tabs.AlphaCalls,
      name: 'Alpha Calls',
      icon: <PhoneIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The Alpha Calls Dashboard tracks all the alpha shared by the Mizar
          community in its official Discord channel.
          <br />
          <br />
          Any Mizar user can join the Mizar Alpha Program by sharing alpha and
          token insights in the Discord channel, with weekly prizes of up to
          $5,000! It’s a great way to discover new tokens, discuss the best
          options, and either trade them manually or add them to your volatility
          bots. Participating—whether as an alpha caller or a reader—is also one
          of the best ways to learn and grow.
        </p>
      ),
      tutorials: [
        {
          title: 'Check the Program',
          link: 'https://docs.mizar.com/sniper-bot-manual/alpha-program',
        },
      ],
    },
  ],
  dashboard: [
    {
      activeTab: Tabs.Performance,
      name: 'Your 30D Performance',
      icon: <ChartBarSquareIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The 30D Performance Dashboard lets you track your performance over the
          last 30 days and provides access to advanced statistics.
          <br />
          <br />
          Use this section to analyze your activity, including realized and
          unrealized profits and losses, a detailed list of aggregated swaps
          (categorized by type, such as sniping or early apes), your typical
          average market cap trades, win/loss ratios, and much more.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/wallets#wallet-advanced-statistics',
        },
      ],
    },
    {
      activeTab: Tabs.OpenTrades,
      name: 'View and Manage Your Open Trades',
      icon: <FireIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The Open Trades Management Dashboard allows you to view and manage all
          your current open trades.
          <br />
          <br />
          In this section, access key details such as current realized and
          unrealized profits and losses, the number of take profits and DCA
          executed or planned, and more.
          <br />
          <br />
          You can also manually manage trades—buy or sell portions, edit trade
          settings, or merge two trades.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/snipe-monitoring',
        },
      ],
    },
    {
      activeTab: Tabs.History,
      name: 'View Your Closed Trades',
      icon: <ChartBarIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The History Dashboard helps you review all closed trades.
          <br />
          <br />
          Once a token is fully sold, the trade is marked as closed. This
          section provides an overview of all closed trades, including realized
          profits and returns, gas fees paid, and other key details.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/snipe-monitoring',
        },
      ],
    },
    {
      activeTab: Tabs.Wallets,
      name: 'Create and Manage Your Wallets',
      icon: <WalletIcon className="h-6 w-6 xxs:text-dex-white" />,
      description: (
        <p>
          The Wallet Management Dashboard enables you to create new wallets and
          manage existing ones.
          <br />
          <br />
          To trade with Mizar, you need first to create a wallet and securely
          store your private keys. These wallets are compatible with Mizar as
          well as services like MetaMask or TrustWallet.
          <br />
          <br />
          In this dashboard, you can also view wallet balances and portfolio
          details within this section.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/wallets',
        },
      ],
    },
  ],
  bot: [
    {
      activeTab: Tabs.VolatilityBot,
      name: 'Buy Low, Sell High, Automatically',
      icon: <ChartBarSquareIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          The Mizar Volatility Bot enables you to create automated swing trading
          strategies on a set of whitelisted tokens. The bot buys a token when
          its price drops by a predefined percentage within a specified time
          frame and sells it once the price rebounds. The bot can trade across
          multiple DEXs, buying in the price pool where the price drop happened,
          and selling in the pool with the highest liquidity, maximizing your
          trading opportunities.
          <br />
          <br />
          By leveraging advanced settings such as DCA (Dollar-Cost Averaging)
          orders, you can build robust strategies designed to optimize profits
          during periods of high volatility—a hallmark of DeFi trading.
          <br />
          <br />
          To identify tokens for your whitelist, use our Research tools and join
          our vibrant Discord community{' '}
          <a
            href="https://discord.com/invite/gM4mAYMeWG"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          , where traders share valuable insights and alpha.
        </p>
      ),
      tutorials: [
        {
          title: 'Watch Tutorial',
          link: 'https://www.youtube.com/watch?v=rYh-0dkEh3M',
        },
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/bots/volatility-bot',
        },
        {
          title: 'Best Settings',
          link: 'https://docs.mizar.com/sniper-bot-manual/bots/volatility-bot/mastering-the-mizar-volatility-bot',
        },
      ],
    },
    {
      activeTab: Tabs.WalletCopyTrading,
      name: 'Copy-trade Other Wallets',
      icon: <CreditCardIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          Copy and Paste a wallet address and instantly replicate its trades
          with high precision and speed.
          <br />
          <br /> The Mizar Wallet Copy-Trading Bot allows you to seamlessly copy
          various trading strategies through our advanced settings. Key features
          include using limit orders for precise entry and exit points,
          copy-buy-amount to match the exact trade volume of the wallet, and DCA
          (Dollar-Cost Averaging) to automatically buy more when the wallet
          executes additional trades.{' '}
        </p>
      ),
      tutorials: [
        {
          title: 'Watch Tutorial',
          link: 'https://www.youtube.com/watch?v=DNF_uWQ9DY4&t=4s',
        },
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/bots/wallet-bot',
        },
      ],
    },
    {
      activeTab: Tabs.TgCopyTrading,
      name: 'Copy-trade Telegram Channels',
      icon: <PhoneIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          Discover the best Telegram channels or groups and automatically buy
          every time a token is mentioned. <br />
          <br /> Mizar Telegram Copy-Trading scans over 600 Telegram channels
          and groups, tracking every token mentioned by the admins or the
          community. This allows you to automatically buy and be among the first
          to act on a call. Leverage advanced settings, such as buying only when
          multiple channels mention the same token within a defined time frame,
          for smarter and more strategic trading.{' '}
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/bots/telegram-bot',
        },
      ],
    },
  ],
  research: [
    {
      activeTab: Tabs.HotTokens,
      name: 'Find the Hottest Tokens in Real-Time',
      icon: <FireIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          Discover top-performing tokens in real time with the Hot Tokens
          Dashboard, for free!
          <br />
          <br />
          Mizar state-of-the-art AI wallet labeling system enables you to track
          the activities of smart wallets, and track what they are buying and
          selling. Gain powerful insights into smart money trends, monitor
          market volatility, and access a wealth of data—all in one intuitive
          dashboard.
        </p>
      ),
      tutorials: [
        {
          title: 'Watch Tutorial',
          link: 'https://www.youtube.com/watch?v=q0ytM91tJR8',
        },
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/hot-tokens',
        },
      ],
    },
    {
      activeTab: Tabs.SmartSwaps,
      name: 'Check what smart wallets are trading',
      icon: <LightBulbIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          Stay ahead of the curve by monitoring top-performing wallets and
          tracking their buying and selling activities—all in real time, and
          completely free! <br />
          <br /> With Mizar's cutting-edge AI wallet labeling system, you can
          seamlessly follow the actions of smart wallets and analyze their
          trading patterns. By checking the latest swaps in real time, you'll
          get powerful insights into where smart money is flowing, empowering
          you to make more informed decisions.{' '}
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/smart-swaps',
        },
      ],
    },
    {
      activeTab: Tabs.NewPools,
      name: 'Find newly deployed tokens and pools',
      icon: <Square3Stack3DIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          Discover newly launched tokens and pools in real-time with Mizar.
          <br />
          <br />
          Mizar continuously scans the blockchain to track the latest pools as
          they are launched. This powerful dashboard enables you to stay ahead
          by finding new tokens early, providing fundamental insights like
          security checks and details such as liquidity levels.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/new-pools',
        },
      ],
    },
    {
      activeTab: Tabs.TelegramCalls,
      name: 'Find Alpha with Telegram calls',
      icon: <PhoneIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          The Telegram Calls dashboard provides real-time insights into trending
          tokens called across more than 600 Telegram channels and groups.
          <br /> <br />
          Mizar’s Telegram scraper monitors over 600 public Telegram channels
          and groups, tracking every mention of new tokens—whether it's a fresh
          launch or a token getting traction. Use this dashboard to discover
          which tokens are being discussed across multiple channels, and
          copy-trade them with ease.
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/telegram-calls',
        },
      ],
    },
    {
      activeTab: Tabs.TelegramChannels,
      name: ' Find the best Telegram channels',
      icon: <AtSymbolIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          The Telegram Channels Dashboard helps you analyze over 600 Telegram
          channels and track their performance over the past 30 days.
          <br /> <br />
          Mizar’s Telegram scraper monitors more than 600 public Telegram
          channels and groups, capturing every mention of new tokens—whether
          it's a fresh launch or a token gaining traction. Use this dashboard to
          visualize the performance of each channel and track how each token
          performs after it’s been called.{' '}
        </p>
      ),
      tutorials: [
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/telegram-channels',
        },
      ],
    },
    {
      activeTab: Tabs.Wallets,
      name: ' Find the best wallets to copy-trade',
      icon: <WalletIcon className="h-6 w-6 xxs:text-dex-white " />,
      description: (
        <p>
          {' '}
          Find top-performing wallets effortlessly with Mizar's AI labeling
          system—completely free! <br />
          <br />
          Mizar's advanced AI analyzes swaps in real time, labeling wallets
          based on their performance. This can help you to identify the best
          snipers and traders, track their buys and sells, and even use Mizar's
          trading bots to copy-trade their strategies. Additionally, Mizar flags
          suspicious wallets, helping you avoid risky tokens and trade more
          confidently.{' '}
        </p>
      ),
      tutorials: [
        {
          title: 'Watch Tutorial',
          link: 'https://www.youtube.com/watch?v=DNF_uWQ9DY4&t=4s',
        },
        {
          title: 'Read the Docs',
          link: 'https://docs.mizar.com/sniper-bot-manual/research/wallets',
        },
      ],
    },
  ],
};
