import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatWithPrecision } from 'utils/FormatNumber';
import Tooltip from 'modules/shared-components/tooltip';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import { LeaderboardRow, UserDetails } from 'api/types/httpsTypes/leaderboard';
import { DiscordImgComponent } from '../discordImgComponent';
import goldImg from '../../../../../assets/img/trader-levels/gold.png';
import silverImg from '../../../../../assets/img/trader-levels/silver.png';
import bronzeImg from '../../../../../assets/img/trader-levels/bronze.png';
import Big from 'big.js';
import { PerformanceStyle } from '../../../components/alerts/performanceStyle';

const UserInfo = ({ user }: { user: UserDetails }) => {
  return (
    <div className="flex xxs:gap-2 items-center">
      <div>
        <DiscordImgComponent
          isAlphaGrandMaster={user.isAlphaGrandMaster}
          isAlphaInternationalMaster={user.isAlphaInternationalMaster}
          isAlphaMaster={user.isAlphaMaster}
          imageUrl={user.img}
          name={user.userName}
        />
      </div>
      <div className="flex space-x-1 items-center">
        <div className="xxs:gap-2">
          <div className="font-bold text-sm max-w-[150px] truncate">
            {user.userName}
          </div>
        </div>
      </div>
    </div>
  );
};

export const desktopColumns = (
  chain: Chains
): TableColumn<LeaderboardRow>[] => {
  return [
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="A season spans one week, starting every Monday at 00:00:00 UTC and ending the following Monday at 00:00:00 UTC.">
            <div>Season</div>
          </Tooltip>
        </div>
      ),
      key: 'SEASON',
      component: ({ data }) => (
        <div className="flex items-center">{data.season}</div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The Calendar Week during which the season took place.">
            <div>CW</div>
          </Tooltip>
        </div>
      ),
      key: 'CW',
      component: ({ data }) => (
        <div className="">
          {data.calendarWeek}-{data.year}
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white xxs:pl-2">
          <Tooltip text="The caller who secured the first position by generating the highest realized profit for the Mizar community through their alphas.">
            <div>
              <img alt="gold" className="h-[25px]" src={goldImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'GOLD',
      component: ({ data }) => (
        <span> {data.gold ? <UserInfo user={data.gold} /> : '??'}</span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white xxs:pl-2">
          <Tooltip text="The caller who secured the second position by generating the second-highest realized profit for the Mizar community through their alphas.">
            <div>
              <img alt="silver" className="h-[25px]" src={silverImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'SILVER',
      component: ({ data }) => (
        <span> {data.silver ? <UserInfo user={data.silver} /> : '??'}</span>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white xxs:pl-2">
          <Tooltip text="The caller who secured the third position by generating the third-highest realized profit for the Mizar community through their alphas.">
            <div>
              <img alt="bronze" className="h-[25px]" src={bronzeImg} />
            </div>
          </Tooltip>
        </div>
      ),
      key: 'BRONZE',
      component: ({ data }) => (
        <span> {data.bronze ? <UserInfo user={data.bronze} /> : '??'}</span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in quote currency from the user's alpha calls.">
            <div>Realized PnL ({chainAsset(chain)})</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_QUOTE',
      component: ({ data }) => (
        <span>
          <PerformanceStyle
            className="xxs:text-sm"
            pnl={data.realizedPnlQuote ?? 0}
            asset={chainAsset(chain)}
          />
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in USD from the user's alpha calls.">
            <div>Realized PnL (USD)</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_USD',
      component: ({ data }) => (
        <span>
          <PerformanceStyle
            className="xxs:text-sm"
            pnl={data.realizedPnlUSD ?? 0}
          />
        </span>
      ),
    },

    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The prize awarded to the user in ETH.">
            <div>Prize ({chainAsset(chain)})</div>
          </Tooltip>
        </div>
      ),
      key: 'PRIZE_QUOTE',
      component: ({ data }) => (
        <span>
          {formatWithPrecision(2, Big(data.prizeInQuote ?? 0).toNumber()) ??
            '--'}{' '}
          {chainAsset(chain)}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The prize awarded to the user in USD.">
            <div>Prize (USD)</div>
          </Tooltip>
        </div>
      ),
      key: 'PRIZE_USD',
      component: ({ data }) => (
        <span>
          $
          {formatWithPrecision(2, Big(data.prizeInUSD ?? 0).toNumber()) ?? '--'}
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The unique alphas called during the season.">
            <div>Alphas</div>
          </Tooltip>
        </div>
      ),
      key: 'CALLS',
      component: ({ data }) => <div className="">{data.alphaCalled}</div>,
    },
  ];
};
