import { TelegramChannelsRowInfo } from 'api/types/httpsTypes/contracts';
import Big from 'big.js';
import Tooltip from 'modules/shared-components/tooltip';

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export const AlertRiskyChannel = (data: TelegramChannelsRowInfo) => {
  return (data.rugPullRatio && Big(data.rugPullRatio).gt(0.05)) ||
    (data.numberWinsOverLossesAndRugPulls &&
      data.numberWinsOverLossesAndRugPulls < 0) ? (
    <Tooltip
      text={`This trader has been labeled as risky because ${
        data.rugPullRatio && Big(data.rugPullRatio).gt(0.05)
          ? 'its rug-pull ratio is higher than 5%'
          : ''
      }${
        data.rugPullRatio &&
        Big(data.rugPullRatio).gt(0.05) &&
        data.numberWinsOverLossesAndRugPulls &&
        data.numberWinsOverLossesAndRugPulls < 0
          ? ' and '
          : ''
      }${
        data.numberWinsOverLossesAndRugPulls &&
        data.numberWinsOverLossesAndRugPulls < 0
          ? 'its hit ratio is below 50%'
          : ''
      }`}
    >
      <span className="flex">
        <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
      </span>
    </Tooltip>
  ) : (
    ''
  );
};
