import { UserV1 } from 'api/types/httpsTypes/user';
import { cookies, AUTH_SESSION_KEY } from './cookieConverter';
import axios from 'axios';
import { REFRESH_TOKEN_URL } from 'modules/user/silentRefresh';

export function getUserFromSession(): UserV1 | null {
  const user = cookies.get() as unknown as { mizar_user?: UserV1 };

  if (user?.mizar_user) return user.mizar_user;
  deleteUserCookies();
  return null;
}

export function deleteUserCookies() {
  cookies.remove(AUTH_SESSION_KEY);
}

export function getLocalRefreshToken() {
  const user = getUserFromSession();
  return user?.refresh_token;
}

export function getLocalAccessToken() {
  const user = getUserFromSession();
  return user?.access_token;
}

export function updateLocalAccessToken(token: string) {
  const user = getUserFromSession();
  if (user) {
    user.access_token = token;
    setUserCookie(user);
  }
}

export function setUserCookie(user: UserV1) {
  cookies.set(AUTH_SESSION_KEY, user, {
    SameSite: 'Strict',
    expires: 15, // cookies are set to expire in 2 weeks
  });
}

export const manuallyRefreshToken = async (): Promise<string | null> => {
  const refreshToken = getLocalRefreshToken();

  if (!refreshToken) {
    console.warn('No refresh token found during manual refresh.');
    return null;
  }

  try {
    const refreshResponse = await axios
      .create({
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      .post(REFRESH_TOKEN_URL);

    const newToken = refreshResponse.data.access_token;

    updateLocalAccessToken(newToken);
    axios.defaults.headers.common.Authorization = 'Bearer ' + newToken;

    return newToken;
  } catch (err) {
    console.error('Manual refresh failed. Logging out...');
    deleteUserCookies();
    window.location.href = window.location.origin + '/login';
    return null;
  }
};
