import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  MobileExpandableCard,
  MobileExpandCardTrigger,
} from 'modules/shared-components/MobileExpandableCard';
import {
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  MinusIcon,
  PlusIcon,
  ScaleIcon,
  ShieldCheckIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import Tooltip from 'modules/shared-components/tooltip';
import { IconButton } from 'modules/shared-components/button/IconButton';
import Big from 'big.js';
import {
  DcaOrderType,
  VolatilityBotFormValues,
  VolatilityPeriod,
} from '../schema';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import {   chainAsset,
  chainDefaultBuyAmountInBaseAsset,
  Chains, } from 'api/types/httpsTypes/d-wallets';
import Button from 'modules/shared-components/button/button';
import { Link } from 'react-router-dom';
import { useToggle } from 'modules/utils/useToggle';
import { PreSetModal } from './PreSetsModal';
import {
  RowLabel,
  RowLabelledCheckBox,
} from '../../../components/RowLabelledInput';
import { NotificationDex } from '../../../components/alerts/notification';
import NumberInput from '../../../components/inputs/number-input';
import Checkbox from '../../../components/inputs/Checkbox';
import TextInput from '../../../components/inputs/text-input/text-input';
import { DexCard } from '../../../components/cards';
import { SortDropdown } from '../../../components/dropdown/sort-dropdown';
import { fromAmount } from '../../../components/chains/units';
import { calculateWordWidth } from 'modules/shared-components/input/number-input/calculateLengthPixel';

interface MaxFundsProps {
  am: number | string;
  ad: { address: string }[] | undefined;
  dcaO: DcaOrderType | undefined;
  isDca: boolean;
  isFromWei?: boolean;
  chain: Chains;
}

export function MaxFundsNeeded({
  am,
  ad,
  dcaO,
  isDca,
  isFromWei,
  chain,
}: MaxFundsProps) {
  const amount = am
    ? (isFromWei
        ? fromAmount(am, chain, 'weiToEther')
        : parseFloat(am.toString())) ?? 0
    : 0;
  const nOfOrders = ad ? ad?.length : 0;
  const dcaOrders = isDca ? dcaO ?? undefined : undefined;

  return Big(
    Big(amount ?? 0)
      .plus(
        dcaOrders
          ? dcaOrders
              .map((l) =>
                l.amount
                  ? isFromWei
                    ? fromAmount(l.amount, chain, 'weiToEther')
                    : parseFloat(l.amount.toString()) ?? 0
                  : 0
              )
              .reduce((acc, a) => acc + a, 0)
          : 0 ?? 0
      )
      .mul(nOfOrders ?? 0)
  )
    .toFixed(5)
    .toString();
}

function DcaOrders() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<VolatilityBotFormValues>();

  const dcaOrders = watch('botSettings.entries.limitOrders') as
    | DcaOrderType
    | undefined;
  const chain = watch('tradingSettings.chain');
  const slippage = watch('tradingSettings.slippage');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'botSettings.entries.limitOrders',
    keyName: 'key',
  });

  return (
    <div className="space-y-1">
      {fields.map((row, index) => (
        <div className="flex xxs:space-x-1" key={row.key}>
          <Controller
            name={`botSettings.entries.limitOrders.${index}.priceDeviationPercentage`}
            control={control}
            defaultValue={'' as any}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                inputSize="small"
                prefix="-"
                suffix="%"
                error={error?.message}
                hideErrorMessage
                value={field.value as number}
              />
            )}
          />

          <Controller
            name={`botSettings.entries.limitOrders.${index}.amount`}
            control={control}
            defaultValue={'' as any}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                inputSize="small"
                suffix={chainAsset(chain)}
                error={error?.message}
                hideErrorMessage
                value={field.value}
              />
            )}
          />

          <Controller
            name={`botSettings.entries.limitOrders.${index}.slippage`}
            control={control}
            defaultValue={'' as any}
            render={({ field, fieldState: { error } }) => (
              <NumberInput
                {...field}
                inputSize="small"
                suffix="%"
                error={error?.message}
                hideErrorMessage
                value={field.value}
              />
            )}
          />

          {index === 0 ? (
            <IconButton
              className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
              type="button"
              onClick={() =>
                append({
                  amount:
                    !dcaOrders || dcaOrders.length === 0
                      ? fromAmount(
                          chainDefaultBuyAmountInBaseAsset(chain),
                          chain,
                          'weiToEther'
                        )
                      : Big(dcaOrders[dcaOrders.length - 1]?.amount ?? 0)
                          .mul(1.5)
                          .toNumber(),
                  priceDeviationPercentage:
                    !dcaOrders || dcaOrders.length === 0
                      ? 5
                      : Big(
                          dcaOrders[dcaOrders.length - 1]
                            ?.priceDeviationPercentage ?? 0
                        )
                          .plus(5)
                          .toNumber(),
                  slippage:
                    !dcaOrders || dcaOrders.length === 0
                      ? slippage
                      : Big(dcaOrders[dcaOrders.length - 1]?.slippage ?? 0)
                          .mul(1.2)
                          .toNumber(),
                })
              }
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </IconButton>
          ) : (
            <IconButton
              className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
              type="button"
              onClick={() => remove(index)}
            >
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            </IconButton>
          )}
        </div>
      ))}
      {errors?.botSettings?.entries?.limitOrders?.message && (
        <div className="text-red-500">
          {errors.botSettings?.entries.limitOrders.message}
        </div>
      )}
    </div>
  );
}

export function VolatilitySettings({
  isEdit,
  isDuplicate,
}: {
  isEdit?: boolean;
  isDuplicate?: boolean;
}) {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<VolatilityBotFormValues>();
  const [isOpen, { open, close }] = useToggle();
  const [openBoxAutoRetry, setOpenBoxAutoRetry] = useState(false);
  const [openBoxLiquidityProtection, setOpenBoxLiquidityProtection] = useState(
    watch('botSettings.entries.liquidityProtectionEnabled')
  );
  const botSettings = watch('botSettings.entries');
  const [openBoxDca, setOpenBoxDca] = useState(
    watch('botSettings.entries.isDcaActive')
  );

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'botSettings.entries.addresses',
    keyName: 'key',
  });

  const addresses = botSettings.addresses;
  const priceChange = botSettings.priceChange;
  const periodInMinutes = botSettings.periodInMinutes;
  const chain = watch('tradingSettings.chain');
  const slippage = watch('tradingSettings.slippage');
  const isDcaActive = watch('botSettings.entries.isDcaActive');
  const dcaOrders = watch('botSettings.entries.limitOrders');
  const takeProfitActive = watch('tradingSettings.takeProfit')?.length > 0;
  const stopLossActive = watch('tradingSettings.stopLoss');

  const amount = watch('tradingSettings.buyAmount');

  const DEFAULT_LIMIT_ORDER = {
    amount:
      amount && Big(amount ?? 0).gt(0)
        ? amount
        : fromAmount(
            chainDefaultBuyAmountInBaseAsset(chain),
            chain,
            'weiToEther'
          ),
    priceDeviationPercentage: 10,
    slippage,
  };

  useEffect(() => {
    if (!isEdit && !isDuplicate) {
      remove();
      setValue('botSettings.entries.addresses', [{ address: '' }]);
    }
  }, [chain]);

  return (
    <MobileExpandableCard
      mobileLabel="Volatility Settings"
      trigger={
        <MobileExpandCardTrigger>
          {errors?.botSettings?.entries ? (
            <div className="flex space-x-1 items-center text-sm">
              <ExclamationCircleIcon className="xxs:text-red-500 xxs:w-6 h-6" />
              <div>Missing Information</div>
            </div>
          ) : (
            <div className="flex space-x-1 items-center text-sm xxs:text-dex-white-secondary">
              <div className="">Volatility:</div>

              <div className="flex space-x-1 items-center font-semibold">
                <div>
                  <ShieldCheckIcon className="w-4 h-4" />
                </div>
                <div className="xxs:text-dex-white">
                  {addresses && addresses.length > 0 && addresses?.[0]?.address
                    ? addresses.length
                    : '--'}
                </div>
              </div>
              <div>|</div>
              <div className="flex space-x-1 items-center font-semibold">
                <div>
                  <ChartBarIcon className="w-4 h-4" />
                </div>
                <div className="xxs:font-semibold">
                  {priceChange ? (
                    <span className="xxs:text-dex-white">
                      -{Big(priceChange ?? 0).toFixed(1)}%
                    </span>
                  ) : (
                    '--'
                  )}
                </div>
              </div>
              <div>|</div>
              <div className="flex space-x-1 items-center font-semibold">
                <div>
                  <ClockIcon className="h-4 w-4" />
                </div>
                <div className="xxs:text-dex-white">
                  {periodInMinutes ? <span>{periodInMinutes}m</span> : '--'}
                </div>
              </div>
              <div>|</div>
              {isDcaActive ? (
                <div className="flex space-x-1 items-center">
                  <div className="font-bold">DCA</div>
                  <CheckCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                </div>
              ) : (
                <div className="flex space-x-1 items-center">
                  <div className="font-bold">DCA</div>
                  <XCircleIcon className="w-5 h-5 xxs:text-dex-white" />
                </div>
              )}
            </div>
          )}
        </MobileExpandCardTrigger>
      }
    >
      <DexCard className="grow" size="xs">
        <div className="hidden lg:block font-bold xxs:text-sm xxs:text-dex-white-secondary">
          Volatility Settings
        </div>
        <fieldset className="space-y-2">
          <div className="lg:flex xxs:space-y-1 lg:space-y-0 lg:space-x-2">
            <div className="w-full lg:w-1/2 ">
              <Controller
                name="botSettings.entries.priceChange"
                defaultValue={'' as any}
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <NumberInput
                      {...field}
                      label="Price Change"
                      tooltip="The price drop within the selected period that will trigger a new entry when hit."
                      prefix={<>-</>}
                      suffix={<>%</>}
                      error={error?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="w-full lg:w-1/2 xxs:space-y-1">
              <Tooltip text="Select the time period over which the price change will be calculated. For example, if you select 5 minutes, the entry will be triggered when the price change over the last 5 minutes reaches your target.">
                <div className="xxs:text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                  {' '}
                  Price Change Period
                </div>
              </Tooltip>
              <Controller
                name="botSettings.entries.periodInMinutes"
                control={control}
                render={({ field }) => (
                  <SortDropdown
                    {...field}
                    mainLabel="Select a period..."
                    options={[
                      {
                        value: VolatilityPeriod.One,
                        label: '1 Min',
                      },
                      {
                        value: VolatilityPeriod.Five,
                        label: '5 Min',
                      },
                      {
                        value: VolatilityPeriod.Fifteen,
                        label: '15 Min',
                      },
                      {
                        value: VolatilityPeriod.Thirty,
                        label: '30 Min',
                      },
                      {
                        value: VolatilityPeriod.Sixty,
                        label: '60 Min',
                      },
                    ]}
                  />
                )}
              />
            </div>
          </div>
          <div className="xxs:space-y-2 lg:space-y-0">
            <div>
              <RowLabelledCheckBox
                label={
                  <RowLabel
                    label="Dollar Cost Averaging (DCA)"
                    isError={!!errors?.botSettings?.entries?.limitOrders}
                    tooltip="Buy more if the price drops beyond your expectations. DCA can help lower your average buy price and increase the chances of making a profit if there’s a rebound."
                  />
                }
                openBox={openBoxDca}
                setOpenBox={
                  watch('botSettings.entries.isDcaActive')
                    ? () => setOpenBoxDca(!openBoxDca)
                    : undefined
                }
                className="xxs:py-1"
              >
                <Controller
                  name={'botSettings.entries.isDcaActive'}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                        checked={
                          field.value === undefined
                            ? false
                            : (field.value as boolean)
                        }
                        onClick={field.onChange}
                        onChange={() => {
                          if (!field.value) {
                            setOpenBoxDca(true);
                            setValue('botSettings.entries.limitOrders', [
                              DEFAULT_LIMIT_ORDER,
                            ]);
                            setValue(
                              'botSettings.entries.expirationInHours',
                              48
                            );
                          } else {
                            if (watch('botSettings.entries.autoRetryEnabled')) {
                              setValue(
                                'botSettings.entries.autoRetry.buy',
                                false
                              );
                            }

                            setValue('botSettings.entries.limitOrders', [
                              DEFAULT_LIMIT_ORDER,
                            ]);
                            setOpenBoxDca(false);
                          }
                        }}
                        id="safetyMeasures.liquidityRange"
                      />
                    );
                  }}
                />
              </RowLabelledCheckBox>
              {openBoxDca && (
                <div className="space-y-1 border-tailwind border-dex-black-700 xxs:p-2 rounded xxs:my-2">
                  <div className="flex justify-between xxs:gap-1 dark:text-black-50 pb-0.5 xxs:text-xs">
                    <div className="w-full">
                      <Tooltip text="The price deviation, calculated from the price at which the first order is executed, determines the price at which an extra buy will be triggered.">
                        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                          {' '}
                          Price Deviation
                        </div>
                      </Tooltip>
                    </div>
                    <div className="w-full flex xxs:space-x-1 items-center">
                      <Tooltip
                        className="w-full"
                        text="The extra amount that will be purchased (DCA) once the price deviation is reached."
                      >
                        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                          {' '}
                          Amount
                        </div>
                      </Tooltip>
                      <div>
                        {isEdit &&
                          watch('botSettings.entries.limitOrdersScale') && (
                            <Button
                              type="button"
                              className="xxs:px-1 xxs:py-0.5"
                              onClick={() => {
                                const a =
                                  watch('tradingSettings.buyAmount') ?? 0;
                                const limitOrdersScale =
                                  watch(
                                    'botSettings.entries.limitOrdersScale'
                                  ) ?? [];

                                if (
                                  !isNaN(a) &&
                                  Big(a).gt(0) &&
                                  limitOrdersScale.length > 0
                                ) {
                                  if (limitOrdersScale) {
                                    limitOrdersScale.forEach((scale) => {
                                      dcaOrders.forEach((dca, index) => {
                                        if (dca.id === scale.id) {
                                          setValue(
                                            `botSettings.entries.limitOrders.${index}.amount`,
                                            Big(amount ?? 0)
                                              .mul(scale.amount ?? 0)
                                              .toNumber()
                                          );
                                        }
                                      });
                                    });
                                  }
                                }
                              }}
                              variant="dexNeutral"
                            >
                              <div>
                                <div className="xxs:space-x-0.5 items-center flex xxs:text-xs normal-case">
                                  <ScaleIcon className="w-4 h-4 lg:hidden" />
                                  <div className="xxs:hidden lg:block">
                                    Auto-scale
                                  </div>
                                  <Tooltip.Info
                                    className="w-3 h-3 xxs:hidden lg:block"
                                    text="Auto-scale your DCA orders based on new buy amount."
                                  />
                                </div>
                              </div>
                            </Button>
                          )}
                      </div>
                    </div>
                    <div className="w-full">
                      <Tooltip
                        className="w-full"
                        text="The slippage applied to the DCA level."
                      >
                        <div className=" xxs:text-dex-white-secondary hover:text-dex-white">
                          {' '}
                          Slippage
                        </div>
                      </Tooltip>
                    </div>
                    <div className="invisible w-[30px] h-[10px]">button</div>
                  </div>
                  <DcaOrders />

                  <Controller
                    name={'botSettings.entries.expirationInHours'}
                    defaultValue={'' as any}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <NumberInput
                          name="Expiration"
                          tooltip="The expiration time (in hours) after which the limit order will be considered expired"
                          value={field.value as number}
                          onChange={(event) =>
                            field.onChange(event.currentTarget.value)
                          }
                          suffix={<>hr</>}
                          label="Expiration time"
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full xxs:space-y-1">
            <div className="flex justify-between items-center">
              <div className="flex space-x-1 items-center">
                <Tooltip text="These are the token addresses that the bot is enabled to trade when triggered.">
                  <div className="xxs:text-dex-white-secondary hover:text-dex-white xxs:text-sm">
                    Whitelisted tokens
                  </div>
                </Tooltip>

                <Button
                  type="button"
                  onClick={open}
                  variant="dexNeutral"
                  className="xxs:px-2 xxs:py-0.5"
                >
                  <span className="xxs:text-xs">Smart pre-sets</span>
                </Button>
                {isOpen && (
                  <PreSetModal
                    handleClose={close}
                    replace={replace}
                    chain={watch('tradingSettings.chain')}
                  />
                )}
              </div>
              <Link
                to="/dex/research?activeTab=hot-tokens"
                className="xxs:text-dex-white-secondary hover:text-dex-white"
              >
                Find hot tokens
              </Link>
            </div>

            <div>
              <div className="xxs:space-y-1">
                {fields.length === 0 ? (
                  <div className="flex xxs:space-x-1" key="empty">
                    <Controller
                      name={`botSettings.entries.addresses.${0}.address`}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field, fieldState: { error: e } }) => {
                        const { data, isLoading, error } = GetContractInfo(
                          chain,
                          field.value
                        );
                        return (
                          <TextInput
                            {...field}
                            inputSize="small"
                            suffix={
                              !field.value
                                ? ''
                                : error
                                ? '?'
                                : isLoading
                                ? '...'
                                : data?.symbol ?? '--'
                            }
                            error={e?.message}
                            hideErrorMessage={true}
                          />
                        );
                      }}
                    />

                    <IconButton
                      className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
                      type="button"
                      onClick={() => append({ address: '' })}
                    >
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </IconButton>
                  </div>
                ) : (
                  fields.map((row, index) => {
                    return (
                      <div className="flex xxs:space-x-1" key={row.key}>
                        <Controller
                          name={`botSettings.entries.addresses.${index}.address`}
                          control={control}
                          render={({ field, fieldState: { error: e } }) => {
                            const { data, isLoading, error } = GetContractInfo(
                              chain,
                              field.value
                            );

                            return (
                              <div className="grid grid-cols-6 space-x-1 w-full">
                                <div className="col-span-5">
                                  <TextInput
                                    {...field}
                                    inputSize="small"
                                    error={e?.message}
                                    hideErrorMessage={true}
                                    value={field.value}
                                  />
                                </div>

                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`/dex/snipe/new/${watch(
                                    'tradingSettings.chain'
                                  )}/${
                                    !field.value
                                      ? '??'
                                      : error
                                      ? '??'
                                      : isLoading
                                      ? '??'
                                      : field.value
                                  }`}
                                  className="text-dex-white-secondary hover:text-dex-white bg-dex-black-700 hover:bg-dex-black-600 rounded xxs:px-2 flex items-center justify-center "
                                >
                                  {!field.value
                                    ? '--'
                                    : error
                                    ? '?'
                                    : isLoading
                                    ? '...'
                                    : (
                                        <div className="truncate">
                                          {data?.symbol}
                                        </div>
                                      ) ?? '--'}
                                </Link>
                              </div>
                            );
                          }}
                        />

                        {index === 0 ? (
                          <IconButton
                            className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
                            type="button"
                            onClick={() => append({ address: '' })}
                          >
                            <PlusIcon className="h-5 w-5" aria-hidden="true" />
                          </IconButton>
                        ) : (
                          <IconButton
                            className="xxs:bg-dex-black-600 hover:bg-dex-black-500 xxs:text-dex-white-secondary hover:text-dex-white xxs:p-1 xxs:rounded"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          </IconButton>
                        )}
                      </div>
                    );
                  })
                )}

                <div>
                  <RowLabelledCheckBox
                    label={
                      <RowLabel
                        label="Auto-retry"
                        tooltip="Enable automatic retries for buy limit orders, take profit, and stop loss transactions. If any of these transactions fail, the bot will automatically attempt to execute the transaction again, retrying once."
                      />
                    }
                    openBox={openBoxAutoRetry}
                    setOpenBox={
                      watch('botSettings.entries.autoRetryEnabled')
                        ? () => setOpenBoxAutoRetry(!openBoxAutoRetry)
                        : undefined
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name={'botSettings.entries.autoRetryEnabled'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Checkbox
                            className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                            checked={
                              field.value === undefined
                                ? false
                                : (field.value as boolean)
                            }
                            onClick={field.onChange}
                            onChange={() => {
                              if (!field.value) {
                                setOpenBoxAutoRetry(true);
                                setValue(
                                  'botSettings.entries.autoRetry.buy',
                                  isDcaActive
                                );
                                setValue(
                                  'botSettings.entries.autoRetry.takeProfit',
                                  takeProfitActive
                                );
                                setValue(
                                  'botSettings.entries.autoRetry.stopLoss',
                                  !!stopLossActive
                                );
                              } else {
                                setValue(
                                  'botSettings.entries.autoRetry.buy',
                                  false
                                );
                                setValue(
                                  'botSettings.entries.autoRetry.takeProfit',
                                  false
                                );
                                setValue(
                                  'botSettings.entries.autoRetry.stopLoss',
                                  false
                                );
                                setOpenBoxAutoRetry(false);
                              }
                            }}
                            id="botSettings.entries.autoRetryEnabled"
                          />
                        );
                      }}
                    />
                  </RowLabelledCheckBox>
                  {openBoxAutoRetry && (
                    <div className="flex flex-col space-y-1">
                      <Controller
                        name="botSettings.entries.autoRetry.buy"
                        defaultValue={'' as any}
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="flex space-x-1 items-center justify-end">
                              <Checkbox
                                disabled={!isDcaActive}
                                className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                                checked={
                                  field.value === undefined
                                    ? false
                                    : (field.value as boolean)
                                }
                                onClick={field.onChange}
                                id="botSettings.entries.autoRetry.buy"
                              />
                              <Tooltip text="Enable this option to automatically retry the buy transaction one more time if the swap fails.">
                                <div className="text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                                  Buy DCA order
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }}
                      />
                      <Controller
                        name="botSettings.entries.autoRetry.takeProfit"
                        defaultValue={'' as any}
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="flex space-x-1 items-center justify-end">
                              <Checkbox
                                className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                                disabled={!takeProfitActive}
                                checked={
                                  field.value === undefined
                                    ? false
                                    : (field.value as boolean)
                                }
                                onClick={field.onChange}
                                id="botSettings.entries.autoRetry.takeProfit"
                              />
                              <Tooltip text="Enable this option to automatically retry the take profit transaction one more time if the swap fails.">
                                <div className="text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                                  Take Profit Order
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }}
                      />
                      <Controller
                        name="botSettings.entries.autoRetry.stopLoss"
                        defaultValue={'' as any}
                        control={control}
                        render={({ field }) => {
                          return (
                            <div className="flex space-x-1 items-center justify-end">
                              <Checkbox
                                className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                                disabled={!stopLossActive}
                                checked={
                                  field.value === undefined
                                    ? false
                                    : (field.value as boolean)
                                }
                                onClick={field.onChange}
                                id="botSettings.entries.autoRetry.stopLoss"
                              />
                              <Tooltip text="Enable this option to automatically retry the stop loss transaction one more time if the swap fails.">
                                <div className="text-sm xxs:text-dex-white-secondary hover:text-dex-white">
                                  Stop Loss Order
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <RowLabelledCheckBox
                    label={
                      <RowLabel
                        label="Min. Liquidity Protection"
                        tooltip="Enable minimum liquidity protection to prevent trading in liquidity pools with total liquidity below the threshold."
                      />
                    }
                    openBox={openBoxLiquidityProtection}
                    setOpenBox={
                      watch('botSettings.entries.liquidityProtectionEnabled')
                        ? () =>
                            setOpenBoxLiquidityProtection(
                              !openBoxLiquidityProtection
                            )
                        : undefined
                    }
                    className="xxs:py-1"
                  >
                    <Controller
                      name={'botSettings.entries.liquidityProtectionEnabled'}
                      defaultValue={'' as any}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Checkbox
                            className="xxs:w-5 xxs:h-5 lg:w-4 lg:h-4"
                            checked={
                              field.value === undefined
                                ? false
                                : (field.value as boolean)
                            }
                            onClick={field.onChange}
                            onChange={() => {
                              if (!field.value) {
                                setOpenBoxLiquidityProtection(true);
                                setValue(
                                  'botSettings.entries.liquidity.min',
                                  10000
                                );
                              } else {
                                setValue(
                                  'botSettings.entries.liquidity.min',
                                  0
                                );
                                setOpenBoxLiquidityProtection(false);
                              }
                            }}
                            id="botSettings.entries.liquidityProtectionEnabled"
                          />
                        );
                      }}
                    />
                  </RowLabelledCheckBox>
                  {openBoxLiquidityProtection && (
                    <div className="flex flex-col space-y-1">
                      <Controller
                        name="botSettings.entries.liquidity.min"
                        defaultValue={'' as any}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <NumberInput
                              {...field}
                              placeholder="0.00"
                              extraStyle={{
                                paddingRight: `${
                                  calculateWordWidth('Min', 12) + 20
                                }px`,
                              }}
                              suffix={<>Min</>}
                              className="text-right"
                              hideErrorMessage={true}
                              prefix="$"
                              error={error?.message}
                            />
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
                <NotificationDex className="xxs:mx-0" type="warning">
                  Do due diligence on each token before trading!
                </NotificationDex>
                {MaxFundsNeeded({
                  am: amount,
                  ad: addresses,
                  dcaO: dcaOrders,
                  isDca: isDcaActive,
                  chain,
                }) && (
                  <NotificationDex className="xxs:mx-0" type="info">
                    <div className="flex space-x-1 items-center">
                      <Tooltip text="Ensure your wallet has at least this amount of funds to open all the orders as configured. Additionally, reserve extra funds to cover gas fees.">
                        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                          Max {chainAsset(chain)} needed:
                        </div>
                      </Tooltip>
                      <div className="xxs:text-dex-white-secondary">
                        {MaxFundsNeeded({
                          am: amount,
                          ad: addresses,
                          dcaO: dcaOrders,
                          isDca: isDcaActive,
                          chain,
                        }) ?? 0}{' '}
                        {chainAsset(chain)}
                      </div>
                    </div>
                  </NotificationDex>
                )}
              </div>
              {errors?.botSettings?.entries?.addresses?.message && (
                <NotificationDex type="error">
                  {errors?.botSettings?.entries?.addresses?.message}
                </NotificationDex>
              )}
            </div>
          </div>
        </fieldset>
      </DexCard>
    </MobileExpandableCard>
  );
}

export function GetContractInfo(
  chain: Chains,
  address: string,
  isNotValid?: boolean
) {
  const [delayedAddress, setDelayedAddress] = useState(address);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedAddress(address);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [address]);

  const { data, isLoading, error } = useQuery({
    queryFn: () => ContractService.getContractInfo(chain, delayedAddress),
    queryKey: contractServiceKeys.getContractInfo(chain, delayedAddress),
    enabled: !isNotValid && !!delayedAddress, // ✅ Prevents query execution when invalid
    keepPreviousData: true,
  });

  return { data, isLoading, error };
}
