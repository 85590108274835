import { AlphaCallsFilterState, useAlphaCallsFilters } from './usePageParams';
import { useEffect, useState } from 'react';
import { useDebouncedState } from 'utils/useDebounce';
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';
import { isValidAddress } from 'helpers/api/web3Utils';
import {
  ActivteFiltersBar,
  FilterTag,
  SelectFiltersBar,
} from '../../../components/filters';
import TextInput from '../../../components/inputs/text-input/text-input';
import { getSeasonForCurrentCalendarWeek } from '../seasons';
import {
  SortDropdown,
  SortDropdownProps,
} from '../../../components/dropdown/sort-dropdown';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import Checkbox from '../../../components/inputs/Checkbox';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useSelectedChain } from '../../../components/chains/getChains';

export const SEASONS_OPTIONS = (selectedChain: Chains) => {
  const currentSeason = getSeasonForCurrentCalendarWeek(selectedChain)
    ? +getSeasonForCurrentCalendarWeek(selectedChain)
    : 0;
  const seasonLength =
    selectedChain === Chains.Base ? 4 : currentSeason > 4 ? 4 : currentSeason;

  return [...Array(seasonLength).keys()]
    .map((s) => ({
      label: `Season ${currentSeason - seasonLength + 1 + s}`,
      value: `${currentSeason - seasonLength + 1 + s}`,
    }))
    .reverse();
};

export const AlphaCallsFilterBar = () => {
  const selectedChain = useSelectedChain();

  const { filtersState: filters, setFilters } =
    useAlphaCallsFilters(selectedChain);
  const [address, debouncedAddress, setHotTokenAddress] = useDebouncedState(
    '',
    250
  );

  const [caller, debouncedCaller, setCaller] = useDebouncedState('', 250);
  const [addressError, setAddressError] = useState(false);
  useEffect(() => {
    if (debouncedAddress) {
      setAddressError(!isValidAddress(debouncedAddress, selectedChain));
      if (isValidAddress(debouncedAddress, selectedChain)) {
        updateFilters({
          address: debouncedAddress ?? '',
        });
      }
    }
  }, [debouncedAddress]);

  useEffect(() => {
    if (debouncedCaller) {
      updateFilters({
        userName: debouncedCaller ?? '',
      });
    }
  }, [debouncedCaller]);

  const handleAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setHotTokenAddress(inputValue);
    if (!inputValue) {
      setAddressError(false);
      updateFilters({
        address: undefined,
      });
    }
  };

  const handleCallerInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const inputValue = e.target.value;
    setCaller(inputValue);
    if (!inputValue) {
      updateFilters({
        userName: undefined,
      });
    }
  };

  const updateFilters = (update: Partial<AlphaCallsFilterState>) => {
    setFilters({ ...filters, ...update });
  };

  const nOfFiltersActive = [
    filters.isMaster,
    filters.address,
    filters.userName,
  ].filter((value) => value !== undefined && value && value !== '').length;
  const isLargeScreen = useMediaQuery(screenGte.large);

  return (
    <div className="xxs:space-y-2 lg:space-y-1">
      <SelectFiltersBar
        sortClass="xxs:w-full lg:w-auto"
        icon={
          <div
            className={twMerge(
              'xxs:p-1 flex xxs:space-x-1 items-center',
              nOfFiltersActive
                ? 'xxs:hover:text-dex-white'
                : ' xxs:hover:text-dex-white xxs:text-dex-white-secondary xxs:px-2 '
            )}
          >
            <FunnelIcon aria-hidden="true" width={16} height={16} />
            {nOfFiltersActive ? <div>{nOfFiltersActive}</div> : ''}
          </div>
        }
        sort={
          !isLargeScreen && (
            <div className="lg:hidden xxs:mr-2 lg:mr-0 gap-x-1">
              <SelectSeasonDropdown
                value={filters.season}
                onChange={(value) => {
                  updateFilters({
                    season: value,
                  });
                }}
              />
            </div>
          )
        }
      >
        <div className="hidden lg:block">
          <SelectSeasonDropdown
            value={filters.season}
            onChange={(value) => {
              updateFilters({
                season: value,
              });
            }}
          />
        </div>
        <div className="">
          <TextInput
            name="address"
            error={addressError ? 'Address not valid' : ''}
            hideErrorMessage={true}
            value={address}
            prefix={
              <MagnifyingGlassIcon className="w-4 xxs:text-dex-white-secondary" />
            }
            onChange={handleAddressInputChange}
            placeholder="Search Address"
          />
        </div>
        <div className="">
          <TextInput
            name="userName"
            hideErrorMessage={true}
            value={caller}
            prefix={'@'}
            onChange={handleCallerInputChange}
            placeholder="Search Caller"
          />
        </div>
        <div className="flex flex-nowrap items-center space-x-1">
          <Checkbox
            className="lg:w-4 lg:h-4 xxs:w-5 xxs:h-5"
            id="trailing"
            checked={filters.isMaster}
            onChange={() => {
              updateFilters({
                isMaster: !filters.isMaster,
              });
            }}
          />
          <Checkbox.Label htmlFor="Alpha masters">
            <div className="xxs:text-sm xxs:text-dex-white-secondary">
              Show Alpha Masters Only
            </div>
          </Checkbox.Label>
        </div>
      </SelectFiltersBar>
      <ActivteFiltersBar>
        {filters.address && (
          <FilterTag
            onRemove={() => {
              setHotTokenAddress('');
              return updateFilters({
                address: undefined,
              });
            }}
            label={`Token: ${filters?.address ?? address ?? '?'}`}
          />
        )}
        {filters.isMaster && (
          <FilterTag
            onRemove={() => {
              return updateFilters({
                isMaster: false,
              });
            }}
            label={`Alpha Master`}
          />
        )}
      </ActivteFiltersBar>
    </div>
  );
};

export function SelectSeasonDropdown(
  props: Omit<SortDropdownProps<string>, 'options'>
) {
  const selectedChain = useSelectedChain();
  return (
    <SortDropdown
      className="xxs:p-2 xxs:text-sm h-[38px]"
      {...props}
      mainLabel="Season"
      options={SEASONS_OPTIONS(selectedChain)}
    />
  );
}
