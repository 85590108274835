import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { Chains, TokenBalance } from 'api/types/httpsTypes/d-wallets';
import { formatTokenAmount, formatUSDAmount } from 'utils/FormatNumber';
import Big from 'big.js';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { useTokenPrice } from 'api/hooks/useTokenPrice';
import { useSelectedChain } from '../../components/chains/getChains';

interface Props {
  chain: Chains;
  data: TokenBalance;
}

const AmountContainer = ({ data }: Partial<Props>) => {
  const chain = useSelectedChain();

  if (!data) {
    return <div className="font-bold text-lg">--</div>;
  } else if (data?.address === '') {
    return (
      <div className="xxs:flex xxs:flex-col sm:block truncate">
        <div className="font-bold text-lg">
          {data.price
            ? `$${formatUSDAmount(
                Big(data.amount ?? 0)
                  .times(data.price ?? 0)
                  .toNumber()
              )}`
            : '--'}
        </div>
        <div className="dark:text-dex-white-secondary">
          {formatTokenAmount(data.amount ?? 0)} {data.symbol}
        </div>
      </div>
    );
  } else return <Amount chain={chain} data={data} />;
};

const Amount = ({ chain, data }: Props) => {
  const currentPrice = useTokenPrice(chain, data.address) ?? 0;
  const currentValue = currentPrice
    ? Big(data.amount ?? 0)
        .times(currentPrice)
        .toNumber()
    : Big(data.amount ?? 0)
        .times(data.price ?? 0)
        .toNumber();

  return (
    <div className="xxs:flex xxs:flex-col sm:block truncate">
      <div className="font-bold text-lg">
        {currentValue ? `$${formatUSDAmount(currentValue)}` : '--'}
      </div>
      <div className="dark:text-dex-white-secondary">
        {formatTokenAmount(data.amount)} {data.symbol}
      </div>
    </div>
  );
};

export const tokenColumn = (
  chain: Chains
): ResponsiveTableColumn<TokenBalance>[] => {
  return [
    {
      label: 'Token',
      key: 'Token',
      showOnMobile: true,
      className: 'xxs:max-w-[80px] md:w-4/12 lg:w-3/12 ',
      component: ({ data }) => (
        <div className="truncate space-y-1 ">
          <div className="flex space-x-1 items-center">
            {data.address && (
              <div className="relative">
                <TokenIconWithChain
                  hasIcon={data.hasIcon}
                  chain={chain}
                  tokenName={data.name}
                  className="h-[25px] w-[25px]"
                  address={data.address}
                  symbol={data.symbol || undefined}
                />
              </div>
            )}
            <div className="truncate flex space-x-2 items-baseline">
              <div className=" whitespace-nowrap max-w-md xxs:text-lg xxs:font-semibold">
                {data.name}{' '}
              </div>
              <div className="whitespace-nowrap xxs:w-[120px] max-w-xs md:w-auto  xxs:text-sm dark:text-dex-white-secondary xxs:font-semibold">
                {data.symbol}
              </div>
            </div>
          </div>
          <div className="">
            <div>
              {data.address ? (
                <EthAddress chain={chain} address={data.address} kind="token" />
              ) : (
                <div className="dark:text-dex-white-secondary">--</div>
              )}
            </div>
          </div>
        </div>
      ),
    },

    {
      label: 'Amount',
      key: 'amount',
      showOnMobile: true,
      className: 'xxs:max-w-[100px] md:w-3/12',
      component: AmountContainer,
    },
  ];
};
