import * as yup from 'yup';
import Big from 'big.js';
import { Chains } from 'api/types/httpsTypes/d-wallets';

export function getSchema() {
  return yup.object({
    maxBalance: yup.string(),
    srcChain: yup.string().oneOf(Object.values(Chains)).required('Required'),
    dstChain: yup.string().oneOf(Object.values(Chains)).required('Required'),
    address: yup.string().required('Required'),
    groupId: yup.string().required('Required'),
    srcChainTokenAmount: yup
      .string()
      .test(
        'isValidAmount',
        'Must be at least 0.01 and lower than available balance',
        (value, context) => {
          if (!value || !context.parent.maxBalance) return false;

          const minAmount = Big(0.01); // Minimum allowed amount
          const maxBalance = Big(context.parent.maxBalance);
          const value18 = Big(value).toFixed(18, Big.roundDown);

          const isGreaterThanMin = Big(value18).gte(minAmount);
          const isLessThanOrEqualToMax = Big(value18).lte(maxBalance);

          return isGreaterThanMin && isLessThanOrEqualToMax;
        }
      )
      .typeError('Number type accepted only')
      .required('Required'),
  });
}

export type FormValues = yup.InferType<ReturnType<typeof getSchema>>;
