import { useState, useEffect } from 'react';

import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useWebsocket } from 'api/hooks/useWebsocket';

interface TokenPriceMessage {
  p: string; // Price
  c: Chains; // Chain
  a: string; // Address
}

export const useTokenPrice = (
  chain: Chains,
  address: string
): string | null => {
  const [price, setPrice] = useState<string | null>(null);
  const message = useWebsocket({
    channel: 'token_price',
    data: { chain, address },
  });

  useEffect(() => {
    if (!message || !message.data) return;

    try {
      const parsedMessage: TokenPriceMessage = message.data;

      // ✅ Only update if it's the correct chain & address
      if (parsedMessage.c === chain && parsedMessage.a === address) {
        setPrice(parsedMessage.p); // Update state
      }
    } catch (error) {
      console.error('❌ Error parsing token price message:', error);
    }
  }, [message, chain, address]);

  return price;
};
