import {
  chainAsset,
  Chains,
  DWalletDetails,
  minimumSafeAmount,
  TokenBalance,
} from 'api/types/httpsTypes/d-wallets';
import Big from 'big.js';
import { NotificationDex } from '../../components/alerts/notification';
import { WarningLowBalanceMessage } from 'modules/shared-components/notification/walletLowBalance';
import { formatTokenAmount, formatUSDAmount } from 'utils/FormatNumber';
import { FullPageNotificationDex } from '../../fullPageNotification';
import Table, { Body } from 'modules/shared-components/data-display/table';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { tokenColumn } from './tokenColumns';
import { MapBalances } from './components';
import { useChainInfo } from 'api/hooks/useChainInfo';

interface Props {
  wallet: DWalletDetails;
  walletChain: Chains;
}

const Portfolio = ({ wallet, walletChain }: Props) => {
  const chainPrice = useChainInfo(wallet.chain)?.price ?? '0';

  const balances = wallet
    ? (MapBalances(wallet, chainPrice, walletChain) as TokenBalance[])
    : [];
  const isLargeScreen = useMediaQuery(screenGte.large);
  const column = tokenColumn(walletChain);

  return (
    <>
      {!!balances.length && (
        <div>
          {wallet.chainBalance &&
            Big(wallet.chainBalance).lt(minimumSafeAmount(wallet.chain)) && (
              <div className="xxs:my-2">
                <NotificationDex className="xxs:mx-0" type="warning">
                  <WarningLowBalanceMessage chain={wallet.chain} />
                </NotificationDex>
              </div>
            )}
          <div className="flex items-center space-x-3">
            <div className="xxs:text-3xl xxs:font-semibold pb-1 xxs:text-dex-white">
              {formatTokenAmount(Big(wallet.totalEthBalance ?? 0).toNumber())}{' '}
              {chainAsset(wallet.chain)}
            </div>
            <div className="xxs:text-xl xxs:text-dex-white-secondary">
              ${formatUSDAmount(Big(wallet.totalUsdBalance ?? 0).toNumber())}
            </div>
          </div>
        </div>
      )}
      {balances.length === 0 && (
        <div className="lg:h-[400px]">
          <FullPageNotificationDex
            title="Your wallet looks empty"
            subTitle="It looks like you don't have any tokens in your wallet."
          />
        </div>
      )}
      {!!balances.length && (
        <div className="max-h-[530px] overflow-y-scroll xxs:py-2">
          {isLargeScreen && (
            <Table>
              <Table.Header
                trClassName="dark:bg-dex-black-800 dark:border-dex-black-700 px-2 dark:text-dex-white-secondary"
                rowClassName=" xxs:text-dex-white-secondary"
                columns={column}
              />
              <Body
                columns={column}
                data={balances.map((balance, index) => ({
                  ...balance,
                  id: `${index}`,
                }))}
                className="xxs:dark:bg-dex-black-800 xxs:dark:border-dex-black-700 px-2"
              />
            </Table>
          )}
          {!isLargeScreen && (
            <Table className="">
              <Table.Header
                columns={column.filter((x) => x.showOnMobile)}
                className="bg-transparent xxs:p-2"
                rowClassName="px-2 font-normal"
              />
              <Body
                columns={column.filter((x) => x.showOnMobile)}
                data={balances.map((balance, index) => ({
                  ...balance,
                  id: `${index}`,
                }))}
                className="xxs:dark:bg-transparent"
                cellClassName="px-2"
              />
            </Table>
          )}
        </div>
      )}
    </>
  );
};

export default Portfolio;
