import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import BannerImgDesktop from '../../../assets/img/alpha-program/alpha-program-leaderboard-desktop.png';
import BannerImgMobile from '../../../assets/img/alpha-program/alpha-program-leaderboard-mobile.png';
import Button from 'modules/shared-components/button/button';
import { PrizeLevel } from 'api/types/httpsTypes/leaderboard';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import { formatWithPrecision } from 'utils/FormatNumber';

export const Banner = ({
  chain,
  prizes,
}: {
  chain: Chains;
  prizes: PrizeLevel[] | undefined;
}) => {
  const isDesktop = useMediaQuery(screenGte.small);
  return (
    <div
      className="relative xxs:py-6 lg:py-4 flex items-center justify-center bg-cover bg-center w-full"
      style={{
        backgroundImage: `url(${
          isDesktop ? BannerImgDesktop : BannerImgMobile
        })`,
      }}
    >
      <div className=" relative z-10 flex flex-col items-center text-center text-white">
        <h1 className="text-2xl lg:text-3xl font-bold">Alpha Program</h1>
        {!prizes ? (
          <p className="text-sm lg:text-lg">
            Share alpha with our MZR community and earn incredible prizes each
            week.
          </p>
        ) : (
          <p className="text-sm lg:text-lg">
            Share alpha with our MZR community and earn up to{' '}
            {formatWithPrecision(1, prizes[prizes.length - 1]?.prize ?? 0)}{' '}
            {chainAsset(chain)} each week.
          </p>
        )}
        <Button
          className="lg:hidden text-white hover:text-white"
          as="a"
          target="_blank"
          rel="noreferrer"
          href="https://docs.mizar.com/sniper-bot-manual/alpha-program"
          variant="magic"
          type="button"
        >
          CHECK PROGRAM
        </Button>
      </div>
    </div>
  );
};
