import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { SwapsDetails } from 'api/types/httpsTypes/contracts';
import { FC } from 'react';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import { useWalletDetailsSearchFilters } from '../usePageParams';

interface Props {
  columnsDef: ResponsiveTableColumn<SwapsDetails>[];
  data: SwapsDetails;
}

export const TableRow: FC<Props> = ({ data, columnsDef }) => {
  const { filtersState, setFilters } = useWalletDetailsSearchFilters();

  return (
    <tr
      onClick={() => {
        setFilters({ ...filtersState, ...{ search: data.tokenAddress } });
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
      className="bl-dex-black-700 hover:bg-dex-black-700 border-dex-black-700 border-bottom-new xxs:border-solid cursor-pointer"
    >
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return;
          return (
            <Table.Td key={column.key}>
              <Column data={data} />
            </Table.Td>
          );
        })}
      </ErrorBoundary>
    </tr>
  );
};
