import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const sizes = {
  default: 'xxs:px-4 xxs:py-2',
  small: 'xxs:px-4 xxs:py-1',
  large: 'inline-flex w-full font-medium justify-center xxs:px-6 xxs:py-3',
  medium: 'xxs:px-8 xxs:py-3',
};

const variants = {
  default: '',
  primary:
    'bg-mizar-blue-500 hover:bg-mizar-blue-400 xxs:text-dex-white hover:text-dex-white xs:shadow-sm',
  secondary: 'hover:bg-mizar-blue-50 bg-mizar-blue-100 text-mizar-blue-700',
  outline: classNames(
    'xxs:border-solid xxs:border dark:text-white hover:text-blue-600'
  ),
  error: 'bg-red-500 hover:bg-red-600 text-white xs:shadow-sm',
  errorDex: 'bg-red-600 xxs:text-red-500 hover:text-dex-white xs:shadow-sm',
  warning: 'bg-yellow-500 hover:bg-yellow-600 text-white xs:shadow-sm',
  success: 'bg-green-500 hover:bg-green-600 text-white xs:shadow-sm',
  magic: 'magic-button',
  neutral:
    'xxs:bg-white-400 hover:bg-white-500 dark:bg-black-600 dark:hover:bg-black-500 xxs:rounded',
  dexNeutral:
    'xxs:bg-dex-black-700 hover:xxs:bg-dex-black-600 xxs:rounded hover:text-dex-white xxs:text-dex-white-secondary',
};

interface SpinnerProps {
  className?: string;
}

export const Spinner: FC<SpinnerProps> = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={classNames('spinner-border spinner-border-sm', className)}
      role="status"
    />
  );
};

export type ButtonProps<T extends 'button' | 'a' | typeof Link> = {
  loading?: boolean;
  disabled?: boolean;
  size?: keyof typeof sizes;
  className?: string;
  variant?: keyof typeof variants;
  as?: T;
} & React.ComponentProps<T>;

export const Button = React.forwardRef(function InnerButton<
  T extends 'button' | 'a' | typeof Link
>(
  {
    children,
    loading,
    disabled,
    className,
    size = 'default',
    variant = 'default',
    as,
    ...props
  }: ButtonProps<T>,
  ref: any
) {
  const Component = as || ('button' as any);

  return (
    <Component
      ref={ref}
      {...props}
      className={twMerge(
        'relative flex justify-center inline-block xxs:rounded-md xxs:text-xs lg:text-sm border-none uppercase xxs:font-semibold',
        sizes[size],
        variants[variant],
        className,
        'cursor-pointer disabled:cursor-not-allowed',
        disabled
          ? 'disabled:bg-dex-black-600 disabled:text-dex-black-secondary '
          : ''
      )}
      disabled={loading || disabled}
    >
      <span className="align-middle flex justify-center items-center ">
        {loading ? (
          <>
            <div className="invisible flex flex-nowrap">{children}</div>
            <div className="mx-auto absolute w-full align-center justify-center flex">
              <Spinner />
            </div>
          </>
        ) : (
          children
        )}
      </span>
    </Component>
  );
});

export default Button;
