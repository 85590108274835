import { Title } from '../../components/cards';
import moment from 'moment';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { DWalletDetails } from 'api/types/httpsTypes/d-wallets';
import { Action, DWalletActions, MobileDWalletSelector } from './components';
import { useState } from 'react';

interface Props {
  wallet: DWalletDetails;
}

const WalletHeader = ({ wallet }: Props) => {
  const [, setActiveAction] = useState<Action>();

  return (
    <>
      <Title className="dark:text-black-50  flex xxs:flex-col md:flex-row justify-between items-start xxs:gap-2 xxs:mb-0">
        <div className="w-full">
          <div className="w-full justify-between items-center xxs:gap-2 flex">
            <h5 className="xxs:my-0 xxs:text-xl md:text-3xl xxs:font-semibold truncate">
              {wallet.label}
            </h5>
            <div className="xxs:flex xxs:gap-2">
              <div className="lg:hidden">
                <MobileDWalletSelector
                  selectedChain={wallet.chain}
                  onCreated={() => setActiveAction('deposit')}
                />
              </div>

              <DWalletActions
                chain={wallet.chain}
                className="xxs:p-2"
                wallet={wallet}
              />
            </div>
          </div>
          <div className="flex xxs:flex-col md:flex-row xxs:gap-2 xxs:text-sm dark:text-black-100 xxs:font-normal">
            <div className="items-center xxs:gap-1 xxs:hidden sm:flex xxs:text-dex-white-secondary">
              Created:{' '}
              {moment(wallet.createdDatetime).format('DD/MM/YYYY HH:mm')}
            </div>
            <span className="xxs:hidden md:block xxs:text-dex-white-secondary">
              |
            </span>
            <div className="flex items-center xxs:gap-1 xxs:text-dex-white-secondary">
              Address:{' '}
              <EthAddress
                chain={wallet.chain}
                kind="account"
                address={wallet.address}
              />
            </div>
          </div>
        </div>
      </Title>
    </>
  );
};

export default WalletHeader;
