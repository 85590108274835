import { usePoolSearchFilters } from '../../usePageParams';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { GetNewPoolsParams } from 'api/types/httpsTypes/contracts';
import { useQuery } from '@tanstack/react-query';
import { PoolFiltersBar } from './PoolFilters';
import { duration } from 'moment';
import { ErrorPageDex, EmptyPageDex, PoolRow } from '../shared/Common';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { desktopColumns, mobileColumns } from './columns';
import { useRef } from 'react';
import { Pagination } from 'modules/shared-components/pagination';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useSelectedChain } from '../../../components/chains/getChains';

export function thDynamicClass(s: string) {
  return s === 'PAIR' ? 'col-span-2' : '';
}

export function SnipingOpportunitiesCard() {
  const { goToPage, filtersState, setSort } = usePoolSearchFilters();
  const selectedChain = useSelectedChain();
  const params: GetNewPoolsParams = {
    status: filtersState.status,
    limit: filtersState.limit,
    offset: filtersState.offset,
    sort: filtersState.sort,
    minLiquidity: filtersState.minLiquidity,
    maxLiquidity: filtersState.maxLiquidity,
    minMarketcap: filtersState.minMarketcap,
    maxMarketcap: filtersState.maxMarketcap,
    chain: selectedChain,
  };

  const {
    data: page,
    error: error,
    isLoading,
  } = useQuery({
    queryKey: contractServiceKeys.getNewPools(params),
    queryFn: () => ContractService.getNewPools(params),
    keepPreviousData: true,
    refetchInterval: duration(12, 'seconds').asMilliseconds(),
  });
  const isLargeScreen = useMediaQuery(screenGte.large);
  const columns = isLargeScreen ? desktopColumns : mobileColumns;
  const listRef = useRef<HTMLTableElement>(null);

  function scrollAndNavigate(pageNumber: number) {
    goToPage(pageNumber);
    if (listRef.current) {
      listRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  const isSortAscending = !filtersState.sort?.includes('-');

  return (
    <div className="space-y-2">
      <div className="xxs:px-2 lg:px-0">
        <PoolFiltersBar />
      </div>

      {error ? (
        <ErrorPageDex />
      ) : (
        <>
          <div className="overflow-x-auto">
            <div className="w-full  xxs:my-3 lg:min-w-[1200px] ">
              {!isLargeScreen && <div ref={listRef} />}
              <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
                <Header
                  trClassName="lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
                  columns={columns}
                  thDynamicClass={thDynamicClass}
                  rowClassName="xxs:p-1"
                  isSortAscending={isSortAscending}
                  sortBy={filtersState.sort?.replace('-', '')}
                  className="xxs:dark:bg-dex-black-800 xxs:text-xs lg:grid"
                  onClickColumn={(_, sortableKey) => {
                    if (sortableKey && !isLoading) setSort(sortableKey);
                  }}
                />
                <tbody className="lg:block lg:h-terminal overflow-y-auto">
                  {isLoading ? (
                    [...Array(10).keys()]
                      .map((i) => i + 1)
                      .map((index) => (
                        <Table.LoadingRow
                          thDynamicClass={thDynamicClass}
                          key={index}
                          elementClassName="xxs:dark:bg-dex-black-700"
                          className="lg:grid lg:grid-cols-9 lg:dark:bg-dex-black-800"
                          columns={columns}
                        />
                      ))
                  ) : page?.data?.length === 0 ? (
                    <EmptyPageDex
                      height={isLargeScreen ? '100px' : '50vh'}
                      title="No Pools Found"
                    />
                  ) : (
                    page?.data?.map((item) => (
                      <PoolRow
                        key={item.poolAddress}
                        data={item}
                        columnsDef={columns}
                      />
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>

          {page && page?.data?.length > 0 && (
            <div className="xxs:px-2 lg:px-0">
              <Pagination
                goToPage={scrollAndNavigate}
                activePage={page.pagination}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
