import ErrorBoundary from 'components/error-boundaries';
import { GetWalletDetailsParams } from 'api/types/httpsTypes/contracts';
import Tooltip from 'modules/shared-components/tooltip';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { EmptyPageDex, ErrorPageDex } from '../../shared/Common';
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { duration } from 'moment';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useWalletDetailsSearchFilters } from './usePageParams';
import { desktopColumns, mobileColumns } from './columns';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useEffect, useState } from 'react';
import { AggregatedSwapsFiltersBar } from './AggregatedSwapsFilters';
import { TableRow } from './TableRow';
import config from '../../../../../../config';
import { formatApiError } from 'helpers/api/apiErrors';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { ArrowPathIcon, ClockIcon } from '@heroicons/react/24/outline';
import { formatDuration } from 'modules/utils/formatDuration';
import Button from 'modules/shared-components/button/button';
import { useUserState } from 'modules/user/UserContext';
import { useInView } from 'react-intersection-observer';

interface Props {
  chain: Chains;
  address: string;
}

export function thDynamicClass(s: string) {
  return s !== 'SWAPS' ? 'col-span-2' : '';
}

export const SwapsTable = ({ chain, address }: Props) => {
  const { filtersState, setSort } = useWalletDetailsSearchFilters();

  const [refetchInterval, setRefetchInterval] = useState(5);
  const params: Partial<GetWalletDetailsParams> & {
    sort: string;
    chain: Chains;
  } = {
    sort: filtersState.sort,
    status: filtersState.status,
    search: filtersState.search,
    address,
    chain,
  };

  const queryClient = useQueryClient();
  const { addNewMessage } = useSnackBar();

  const { mutate, isLoading: loadingUpdate } = useMutation({
    mutationFn: () => ContractService.updateWalletDetails({ chain, address }),
    onSuccess: () => {
      addNewMessage({
        type: 'success',
        title: 'Swaps Update Request Sent',
        message: 'The update might require several seconds. Reload the page.',
      });
      void queryClient.invalidateQueries(contractServiceKeys.all());
    },
    onError: (err: string) => {
      if (config.isTelegramBrowser) {
        addNewMessage({
          message: formatApiError(err, 'An error occurred. Try again.'),
          title: 'Error',
          type: 'error',
        });
      } else {
        addNewMessage({
          type: 'error',
          title: 'Error',
          message: 'An error occurred. Try again.',
        });
      }
    },
  });

  const {
    data,
    error,
    isInitialLoading: isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 10, offset: 0 } }) => {
      return ContractService.getWalletDetails({
        ...params,
        limit: pageParam.limit,
        offset: pageParam.offset,
      });
    },
    queryKey: contractServiceKeys.getWalletDetails(params),
    getNextPageParam: (lastPage) => ({
      offset: lastPage.pagination.offset + lastPage.pagination.limit ?? 0,
      limit: 10,
    }),
    keepPreviousData: true,
    enabled: !!(address && chain),
    refetchInterval: duration(refetchInterval, 'minutes').asMilliseconds(),
  });

  const items = data?.pages?.flatMap((page) => page.data) || [];
  const pagination = data?.pages?.[0]?.pagination ?? {
    limit: 0,
    offset: 0,
    total: 0,
  };
  const status = data?.pages?.[0]?.status ?? {
    status: 'in_progress',
    lastUpdateTime: '01/01/1970',
  };

  const page = { pagination, data: items, status };

  useEffect(() => {
    if (page && page?.status?.status === 'in_progress') {
      setRefetchInterval(12 / 60);
    } else {
      setRefetchInterval(5);
    }
  }, [page]);

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        void fetchNextPage();
      }
    },
  });

  const isLargeScreen = useMediaQuery(screenGte.xLarge);
  const { user } = useUserState();

  const isSortAscending = !filtersState.sort?.includes('-');

  const columns = isLargeScreen ? desktopColumns : mobileColumns;

  return (
    <>
      <ErrorBoundary>
        <div className="bg-dex-black-800 xxs:p-4 rounded-md shadow-md space-y-1 h-full ">
          <div className="flex justify-between items-center">
            <Tooltip text="The detail of all the swaps executed during the last 30 days, aggregated per token.">
              <div className="xxs:text-dex-white-secondary hover:text-dex-white font-bold text-base">
                30D Aggregated Swaps
              </div>
            </Tooltip>

            {page?.status && user && (
              <div className="flex space-x-1 items-center xxs:text-dex-white-secondary hover:text-dex-white">
                <ClockIcon className="h-4 w-4 " />
                <div className="hidden lg:block">Last update:</div>
                {page.status?.status === 'in_progress' ? (
                  <div>in progress...</div>
                ) : (
                  <div className="flex space-x-1 items-center">
                    <div>
                      {formatDuration(page?.status?.lastUpdateTime)} ago
                    </div>
                    <Button
                      onClick={() => void mutate()}
                      loading={loadingUpdate}
                      variant="dexNeutral"
                      className="xxs:p-1"
                    >
                      <ArrowPathIcon className="h-3 w-3" />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>

          <AggregatedSwapsFiltersBar />

          {error ? (
            <ErrorPageDex />
          ) : (
            <div className="overflow-x-auto">
              <div className="lg:h-terminal w-full space-y-1 xxs:my-3 ">
                <Table className="xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6">
                  <Header
                    columns={columns}
                    rowClassName="xxs:p-1"
                    trClassName="xl:grid xl:grid-cols-7 xxs:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
                    isSortAscending={isSortAscending}
                    sortBy={filtersState.sort?.replace('-', '')}
                    className="xxs:dark:bg-dex-black-800 xxs:text-xs xl:grid"
                    onClickColumn={(_, sortableKey) => {
                      if (sortableKey && !isLoading) setSort(sortableKey);
                    }}
                  />
                  <tbody>
                    {isLoading
                      ? [...Array(10).keys()]
                          .map((i) => i + 1)
                          .map((index) => (
                            <Table.LoadingRow
                              elementClassName="xxs:dark:bg-dex-black-700"
                              className="xl:grid xl:grid-cols-7 xxs:dark:bg-dex-black-800"
                              key={index}
                              columns={columns}
                            />
                          ))
                      : page?.data?.map((item) => (
                          <TableRow
                            key={item.tokenAddress + '_' + item.chain}
                            data={item}
                            columnsDef={columns}
                          />
                        ))}
                    {pagination && pagination.total > items?.length && (
                      <Table.LoadingRow
                        ref={ref}
                        elementClassName="xxs:dark:bg-dex-black-700"
                        className={`lg:grid lg:grid-cols-${columns.length} lg:dark:bg-dex-black-800`}
                        columns={columns}
                      />
                    )}
                  </tbody>
                </Table>
              </div>
              {page?.data?.length === 0 && (
                <EmptyPageDex title="No Swaps Found" />
              )}
            </div>
          )}
        </div>
      </ErrorBoundary>
    </>
  );
};
