import { useState, useEffect } from 'react';
import { useWebsocket } from 'api/hooks/useWebsocket';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useQuery } from '@tanstack/react-query';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/httpServices/BlockchainInfoService';

export interface ChainInfoMessage {
  p: string; // Chain Asset Price
  c: Chains; // Chain
  b: number; // Block
  g: number; // Gas
}

export interface ChainInfoProps {
  price: string | null | undefined;
  blockNumber: number | null | undefined;
  gasPrice: number | null | undefined;
}

export const useChainInfo = (chain: Chains): ChainInfoProps | null => {
  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(chain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(chain),
  });

  const chainInfo = useChainInfoWebsocket(chain);

  return {
    price: chainInfo ? chainInfo.p : gasInfo?.price ?? null,
    gasPrice: chainInfo ? chainInfo.g : gasInfo ? gasInfo.gasPrice : null,
    blockNumber: chainInfo ? chainInfo.b : gasInfo?.blockNumber ?? null,
  };
};

export const useChainInfoWebsocket = (
  chain: Chains
): ChainInfoMessage | null => {
  const [chainInfo, setChainInfo] = useState<ChainInfoMessage | null>(null);

  const message = useWebsocket({
    channel: 'chain_info',
    data: { chain },
  });

  useEffect(() => {
    setChainInfo(null); // ✅ Clear old WebSocket data on chain switch
  }, [chain]);

  useEffect(() => {
    if (!message || !message.data) return;

    const parsedMessage: ChainInfoMessage = message.data;

    // ✅ Only update if it's the correct chain
    if (parsedMessage.c === chain) {
      setChainInfo(parsedMessage); // Update state
    }
  }, [message, chain]);

  return chainInfo;
};
