import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BotType, DexSniperBot } from 'api/types/httpsTypes/dex-bot';
import {
  dexBotKeys,
  DexBotService,
} from 'api/services/httpServices/DexBotService';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import Button from 'modules/shared-components/button/button';
import { useHistory } from 'react-router-dom';
import { NotificationDex } from '../alerts/notification';
import CustomModal from '../modal/CustomModal';
import { Tabs } from '../layout/SIDEBAR_CONST';

type Props = {
  handleClose: () => void;
  bot: DexSniperBot;
  botType: BotType;
};

export function DeleteDexBotModal({ handleClose, bot, botType }: Props) {
  const history = useHistory();
  const { addNewMessage } = useSnackBar();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: () => DexBotService.deleteBot(bot.id, botType),
    onSuccess: async () => {
      history.push(
        `/dex/bots/${
          botType === BotType.Volatility
            ? Tabs.VolatilityBot
            : botType === BotType.Wallet
            ? Tabs.WalletCopyTrading
            : Tabs.TgCopyTrading
        }`
      );
      await queryClient.invalidateQueries(dexBotKeys.all());
      addNewMessage({
        message: 'Bot deleted successfully',
        title: 'Success',
        type: 'success',
      });
    },
  });

  return (
    <CustomModal title="Delete Bot" handleClose={handleClose} showModal>
      <CustomModal.Body className="xxs:p-4">
        <CustomModal.Title className="hidden lg:flex">
          Delete Bot
        </CustomModal.Title>

        <CustomModal.Content>
          <div className="flex flex-col xxs:gap-2">
            <div>
              <p>By deleting this bot, you will:</p>
              <ul>
                <li>Stop opening new trades</li>
                <li>Delete all your settings</li>
              </ul>
              <p>This operation cannot be undone.</p>
            </div>
          </div>
          {bot.numActivePositions > 0 && (
            <NotificationDex type="error">
              Before deleting this bot, close all its associated trades.
            </NotificationDex>
          )}
          {error && (
            <NotificationDex
              type="error"
              errorMessage={error}
              className="xxs:my-2"
            >
              An error occurred.
            </NotificationDex>
          )}{' '}
        </CustomModal.Content>
      </CustomModal.Body>
      <CustomModal.Footer>
        <PrimaryButton
          className="xxs:text-base lg:text-xs"
          disabled={bot.numActivePositions > 0}
          loading={isLoading}
          onClick={() => {
            mutate();
          }}
        >
          Delete
        </PrimaryButton>
        <Button
          className="xxs:text-base lg:text-xs"
          type="button"
          variant="dexNeutral"
          onClick={() => handleClose()}
        >
          CANCEL
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
}
