import * as yup from 'yup';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ManageTokensBody } from './ManageTokensModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useDebouncedState } from 'utils/useDebounce';
import { useEffect } from 'react';
import { GetContractInfo } from '../../volatility/sections/VolatilitySettings';
import CustomModal from '../../../components/modal/CustomModal';
import TextInput from '../../../components/inputs/text-input/text-input';
import { NotificationDex } from '../../../components/alerts/notification';
import { useSelectedChain } from '../../../components/chains/getChains';
import { isValidAddress } from 'helpers/api/web3Utils';
import { GetWhitelistedTokensParams } from 'api/types/httpsTypes/dex-bot';
import {
  dexBotKeys,
  DexBotService,
} from 'api/services/httpServices/DexBotService';
import { Chains } from 'api/types/httpsTypes/d-wallets';

type Props = {
  handleClose: (arg0?: boolean) => void;
  tA?: string;
};

const schema = yup.object({
  chain: yup.string().oneOf(Object.values(Chains)).required(),
  address: yup
    .string()
    .test('is-valid-address', 'Invalid address', function (value) {
      const chain = this.parent?.chain;
      return !!value && isValidAddress(value, chain);
    })
    .required(),
});

type NewTokensFormValues = yup.InferType<typeof schema>;

const AddNewTokenModal = ({ handleClose, tA }: Props) => {
  const selectedChain = useSelectedChain();
  const form = useForm<NewTokensFormValues>({
    resolver: yupResolver(schema),
    defaultValues: tA
      ? { address: tA, chain: selectedChain }
      : { chain: selectedChain },
  });

  const {
    watch,
    setValue,
    control,
    formState: { isValid },
    trigger,
  } = form;

  const address = watch('address');
  const chain = selectedChain;

  const params: GetWhitelistedTokensParams = {
    limit: 1,
    offset: 0,
    chain,
    search: address ?? '',
    withAnalytics: false,
  };

  const {
    data: page,
    error,
    isLoading: isBotsLoading,
  } = useQuery({
    queryKey: dexBotKeys.getWhitelistedTokens(params),
    queryFn: () => DexBotService.getWhitelistedTokens(params),
    keepPreviousData: true,
    enabled: isValid,
  });

  const { data: token, error: tokenError } = GetContractInfo(
    chain,
    address,
    !isValid
  );

  const bots = page?.data ? page.data?.[0]?.bots ?? [] : [];
  const symbol = token?.symbol || '';

  const [tokenAddress, debouncedTokenAddress, setTokenAddress] =
    useDebouncedState('', 250);

  useEffect(() => {
    if (debouncedTokenAddress) {
      setValue('address', debouncedTokenAddress, { shouldValidate: true });
      trigger()
        .then(() => {})
        .catch((er) => {
          console.error('Error', er);
        });
    }
  }, [debouncedTokenAddress]);

  const handleAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setTokenAddress(inputValue);
  };
  const queryClient = useQueryClient();
  return (
    <CustomModal
      showModal
      title={`Add ${symbol ?? 'a new token'} to a volatility bot`}
      handleClose={() => {
        void queryClient.invalidateQueries(dexBotKeys.all());
        handleClose();
      }}
    >
      <CustomModal.Body>
        <CustomModal.Title className="hidden lg:flex">
          Add {symbol ?? <span>a new token</span>} to a volatility bot
        </CustomModal.Title>
        <CustomModal.Content>
          <form autoComplete="off">
            <FormProvider {...form}>
              <div className="flex flex-col xxs:gap-2 ">
                {!tA && (
                  <div className="xxs:space-y-4">
                    <div>
                      Add {symbol ?? <span>a new token</span>} to existing bots
                      and start scalping it.
                    </div>
                  </div>
                )}

                {!tA && (
                  <div className="xxs:text-dex-white-secondary">
                    Input token address:
                  </div>
                )}

                {!tA && (
                  <Controller
                    name={`address`}
                    control={control}
                    defaultValue={'' as any}
                    render={({ field, fieldState: { error: e } }) => (
                      <div className="grid grid-cols-6 space-x-1 w-full">
                        <div className="col-span-5">
                          <TextInput
                            name="address"
                            value={tokenAddress}
                            onChange={handleAddressInputChange}
                            inputSize="small"
                            error={error ? 'Token not found' : e?.message}
                            hideErrorMessage={true}
                          />
                        </div>

                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`/dex/snipe/new/${chain}/${
                            !field.value
                              ? '??'
                              : tokenError
                              ? '??'
                              : !symbol
                              ? '??'
                              : field.value
                          }`}
                          className="text-dex-white-secondary hover:text-dex-white bg-dex-black-700 hover:bg-dex-black-600 rounded xxs:px-2 flex items-center justify-center "
                        >
                          {!field.value
                            ? '--'
                            : tokenError
                            ? '?'
                            : !symbol
                            ? '...'
                            : (
                                <div className="text-dex-white-secondary hover:text-dex-white truncate">
                                  {symbol}
                                </div>
                              ) ?? '--'}
                        </Link>
                      </div>
                    )}
                  />
                )}

                {error || tokenError ? (
                  <NotificationDex
                    type="error"
                    errorMessage={error ?? tokenError}
                  >
                    An error occurred. Try again
                  </NotificationDex>
                ) : (
                  bots &&
                  symbol &&
                  chain &&
                  address && (
                    <ManageTokensBody
                      bots={bots}
                      symbol={symbol}
                      chain={chain}
                      isBotsLoading={isBotsLoading}
                      address={address}
                    />
                  )
                )}
              </div>
            </FormProvider>
          </form>
        </CustomModal.Content>
      </CustomModal.Body>
    </CustomModal>
  );
};

export default AddNewTokenModal;
