import { Link, useHistory } from 'react-router-dom';
import { AuthLayout } from 'modules/layouts/AuthLayout';
import { useQueryParams } from 'modules/routing/useQueryParams';
import * as yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import UserService, {
  userServiceKeys,
} from 'api/services/httpServices/UserService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiErrorNotification } from 'modules/shared-components/notification/ApiErrorNotification';
import Input from 'modules/shared-components/input/Input';
import RecaptchaField from 'modules/authentication/components/RecaptchaField';
import { Button } from 'modules/shared-components/button/button';

const schema = yup.object({
  password: yup
    .string()
    .required('Required')
    .min(12, 'Minimum length of password is 12.')
    .max(128, 'Maximum length of password is 128'),
  repeat_password: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match'),
  captcha: yup.string().required('Incorrect captcha'),
});

type FormValues = yup.InferType<typeof schema>;

type Props = {
  initialState?: Partial<FormValues>;
};

const CreateNewAccount = ({ initialState }: Props) => {
  const history = useHistory();
  const [{ token, 'referral-code': referralCode }] = useQueryParams({
    token: yup.string(),
    'referral-code': yup.string(),
  });

  const { error: validationError } = useQuery({
    queryFn: () => UserService.validateJWTToken(token!),
    queryKey: userServiceKeys.validateJWTToken(token!),
    enabled: !!token,
  });

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const { mutate, error, isLoading } = useMutation({
    mutationFn: UserService.createPasswordSignup,
    onSuccess: () => {
      history.push('/login', {
        message: 'Sign up successfull. Use your email and password to login',
      });
    },
  });

  const onSubmit = handleSubmit((values: FormValues) => {
    mutate({
      recaptcha_token: values.captcha,
      confirm_password: values.repeat_password,
      password: values.password,
      token: token!,
      referral_code: referralCode,
    });
  });

  if (validationError || !token) {
    return (
      <AuthLayout>
        <div className="text-center xxs:py-5">
          <h3>No token</h3>
          <h5 className="xxs:py-2">
            Your token is not valid. Try to sign-up again.
          </h5>
          <div className="xxs:py-3">
            <Button as={Link} to="/sign-up" variant="primary" size="large">
              SIGNUP
            </Button>
          </div>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <form
        onSubmit={(e) => {
          void onSubmit(e);
        }}
      >
        <div className="xxs:mx-4 lg:mx-24 sm:w-[400px] sm:mx-auto">
          <h1>Create a new account</h1>
          <div className="xxs:px-4 xxs:py-4 xxs:bg-black-700 shadow-lg rounded-lg">
            <p className="xxs:text-sm">
              Set your password below to create a new account and access Mizar.
            </p>

            <div className="xxs:flex flex-col xxs:mt-2">
              <Input
                {...register('password')}
                type="password"
                label="New Password"
                placeholder="**********"
                errorText={errors.password?.message}
              />
            </div>
            <div className="xxs:flex flex-col xxs:mt-2">
              <Input
                {...register('repeat_password')}
                type="password"
                label="Repeat Password"
                placeholder="**********"
                errorText={errors.repeat_password?.message}
              />
            </div>
            <RecaptchaField control={control} />
            {error && (
              <ApiErrorNotification error={error} className="xxs:my-2" />
            )}

            <Button
              loading={isLoading}
              type="submit"
              variant="primary"
              className="w-full"
              size="large"
            >
              Create new account
            </Button>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default CreateNewAccount;
