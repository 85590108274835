import {
  LimitOrderStatus,
  TakeProfitStatus,
} from 'api/types/httpsTypes/dex-trade';
import Big from 'big.js';
import * as yup from 'yup';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { gasMinMaxTest } from '../components/inputs/inputTests';

export enum EntryKind {
  Snipe = 'snipe',
  Buy = 'buy',
}

export enum OrderKind {
  Market = 'market',
  Limit = 'limit',
  Snipe = 'snipe',
  SnipeWithoutMemPool = 'snipeWithoutMemPool',
}

export const takeProfitSchema = yup
  .array(
    yup.object({
      id: yup.string().optional(),
      status: yup.string<TakeProfitStatus>().optional(),
      threshold: yup
        .number()
        .typeError('Required')
        .min(0, 'Must be above 0%')
        .required('Required'),
      weight: yup
        .number()
        .typeError('Required')
        .min(1, 'Must be above 1%')
        .max(100)
        .required('Required'),
      isFailed: yup.boolean().optional(),
    })
  )
  .test(
    'weights-lte-100',
    'Sum of all weights need to be below 100%',
    areWeightsLte100
  )
  .required();

export const snipeAmountSchema = yup
  .object({
    amount: yup
      .number()
      .min(0.00000001, 'Must be greater than 0.')
      .typeError('Required')
      .required('Required'),
    slippage: yup
      .mixed<number>()
      .when('blockOneRetry', ([blockOneRetry], s) => {
        return blockOneRetry
          ? yup
              .number()
              .typeError('Required')
              .min(0.01, 'Min is 0.01')
              .max(100, 'Max is 100')
              .required('Slippage is required')
          : s.nullable().optional();
      }),
    backupBribe: yup.mixed().when('blockOneRetry', ([blockOneRetry], s) => {
      return blockOneRetry
        ? yup
            .number()
            .typeError('Required')
            .min(0.01, 'Must be above 0.01')
            .required('Required')
        : s.nullable().optional();
    }),
    blockOneRetry: yup.boolean().default(false).required(),
    bribe: yup
      .number()
      .typeError('Required')
      .min(0.005, 'Must be above 0.005')
      .max(1, 'Max is 1')
      .required('Bribing Fee is required'),
  })
  .required();

export type SnipeAmountSchema = yup.InferType<typeof snipeAmountSchema>;

export const marketBuyAmountSchema = yup
  .object({
    amount: yup
      .number()
      .min(0.00000001, 'Must be greater than 0.')
      .typeError('Required')
      .required('Required'),
    slippage: yup
      .number()
      .min(0.01, 'Min is 0.01')
      .max(100, 'Max is 100')
      .required('Slippage is required'),
    antiMev: yup.boolean().default(false),
    isMaxBuyGasPriorityEdited: yup.boolean(),

    maxBuyGasPriority: yup
      .number()
      .test('min-max-value', function (value) {
        const chain = this.from?.[2]?.value?.chain as Chains;
        if (!value || !chain) {
          return true;
        }
        return gasMinMaxTest.call(this, chain, value);
      })
      .typeError('Required')
      .required('Required'),
    bribe: yup
      .number()
      .min(0, 'Must be greater than 0.')
      .max(1, 'Max is 1.')
      .typeError('Required')
      .required('Required'),
  })
  .required();

export type MarketBuyAmountSchema = yup.InferType<typeof marketBuyAmountSchema>;

export const limitOrdersSchema = yup
  .array(
    yup.object({
      id: yup.string().optional(),
      status: yup.string<LimitOrderStatus>().optional(),
      antiMev: yup.boolean(),
      priceDeviationPercentage: yup
        .number()
        .typeError('Required')
        .min(0, 'Must be below 0%')
        .max(100, 'Cannot be below -100%'),
      amount: yup
        .number()
        .typeError('Required')
        .min(0.00000001, 'Must be above 0 ')
        .max(50, 'Cannot be above 50 ')
        .required('Required'),
      isFailed: yup.boolean().optional(),
      price: yup
        .number()
        .typeError('Required')
        .min(0, 'Cannot be $0')
        .test(
          'isBelowCurrentPrice',
          'Must be below current price',
          (value, context) => {
            const status = context.parent.status as
              | LimitOrderStatus
              | undefined;
            if (status && status !== 'ACTIVE') {
              return true;
            }
            const root = context.from![context.from!.length - 1];
            const { currentTokenPrice } = root.value;
            return (
              !!currentTokenPrice && !!value && Big(value).lt(currentTokenPrice)
            );
          }
        )
        .required('Required'),
    })
  )
  .required();

export const limitBuyAmountSchema = yup
  .object({
    orders: limitOrdersSchema,
    slippage: yup
      .number()
      .min(0.01, 'Min is 0.01')
      .max(100, 'Max is 100')
      .required('Required'),
    antiMev: yup.boolean(),
    maxBuyGasPriority: yup
      .number()
      .test('min-max-value', function (value) {
        const chain = this.from?.[2]?.value?.chain as Chains;
        if (!value || !chain) {
          return true;
        }
        return gasMinMaxTest.call(this, chain, value);
      })
      .required('Required'),
    bribe: yup
      .number()
      .min(0, 'Must be greater than 0.')
      .max(1, 'Max is 1.')
      .typeError('Required')
      .required('Required'),
    expirationInHours: yup
      .number()
      .min(0, 'Min 0')
      .max(720, 'Max 720')
      .typeError('Required')
      .required('Required'),
  })
  .required();
export type LimitBuyAmountSchema = yup.InferType<typeof limitBuyAmountSchema>;

function areWeightsLte100(
  levels: {
    status?: TakeProfitStatus | undefined;
    weight: number;
    threshold: number;
    isFailed?: boolean;
  }[] = []
) {
  return (
    levels
      .filter((x) => !x.isFailed && (!x.status || x.status === 'ACTIVE'))
      .reduce(
        (acc, { weight }) => (weight ? Big(acc).add(weight).toNumber() : acc),
        0
      ) <= 100
  );
}

export const takeProfitConfigSchema = yup.object({
  slippage: yup
    .number()
    .min(0.01, 'Min is 0.01')
    .max(100, 'Max is 100')
    .typeError('Required')
    .required('Required'),
  isMaxSellGasPriorityEdited: yup.boolean(),
  maxSellGasPriority: yup
    .number()
    .test('min-max-value', function (value) {
      const chain = this.from?.[1]?.value?.chain as Chains;
      if (!value || !chain) {
        return true;
      }
      return gasMinMaxTest.call(this, chain, value);
    })
    .typeError('Required')
    .required('Required'),
  bribe: yup
    .number()
    .min(0, 'Must be greater than 0.')
    .max(1, 'Max is 1.')
    .typeError('Required')
    .required('Required'),
  antiMev: yup.boolean().required(),
});

export const stopLossSchema = yup
  .object({
    id: yup.string().optional(),
    threshold: yup
      .number()
      .typeError('Required')
      .min(1)
      .max(100)
      .required('Required'),
    isTrailingEnabled: yup.boolean().required(),
    deviation: yup
      .number()
      .typeError('Required')
      .min(0)
      .max(100)
      .required('Required')
      .nullable(),
    isFailed: yup.boolean().optional(),
  })
  .nullable();

export const safetyMeasuresSchema = yup.object({
  marketCapRange: yup.boolean(),
  liquidityRange: yup.boolean(),
  gasLimitEnabled: yup.boolean(),

  gasLimit: yup.mixed().when('gasLimitEnabled', ([gasLimitEnabled], s) => {
    return gasLimitEnabled
      ? yup
          .number()
          .integer('Must be an integer')
          .typeError('Must be greater than 0 wei')
          .min(1, 'Must be greater than 1 wei')
          .max(5000000, 'Must be lower than 5,000,000 wei')
          .required('Required')
      : s.nullable().optional();
  }),

  autoRetryEnabled: yup.boolean().required(),

  autoRetry: yup.object({
    buy: yup.boolean().required(),
    takeProfit: yup.boolean().required(),
    stopLoss: yup.boolean().required(),
  }),

  liquidity: yup.object({
    min: yup
      .number()
      .typeError('Required')
      .min(0, 'Minimum is $0')
      .required('Required'),
    max: yup
      .number()
      .typeError('Required')
      .min(0, 'Minimum is $0')
      .required('Required'),
  }),
  taxProtectionEnabled: yup.boolean(),
  taxProtection: yup.object({
    buy: yup
      .number()
      .typeError('Must be between 0% and 100%')
      .required('Required')
      .min(0, 'Must be greater or equal to 0')
      .max(100, 'Must be less or equal to 100%'),
    sell: yup
      .number()
      .typeError('Must be between 0% and 100%')
      .required('Required')
      .min(0, 'Must be greater or equal to 0')
      .max(100, 'Must be less or equal to 100%'),
  }),
  marketCap: yup.object({
    min: yup
      .number()
      .typeError('Required')
      .min(0, 'Minimum is $0')
      .required('Required'),
    max: yup
      .number()
      .typeError('Required')
      .min(0, 'Minimum is $0')
      .required('Required'),
  }),
  honeypotProtection: yup.boolean().default(true),
});

export const entriesSchema = yup
  .object({
    kind: yup
      .string()
      .oneOf([
        OrderKind.Market,
        OrderKind.Limit,
        OrderKind.Snipe,
        OrderKind.SnipeWithoutMemPool,
      ])
      .required('Required'),
    entries: yup
      .mixed<LimitBuyAmountSchema | MarketBuyAmountSchema | SnipeAmountSchema>()
      .when('kind', ([kind]) => {
        return kind === OrderKind.Market
          ? marketBuyAmountSchema
          : kind === OrderKind.Snipe
          ? snipeAmountSchema
          : kind === OrderKind.SnipeWithoutMemPool
          ? marketBuyAmountSchema
          : limitBuyAmountSchema;
      })
      .required(),
  })
  .required();

export const schema = yup.object({
  address: yup.string().required('Required'),
  chain: yup
    .string()
    .oneOf([...Object.values(Chains)])
    .required('Required'),
  entryKind: yup
    .string()
    .oneOf([EntryKind.Buy, EntryKind.Snipe])
    .required('Required'),
  currentTokenPrice: yup.number(),
  buyOrder: entriesSchema,
  wallets: yup
    .array()
    .of(yup.string().required())
    .required()
    .min(1, 'The array cannot be empty'),
  safetyMeasures: safetyMeasuresSchema,
  takeProfit: takeProfitSchema,
  takeProfitConfig: takeProfitConfigSchema,
  stopLoss: stopLossSchema,
});

export type FormValues = yup.InferType<typeof schema>;

export function isLimitBuyAmount(
  kind:
    | OrderKind.Market
    | OrderKind.Limit
    | OrderKind.Snipe
    | OrderKind.SnipeWithoutMemPool
    | undefined,
  buyOrderForm:
    | LimitBuyAmountSchema
    | MarketBuyAmountSchema
    | SnipeAmountSchema
    | undefined
): buyOrderForm is LimitBuyAmountSchema {
  return kind === OrderKind.Limit;
}

export function isMarketBuyAmount(
  kind:
    | OrderKind.Market
    | OrderKind.Limit
    | OrderKind.Snipe
    | OrderKind.SnipeWithoutMemPool
    | undefined,
  buyOrderForm:
    | LimitBuyAmountSchema
    | MarketBuyAmountSchema
    | SnipeAmountSchema
    | undefined
): buyOrderForm is MarketBuyAmountSchema {
  return kind === OrderKind.Market || kind === OrderKind.SnipeWithoutMemPool;
}

export function isSnipeBuyAmount(
  kind:
    | OrderKind.Market
    | OrderKind.Limit
    | OrderKind.Snipe
    | OrderKind.SnipeWithoutMemPool
    | undefined,
  buyOrderForm:
    | LimitBuyAmountSchema
    | MarketBuyAmountSchema
    | SnipeAmountSchema
    | undefined
): buyOrderForm is SnipeAmountSchema {
  return kind === OrderKind.Snipe;
}
