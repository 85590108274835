/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import {
  SwapsDetails,
  WalletsSwapsDetailsTableSortKeys,
} from 'api/types/httpsTypes/contracts';
import { formatDuration } from 'modules/utils/formatDuration';
import { ClockIcon } from '@heroicons/react/24/outline';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import Big from 'big.js';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import Tooltip from 'modules/shared-components/tooltip';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { twMerge } from 'tailwind-merge';

export const desktopColumns: TableColumn<SwapsDetails>[] = [
  {
    label: (
      <div className="flex xxs:space-x-2 items-center">
        <div>Pair</div>
        <Tooltip.Info text="The traded pair." />
      </div>
    ),
    key: 'PAIR',
    component: ({ data }) => (
      <div>
        <div className="flex items-center xxs:gap-2">
          <TokenIconWithChain
            hasIcon={data.hasIcon}
            tokenName={data.tokenBaseSymbol}
            chain={data.chain ?? Chains.Ethereum}
            protocol={data.dex}
            symbol={data.tokenBaseSymbol}
            className="w-6 h-6"
            address={data.tokenAddress}
          />

          <div className="max-w-[120px] overflow-hidden truncate">
            <div>
              <span className="font-bold text-sm">{data.tokenBaseSymbol}</span>/
              <span className="xxs:text-dex-white-secondary xxs:text-xs">
                {data.tokenQuoteSymbol}
              </span>
            </div>
            <div>
              <EthAddress
                address={data.tokenAddress}
                precision={4}
                kind="token"
                className="text-xs"
                chain={data.chain ?? Chains.Ethereum}
              />
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    label: (
      <div className="flex xxs:space-x-2 items-center">
        <div>Side</div>
        <Tooltip.Info text="The side can be either buy or sell." />
      </div>
    ),
    key: 'SIDE',
    component: ({ data }) => (
      <div className="flex space-x-1 items-center">
        <div
          className={twMerge(
            'text-xs  rounded text-white xxs:py-1 xxs:px-2',
            data.swapDirection === 'buy' ? 'bg-green-500' : 'bg-red-500'
          )}
        >
          {data.swapDirection === 'buy'
            ? `Buy ${data.blockZeroBlockOne ? '1️⃣ block' : ''}`
            : 'Sell'}
        </div>
      </div>
    ),
  },
  {
    label: (
      <div className="flex xxs:space-x-2 items-center">
        <div>Amount</div>
        <Tooltip.Info text="The total amount of tokens purchased or sold." />
      </div>
    ),
    key: 'AMOUNT',
    component: ({ data }) => (
      <div className="text-xs">
        <div className="flex space-x-1 items-center">
          <div
            className={
              data.swapDirection === 'buy' ? 'text-green-500' : 'text-red-500'
            }
          >
            {formatNumberWithSuffix(Big(data.quoteAmount ?? 0).toNumber())}{' '}
            {data.tokenQuoteSymbol}
          </div>
        </div>
        <div className="flex space-x-1 items-center">
          <div className="text-[10px]">
            {formatNumberWithSuffix(Big(data.tokenAmount ?? 0).toNumber())}{' '}
          </div>
        </div>
      </div>
    ),
  },

  {
    label: (
      <div className="flex xxs:space-x-2 items-center">
        <div>Price</div>
        <Tooltip.Info text="The price at which the swap has been executed." />
      </div>
    ),
    key: 'PRICE',
    sortableKey: WalletsSwapsDetailsTableSortKeys.Pnl,
    component: ({ data }) => (
      <div>
        {data.price ? (
          <div className="text-white text-xs">
            ${formatNumberWithSuffix(data.price)}
          </div>
        ) : (
          <div className="text-white text-xs">--</div>
        )}
      </div>
    ),
  },

  {
    label: (
      <div className="flex xxs:space-x-2 items-center">
        <div>Time</div>
        <Tooltip.Info text="The time at which the swap has been executed, and the transaction hash" />
      </div>
    ),
    key: 'TIME',
    sortableKey: WalletsSwapsDetailsTableSortKeys.LastSwap,
    component: ({ data }) => (
      <div>
        <div className="text-white">
          {data.timestamp ? (
            <div>
              <div className="flex space-x-1 items-center text-xs">
                <ClockIcon className="h-4 w-4" />
                <div>{formatDuration(data.timestamp ?? '0')}</div>
              </div>
              <EthAddress
                precision={4}
                className="text-xs"
                address={data.transactionHash}
                chain={data.chain}
                kind="tx"
              />
            </div>
          ) : (
            '--'
          )}
        </div>
      </div>
    ),
  },
];

export const mobileColumns: TableColumn<SwapsDetails>[] = [
  {
    key: 'WALLETS',
    component: ({ data }) => (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <div className="flex items-center xxs:gap-2">
              <TokenIconWithChain
                hasIcon={data.hasIcon}
                tokenName={data.tokenBaseSymbol}
                chain={data.chain ?? Chains.Ethereum}
                protocol={data.dex}
                symbol={data.tokenBaseSymbol}
                className="w-6 h-6"
                address={data.tokenAddress}
              />

              <div className="max-w-[120px] overflow-hidden truncate">
                <div>
                  <span className="font-bold text-sm">
                    {data.tokenBaseSymbol}
                  </span>
                  /
                  <span className="xxs:text-dex-white-secondary xxs:text-xs">
                    {data.tokenQuoteSymbol}
                  </span>
                </div>

                <div className="flex space-x-1 items-center">
                  <EthAddress
                    address={data.tokenAddress}
                    precision={4}
                    kind="token"
                    className="text-xs"
                    chain={data.chain ?? Chains.Ethereum}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-xs">
            <div className="flex space-x-1 items-center justify-end">
              <div
                className={
                  data.swapDirection === 'buy'
                    ? 'text-green-500'
                    : 'text-red-500'
                }
              >
                {formatNumberWithSuffix(Big(data.quoteAmount ?? 0).toNumber())}{' '}
                {data.tokenQuoteSymbol}
              </div>
              <div>
                {data.price ? (
                  <div className="text-white text-xs">
                    ${formatNumberWithSuffix(data.price)}
                  </div>
                ) : (
                  <div className="text-white text-xs">--</div>
                )}
              </div>
            </div>
            <div className="flex space-x-1 items-center justify-end">
              <div className="flex space-x-1 items-center text-xs xxs:text-dex-white-secondary">
                <ClockIcon className="h-4 w-4" />
                <div>{formatDuration(data.timestamp ?? '0')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
];
