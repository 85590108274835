import { Chains } from 'api/types/httpsTypes/d-wallets';

export function getSeasonForCurrentCalendarWeek(selectedChain: Chains): string {
  const currentDate = new Date();

  // Define the year and week where Season 1 starts (CW-44 of 2024)
  const startYear = selectedChain === Chains.Base ? 2024 : 2025;
  const startSeasonWeek = selectedChain === Chains.Base ? 44 : 8;

  // Get the first day of the year (January 1st)
  const firstDayOfYear = new Date(startYear, 0, 1);

  // Calculate the first Monday of the year (this helps us get the correct ISO week number)
  const firstMondayOfYear = new Date(firstDayOfYear);
  const dayOfWeek = firstMondayOfYear.getDay();
  const daysToAdd = dayOfWeek <= 4 ? 1 - dayOfWeek : 8 - dayOfWeek;
  firstMondayOfYear.setDate(firstMondayOfYear.getDate() + daysToAdd);

  // Calculate the start of season 1 (the first Monday of CW-44 in 2024)
  const startOfSeason1 = new Date(firstMondayOfYear);
  startOfSeason1.setDate(
    firstMondayOfYear.getDate() + (startSeasonWeek - 1) * 7
  );

  // Get the current date and calculate the difference in weeks
  const timeDifference = currentDate.getTime() - startOfSeason1.getTime();

  const currentWeekNumber =
    Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7)) + 1;

  // Calculate the season, with seasons repeating every 12 weeks

  return `${currentWeekNumber}`;
}
