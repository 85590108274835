import * as yup from 'yup';
import {
  safetyMeasuresSchema,
  stopLossSchema,
  takeProfitConfigSchema,
  takeProfitSchema,
} from '../../trade/schema';
import { isValidAddress } from 'helpers/api/web3Utils';
import { chainHasMemPool, Chains } from 'api/types/httpsTypes/d-wallets';
import { validateLabel } from '../../components/inputs/text-input/text-validation';
import {
  amountMinMaxTest,
  gasMinMaxTest,
} from '../../components/inputs/inputTests';

const buyAmountSchema = yup
  .number()
  .test('min-max-value', function (value) {
    const chain = this?.parent?.chain as Chains;
    if (!value || !chain) {
      return true;
    }
    return amountMinMaxTest.call(this, chain, value);
  })
  .typeError('Required')
  .required('Required');

export const maxNumberOfSnipesSchema = yup
  .number()
  .min(1)
  .typeError('Required')
  .max(100, 'Must be less than 100')
  .required('Required');

export enum BotKind {
  WalletCopyTrading = 'WalletCopyTrading',
  TelegramCopyTrading = 'TelegramCopyTrading',
}

export const telegramCopyTradingSchema = yup.object({
  channels: yup
    .array(yup.string().required())
    .min(1, 'At least a trader need to be selected')
    .max(100, "You can't select more than 100 traders.")
    .required('At least a trader need to be selected'),
  callsFilter: yup
    .object({
      minNumberOfCalls: yup
        .number()
        .min(1)
        .max(5, 'Max is 5')
        .required('Required'),
      timePeriod: yup.number().oneOf([1, 3, 7]).required('Required'),
    })
    .nullable(),
  cooldownPeriod: yup
    .object({
      periodInHours: yup.number().max(720, 'Max is 720').required('Required'),
    })
    .nullable(),
});

export type TelegramCopyTradingSchema = yup.InferType<
  typeof telegramCopyTradingSchema
>;

export enum OrderType {
  Market = 'market',
  Limit = 'limit',
}

export const walletCopyTradingSchema = yup.object({
  walletAddressToCopy: yup
    .string()
    .test('address-type', 'Invalid wallet address', function (value) {
      const chain = this?.from?.[2]?.value?.tradingSettings?.chain;
      if (!chain) {
        return true;
      }
      return !!value && isValidAddress(value, chain);
    })
    .typeError('Required')
    .required('Required'),
  copySellSwaps: yup.boolean().required('Required').typeError('Required'),
  copyLimitExpirationInHours: yup
    .number()
    .min(0, 'Must be above 0h')
    .max(720, 'Must be below 720h')
    .typeError('Required'),
  isFirstBuy: yup.boolean().required('Required').typeError('Required'),
  copyBuyMore: yup.boolean().required('Required').typeError('Required'),
  copyBuyAmount: yup.boolean().required('Required').typeError('Required'),
  copyBuyAmountPercentage: yup
    .number()
    .integer('Must be integer')
    .min(5, 'Min. is 5%')
    .max(1000, 'Max. is 1000% (10x)')
    .required('Required')
    .typeError('Required'),
  maxBuyMore: yup
    .number()
    .integer('Must be integer')
    .min(0, 'Min. is 0 buy')
    .max(50, 'Max. is 50 buys')
    .required('Required')
    .typeError('Required'),
  orderType: yup
    .string()
    .oneOf([OrderType.Market, OrderType.Limit])
    .required('Required')
    .typeError('Required'),
});

export type WalletCopyTradingSchema = yup.InferType<
  typeof walletCopyTradingSchema
>;

export const walletBotSettingsSchema = yup
  .object({
    maxNOfSnipes: maxNumberOfSnipesSchema,
    entries: walletCopyTradingSchema,
  })
  .required();

export type WalletBotSettingsSchema = yup.InferType<
  typeof walletBotSettingsSchema
>;

export const telegramBotSettingsSchema = yup
  .object({
    maxNOfSnipes: maxNumberOfSnipesSchema,
    entries: telegramCopyTradingSchema,
  })
  .required();

export type TelegramBotSettingsSchema = yup.InferType<
  typeof telegramBotSettingsSchema
>;

const walletCopyTradingSettingsSchema = yup
  .object({
    chain: yup
      .string()
      .oneOf([...Object.values(Chains)])
      .required('Required'),
    walletId: yup.string().required('Required'),
    buyAmount: buyAmountSchema,
    slippage: yup.number().required('Required'),
    antiMev: yup.boolean().default(false).required(),
    isMaxBuyGasPriorityEdited: yup.boolean(),
    maxBuyGasPriority: yup
      .number()
      .test('min-max-value', function (value) {
        const chain = this?.parent?.chain as Chains;
        if (!value || !chain) {
          return true;
        }
        return gasMinMaxTest.call(this, chain, value);
      })
      .required('Required'),
    bribe: yup
      .number()
      .min(0, 'Must be above 0')
      .max(1, 'Max is 1.')
      .required('Required'),
    safetyMeasures: safetyMeasuresSchema,
    takeProfit: takeProfitSchema,
    takeProfitConfig: takeProfitConfigSchema,
    stopLoss: stopLossSchema,
  })
  .required();

export type WalletCopyTradingSettingsSchema = yup.InferType<
  typeof walletCopyTradingSettingsSchema
>;

const telegramCopyTradingSettingsSchema = yup
  .object({
    chain: yup
      .string()
      .oneOf([...Object.values(Chains)])
      .required('Required'),
    launchedFilter: yup.boolean().default(true).required(),
    notLaunchedFilter: yup.boolean().default(false).required(),
    oneFilterSelected: yup
      .boolean()
      .test(
        'is-true',
        'One of the two token type filter has to be selected',
        (value) => value === true
      )
      .required(),
    walletId: yup.string().required('Required'),
    buyAmount: buyAmountSchema,
    antiMev: yup.mixed().when(['launchedFilter'], ([launchedFilter], s) => {
      return launchedFilter
        ? yup.boolean().required('Required')
        : s.nullable().optional();
    }),
    slippage: yup
      .mixed<number>()
      .when(
        ['blockOneRetry', 'launchedFilter', 'chain'],
        ([blockOneRetry, launchedFilter, chain], s) => {
          return !chainHasMemPool(chain as Chains) ||
            blockOneRetry ||
            launchedFilter
            ? yup
                .number()
                .typeError('Required')
                .min(0.01, 'Must be above 0.01')
                .required('Required')
            : s.nullable().optional();
        }
      ),
    backupBribe: yup
      .mixed()
      .when(
        ['blockOneRetry', 'notLaunchedFilter', 'chain'],
        ([blockOneRetry, notLaunchedFilter, chain], s) => {
          return chainHasMemPool(chain as Chains) &&
            blockOneRetry &&
            notLaunchedFilter
            ? yup
                .number()
                .typeError('Required')
                .min(0.001, 'Must be above 0.001 ETH')
                .required('Required')
            : s.nullable().optional();
        }
      ),
    blockOneRetry: yup
      .mixed()
      .when(['notLaunchedFilter', 'chain'], ([notLaunchedFilter, chain], s) => {
        return chainHasMemPool(chain as Chains) && notLaunchedFilter
          ? yup.boolean().typeError('Required').required('Required')
          : s.nullable().optional();
      }),
    bribe: yup
      .mixed()
      .when(['notLaunchedFilter', 'chain'], ([notLaunchedFilter, chain], s) => {
        return chainHasMemPool(chain as Chains) && notLaunchedFilter
          ? yup
              .number()
              .typeError('Required')
              .min(0, 'Must be above 0')
              .required('Required')
          : s.nullable().optional();
      }),

    isMaxBuyGasPriorityEdited: yup.boolean(),
    maxBuyGasPriority: yup
      .mixed()
      .when(['launchedFilter', 'chain'], ([launchedFilter, chain], s) => {
        return !chainHasMemPool(chain as Chains) || launchedFilter
          ? yup
              .number()
              .typeError('Required')
              .test('min-max-value', function (value) {
                const c = this?.parent?.chain as Chains;
                if (!value || !c) {
                  return true;
                }
                return gasMinMaxTest.call(this, c, value);
              })
              .required('Required')
          : s.nullable().optional();
      }),
    safetyMeasures: safetyMeasuresSchema,
    takeProfit: takeProfitSchema,
    takeProfitConfig: takeProfitConfigSchema,
    stopLoss: stopLossSchema,
  })
  .required();

export type TelegramCopyTradingSettingsSchema = yup.InferType<
  typeof telegramCopyTradingSettingsSchema
>;

export const schema = yup.object({
  name: yup
    .string()
    .typeError('Required')
    .required('Required')
    .test(
      'is-valid-label',
      'Invalid label. Text can contain only letters, spaces, numbers, and -,.. It cannot be longer than 127 characters.',
      (value) => {
        try {
          return validateLabel(value || '');
        } catch {
          return false;
        }
      }
    ),
  tradingSettings: yup
    .mixed<
      WalletCopyTradingSettingsSchema | TelegramCopyTradingSettingsSchema
    >()
    .when('type', {
      is: BotKind.WalletCopyTrading,
      then: () => walletCopyTradingSettingsSchema,
      otherwise: () => telegramCopyTradingSettingsSchema,
    })
    .required(),
  type: yup
    .string()
    .oneOf([BotKind.WalletCopyTrading, BotKind.TelegramCopyTrading])
    .required('Required'),

  botSettings: yup
    .mixed<WalletBotSettingsSchema | TelegramBotSettingsSchema>()
    .when('type', {
      is: BotKind.WalletCopyTrading,
      then: () => walletBotSettingsSchema,
      otherwise: () => telegramBotSettingsSchema,
    })
    .required(),
});

export type TokenType = 'launched-only' | 'not-launched-only' | 'all';

export { buyAmountSchema };

export type FormValues = yup.InferType<typeof schema>;

export function isWalletCopyTradingType(
  kind: BotKind.WalletCopyTrading | BotKind.TelegramCopyTrading,
  botEntriesForm: TelegramCopyTradingSchema | WalletCopyTradingSchema
): botEntriesForm is WalletCopyTradingSchema {
  return kind === BotKind.WalletCopyTrading;
}
