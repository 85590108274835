import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'modules/shared-components/button/button';
import {
  DexTradeService,
  dexTradeKeys,
} from 'api/services/httpServices/DexTradeService';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DexTradeTemplate } from 'api/types/httpsTypes/dex-trade';
import { PrimaryButton } from 'modules/shared-components/button/SubmitButton';
import CustomModal from '../../../components/modal/CustomModal';
import { NotificationDex } from '../../../components/alerts/notification';
import TextInput from '../../../components/inputs/text-input/text-input';
import { validateLabel } from '../../../components/inputs/text-input/text-validation';

type Props = {
  handleClose: () => void;
  settings: DexTradeTemplate;
};

export const yupTemplateName = yup
  .string()
  .typeError('Required')
  .required('Required')
  .test(
    'is-valid-label',
    'Invalid label. Text can contain only letters, spaces, numbers, and -,.. It cannot be longer than 127 characters.',
    (value) => {
      try {
        return validateLabel(value || '');
      } catch {
        return false;
      }
    }
  );

export const schema = yup.object({
  name: yupTemplateName,
});

export type RenameTemplateFormState = yup.InferType<typeof schema>;

export function RenameTemplateModal({ settings, handleClose }: Props) {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RenameTemplateFormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: settings.name,
    },
  });
  const { mutate, error, isLoading } = useMutation({
    mutationFn: (name: string) =>
      DexTradeService.updateTemplate(settings.id, { ...settings, name }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(dexTradeKeys.getTemplates());
      handleClose();
    },
  });

  const onSubmit = handleSubmit(({ name }) => {
    mutate(name);
  });

  return (
    <CustomModal title="Rename Settings" showModal handleClose={handleClose}>
      <form
        id="rename-template"
        className="contents"
        onSubmit={(e) => {
          e?.stopPropagation();

          void onSubmit(e);
        }}
      >
        <CustomModal.Body className="xxs:p-4">
          <CustomModal.Title className="hidden lg:flex">
            Rename Settings
          </CustomModal.Title>
          <CustomModal.Content>
            <TextInput
              {...register('name')}
              type="text"
              label="Give a new name to your settings"
              form="rename-template"
              error={errors?.name?.message}
            />
            {error && (
              <NotificationDex type="error" errorMessage={error}>
                An error occurred.
              </NotificationDex>
            )}
          </CustomModal.Content>
        </CustomModal.Body>

        <CustomModal.Footer>
          <PrimaryButton
            className="xxs:text-base lg:text-xs"
            form="rename-template"
            type="submit"
            loading={isLoading}
          >
            Save
          </PrimaryButton>
          <Button
            className="xxs:text-base lg:text-xs"
            type="button"
            variant="dexNeutral"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </CustomModal.Footer>
      </form>
    </CustomModal>
  );
}
