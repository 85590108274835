import { SwapsDetails } from 'api/types/httpsTypes/contracts';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useRef } from 'react';
import Table from 'modules/shared-components/data-display/table';
import Header from 'modules/shared-components/data-display/table/header';
import { desktopColumns, mobileColumns } from './columns';
import { TableRow } from './TableRow';

type Props = {
  data: SwapsDetails[];
  isLast10Swaps?: boolean;
};

export function SwapsDetailsTable({ data: items, isLast10Swaps }: Props) {
  const listRef = useRef<HTMLTableElement>(null);
  const isLargeScreen = useMediaQuery(screenGte.large);

  const columns =
    isLargeScreen && !isLast10Swaps ? desktopColumns : mobileColumns;

  return (
    <>
      <>
        <div className="w-full overflow-x-auto xxs:my-3">
          {!isLargeScreen && <div ref={listRef} />}
          <Table
            className={
              isLast10Swaps
                ? 'min-w-full [&_td:first-of-type:not(.details)]:pl-0 [&_th:first-of-type]:pl-0'
                : 'xxs:[&_td:first-of-type:not(.details)]:pl-2 xxs:[&_th:first-of-type]:pl-2 lg:[&_td:first-of-type:not(.details)]:pl-6 lg:[&_th:first-of-type]:pl-6'
            }
          >
            <Header
              columns={columns}
              trClassName="xxs:dark:bg-dex-black-800  border-tailwind xxs:border-x-0 xxs:border-t-0 xxs:border-b-dex-black-700"
              rowClassName="xxs:p-1"
              className="xxs:dark:bg-dex-black-800 xxs:text-xs"
            />
            <tbody>
              {items?.map((item) => (
                <TableRow
                  key={item.tokenAddress + '_' + item.chain}
                  data={item}
                  columnsDef={columns}
                />
              ))}
              {!items &&
                [...Array(10).keys()]
                  .map((i) => i + 1)
                  .map((key) => (
                    <Table.LoadingRow
                      className=" xxs:dark:bg-dex-black-800"
                      elementClassName="xxs:dark:bg-dex-black-700"
                      columns={items}
                      key={key}
                    />
                  ))}
            </tbody>
          </Table>
        </div>
      </>
    </>
  );
}
