import {
  UserIcon,
  CodeBracketIcon,
  TableCellsIcon,
  GiftIcon,
  CircleStackIcon,
  ArrowTrendingUpIcon,
  MagnifyingGlassCircleIcon,
  FireIcon,
  CreditCardIcon,
  ChartBarSquareIcon,
  ChartBarIcon,
  CpuChipIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { Tabs } from '../../../app/dex/components/layout/SIDEBAR_CONST';

interface NavigationItem {
  name: string;
  icon: React.ReactNode;
  href: string;
  id: string;
  tag?: string;
  subMenus: {
    description?: string;
    items: {
      label: string;
      href: string;
    }[];
  }[];
}

export const cexNavigation = [
  {
    name: 'DASHBOARD',
    icon: <i className="mdi mdi-view-dashboard" />,
    href: '/dashboard/console',
    id: 'dashboard_intro',
  },
  {
    name: 'EXCHANGES',
    icon: <i className="mdi mdi-wallet" />,
    href: '/dashboard/exchanges',
    id: 'exchanges',
  },
  {
    name: 'MARKETPLACE',
    icon: <i className="mdi mdi-rocket" />,
    href: '/dashboard/marketplace/main',
    id: 'marketplace',
  },
  {
    name: 'DCA-BOTS',
    icon: <i className="mdi mdi-robot" />,
    href: '/dashboard/dca-bots',
    id: 'dca_bots',
    subMenus: [
      {
        description: 'Monitor',
        items: [
          {
            label: 'Subscriptions',
            href: '/dashboard/dca-bots/active-subscriptions',
          },
          {
            label: 'Positions',
            href: '/dashboard/dca-bots/open-positions',
          },
          {
            label: 'Bots',
            href: '/dashboard/dca-bots/bots',
          },
        ],
      },
      {
        description: 'History',
        items: [
          {
            label: 'Subscriptions',
            href: '/dashboard/dca-bots/subscriptions-history',
          },
          {
            label: 'Positions',
            href: '/dashboard/dca-bots/positions-history',
          },
        ],
      },
    ],
  },
] as NavigationItem[];

export const cexMenu = [
  {
    name: 'ACCOUNT',
    icon: <UserIcon className="h-5" />,
    href: '/dashboard/settings/Account',
  },
  {
    name: 'FEE MANAGER',
    icon: <TableCellsIcon className="h-5" />,
    href: '/dashboard/fee-manager',
  },
  {
    name: 'REWARDS',
    icon: <GiftIcon className="h-5" />,
    href: '/dashboard/rewards-dashboard',
  },
  {
    name: 'STAKING',
    icon: <CircleStackIcon className="h-5" />,
    href: '/dashboard/staking',
  },
  {
    name: 'API',
    icon: <CodeBracketIcon className="h-5" />,
    href: '/dashboard/settings/API',
  },
] as NavigationItem[];

export const dexMenu = [
  {
    name: 'ACCOUNT',
    icon: <UserIcon className="h-5" />,
    href: '/dashboard/settings/Account',
  },
  {
    name: 'STAKING',
    icon: <CircleStackIcon className="h-5" />,
    href: '/dashboard/staking',
  },
  {
    name: 'REWARDS',
    icon: <GiftIcon className="h-5" />,
    href: '/dex/rewards',
  },
] as NavigationItem[];

export const dexNavigation = [
  {
    name: 'TERMINAL',
    icon: <ArrowTrendingUpIcon className="h-5 w-5" />,
    href: '/dex/snipe',
    id: 'snipe',
  },
  {
    name: 'DASHBOARD',
    icon: <ChartBarIcon className="h-5 w-5" />,
    href: '/dex/dashboard',
    id: 'dashboard',
  },
  {
    name: 'RESEARCH',
    icon: <MagnifyingGlassCircleIcon className="h-5 w-5" />,
    href: `/dex/research`,
    id: 'research',
  },
  {
    name: 'BOTS',
    icon: <MagnifyingGlassCircleIcon className="h-5 w-5" />,
    href: `/dex/bots`,
    id: 'bots',
  },
  {
    name: '🔥 ALPHA',
    icon: <TrophyIcon className="h-5 w-5" />,
    href: `/dex/alpha-program/leaderboard`,
    id: 'bots',
  },
] as NavigationItem[];

export const dexMobileNavigation = [
  {
    name: 'TRADE',
    href: '/dex/search',
    icon: <ChartBarSquareIcon className="h-10 w-10" />,
    id: 'snipe-mobile',
  },
  {
    name: 'WALLETS',
    href: `/dex/wallets/${Tabs.Portfolio}`,
    icon: <CreditCardIcon className="h-10 w-10" />,
    id: 'wallets-mobile',
  },
  {
    name: 'TRADES',
    href: `/dex/trade-monitoring/${Tabs.OpenTrades}`,
    icon: <FireIcon className="h-10 w-10" />,
    id: 'trades-mobile',
  },
  {
    name: 'HISTORY',
    href: `/dex/trade-monitoring/${Tabs.History}`,
    icon: <ChartBarIcon className="h-10 w-10" />,
    id: 'history-mobile',
  },
  {
    name: 'RESEARCH',
    href: `/dex/research`,
    icon: <MagnifyingGlassCircleIcon className="h-10 w-10" />,
    id: 'research-mobile',
  },
  {
    name: 'BOTS',
    href: `/dex/bots`,
    icon: <CpuChipIcon className="h-10 w-10" />,
  },
] as NavigationItem[];
