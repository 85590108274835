import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { FunnelIcon } from '@heroicons/react/24/outline';
import React, { Fragment, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { MobileSelectHeader } from '../modal/SelectionModal';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sort?: React.ReactNode;
  isCondensed?: boolean;
  icon?: JSX.Element;
  sortClass?: string;
}

export function SelectFiltersBar({
  children,
  isCondensed,
  sort,
  icon,
  sortClass,
  ...rest
}: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false);

  return (
    <div
      {...rest}
      className={twMerge(
        'xxs:flex',
        sort && 'xxs:justify-between',
        rest.className
      )}
    >
      <div className={twMerge('text-nowrap', sortClass)}>{sort}</div>

      {!isCondensed && (
        <div
          className={classNames(
            'hidden lg:flex flex-col lg:flex-row lg:space-x-2',
            sort && 'lg:justify-end'
          )}
        >
          {children}
        </div>
      )}
      {children ? (
        <div className={twMerge('xxs:flex ', !isCondensed ? 'lg:hidden' : '')}>
          <IconButton
            onClick={() => setOpen(true)}
            className="xxs:border xxs:border-solid xxs:rounded xxs:border-dex-black-700 xxs:bg-dex-black-800 hover:bg-dex-black-700 xxs:hover:text-dex-white xxs:text-dex-white-secondary"
          >
            {icon ? (
              icon
            ) : (
              <div className="xxs:p-1">
                <FunnelIcon aria-hidden="true" width={24} height={24} />
              </div>
            )}
          </IconButton>
        </div>
      ) : (
        ''
      )}
      {children ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className={twMerge(
              'relative z-50',
              !isCondensed ? 'lg:hidden' : ''
            )}
            onClose={setOpen}
          >
            {/* Backdrop */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 xxs:bg-dex-black-900 lg:bg-dex-black-900/80" />
            </Transition.Child>

            <div className="fixed top-16 inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div
                  className={twMerge(
                    'pointer-events-none fixed inset-y-0 right-0 flex max-w-full top-0',
                    isCondensed ? 'max-w-[750px]' : 'max-w-full'
                  )}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen lg:px-3">
                      <div className="lg:hidden xxs:text-dex-white">
                        <MobileSelectHeader
                          close={() => setOpen(false)}
                          label="Filters"
                        />
                      </div>

                      <div className="  h-full flex-col overflow-y-scroll xxs:bg-dex-black-800 border-tailwind xxs:border-solid xxs:border-t-0 xxs:border-r-0 xxs:border-l-1 border-dex-black-700 xxs:p-4 lg:px-4 lg:py-6">
                        <div className="xxs:hidden lg:flex lg:justify-end">
                          <Dialog.Title className="xxs:text-dex-white-secondary xxs:flex xxs:justify-end">
                            <div className="xxs:mt-4 xxs:mb-2 sticky top-2">
                              <IconButton
                                onClick={() => setOpen(false)}
                                className="z-50 ml-auto xxs:rounded xxs:text-dex-white-secondary hover:text-dex-white xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:p-1 "
                              >
                                <XMarkIcon className="w-6" />
                              </IconButton>
                            </div>
                          </Dialog.Title>
                        </div>
                        <div className="relative flex-1">
                          <div className="absolute inset-0">
                            <div
                              className="h-full flex flex-col gap-2"
                              aria-hidden="true"
                            >
                              {children}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : (
        ''
      )}
    </div>
  );
}
