import {
  MagnifyingGlassIcon,
  StarIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { formatApiError } from 'helpers/api/apiErrors';
import { twMerge } from 'tailwind-merge';
import { useDebouncedState } from 'utils/useDebounce';
import { allSuffixes, formatNumberWithSuffix } from 'utils/FormatNumber';
import moment, { duration } from 'moment';
import { ContractSearchResult } from 'api/types/httpsTypes/contracts';
import { RefObject, useEffect, useRef } from 'react';
import { IconButton } from 'modules/shared-components/button/IconButton';
import { FullPageModal } from 'modules/shared-components/modal/FullPage';
import { useToggle } from 'modules/utils/useToggle';
import { BaseButton } from 'modules/shared-components/button/base-button';
import { uniqBy } from 'lodash/fp';
import { useUserState } from 'modules/user/UserContext';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import {
  ChainsIcon,
  ExchangeIconStretch,
} from 'modules/shared-components/exchange/exchange-icon';
import { AddToFavouritesButton } from '../../components';
import Tag from 'modules/shared-components/data-display/tag';
import { StackedIcons } from '../../components/ContractIcons';
import { PaginatedList } from 'api/types/httpsTypes';
import { useInView } from 'react-intersection-observer';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import TextInput from '../../components/inputs/text-input/text-input';
import { LoaderDex } from '../../components/alerts/Loader';
import { Favourites } from './Favourites';
import { useHistory } from 'react-router-dom';

type SearchProps = {
  onSelect: (chain: Chains, address: string) => void;
  selected?: string;
} & Pick<Props<string>, 'className' | 'label' | 'autoFocus' | 'defaultOpen'>;

type Props<T extends string | number> = {
  name: string;
  recentSearches?: ContractSearchResult[];
  options?: PaginatedList<ContractSearchResult>;
  selected: string | undefined;
  onSelect: (chain: Chains, address: T) => void;
  setQuery: (value: string) => void;
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  error?: string;
  disabled?: boolean;
  isLoading?: boolean;
  query: string;
  children?: React.ReactNode;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  fetchNextPage: () => void;
  defaultOpen?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  handleClose?: () => void;
};

export function SearchAddress({ onSelect, selected, ...rest }: SearchProps) {
  const queryClient = useQueryClient();
  const { user } = useUserState();

  const [query, debouncedQuery, setQuery] = useDebouncedState(
    selected || '',
    250
  );
  const { data, error, isInitialLoading, fetchNextPage } = useInfiniteQuery({
    queryFn: ({ pageParam = { limit: 10, offset: 0 } }) =>
      ContractService.search({
        text: debouncedQuery,
        limit: pageParam.limit,
        offset: pageParam.offset,
      }),
    queryKey: contractServiceKeys.search(debouncedQuery),
    getNextPageParam: (lastPage) => {
      return {
        offset: lastPage.pagination.offset + lastPage.pagination.limit,
        limit: 10,
      };
    },
    enabled: debouncedQuery.length >= 1,
  });

  const { data: previousSearchesData } = useQuery({
    queryFn: () => ContractService.previousSearches(),
    queryKey: contractServiceKeys.previousSearches(),
    staleTime: duration('1', 'hour').asMilliseconds(),
    enabled: !!user,
  });

  const items = data?.pages?.flatMap((page) => page.data) || [];
  const pagination = data?.pages?.[0]?.pagination;

  useEffect(() => {
    if (selected) {
      setQuery(selected);
    }
  }, [selected]);

  return (
    <Search
      {...rest}
      isLoading={isInitialLoading}
      fetchNextPage={() => {
        void fetchNextPage();
      }}
      error={
        error ? formatApiError(error, 'Could not find contract') : undefined
      }
      name="search-address"
      placeholder="Search token/contract"
      query={query}
      setQuery={(text) => {
        setQuery(text);
      }}
      onSelect={(chain, addr) => {
        const [option] =
          [...(items || []), ...(previousSearchesData || [])]?.filter(
            (x) => x.address === addr
          ) || [];

        queryClient.setQueryData(contractServiceKeys.search(addr), [option]);
        setQuery(addr);

        onSelect(chain, addr);

        if (previousSearchesData) {
          void queryClient.setQueryData<ContractSearchResult[]>(
            contractServiceKeys.previousSearches(),
            uniqBy('address', [option, ...previousSearchesData])
          );
        }
      }}
      prefix={
        <MagnifyingGlassIcon className="w-4 xxs:text-dex-white-secondary" />
      }
      suffix={isInitialLoading ? <LoaderDex /> : undefined}
      selected={selected || undefined}
      options={
        pagination
          ? {
              data: items,
              pagination,
            }
          : undefined
      }
      recentSearches={previousSearchesData}
    />
  );
}

export const SearchComponent = ({
  children,
  options,
  recentSearches,
  setQuery,
  onSelect,
  suffix,
  prefix,
  error,
  query,
  fetchNextPage,
  inputRef,
  handleClose,
}: Props<string>) => {
  const { ref } = useInView({
    onChange: (inView) => {
      if (inView) {
        fetchNextPage();
      }
    },
  });

  const { user } = useUserState();
  const history = useHistory();

  return (
    <>
      <div className="lg:px-8 ">
        <div className="max-w-[720px] mx-auto">
          <div className="lg:bg-dex-black-800/90 xxs:p-3 mb-2 lg:mb-0 lg:px-0 lg:p-2 xxs:flex xxs:gap-2 xxs:sticky top-0 items-center ">
            <TextInput
              name="search"
              prefix={<>{prefix}</>}
              ref={inputRef}
              suffix={<>{suffix}</>}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              autoComplete="off"
              placeholder="Symbol, name or address"
            />
            <div className="lg:hidden">
              {user && (
                <Favourites
                  onSelect={(chain, address) => {
                    history.push({
                      pathname: `/dex/research/${chain}/${address}`,
                      state: {
                        from: history.location.pathname,
                      },
                    });
                  }}
                >
                  {({ open }) => (
                    <div
                      className="xxs:px-2 xxs:py-2 flex grow cursor-pointer rounded xxs:bg-dex-black-700 hover:bg-dex-black-600 justify-center items-center"
                      onClick={open}
                    >
                      <StarIcon className="xxs:w-5 xxs:h-5" />
                    </div>
                  )}
                </Favourites>
              )}
            </div>
          </div>
          {children}
          {error && (
            <div className="xxs:py-1 xxs:mb-3 xxs:pl-3 xxs:text-red-500">
              Could not load options
            </div>
          )}
          {options?.pagination && (
            <div className="xxs:text-dex-white-secondary xxs:text-xs xxs:mb-1 xxs:ml-3 lg:ml-0 flex items-center xxs:gap-1">
              {options?.pagination.total} Result(s) for {query}
            </div>
          )}
          <div className="xxs:divide-x-0 overflow-auto-y-scroll  xxs:divide-y xxs:divide-solid xxs:divide-dex-black-700 dark:divide-dex-black-700 border-0">
            {!!query.length &&
              options?.data.map((option) => (
                <ContractSearchResultItem
                  key={option.address}
                  option={option}
                  onSelect={() => {
                    onSelect(option.chain.key, option.address);
                    if (handleClose !== undefined) {
                      handleClose();
                    }
                  }}
                />
              ))}
            {options && options?.pagination.total > options?.data.length && (
              <div className="flex justify-center xxs:py-2">
                <LoaderDex ref={ref} />
              </div>
            )}
            {recentSearches && query.length === 0 ? (
              <>
                <div className="dark:text-dex-white-secondary xxs:text-xs xxs:mb-1 xxs:ml-3 lg:ml-0 flex items-center xxs:gap-1 ">
                  Recent Searches
                </div>
                <div className="xxs:divide-x-0 xxs:divide-y xxs:divide-solid xxs:divide-black-100 dark:divide-dex-black-700 border-0">
                  {recentSearches?.map((option) => (
                    <ContractSearchResultItem
                      key={option.address}
                      option={option}
                      onSelect={() => {
                        onSelect(option.chain.key, option.address);
                        if (handleClose !== undefined) {
                          handleClose();
                        }
                      }}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="dark:text-dex-white-secondary xxs:text-xs xxs:mb-1 xxs:ml-3 lg:ml-0 flex items-center xxs:gap-1 ">
                  Search a token or a contract and start sniping!
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function Search({
  children,
  options,
  recentSearches,
  setQuery,
  onSelect,
  label,
  suffix,
  prefix,
  selected,
  name,
  error,
  query,
  placeholder,
  fetchNextPage,
  defaultOpen,
}: Props<string>) {
  const [isOpen, { close, open }] = useToggle(defaultOpen);
  const inputRef = useRef<HTMLInputElement>(null);
  if (!isOpen) {
    return (
      <TextInput
        name={name}
        prefix={<>{prefix}</>}
        onFocus={() => {
          setQuery('');
          open();
        }}
        label={label}
        suffix={<>{suffix}</>}
        value={selected}
        onChange={() => {}}
        placeholder={placeholder}
      />
    );
  }

  return (
    <FullPageModal
      showModal
      handleClose={close}
      className="xxs:text-dex-white-secondary dark:bg-dex-black-800 h-screen lg:h-[80vh] border-bottom-new border-b-1 xxs:border-b-1 xxs:border-dex-black-600"
      backdropClassName="dark:bg-black-900/70"
      initialFocus={inputRef}
    >
      <div className="xxs:hidden lg:block xxs:mt-4 xxs:mb-2 xxs:px-8 sticky top-2">
        <IconButton
          onClick={close}
          className="z-50 ml-auto xxs:rounded xxs:text-dex-white-secondary hover:text-dex-white xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:p-1 "
        >
          <XMarkIcon className="w-6" />
        </IconButton>
      </div>
      <SearchComponent
        prefix={prefix}
        suffix={suffix}
        error={error}
        options={options}
        recentSearches={recentSearches}
        inputRef={inputRef}
        name={name}
        handleClose={close}
        selected={selected}
        onSelect={onSelect}
        setQuery={setQuery}
        query={query}
        fetchNextPage={fetchNextPage}
      >
        {children}
      </SearchComponent>
    </FullPageModal>
  );
}

export function ContractSearchResultItem({
  option,
  onSelect,
}: {
  option: ContractSearchResult;
  onSelect: (chain: Chains, addr: string) => void;
}) {
  const { user } = useUserState();

  return (
    <BaseButton
      className="grid grid-cols-[minmax(0,3fr)_minmax(0,3fr)_minmax(0,2fr)_48px] lg:grid-cols-[minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)_min-content] items-center dark:text-dex-white w-full dark:hover:bg-dex-black-700 hover:bg-white-200 "
      onClick={() => {
        onSelect(option.chain.key, option.address);
      }}
    >
      <OptionColumn>
        <div className="flex items-center xxs:gap-3">
          <div className="relative">
            <TokenIconWithChain
              hasIcon={option.hasIcon}
              size="lg"
              chain={option.chain?.key ?? Chains.Ethereum}
              tokenName={option.name}
              protocol={option.primaryDex?.key}
              symbol={option.symbol}
              className="w-8 h-8 xxs:text-[18px]"
              address={option.address}
            />
          </div>
          <div className="flex flex-col dark:text-white-50">
            <div className="xxs:text-sm truncate">{option.symbol}</div>
            <div className="xxs:text-xs truncate">{option.name}</div>
            <div className="xxs:text-xs">
              <EthAddress
                address={option.address}
                precision={3}
                kind="token"
                chain={option.chain?.key ?? Chains.Ethereum}
              />
            </div>
          </div>
        </div>
      </OptionColumn>

      <OptionColumn className="xxs:hidden lg:block">
        <>
          <div className="xxs:text-sm lg:text-base dark:text-dex-white">
            {option.price ? (
              <>P: ${formatNumberWithSuffix(option.price, { precision: 2 })}</>
            ) : (
              <>--</>
            )}
          </div>
          <div className="xxs:text-xs xxs:text-dex-white-secondary">
            {option.marketCap ? (
              <>
                MC:{' '}
                <span className="xxs:font-semibold">
                  ${formatNumberWithSuffix(option.marketCap, { precision: 2 })}
                </span>
              </>
            ) : (
              <>--</>
            )}
          </div>
        </>
      </OptionColumn>
      <OptionColumn
        className={twMerge(
          'xxs:px-3 lg:px-2 truncate',
          !option.liquidity && 'xxs:hidden lg:block'
        )}
      >
        <div className="xxs:text-sm lg:text-base dark:text-white">
          Liq:{' '}
          {option.liquidity ? (
            <>
              $
              {formatNumberWithSuffix(option.liquidity, {
                precision: 2,
                suffixes: allSuffixes,
              })}
            </>
          ) : (
            <>--</>
          )}
        </div>
        <div className="xxs:text-xs xxs:text-dex-white-secondary">
          Pool:{' '}
          <span className="xxs:font-semibold">
            {' '}
            {option.tokenQuoteReserves ? (
              <>
                {formatNumberWithSuffix(option.tokenQuoteReserves, {
                  precision: 2,
                  suffixes: allSuffixes,
                })}
                {option.tokenQuoteSymbol}
              </>
            ) : (
              <>--</>
            )}
          </span>
        </div>
      </OptionColumn>
      <OptionColumn
        className={twMerge(
          'xxs:px-0 lg:px-4 flex justify-center',
          !option.liquidity && 'xxs:col-span-2 lg:col-span-1'
        )}
      >
        {option.isSnipeable && (
          <Tag condensed color="dexNeutral" className="mx-auto">
            NOT LAUNCHED
          </Tag>
        )}
        {!option.isSnipeable && option.primaryDex && (
          <div className="flex flex-col justify-center items-center xxs:space-y-1">
            <div className="items-center flex xxs:gap-0.5 xxs:text-sm">
              <StackedIcons className="-space-x-1.5">
                <ChainsIcon className="w-5 h-5" imgName={option.chain.key} />
                <ExchangeIconStretch
                  className="h-5 w-5"
                  imgName={option.primaryDex.key}
                />
              </StackedIcons>
              {option.primaryDex.key.includes('2') ? 'v2.0' : 'v3.0'}
            </div>
            {option.liquidityPoolCreationDatetime && (
              <div className="xxs:text-xs xxs:font-semibold xxs:text-dex-white-secondary">
                {moment(option.liquidityPoolCreationDatetime)
                  .locale('en')
                  .fromNow()}
              </div>
            )}
          </div>
        )}
      </OptionColumn>
      <OptionColumn className="xxs:p-2">
        {user && (
          <AddToFavouritesButton
            chain={option.chain.key}
            className="xxs:w-[32px] xxs:p-1 xxs:rounded"
            contractAddress={option.address}
          />
        )}
      </OptionColumn>
    </BaseButton>
  );
}

const OptionColumn = (props: React.ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:p-4 xxs:px-3 lg:px-4 overflow-hidden',
        props.className
      )}
    />
  );
};
