/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import {
  SwapsStatus,
  SwapType,
  WalletsSwapsDetailsTableSortKeys,
  WalletSwapsDetails,
} from 'api/types/httpsTypes/contracts';
import { formatDuration } from 'modules/utils/formatDuration';
import { ArrowsRightLeftIcon, ClockIcon } from '@heroicons/react/24/outline';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import Big from 'big.js';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import Tooltip from 'modules/shared-components/tooltip';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { PercentageChange } from '../../TelegramCalls/columns';
import { useToggle } from 'modules/utils/useToggle';
import Button from 'modules/shared-components/button/button';
import SwapsModal from './swaps/SwapsModal';
import { twMerge } from 'tailwind-merge';
import Tag from '../../../../components/alerts/Tags';
import { PerformanceStyle } from '../../../../components/alerts/performanceStyle';

const honeyPotClass = (honeypot: boolean) => {
  return honeypot ? 'text-white-700' : '';
};

export interface SwapsModalProps {
  fromDatetime: string;
  tradedTokenId: string;
  makerAddress: string;
  chain: Chains;
}

const SwapsDetails = ({
  fromDatetime,
  tradedTokenId,
  makerAddress,
  chain,
}: SwapsModalProps) => {
  const [isOpen, { open, close }] = useToggle();
  return (
    <div
      className="z-10"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Tooltip text="View swaps details">
        <div className="flex w-full lg:w-auto ">
          <Button
            type="button"
            variant="dexNeutral"
            className="xxs:p-1 rounded text-white w-full lg:w-auto flex justify-center"
            onClick={open}
          >
            <ArrowsRightLeftIcon className="w-4 h-4" />
          </Button>
        </div>
      </Tooltip>

      {isOpen && (
        <SwapsModal
          fromDatetime={fromDatetime}
          tradedTokenId={tradedTokenId}
          chain={chain}
          makerAddress={makerAddress}
          handleClose={close}
        />
      )}
    </div>
  );
};
export const desktopColumns: TableColumn<WalletSwapsDetails>[] = [
  {
    label: (
      <Tooltip text="The traded token.">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Token
        </div>
      </Tooltip>
    ),
    key: 'PAIR',
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        <div className="flex items-center xxs:gap-2">
          <TokenIconWithChain
            hasIcon={data.hasIcon}
            tokenName={data.tokenBaseSymbol}
            chain={data.chain ?? Chains.Ethereum}
            protocol={data.dex}
            symbol={data.tokenBaseSymbol}
            className="w-6 h-6"
            address={data.tokenAddress}
          />

          <div className="max-w-[120px] overflow-hidden truncate">
            <div>
              <span className="font-bold text-sm">{data.tokenBaseSymbol}</span>/
              <span className="xxs:text-dex-white-secondary xxs:text-xs">
                {data.tokenQuoteSymbol}
              </span>
            </div>
            <div>
              <EthAddress
                address={data.tokenAddress}
                precision={3}
                kind="token"
                className="text-xs"
                chain={data.chain ?? Chains.Ethereum}
              />
            </div>
          </div>
        </div>
      </div>
    ),
  },
  {
    label: (
      <Tooltip text="The swap type (Snipe, Early Ape, Trade) and status (Open, Closed, Transfer).">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Main Info
        </div>
      </Tooltip>
    ),
    key: 'MAIN_INFO',
    component: ({ data }) => (
      <div className="flex space-x-1 items-center">
        <div className="">
          {data.buyTokenAmount ? (
            data.status === SwapsStatus.Closed ? (
              <Tooltip text="Closed: more than 90% of the purchased tokens have been already sold.">
                <div>
                  <Tag type="error">Closed</Tag>
                </div>
              </Tooltip>
            ) : (
              <Tooltip text="Open: less than 90% of the purchased tokens have been sold.">
                <div>
                  <Tag type="success"> Open</Tag>
                </div>
              </Tooltip>
            )
          ) : (
            <Tooltip text="Transfer: the tokens have never been purchased, but they have been transferred from another wallet.">
              <div className="bg-dex-black-600 hover:bg-dex-black-500 text-white xxs:p-1 rounded text-xs flex justify-center">
                Transfer
              </div>
            </Tooltip>
          )}
        </div>
        <div className="">
          {data.honeypot ? (
            <Tooltip text="This token performed a rug-pull">
              <div className="text-xs bg-red-500 rounded text-white xxs:py-1 xxs:px-2">
                🚨 Rug
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              text={
                data.tradeType === SwapType.Trade
                  ? 'When the wallet made its first buy, the token had been launched for more than 2 weeks.'
                  : data.tradeType === SwapType.Early
                  ? 'When the wallet made its first buy, the token had already been launched for more than an hour, but less than 2 weeks.'
                  : 'When the wallet made its first buy, the token had already been launched since less than one hour.'
              }
            >
              <div>
                <Tag type="info" className=" xxs:py-1 xxs:px-2">
                  <div className="xxs:text-xs xxs:text-dex-white">
                    {data.tradeType === SwapType.Trade
                      ? '🔃 Trade'
                      : data.tradeType === SwapType.Early
                      ? '🐒 Early Ape'
                      : `🔫 Snipe ${data.blockZeroBlockOne ? '1️⃣ blocks' : ''}`}
                  </div>
                </Tag>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    ),
  },

  {
    label: (
      <Tooltip text="The total amount of tokens purchased.">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Buy Amount
        </div>
      </Tooltip>
    ),
    key: 'BUY_AMOUNT',
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        {Big(data.buyTokenAmount).gt(0) ? (
          <div className="text-xs">
            <div className="flex space-x-1 items-center">
              <div className="text-green-500">
                {formatNumberWithSuffix(
                  Big(data.buyQuoteAmount ?? 0).toNumber()
                )}{' '}
                {data.tokenQuoteSymbol}
              </div>
              <Tooltip text="Average entry price">
                <div className="text-[10px]">
                  {' '}
                  $
                  {formatNumberWithSuffix(
                    Big(data.avgEntryPrice ?? 0).toNumber(),
                    { precision: 1 }
                  )}
                </div>
              </Tooltip>
            </div>
            <div className="flex space-x-1 items-center xxs:text-dex-white-secondary">
              <div className="text-[10px]">
                {formatNumberWithSuffix(
                  Big(data.buyTokenAmount ?? 0).toNumber()
                )}{' '}
              </div>
              <Tooltip text="Number of buy swaps">
                <div className="flex space-x-0.5 items-center text-[10px]">
                  <div>/</div>
                  <div className="hover:text-dex-white">{data.nBuys}</div>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="text-xs">--</div>
        )}
      </div>
    ),
  },
  {
    label: (
      <Tooltip text="The total amount of tokens sold.">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Sell Amount
        </div>
      </Tooltip>
    ),
    key: 'SELL_AMOUNT',
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        {Big(data.sellTokenAmount).gt(0) ? (
          <div className="text-xs">
            <div className="flex space-x-1 items-center">
              <div className="text-red-500">
                {formatNumberWithSuffix(
                  Big(data.sellQuoteAmount ?? 0).toNumber()
                )}{' '}
                {data.tokenQuoteSymbol}
              </div>
              <Tooltip text="Average exit price">
                <div className="text-[10px]">
                  {' '}
                  $
                  {formatNumberWithSuffix(
                    Big(data.avgExitPrice ?? 0).toNumber(),
                    { precision: 1 }
                  )}
                </div>
              </Tooltip>
            </div>
            <div className="flex space-x-1 items-center">
              <div className="flex space-x-0.5 items-center xxs:text-dex-white-secondary">
                <div className="text-[10px]">
                  {formatNumberWithSuffix(
                    Big(data.sellTokenAmount ?? 0).toNumber()
                  )}{' '}
                </div>
              </div>

              <Tooltip text="Number of sell swaps">
                <div className="flex space-x-0.5 items-center text-[10px]">
                  <div>/</div>
                  <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                    {data.nSells}
                  </div>
                </div>
              </Tooltip>
              {data.buyTokenAmount && (
                <div className="xxs:flex justify-center items-center">
                  <Tag type="error" className="xxs:p-0 xxs:px-0.5">
                    <div className="text-[8px]">
                      {Big(data.sellTokenAmount).gt(data.buyTokenAmount)
                        ? 100
                        : Big(data.sellTokenAmount)
                            .div(data.buyTokenAmount)
                            .mul(100)
                            .toFixed(0)}
                      %
                    </div>
                  </Tag>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-xs">--</div>
        )}
      </div>
    ),
  },

  {
    label: (
      <Tooltip text="The total profit and loss, taking into consideration realized and unrealized PnL.">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Total PnL
        </div>
      </Tooltip>
    ),
    key: 'PNL',
    sortableKey: WalletsSwapsDetailsTableSortKeys.Pnl,
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        <div className="xxs:text-dex-white-secondary">
          <PerformanceStyle pnl={data.pnl} />
        </div>
        <div className="xxs:text-dex-white-secondary">
          <PercentageChange
            className="xxs:text-[10px]"
            change={Big(data.returns ?? 0).mul(100)}
          />
        </div>
      </div>
    ),
  },

  {
    label: (
      <Tooltip text="The last time the wallet has swapped (buy or sell) the token. ">
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Last Swap
        </div>
      </Tooltip>
    ),
    key: 'LAST_SWAP',
    sortableKey: WalletsSwapsDetailsTableSortKeys.LastSwap,
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        <div className="flex lg:flex-col space-x-2 lg:space-x-0 lg:space-y-1 items-center ">
          {data.lastSwap ? (
            <div className="self-start flex space-x-1 items-center text-xs">
              <ClockIcon className="h-4 w-4" />
              <div>{formatDuration(data.lastSwap ?? '0')}</div>
            </div>
          ) : (
            '--'
          )}
        </div>
      </div>
    ),
  },
  {
    label: (
      <div>
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          Action
        </div>
      </div>
    ),
    key: 'ACTION',
    component: ({ data }) => (
      <div className={honeyPotClass(data.honeypot)}>
        <SwapsDetails
          fromDatetime={data.firstBuyTimestamp}
          tradedTokenId={data.tradedTokenId}
          makerAddress={data.makerAddress}
          chain={data.chain}
        />
      </div>
    ),
  },
];

export const mobileColumns: TableColumn<WalletSwapsDetails>[] = [
  {
    key: 'WALLETS',
    component: ({ data }) => (
      <div
        className={twMerge(
          'flex justify-between items-center',
          honeyPotClass(data.honeypot)
        )}
      >
        <div className="flex items-center xxs:gap-2">
          <TokenIconWithChain
            hasIcon={data.hasIcon}
            tokenName={data.tokenBaseSymbol}
            chain={data.chain ?? Chains.Ethereum}
            protocol={data.dex}
            symbol={data.tokenBaseSymbol}
            className="w-6 h-6"
            address={data.tokenAddress}
          />

          <div className="max-w-[120px] overflow-hidden truncate">
            <div>
              <span className="font-bold text-sm">{data.tokenBaseSymbol}</span>/
              <span className="xxs:text-dex-white-secondary xxs:text-xs">
                {data.tokenQuoteSymbol}
              </span>
            </div>
            <div className="flex space-x-1 items-center">
              <div className="text-xs">
                {data.honeypot
                  ? '🚨'
                  : data.tradeType === SwapType.Trade
                  ? '🔃'
                  : data.tradeType === SwapType.Early
                  ? '🐒'
                  : `🔫`}
              </div>
              <EthAddress
                address={data.tokenAddress}
                precision={3}
                kind="token"
                className="text-xs"
                chain={data.chain ?? Chains.Ethereum}
              />
              {data.buyTokenAmount &&
                (Big(data.sellTokenAmount ?? 0)
                  .div(data.buyTokenAmount)
                  .gt(0.9) ? (
                  <div className="bg-red-500 text-white xxs:px-0.5 rounded text-[8px]">
                    Closed
                  </div>
                ) : (
                  <div className="bg-green-500 text-white xxs:px-0.5 xxs:py-0 rounded text-[8px]">
                    Open
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex items-center justify-end space-x-1">
            <div className="xxs:text-dex-white-secondary">
              <PerformanceStyle pnl={data.pnl} className="xxs:text-lg" />
            </div>
            <div className="xxs:text-dex-white-secondary">
              <PercentageChange
                className="xxs:text-xs"
                change={Big(data.returns ?? 0).mul(100)}
              />
            </div>
          </div>
          <div className="flex lg:flex-col space-x-2 lg:space-x-0 lg:space-y-1 items-center justify-end ">
            {data.lastSwap ? (
              <div className="self-start flex space-x-1 items-center text-xs">
                <ClockIcon className="h-4 w-4" />
                <div>{formatDuration(data.lastSwap ?? '0')}</div>
              </div>
            ) : (
              '--'
            )}
          </div>
        </div>
      </div>
    ),
  },
];
