import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { formatNumberWithSuffix, safeBig } from 'utils/FormatNumber';

export function PerformanceStyle({
  pnl,
  className,
  asset,
  isOnlyFlashEnabled,
  formatFn,
}: {
  pnl: string;
  className?: string;
  asset?: string;
  isOnlyFlashEnabled?: boolean;
  formatFn?: (value: number) => string;
}) {
  const [highlightClass, setHighlightClass] = useState('');
  const prevPnlRef = useRef<string | null>(null);

  useEffect(() => {
    if (prevPnlRef.current !== null) {
      const prevValue = safeBig(prevPnlRef.current);
      const currentValue = safeBig(pnl);

      if (currentValue.gt(prevValue)) {
        setHighlightClass('xxs:text-green-500/70 transition-all duration-200');
      } else if (currentValue.lt(prevValue)) {
        setHighlightClass('xxs:text-red-500/70 transition-all duration-200');
      }

      const timer = setTimeout(() => setHighlightClass(''), 200);
      return () => clearTimeout(timer);
    }

    prevPnlRef.current = pnl;
  }, [pnl]);

  const bigPnl = safeBig(pnl);
  const isPositive = bigPnl.gt(0);
  const isNegative = bigPnl.lt(0);

  const numericValue = isPositive ? bigPnl.toNumber() : -bigPnl.toNumber();
  const formattedNumericValue = formatFn
    ? formatFn(numericValue)
    : formatNumberWithSuffix(numericValue, { precision: 2 });

  return (
    <div className={twMerge('xxs:text-xs rounded', className)}>
      <span
        className={twMerge(
          !isOnlyFlashEnabled && isPositive
            ? 'xxs:text-green-500'
            : isNegative
            ? 'xxs:text-red-500'
            : '',
          highlightClass
        )}
      >
        {!isOnlyFlashEnabled && (isPositive ? '+' : isNegative ? '-' : '')}
        {!asset ? '$' : ''}
        {formattedNumericValue}
        {asset ? <span> {asset}</span> : ''}
      </span>
    </div>
  );
}
