import config from 'config';
import { User, useUserState } from './UserContext';

export function useUser(): User | null {
  const user = useUserState()?.user;
  return user && 'access_token' in user ? user : null;
}

export function useIsAdmin(): boolean {
  const userId = useUser()?.id;
  if (userId) {
    return config.adminIds.includes(userId);
  } else return false;
}
