import { useFormContext } from 'react-hook-form';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import { formatTokenAmount } from 'utils/FormatNumber';
import Big from 'big.js';
import Tooltip from 'modules/shared-components/tooltip';
import { FormValues } from './schema';
import { chainName, Chains, chainScan } from 'api/types/httpsTypes/d-wallets';

const TxInfo = ({ chain }: { chain: Chains }) => {
  const { watch } = useFormContext<FormValues>();

  const inputValues = watch();
  const tokenName = inputValues.tokenName;
  const tokenSymbol = inputValues.tokenSymbol;

  const txInfoMapped = [
    {
      title: 'Token Name & Chain',
      value: (
        <div className="max-w-[200px] ">
          <div className="truncate">
            {tokenName} | {chainName(chain)} Chain
          </div>
        </div>
      ),
      tooltip: 'The token symbol and the chain.',
    },
    {
      title: 'Status',
      value: (
        <a
          className="xxs:text-dex-white-secondary hover:text-dex-white"
          href={`https://${chainScan(chain)}/tx/${inputValues.transactionHash}`}
          rel="noreferrer"
          target="_blank"
        >
          View on block explorer
        </a>
      ),
      tooltip: 'The status of the transaction on the blockchain.',
    },
    {
      title: 'Transaction ID',
      value: (
        <EthAddress
          chain={chain}
          address={inputValues.transactionHash || ''}
          kind="tx"
        />
      ),
      tooltip: 'The transaction id on the blockchain.',
    },
    {
      title: 'From Address',
      value: (
        <EthAddress
          address={inputValues.fromAddress || ''}
          chain={chain}
          kind="account"
        />
      ),
      tooltip: 'The wallet address from which the tokens will be sent.',
    },
    {
      title: 'To Address',
      value: (
        <EthAddress
          address={inputValues.toAddress || ''}
          chain={chain}
          kind="account"
        />
      ),
      tooltip: 'The wallet address to which the tokens will be sent.',
    },
    {
      title: 'Amount',
      value: (
        <div className="flex space-x-1">
          <div>
            {formatTokenAmount(Big(inputValues.amount || 0).toNumber())}
          </div>
          <div>{tokenSymbol}</div>
        </div>
      ),
      tooltip: 'The amount of tokens that will be sent.',
    },
  ];

  return (
    <div className="space-y-4">
      <div className="xxs:text-dex-white-secondary">
        Your transaction has been successfully initiated.
      </div>

      <div className="xxs:space-y-2">
        {txInfoMapped.map((d, index) => (
          <div className="" key={'tx_info_' + index}>
            <div className="flex justify-between items-center">
              <Tooltip text={d.tooltip}>
                <div className="xxs:text-dex-white-secondary hover:text-dex-white">
                  {d.title}
                </div>
              </Tooltip>

              <div className="text-sm">{d.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TxInfo;
