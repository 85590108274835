import {
  AlphaCallsParams,
  AlphaCallsResponse,
  AlphaHistoryParams,
  AlphaLeaderboardParams,
  HistoryResponse,
  LeaderboardEntry,
  PrizeLevel,
} from 'api/types/httpsTypes/leaderboard';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { api } from 'helpers/api/apiCore';

const SERVICE_KEY = 'alpha-program';

export const leaderboardServiceKeys = {
  all: () => [SERVICE_KEY],
  getAlphaCalls: (params: Partial<AlphaCallsParams>) => [
    SERVICE_KEY,
    'alpha-calls',
    params,
  ],
  getHistory: (params: { chain: Chains }) => [
    SERVICE_KEY,
    'ledarboard',
    params,
  ],
  getLeaderboard: (params: Partial<AlphaCallsParams>) => [
    SERVICE_KEY,
    'ledarboard',
    params,
  ],
  getPrizeStructure: (params: { chain: Chains }) => [
    SERVICE_KEY,
    'prize-structure',
    params,
  ],
};

export const LeaderboardService = {
  async getAlphaCalls(params: AlphaCallsParams) {
    const response = await api.get<AlphaCallsResponse>(
      `/async/api/v2/d-trade/alpha-main-call-board`,
      params
    );

    return response.data;
  },

  async getLeaderboard(params: AlphaLeaderboardParams) {
    const response = await api.get<LeaderboardEntry>(
      `/async/api/v2/d-trade/alpha-season-leaderboard`,
      params
    );

    return response.data;
  },

  async getPrizeStructure(params: { chain: Chains }) {
    const response = await api.get<PrizeLevel[]>(
      `/async/api/v2/d-trade/alpha-prizes`,
      params
    );

    return response.data;
  },

  async getHistory(params: AlphaHistoryParams) {
    const response = await api.get<HistoryResponse>(
      `/async/api/v2/d-trade/alpha-leaderboard`,
      params
    );

    return response.data;
  },
};
