import { twMerge } from 'tailwind-merge';
import { MobileFooterResearch } from './MobileDexNavPage';
import { Link, useHistory } from 'react-router-dom';
import Button from 'modules/shared-components/button/button';
import { SidebarConfigProps } from './SIDEBAR_CONST';
import { useState } from 'react';
import {
  BookOpenIcon,
  CircleStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { DiscordIcon } from '../../trade/alphaProgramModal';

interface Props {
  sidebar: SidebarConfigProps['items'];
}

export function isPathIncluded(basePath: string, fullPath: string) {
  const sanitizePath = (path: string) =>
    path.split('?')[0].split('#')[0].trim();

  const basePathWithoutParams = sanitizePath(basePath);
  const fullPathWithoutParams = sanitizePath(fullPath);

  return fullPathWithoutParams.startsWith(basePathWithoutParams);
}

const Sidebar = ({ sidebar }: Props) => {
  const history = useHistory();
  const url = history.location.pathname;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className={twMerge(
          'hidden lg:flex lg:flex-col bottom-0 bg-dex-black-800 fixed top-16 left-0 z-10',
          isHovered ? 'w-52' : 'w-12'
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={twMerge(
            'xxs:py-4 xxs:px-2 space-y-8 w-full flex flex-col justify-between grow'
          )}
        >
          <div className="xxs:space-y-2">
            {sidebar.map((item) => (
              <Button
                key={item.to}
                onClick={() => (item.to ? history.push(item.to) : {})}
                className={twMerge(
                  'w-full flex xxs:text-dex-white-secondary hover:text-dex-white  hover:bg-dex-black-700 rounded-md',
                  isPathIncluded(item.to, url)
                    ? 'xxs:bg-dex-black-700 xxs:text-dex-white'
                    : '',
                  isHovered
                    ? 'xxs:p-2 justify-start'
                    : 'xxs:px-1 xxs:py-2 justify-center'
                )}
              >
                <div
                  className={twMerge(
                    'flex items-center xxs:space-x-3 font-normal text-sm',
                    isHovered ? 'align-start' : 'justify-center'
                  )}
                >
                  {item.icon}
                  <div
                    className={twMerge(
                      'space-x-1 normal-case text-nowrap ',
                      isHovered ? 'opacity-100' : 'opacity-0 h-0 w-0 hidden'
                    )}
                  >
                    {item.label}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
        <div
          className={twMerge(
            'xxs:py-2 xxs:space-y-1 w-full',
            isHovered ? 'xxs:px-3' : 'xxs:px-2 xxs:mt-0.5'
          )}
        >
          <a
            href="https://discord.com/invite/gM4mAYMeWG"
            target="_blank"
            rel="noreferrer"
            className={twMerge(
              'w-full flex xxs:text-dex-white-secondary hover:text-dex-white  hover:bg-dex-black-700 rounded-md',
              isHovered
                ? 'xxs:p-2 justify-start'
                : 'xxs:px-1 xxs:py-2 justify-center'
            )}
          >
            <div
              className={twMerge(
                'flex items-center xxs:space-x-3 font-normal text-sm',
                isHovered ? 'align-start' : 'justify-center'
              )}
            >
              <DiscordIcon className="xxs:w-5 xxs:h-5" />
              <div
                className={twMerge(
                  'space-x-1 normal-case',
                  isHovered ? 'flex' : 'hidden'
                )}
              >
                Alpha Club
              </div>
            </div>
          </a>
          <a
            href="https://docs.mizar.com/sniper-bot-manual"
            target="_blank"
            rel="noreferrer"
            className={twMerge(
              'w-full flex xxs:text-dex-white-secondary hover:text-dex-white  hover:bg-dex-black-700 rounded-md',
              isHovered
                ? 'xxs:p-2 justify-start'
                : 'xxs:px-1 xxs:py-2 justify-center'
            )}
          >
            <div
              className={twMerge(
                'flex items-center xxs:space-x-3 font-normal text-sm',
                isHovered ? 'align-start' : 'justify-center'
              )}
            >
              <BookOpenIcon className="xxs:w-5 xxs:h-5" />
              <div
                className={twMerge(
                  'space-x-1 normal-case',
                  isHovered ? 'flex' : 'hidden'
                )}
              >
                Tutorials
              </div>
            </div>
          </a>
          <Button
            as={Link}
            to="/dashboard/staking"
            className={twMerge(
              'w-full flex xxs:text-dex-white-secondary hover:text-dex-white  hover:bg-dex-black-700 rounded-md',
              isHovered
                ? 'xxs:p-2 justify-start'
                : 'xxs:px-1 xxs:py-2 justify-center'
            )}
          >
            <div
              className={twMerge(
                'flex items-center xxs:space-x-3 font-normal text-sm',
                isHovered ? 'align-start' : 'justify-center'
              )}
            >
              <CircleStackIcon className="xxs:w-5 xxs:h-5" />
              <div
                className={twMerge(
                  'space-x-1 normal-case',
                  isHovered ? 'flex' : 'hidden'
                )}
              >
                Staking
              </div>
            </div>
          </Button>
          <Button
            as={Link}
            to="/dashboard/settings/Account"
            className={twMerge(
              'w-full flex xxs:text-dex-white-secondary hover:text-dex-white  hover:bg-dex-black-700 rounded-md',
              isHovered
                ? 'xxs:p-2 justify-start'
                : 'xxs:px-1 xxs:py-2 justify-center'
            )}
          >
            <div
              className={twMerge(
                'flex items-center xxs:space-x-3 font-normal text-sm',
                isHovered ? 'align-start' : 'justify-center'
              )}
            >
              <UserIcon className="xxs:w-5 xxs:h-5" />
              <div
                className={twMerge(
                  'space-x-1 normal-case',
                  isHovered ? 'flex' : 'hidden'
                )}
              >
                Account
              </div>
            </div>
          </Button>
        </div>
      </div>

      <MobileFooterResearch config={sidebar} />
    </>
  );
};

export default Sidebar;
