/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableColumn } from 'modules/shared-components/data-display/table/types';
import { formatNumberWithSuffix } from 'utils/FormatNumber';
import { TokenIconWithChain } from 'modules/shared-components/asset/token-icon';
import { EthAddress } from 'modules/ethereum/components/EthAddress';
import Tooltip from 'modules/shared-components/tooltip';
import { chainAsset, Chains } from 'api/types/httpsTypes/d-wallets';
import Big from 'big.js';
import moment from 'moment';

import { AlphaCallsRow } from 'api/types/httpsTypes/leaderboard';
import {
  isTokenSuspicious,
  isTokenSuspiciousCheck,
  SuspiciousLabelAlert,
} from '../../../research/components/Wallets/labels';
import { HotTokensPeriods } from '../../../research/components/Wallets/usePageParams';
import { Actions } from '../../../research/components/SnipingOpportunitiesCard/columns';
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { DiscordImgComponent } from '../discordImgComponent';
import AlphaMaster from '../../../../../assets/img/icons/alpha-master-img.svg';
import GrandAlphaMaster from '../../../../../assets/img/icons/grand-alpha-master.svg';
import InternationalAlphaMaster from '../../../../../assets/img/icons/international-alpha-master.svg';
import Button from 'modules/shared-components/button/button';
import { PerformanceStyle } from '../../../components/alerts/performanceStyle';

export const top10CallsDesktopColumns = (
  chain: Chains
): TableColumn<AlphaCallsRow>[] => {
  return [
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The Token address, chain, and DEX.">
            <div>Token</div>
          </Tooltip>
        </div>
      ),
      key: 'PAIR',
      component: ({ data }) => (
        <span
          className={
            data.status === 'RUG_PULL' ||
            isTokenSuspiciousCheck(data.hotTokenStats)
              ? 'xxs:text-dex-white-secondary'
              : ''
          }
        >
          <div className="flex items-center xxs:gap-2">
            <div className=" ">
              <TokenIconWithChain
                hasIcon={data.hasIcon}
                tokenName={data.symbol}
                chain={data.chain ?? Chains.Ethereum}
                symbol={data.symbol}
                className="w-6 h-6"
                address={data.address}
              />
            </div>
            <div className="max-w-[120px] overflow-hidden truncate">
              <span>
                <span className="font-bold text-sm">{data.symbol}</span>
              </span>
              <div>
                <EthAddress
                  address={data.address}
                  precision={3}
                  kind="token"
                  className="text-xs"
                  chain={data.chain ?? Chains.Ethereum}
                />
              </div>
            </div>
            <SuspiciousLabelAlert
              period={HotTokensPeriods.TwentyFourHour}
              alertItems={isTokenSuspicious({
                suspiciousBuy: data.hotTokenStats.suspiciousBuy,
                rugAddictedBuy: data.hotTokenStats.rugAddictedBuy,
                scammerBuy: data.hotTokenStats.scammerBuy,
                freshBuy: data.hotTokenStats.freshBuy,
                totalBuy: data.hotTokenStats.totalBuy,
              })}
            />
          </div>
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip
            text={`The trader that called this alpha in Mizar Discord channel.`}
          >
            <div>Caller</div>
          </Tooltip>
        </div>
      ),

      key: 'CALLER',
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary '
              : ''
          }
        >
          {data.user ? (
            <div className="flex xxs:gap-2 items-center">
              <div className=" ">
                <DiscordImgComponent
                  isAlphaGrandMaster={data.user.isAlphaGrandMaster}
                  isAlphaInternationalMaster={
                    data.user.isAlphaInternationalMaster
                  }
                  isAlphaMaster={data.user.isAlphaMaster}
                  imageUrl={data.user.img}
                  name={data.user.userName}
                />
              </div>
              <div className="flex space-x-1 items-center">
                <div className="xxs:gap-2">
                  <div className=" font-bold text-sm max-w-[150px] truncate">
                    {data.user.userName}
                  </div>
                  <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      e.stopPropagation()
                    }
                    as="a"
                    href={data.messageLink ?? ''}
                    className="m-0 p-0 normal-case	"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="xxs:text-xs xxs:text-dex-white-secondary hover:text-dex-white flex xxs:space-x-0.5 items-center">
                      <ChatBubbleOvalLeftEllipsisIcon className="h-3 w-3" />
                      <div>
                        {moment(data.callTime).format('DD/MM/YY HH:mm:ss')}
                      </div>
                      <div>
                        <ArrowTopRightOnSquareIcon className="h-3 w-3 xxs:pb-0.5" />
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            '??'
          )}
        </div>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in quote currency from the user's alpha calls.">
            <div>Realized PnL ({chainAsset(chain)})</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_QUOTE',
      component: ({ data }) => (
        <span>
          <PerformanceStyle
            asset={chainAsset(chain)}
            className="xxs:text-sm"
            pnl={Big(data.pnlInQuote ?? 0).toString()}
          />
        </span>
      ),
    },
    {
      label: (
        <div className="xxs:text-dex-white-secondary hover:text-dex-white">
          <Tooltip text="The total realized profit in USD from the user's alpha calls.">
            <div>Realized PnL (USD)</div>
          </Tooltip>
        </div>
      ),
      key: 'REALIZED_PNL_USD',
      component: ({ data }) => (
        <span>
          <PerformanceStyle className="xxs:text-sm" pnl={data.pnlInUSD} />
        </span>
      ),
    },
    {
      label: <div className="xxs:text-dex-white-secondary" />,
      key: 'ACTIONS',
      component: ({ data }) => (
        <Actions
          tokenAddress={data.address}
          chain={data.chain}
          symbol={data.symbol}
          poolAddress={data.address}
        />
      ),
    },
  ];
};

export const top10CallsMobileColumns = (
  chain: Chains
): TableColumn<AlphaCallsRow>[] => {
  return [
    {
      key: 'PAIR',
      component: ({ data }) => (
        <div
          className={
            data.status === 'RUG_PULL' || isTokenSuspiciousCheck(data)
              ? 'xxs:text-dex-white-secondary'
              : ''
          }
        >
          <div className="flex justify-between items-center ">
            <div className="">
              <div className="flex items-center xxs:gap-2">
                <div className=" ">
                  <TokenIconWithChain
                    hasIcon={data.hasIcon}
                    tokenName={data.symbol}
                    chain={data.chain ?? Chains.Ethereum}
                    symbol={data.symbol}
                    className="w-6 h-6"
                    address={data.address}
                  />
                </div>
                <div className="max-w-[120px] overflow-hidden truncate">
                  <span>
                    <span className="font-bold text-sm">{data.symbol}</span>
                  </span>
                  <div>
                    <EthAddress
                      address={data.address}
                      precision={3}
                      kind="token"
                      className="text-xs"
                      chain={data.chain ?? Chains.Ethereum}
                    />
                  </div>
                </div>
                <SuspiciousLabelAlert
                  period={HotTokensPeriods.TwentyFourHour}
                  alertItems={isTokenSuspicious({
                    suspiciousBuy: data.hotTokenStats.suspiciousBuy,
                    rugAddictedBuy: data.hotTokenStats.rugAddictedBuy,
                    scammerBuy: data.hotTokenStats.scammerBuy,
                    freshBuy: data.hotTokenStats.freshBuy,
                    totalBuy: data.hotTokenStats.totalBuy,
                  })}
                />
              </div>
              {data.user && (
                <Button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    e.stopPropagation()
                  }
                  as="a"
                  href={data.messageLink ?? ''}
                  className="m-0 p-0 normal-case	"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex space-x-1 items-center xxs:text-dex-white-secondary">
                    {(data.user.isAlphaMaster ||
                      data.user.isAlphaGrandMaster ||
                      data.user.isAlphaInternationalMaster) && (
                      <img
                        src={
                          data.user.isAlphaGrandMaster
                            ? GrandAlphaMaster
                            : data.user.isAlphaInternationalMaster
                            ? InternationalAlphaMaster
                            : AlphaMaster
                        }
                        className="w-5 h-5"
                      />
                    )}
                    <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                    <div className="text-xs max-w-[150px] truncate">
                      {data.user.userName}
                    </div>

                    <ArrowTopRightOnSquareIcon className="h-4 w-4 xxs:pb-0.5" />
                  </div>
                </Button>
              )}
            </div>

            <div className="xxs:space-y-0.5">
              <div className="justify-end flex flex-col items-end">
                {data.pnlInQuote ? (
                  <PerformanceStyle
                    asset={chainAsset(chain)}
                    className="xxs:text-base"
                    pnl={data.pnlInQuote}
                  />
                ) : (
                  <div className="text-xs">--</div>
                )}
              </div>

              <div className="justify-end flex flex-col items-end">
                {data.pnlInUSD ? (
                  <PerformanceStyle
                    className="xxs:text-xs"
                    pnl={data.pnlInUSD}
                  />
                ) : (
                  <div className="text-xs">--</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
};
