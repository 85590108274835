// useWebSocket.ts
import { useContext } from 'react';
import { WebSocketContext } from 'api/services/websocket/websocketProvider';

export const useWebsocketConnect = () => {
  const ctx = useContext(WebSocketContext);
  if (!ctx) {
    throw new Error('❌ useWebSocket must be used within WebSocketProvider');
  }
  return ctx;
};
