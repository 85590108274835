import { IconButton } from 'modules/shared-components/button/IconButton';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

type Props = {
  close: () => void;
  children: ReactNode;
  className?: string;
  backdropClassName?: string;
  maxWidth?: string;
};

export function FullScreenModal({ close, children, maxWidth }: Props) {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className={twMerge('relative z-50')} onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="xxs:ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="xxs:ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 xxs:bg-dex-black-900 lg:bg-dex-black-900/80" />
        </Transition.Child>

        <div className="fixed top-16 inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={twMerge(
                'pointer-events-none fixed inset-y-0 right-0 flex max-w-full top-0',
                maxWidth ?? 'max-w-[1000px]'
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="xxs:transform xxs:transition xxs:ease-in-out xxs:duration-500 sm:duration-700"
                enterFrom="xxs:translate-x-full"
                enterTo="xxs:translate-x-0"
                leave="xxs:transform xxs:transition xxs:ease-in-out xxs:duration-500 sm:duration-700"
                leaveFrom="xxs:translate-x-0"
                leaveTo="xxs:translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen lg:px-3">
                  <div className="flex h-full flex-col overflow-y-scroll xxs:bg-dex-black-900 border-tailwind xxs:border-solid xxs:border-t-0 xxs:border-r-0 xxs:border-l-1 border-dex-black-700 lg:px-4 lg:py-6">
                    <div className="xxs:hidden lg:block ">
                      <Dialog.Title className="xxs:text-dex-white-secondary xxs:flex xxs:justify-end">
                        <div className="xxs:mt-4 xxs:mb-2 sticky top-2">
                          <IconButton
                            onClick={close}
                            className="z-50 ml-auto xxs:rounded xxs:text-dex-white-secondary hover:text-dex-white xxs:bg-dex-black-700 hover:bg-dex-black-600 xxs:p-1 "
                          >
                            <XMarkIcon className="w-6" />
                          </IconButton>
                        </div>
                      </Dialog.Title>
                    </div>
                    <div className="relative flex-1">
                      <div className="absolute inset-0">
                        <div
                          className="h-full flex flex-col lg:gap-2 xxs:text-dex-white"
                          aria-hidden="true"
                        >
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
