import { useQuery } from '@tanstack/react-query';
import {
  DWalletService,
  dWalletServiceKeys,
  MultiChainDWalletService,
  multiChainDWalletServiceKeys,
} from 'api/services/httpServices/DWalletService';
import { MobileOnly } from 'modules/media/MobileOnly';
import config from 'config';
import { Chains } from 'api/types/httpsTypes/d-wallets';
import { useParams } from 'react-router-dom';
import { LoaderDex } from '../../components/alerts/Loader';
import { NotificationDex } from '../../components/alerts/notification';
import PageTemplate from '../../components/layout';
import {
  Tabs,
  WALLET_MOBILE_SIDEBAR,
} from '../../components/layout/SIDEBAR_CONST';
import WalletHeader from './WalletHeader';
import { BalancesLoading, MobileDWalletSelector } from './components';
import Portfolio from './Portfolio';
import { SwapsTable } from '../../research/components/Wallets/[address]/SwapsTable';
import { WalletStats } from '../../research/components/Wallets/[address]/WalletStats';
import {
  GetWalletsParams,
  WalletsTableSortKeys,
} from 'api/types/httpsTypes/contracts';
import { WalletPeriods } from '../../research/components/Wallets/usePageParams';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';

import { useUserState } from 'modules/user/UserContext';
import { useSelectedChain } from '../../components/chains/getChains';
import { usePageParams } from './usePageParams';
import { useEffect } from 'react';

/**
 * Hook to fetch wallets based on the selected chain
 */
export function useGetWallets(selectedChain: Chains, isEvmDifferent: boolean) {
  const { user } = useUserState();

  return useQuery({
    queryFn: () => MultiChainDWalletService.getWallets(selectedChain),
    queryKey: multiChainDWalletServiceKeys.getWallets(selectedChain),
    keepPreviousData: true,
    enabled: !!user && !!selectedChain && isEvmDifferent, // Only fetch when user and chain are available
  });
}

export function useUpdateWalletUrl(
  groupId: string | undefined,
  isEvmDifferent: boolean
) {
  const selectedChain = useSelectedChain();
  const [{ walletAddress }, setAddress] = usePageParams();

  const { data: wallets, isLoading } = useGetWallets(
    selectedChain,
    isEvmDifferent
  );

  useEffect(() => {
    if (
      isLoading ||
      !wallets ||
      wallets.length === 0 ||
      !groupId ||
      !isEvmDifferent
    )
      return;

    const matchedWallet = wallets.find((wallet) => wallet.groupId === groupId);

    const newWalletAddress = matchedWallet
      ? matchedWallet.address
      : wallets[0]?.address; // Default to first wallet

    if (newWalletAddress && newWalletAddress !== walletAddress) {
      setAddress({ walletAddress: newWalletAddress });
    }
  }, [selectedChain, wallets, isLoading, groupId]);
}

interface Props {
  walletAddress: string;
  walletChain: Chains;
}

export default function WalletMobileWrapper({
  walletAddress,
  walletChain,
}: Props) {
  const { tab } = useParams<{ tab: Tabs }>();

  const { isLoading, data, error } = useQuery({
    queryFn: () =>
      DWalletService.getWalletWithAddress(walletChain, walletAddress as string),
    queryKey: dWalletServiceKeys.getWalletWithAddress(
      walletChain,
      walletAddress as string
    ),
    enabled: !!(walletChain && walletAddress),
    keepPreviousData: true,
  });

  const isEvmDifferent = data
    ? (walletChain === Chains.Solana && data.chain !== Chains.Solana) ||
      (walletChain !== Chains.Solana && data.chain === Chains.Solana)
    : false;

  useUpdateWalletUrl(data?.groupId, isEvmDifferent);

  if (isLoading) {
    return (
      <MobileOnly>
        <PageTemplate sidebar={WALLET_MOBILE_SIDEBAR(walletAddress)}>
          <div className="flex justify-center">
            <LoaderDex className="xxs:my-4" />
          </div>
        </PageTemplate>
      </MobileOnly>
    );
  }

  if (error || !data) {
    return (
      <MobileOnly>
        <PageTemplate sidebar={WALLET_MOBILE_SIDEBAR(walletAddress)}>
          <div className="xxs:space-y-2  ">
            <div className="flex justify-end xxs:px-2">
              <MobileDWalletSelector
                onCreated={() => {}}
                selectedChain={walletChain}
              />
            </div>

            <NotificationDex
              className="xxs:mx-2 lg:mx-0"
              errorMessage={error}
              type="error"
            >
              Wallet not found.
            </NotificationDex>
          </div>
        </PageTemplate>
      </MobileOnly>
    );
  }

  return (
    <MobileOnly>
      <PageTemplate
        sidebar={WALLET_MOBILE_SIDEBAR(walletAddress)}
        pages={[
          ...(config.isTelegramBrowser
            ? [
                {
                  href: '/dex/navigation',
                  name: 'Navigation',
                },
              ]
            : []),
          {
            href: `/dex/wallets/${Tabs.Portfolio}`,
            name: 'Wallets',
          },
        ]}
      >
        <div className="xxs:p-2">
          <WalletHeader wallet={data} />
        </div>
        {tab === Tabs.Portfolio &&
          (isLoading ? (
            <div className="xxs:p-2">
              <BalancesLoading />{' '}
            </div>
          ) : (
            <div className="xxs:p-2">
              <Portfolio wallet={data} walletChain={walletChain} />{' '}
            </div>
          ))}
        {tab === Tabs.Performance && (
          <WalletStatsWrapper address={walletAddress} chain={walletChain} />
        )}
        {tab === Tabs.Swaps && (
          <SwapsTable chain={walletChain} address={walletAddress} />
        )}
      </PageTemplate>
    </MobileOnly>
  );
}

interface WalletStatsProps {
  address: string;
  chain: Chains;
}

const WalletStatsWrapper = ({ address, chain }: WalletStatsProps) => {
  const paramsWallet: GetWalletsParams = {
    sort: `-${WalletsTableSortKeys.PnL}`,
    chain,
    period: WalletPeriods.Thirty,
    address,
  };

  const { data, error, isLoading } = useQuery({
    queryKey: contractServiceKeys.getWallets(paramsWallet),
    queryFn: () => ContractService.getWallets(paramsWallet),
    keepPreviousData: true,
    enabled: !!(address && chain),
  });

  if (error) {
    return (
      <NotificationDex
        className="xxs:mx-2 lg:mx-0"
        errorMessage={error}
        type="error"
      >
        Can't load wallet stats.
      </NotificationDex>
    );
  }

  const wallet =
    data && address ? data.filter((w) => w.address === address)[0] : null;

  return <WalletStats isLoading={isLoading} wallet={wallet} />;
};
