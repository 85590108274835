import { ResponsiveTableColumn } from 'modules/shared-components/data-display/table/types';
import { FC } from 'react';
import ErrorBoundary from 'components/error-boundaries';
import Table from 'modules/shared-components/data-display/table';
import { Link, useHistory } from 'react-router-dom';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { AlphaCallsRow } from 'api/types/httpsTypes/leaderboard';
import Button from 'modules/shared-components/button/button';

interface Props {
  columnsDef: ResponsiveTableColumn<AlphaCallsRow>[];
  item: AlphaCallsRow;
  isUserNotAllowed: boolean;
  index: number;
  isLoggedIn: boolean;
}

export const TableRow: FC<Props> = ({
  item,
  columnsDef,
  isUserNotAllowed,
  index,
  isLoggedIn,
}) => {
  const history = useHistory();
  const isDesktop = useMediaQuery(screenGte.small);
  const gridCols = columnsDef.length;

  if (isUserNotAllowed && index === 7) {
    return (
      <div className="relative">
        {/* Overlay */}
        {isUserNotAllowed && index === 7 && (
          <div className="absolute  xxs:bg-dex-black-900 lg:bg-transparent inset-0 flex  items-center justify-center  text-white font-bold z-10">
            {isLoggedIn ? (
              <Button
                className="text-white"
                as={Link}
                variant="primary"
                to="/dashboard/staking"
              >
                UPGRADE to Bright Giant
              </Button>
            ) : (
              <Button
                className="text-white"
                as={Link}
                variant="primary"
                to="/login"
              >
                LOGIN
              </Button>
            )}
          </div>
        )}

        {/* Table Row */}
        <tr
          onClick={() =>
            isUserNotAllowed && index > 4
              ? {}
              : history.push({
                  pathname: !isDesktop
                    ? `/dex/research/${item.chain}/${item.address}`
                    : `/dex/snipe/new/${item.chain}/${item.address}`,
                  state: {
                    from: `${history.location.pathname}${history.location.search}`,
                  },
                })
          }
          className={`border-t border-dex-black-700 lg:dark:bg-dex-black-800  items-center  lg:grid lg:grid-cols-${gridCols} ${
            isUserNotAllowed && index > 4
              ? 'xxs:blur-sm'
              : 'cursor-pointer hover:dark:lg:bg-dex-black-700'
          }`}
        >
          <ErrorBoundary>
            {columnsDef.map((column) => {
              const Column = column.component;
              if (!Column) return null;
              return (
                <Table.Td key={column.key}>
                  <Column data={item} />
                </Table.Td>
              );
            })}
          </ErrorBoundary>
        </tr>
      </div>
    );
  }
  return (
    <tr
      onClick={() =>
        isUserNotAllowed && index > 4
          ? {}
          : history.push({
              pathname: !isDesktop
                ? `/dex/research/${item.chain}/${item.address}`
                : `/dex/snipe/new/${item.chain}/${item.address}`,
              state: {
                from: `${history.location.pathname}${history.location.search}`,
              },
            })
      }
      className={`border-t border-dex-black-700 lg:dark:bg-dex-black-800  items-center lg:grid lg:grid-cols-${gridCols} ${
        isUserNotAllowed && index > 4
          ? 'xxs:blur-sm'
          : 'cursor-pointer hover:dark:lg:bg-dex-black-700'
      }`}
    >
      <ErrorBoundary>
        {columnsDef.map((column) => {
          const Column = column.component;
          if (!Column) return null;
          return (
            <Table.Td key={column.key}>
              <Column data={item} />
            </Table.Td>
          );
        })}
      </ErrorBoundary>
    </tr>
  );
};
