import {
  LimitOrder,
  StopLossEntry,
  StopLossInput,
  TakeProfitEntry,
  TakeProfitInput,
} from './dex-trade';
import { Chains, Protocols } from 'api/types/httpsTypes/d-wallets';
import { VolatilityPeriod } from '../../../app/dex/bots/volatility/schema';

export type SniperBotInput = {
  name: string;
  vaultId: string;
  position: {
    buyAmountInWei: string;
    antiMev: boolean;
    maxSlippage: number;
    maxPriorityFeePerGasWei: string;
    gasLimit?: number;
    minLiquidityInUsd: number;
    maxLiquidityInUsd: number;
    minMarketcapInUsd: number;
    maxMarketcapInUsd: number;
    maxBuyTax: number;
    maxSellTax: number;
    honeypotProtection: boolean;
    chain: string;
    takeProfits?: TakeProfitInput[];
    vaultId: string;
    trailingStopLoss: StopLossInput | null;
  };
  maxNumActivePositions: number;
  isSnipingPreLaunch: boolean;
  isSnipingPostLaunch: boolean;
  telegramChannelFilter: {
    subscribed_channels_ids: string[];
  } | null;
  telegramCallsPerTimePeriodFilter: {
    numberOfCalls: number;
    timePeriodWindowInDays: number;
  } | null;
  cooldownInHours: number | undefined;
  walletAddressToCopy?: string;
  copySellSwaps?: boolean;
};

export type VolatilityBotInput = {
  name: string;
  vaultId: string;
  position: {
    minLiquidityInUsd: string;
    buyAmountInWei: string;
    maxPriorityFeePerGasWei: string;
    antiMev: boolean;
    maxSlippage: number;
    gasLimit?: number;
    chain: string;
    takeProfits?: TakeProfitInput[];
    vaultId: string;
    trailingStopLoss: StopLossInput | null;
  };
  priceChange: number;
  periodInMinutes: VolatilityPeriod;
  addresses: string[];
};

export type DexSniperBotAnalytics = {
  pnlDollar: string;
  volumeWei: string;
  volumeDollar: string;
  gasFeeWei: string;
  gasFeeDollar: string;
  gasFeeVolumeRatio: string;
};

export type BotWhitelistedTokens = {
  name: string;
  botId: string;
  status: DexBotStatus;
};
export type WhitelistedTokenData = {
  status: 'RUG_PULL' | 'ACTIVE' | 'INACTIVE';
  base: string;
  quote: string;
  tokenAddress: string;
  hasIcon: boolean;
  chain: Chains;
  dex: Protocols;
  currentLiquidity: string;
  currentMarketCap: string;
  oneHourVolume: string;
  sixHoursVolume: string;
  twentyFourHoursVolume: string;
  bots: BotWhitelistedTokens[];
  suspiciousBuy: number;
  rugAddictedBuy: number;
  scammerBuy: number;
  freshBuy: number;
  totalBuy: number;
};

export type DexSniperBot = {
  analytics: DexSniperBotAnalytics;
  copyBuyAmountPercentage?: number;
  insufficientBalance: boolean;
  name: string;
  chain: Chains;
  copyLimitExpirationInHours: number;
  vaultId: string;
  position: Position;
  numActivePositions: number;
  maxNumActivePositions: number;
  walletAddressToCopy?: string;
  copySellSwaps?: boolean;
  copyLimit?: boolean;
  copyBuyMore?: boolean;
  maxBuyMore?: number;
  openOnlyIfFirst?: boolean;
  isSnipingPreLaunch?: boolean;
  isSnipingPostLaunch?: boolean;
  telegramChannelFilter?: TelegramChannelFilter | null;
  telegramCallsPerTimePeriodFilter?: TelegramCallsPerTimePeriodFilter | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  status: DexBotStatus;
  isDeleted: boolean;
  cooldownInHours?: number;
  priceChange?: number;
  periodInMinutes?: VolatilityPeriod;
  addresses?: string[];
};

export type DexBotStatus = 'ACTIVE' | 'PAUSED';

type Position = {
  vaultId: string;
  antiMev: boolean;
  buyAmountInWei: string;
  bribe: string;
  backupBribe: string;
  blockOneRetry: boolean;
  maxSlippage: string;
  maxPriorityFeePerGasWei: string;
  gasLimit?: number;
  minLiquidityInUsd: string;
  maxLiquidityInUsd: string;
  minMarketcapInUsd: string;
  maxMarketcapInUsd: string;
  maxBuyTax: string;
  maxSellTax: string;
  honeypotProtection: boolean;
  takeProfits: TakeProfitEntry[];
  limitOrders: LimitOrder[];
  id: string;
  createdAt: string;
  updatedAt: string;
  telegramSniperBotId: string;
  vaultAddress: string;
  trailingStopLoss: StopLossEntry | null;
};

type TelegramChannelFilter = {
  subscribedChannelsIds: string[];
  id: string;
  createdAt: string;
  updatedAt: string;
  telegramSniperBotId: string;
};

type TelegramCallsPerTimePeriodFilter = {
  numberOfCalls: number;
  timePeriodWindowInDays: number;
  id: string;
  createdAt: string;
  updatedAt: string;
  telegramSniperBotId: string;
};

export enum BotType {
  Wallet = 'wallet',
  Volatility = 'volatility',
  Telegram = 'telegram',
}

export type GetDexBotsParams = {
  limit?: number;
  offset?: number;
  status?: DexBotStatus[];
  vaultId?: string[];
  sort?: string;
  botType?: BotType;
  chain?: Chains;
};

export type GetWhitelistedTokensParams = {
  limit: number;
  offset: number;
  search?: string;
  chain?: Chains;
  withAnalytics: boolean;
};
