import * as yup from 'yup';
import { useQueryParams } from 'modules/routing/useQueryParams';
import { Chains } from 'api/types/httpsTypes/d-wallets';

const PAGE_SIZE = 20;

export enum BotsSortKeys {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at',
  Name = 'name',
  Status = 'status',
  PnlDollar = 'pnl_dollar',
  VolumeDollar = 'volume_dollar',
}

const schema = {
  botSort: yup.string().default(`-${BotsSortKeys.Name}`).required(),
};

export type WalletDetailsFilterState = yup.TypeFromShape<typeof schema, any>;

export function usePageParams() {
  return useQueryParams(schema);
}

export function useBotsSearchFilters() {
  const [filtersState, setFilters] = usePageParams();

  const setSort = (key: string) => {
    let newSortKey;
    if (filtersState.botSort?.includes(key)) {
      if (filtersState.botSort?.charAt(0) === '-') {
        newSortKey = key;
      } else {
        newSortKey = `-${key}`;
      }
    }
    setFilters({
      ...filtersState,
      botSort: newSortKey || key,
    });
  };

  return { filtersState, setFilters, setSort };
}

const schemaWhitelistedTokens = {
  whitelistedTokensLimit: yup.number().default(PAGE_SIZE).required(),
  whitelistedTokensOffset: yup.number().default(0).required(),
  whitelistedTokensAddress: yup.string(),
  whitelistedTokensChain: yup.string().oneOf(Object.values(Chains)),
};

export type WhitelistedTokensFilterState = yup.TypeFromShape<
  typeof schemaWhitelistedTokens,
  any
>;

export function useWhitelistedTokensPageParams() {
  return useQueryParams(schemaWhitelistedTokens);
}

export function useWhitelistedTokensSearchFilters() {
  const [filtersState, setFilters] = useWhitelistedTokensPageParams();

  return { filtersState, setFilters };
}
