import { DWalletDetails } from 'api/types/httpsTypes/d-wallets';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { Address } from 'wagmi';

interface DepositState {
  wallet?: DWalletDetails;
  assetSymbol?: string;
  chainId?: number;
  amount?: number;
  depositTxHash?: Address;
}

type DepositContextState = [
  DepositState,
  Dispatch<SetStateAction<DepositState>>
];

export const DepositContext = createContext(
  [] as unknown as DepositContextState
);

export const DepositContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<{ initialState: DepositState }>) => {
  return (
    <DepositContext.Provider value={useState(initialState)}>
      {children}
    </DepositContext.Provider>
  );
};

export function useDepositState() {
  return useContext(DepositContext);
}
