import {
  ExclamationTriangleIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import {
  WalletService,
  walletKeys,
} from 'api/services/httpServices/WalletService';
import { GetWalletBalanceResponse } from 'api/types/httpsTypes/balance';
import { SpinnerLoading } from 'modules/utils/spinner';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import classNames from 'classnames';

const CONFIG = {
  normal: {
    container:
      'dark:xxs:border-black-700 dark:xxs:bg-black-800 xxs:bg-black-50 xxs:border-black-100',
    icon: '',
    hasWarningIcon: false,
  },
  warning: {
    container: 'xxs:border-orange-warning xxs:bg-orange-warning',
    icon: 'xxs:bg-orange-100 xxs:text-orange-warning',
    hasWarningIcon: true,
  },
  error: {
    container: 'xxs:border-red-warning xxs:bg-red-warning',
    icon: 'xxs:bg-red-100 xxs:text-red-warning',
    hasWarningIcon: true,
  },
};

const getCurrentConfig = (
  walletBalance: GetWalletBalanceResponse | undefined
) => {
  let state: keyof typeof CONFIG = 'normal';
  if (walletBalance) {
    if (walletBalance.data.available < 10) state = 'warning';
    if (walletBalance.data.available < 5) state = 'error';
  }
  return CONFIG[state];
};

const CreditsOverview = () => {
  const { data: walletBalance, isLoading: isLoadingWalletBalance } = useQuery({
    queryKey: walletKeys.getWalletBalance(),
    queryFn: () => WalletService.getWalletBalance(),
    staleTime: 1000 * 60 * 10,
  });

  const config = getCurrentConfig(walletBalance);

  return (
    <Link
      to="/dashboard/fee-manager"
      className={twMerge(
        'flex xxs:gap-1 dark:xxs:text-white xxs:text-black dark:hover:text-white hover:text-black items-center xxs:border border-solid xxs:p-2 xxs:rounded',
        config.container
      )}
    >
      {!config.hasWarningIcon && <WalletIcon width={16} />}
      {config.hasWarningIcon && (
        <div
          className={twMerge(
            'xxs:rounded-full w-[18px] h-[18px] flex items-center justify-center',
            config.icon
          )}
        >
          <ExclamationTriangleIcon width={12} />
        </div>
      )}
      <span
        className={classNames(
          'xxs:text-xs dark:xxs:text-white-50 xxs:hidden md:inline'
        )}
      >
        Credits:
      </span>
      <span className="xxs:text-xs font-semibold">
        {isLoadingWalletBalance && <SpinnerLoading color="text-mizar-blue" />}
        {!isLoadingWalletBalance &&
          `${formatWithoutDecimals(walletBalance?.data.available || 0)} USD`}
      </span>
    </Link>
  );
};

export default CreditsOverview;
