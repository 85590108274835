import {
  HotTokensRowInfo,
  WalletKeys,
  WalletMarkets,
  WalletRowInfo,
} from 'api/types/httpsTypes/contracts';
import Tooltip from 'modules/shared-components/tooltip';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import Big from 'big.js';
import { HotTokensPeriods, WalletPeriods } from './usePageParams';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { PERIOD_FILTERS } from '../HotTokens/HotTokensFilters';

export const labelIcon = (label: WalletKeys) => {
  switch (label) {
    case WalletKeys.EarlyApe:
      return '🐒';
    case WalletKeys.Suspicious:
      return '⛔';
    case WalletKeys.Scammer:
      return '⛔';
    case WalletKeys.TopCopy:
      return '📄';
    case WalletKeys.ScamAddicted:
      return '🚨';
    case WalletKeys.Trader:
      return '📈';
    case WalletKeys.TopSniper:
      return '🎯';
    default:
      return '';
  }
};

export function getMarketCapForWalletLabel(mc: WalletMarkets) {
  if (!mc || mc.length === 0) {
    return '';
  }
  switch (mc) {
    case WalletMarkets.OneMillion:
      return 'below $1M';
    case WalletMarkets.TenMillions:
      return 'between $1M and $10M';
    case WalletMarkets.HundredMillions:
      return 'between $10M and $100M';
    case WalletMarkets.OneBillion:
      return 'between $100M and $1B';
    case WalletMarkets.Max:
      return 'above $1B';
    default:
      return '';
  }
}

export function getMarketCapForWalletShortName(mc: WalletMarkets) {
  if (!mc || mc.length === 0) {
    return '';
  }
  switch (mc) {
    case WalletMarkets.OneMillion:
      return 'Micro Cap';
    case WalletMarkets.TenMillions:
      return 'Small Cap';
    case WalletMarkets.HundredMillions:
      return 'Medium Cap';
    case WalletMarkets.OneBillion:
      return 'Large Cap';
    case WalletMarkets.Max:
      return 'Mega Cap';
    default:
      return '';
  }
}

export const labelText = (label: WalletKeys, period?: WalletPeriods) => {
  switch (label) {
    case WalletKeys.TopCopy:
      return `Best to copy-trade: based on Mizar analytics, this wallet could be a potential wallet to be copy-traded.`;
    case WalletKeys.Scammer:
      return `Dangerous Wallet: this wallet is linked to different scams. Avoid any interactions with it.`;
    case WalletKeys.Suspicious:
      return `Suspicious Wallet: this wallet appears suspicious and may indicate malicious intent. Please conduct thorough due diligence.`;
    case WalletKeys.ScamAddicted:
      return `Rug Addicted: this wallet purchases consisted of more than 20% scam tokens in the last ${period} days.`;
    case WalletKeys.Trader:
      return `Top Trader: this wallet ranks in the top 10% of all wallets based on PnL from trades made 2 weeks after launch.`;
    case WalletKeys.EarlyApe:
      return `Early Ape: this wallet ranks in the top 10% of all wallets based on PnL from early apes (within 1 hour and 2 weeks after launch).`;
    case WalletKeys.TopSniper:
      return `Top Sniper: this wallet ranks in the top 10% of all wallets based on PnL from snipes (within 1 hour after launch).`;
    default:
      return '';
  }
};

interface IsTokenSuspiciousProps {
  suspiciousBuy: number;
  rugAddictedBuy: number;
  scammerBuy: number;
  freshBuy: number;
  totalBuy: number;
  skip?: boolean;
}

interface SuspiciousLabelAlertProps {
  alertItems: string[] | [];
  period?: HotTokensPeriods;
  children?: React.ReactNode;
}

export function isTokenSuspiciousCheck(data: Partial<HotTokensRowInfo>) {
  return (
    isTokenSuspicious({
      suspiciousBuy: data.suspiciousBuy ?? 0,
      rugAddictedBuy: data.rugAddictedBuy ?? 0,
      scammerBuy: data.scammerBuy ?? 0,
      freshBuy: data.freshBuy ?? 0,
      totalBuy: data.totalBuy ?? 0,
    }).length > 0
  );
}

export function isTokenSuspicious({
  suspiciousBuy,
  rugAddictedBuy,
  scammerBuy,
  freshBuy,
  totalBuy,
  skip,
}: IsTokenSuspiciousProps) {
  const MINIMUM_ALERT_TRIGGER = skip ? 0 : 0.2;
  const denominator = totalBuy ?? 1;
  const suspiciousRatio = suspiciousBuy / denominator ?? 0;
  const rugAddictedRatio = rugAddictedBuy / denominator ?? 0;
  const scammerRatio = scammerBuy / denominator ?? 0;
  const freshRatio = freshBuy / denominator ?? 0;

  const alertItems = [];

  if (suspiciousRatio > MINIMUM_ALERT_TRIGGER) {
    alertItems.push(
      `${(suspiciousRatio * 100).toFixed(1)}% are labeled as suspicious.`
    );
  }

  if (rugAddictedRatio > MINIMUM_ALERT_TRIGGER) {
    alertItems.push(
      `${(rugAddictedRatio * 100).toFixed(1)}% are labeled as rug addicted.`
    );
  }

  if (scammerRatio > MINIMUM_ALERT_TRIGGER) {
    alertItems.push(
      `${(scammerRatio * 100).toFixed(1)}% are labeled as scammer wallets.`
    );
  }

  if (freshRatio > MINIMUM_ALERT_TRIGGER) {
    alertItems.push(
      `${(freshRatio * 100).toFixed(
        1
      )}% are new or have limited activity history.`
    );
  }
  return alertItems;
}

export const SuspiciousLabelAlert = ({
  alertItems,
  period,
  children,
}: SuspiciousLabelAlertProps) => {
  const selectedPeriod =
    PERIOD_FILTERS.find(
      (filter) => filter.value === period
    )?.label?.toLowerCase() ?? null;

  if (alertItems.length > 0) {
    return (
      <Tooltip
        text={
          <div className="xxs:space-y-1">
            <p className="xxs:mb-0">
              Warning: some wallets that bought this token in the{' '}
              <span>{selectedPeriod ?? 'last 24 hours'}</span> need some due
              diligence.
            </p>
            <ul className="xxs:pl-4 xxs:mb-0">
              {alertItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        }
      >
        {children ? (
          <div className="flex">{children}</div>
        ) : (
          <div className="flex">
            <ExclamationCircleIcon className="w-5 h-5 xxs:text-red-500 hover:text-red-600" />
          </div>
        )}
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export const TokenLabels = (data: WalletRowInfo, period: WalletPeriods) => {
  if (!data.totalSwaps) {
    return <></>;
  } else {
    return (
      <div className="flex space-x-2 items-center text-xs">
        {data.snipes ? (
          <Tooltip
            text={`Number of snipes: tokens purchased within the first hour after launch, in the last ${period} days.`}
          >
            <div>🔫 {formatWithoutDecimals(Big(data.snipes).toNumber())}</div>
          </Tooltip>
        ) : null}
        {data.earlyTrades ? (
          <Tooltip
            text={`Number of early apes: tokens purchased between one hour and two weeks after launch, in the last ${period} days.`}
          >
            <div>
              🐒 {formatWithoutDecimals(Big(data.earlyTrades).toNumber())}
            </div>
          </Tooltip>
        ) : null}
        {data.trades ? (
          <Tooltip
            text={`Number of trades: tokens purchased two weeks after launch, in the last ${period} days.`}
          >
            <div>🔃 {formatWithoutDecimals(Big(data.trades).toNumber())}</div>
          </Tooltip>
        ) : null}
      </div>
    );
  }
};
