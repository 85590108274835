import Big from 'big.js';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import GasIcon from '../../../../assets/img/icons/gas-icon.svg';
import { chainAssetImg, Chains } from 'api/types/httpsTypes/d-wallets';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';
import { CHAINS_OPTIONS } from '../../../../app/dex/side-panels/WalletsPanel';
import { useHistory } from 'react-router-dom';
import { SortDropdown } from '../../../../app/dex/components/dropdown/sort-dropdown';
import { fromAmount } from '../../../../app/dex/components/chains/units';

import {
  handleChainChange,
  useChainPersistedState,
} from '../../../../app/dex/components/chains/getChains';
import {
  defaultChain,
  defaultContract,
  useLastVisitedAddress,
  useLastVisitedChain,
} from '../../../../app/dex/trade/snipeContainer';
import { useChainInfo } from 'api/hooks/useChainInfo';
import { PerformanceStyle } from '../../../../app/dex/components/alerts/performanceStyle';
import { formatTokenAmountCompact } from 'utils/FormatNumber';

export const GasInfo = () => {
  const [selectedChain, setSelectedChain] = useChainPersistedState(Chains.Base);
  const history = useHistory();

  const chainInfo = useChainInfo(selectedChain);

  const [, setAddress] = useLastVisitedAddress(defaultContract);
  const [, setChain] = useLastVisitedChain(defaultChain);

  return (
    <div className="">
      <div className="space-x-3 flex items-center">
        <div className="hidden lg:flex items-center xxs:space-x-1 " id="chains">
          <SortDropdown
            className="xxs:p-2 xxs:text-sm h-[38px]"
            mainLabel="Chain"
            onChange={(value) => {
              handleChainChange({
                chainValue: value,
                setSelectedChain,
                history,
                setAddress,
                setChain,
              });
            }}
            value={
              CHAINS_OPTIONS.filter((c) => c.value === selectedChain)?.[0]
                ?.value ?? Chains.Base
            }
            options={CHAINS_OPTIONS}
          />
        </div>

        <div className="flex space-x-1 items-center justify-end  xl:min-w-[230px] ">
          <div className="bg-dex-black-700 xxs:p-2 rounded lg:hidden">
            <div className="flex xxs:space-x-1">
              <div className="">
                <ChainsIcon
                  className="xxs:h-5 xxs:w-5"
                  imgName={selectedChain}
                />
              </div>
            </div>
          </div>
          <div className="lg:space-x-1 hidden lg:flex xxs:flex-col lg:flex-row items-center justify-end xxs:text-dex-white-secondary">
            <CubeTransparentIcon className="w-4 h-4 hidden xl:inline-block" />
            <div className="hidden xl:inline-block xxs:text-dex-white">
              {chainInfo?.blockNumber ? (
                chainInfo.blockNumber
              ) : (
                <span className="xxs:text-dex-white-secondary">--</span>
              )}
            </div>
            <div className="hidden xl:inline-block">|</div>
            <div className="flex xxs:w-full lg:w-auto xxs:space-x-1 items-center justify-between lg:justify-start">
              <img className="h-4 w-4" alt="gas icon" src={GasIcon} />
              <div className="xxs:text-dex-white">
                {chainInfo?.gasPrice ? (
                  Big(
                    fromAmount(chainInfo.gasPrice, selectedChain, 'weiToGwei')
                  ).toFixed(4)
                ) : (
                  <span className="xxs:text-dex-white-secondary">--</span>
                )}
              </div>
            </div>

            <div className="hidden lg:block">|</div>
            <div className="flex xxs:w-full lg:w-auto items-center space-x-1 justify-between lg:justify-start">
              <img
                className="h-4 w-4"
                alt="token icon"
                src={chainAssetImg(selectedChain)}
              />
              <div className="xxs:text-dex-white">
                {chainInfo?.price ? (
                  <PerformanceStyle
                    isOnlyFlashEnabled={true}
                    formatFn={formatTokenAmountCompact}
                    pnl={Big(chainInfo.price).toString()}
                  />
                ) : (
                  <span className="xxs:text-dex-white-secondary">--</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
