import { useEffect, Suspense, useState, useRef } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ScrollToTop } from 'modules/routing';
import VerticalLayout from 'modules/layouts/Vertical';
import { authProtectedFlattenRoutes, noLayoutFlattenRoutes } from './index';
import { useTheme } from 'modules/media/theme';
import ReferralIdObserver from 'modules/routing/ReferralIdObserver';
import { useExpandTelegramApp } from 'modules/telegram/core/useExpandTelegramApp';
import { getLocalAccessToken } from 'modules/user/userCookies';
import {
  getAccessTokenRefreshDelay,
  performRefresh,
  startSilentRefresh,
} from 'modules/user/silentRefresh';
import { useWebsocketConnect } from 'api/services/websocket/useWebsocketConnect';
import { Loader } from 'utils/Loader';
import { UserTwoFactor, useUserState } from 'modules/user/UserContext';
import { useConnectionStatus } from 'modules/routing/ConnectionStatusProvider';
import { useQueryClient } from '@tanstack/react-query';

const SentryRoute = Sentry.withSentryRouting(Route);
const Routes = (props: any) => {
  const loading = () => (
    <div className="vh-100 justify-center flex items-center">
      <Loader />
    </div>
  );
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  useExpandTelegramApp();
  const user = useUserState()?.user;
  const { isOnline } = useConnectionStatus();
  const queryClient = useQueryClient();
  const { sendAuth, connect, waitForSocketOpen, disconnect } =
    useWebsocketConnect();
  const token = getLocalAccessToken();
  const [isReady, setIsReady] = useState(false);

  const wasOnline = useRef<boolean>(isOnline);

  useEffect(() => {
    const run = async () => {
      try {
        if (user) {
          await performRefresh();
        }
        connect();
        if (user) {
          await waitForSocketOpen();
          sendAuth();
        }
      } catch (err) {
        console.error('Silent refresh failed at reconnection', err);
      } finally {
        void (await queryClient.invalidateQueries());
      }
    };

    // 🟢 Just came online
    if (!wasOnline.current && isOnline) {
      setTimeout(() => {
        void run();
      }, 500);
    }

    // 🔴 Just went offline
    if (wasOnline.current && !isOnline) {
      disconnect?.();
    }

    // Update previous state
    wasOnline.current = isOnline;
  }, [isOnline]);

  useEffect(() => {
    const run = async () => {
      try {
        connect(); // always connect, even before user check

        if (user) {
          await waitForSocketOpen();
          sendAuth();
        }
      } catch (err) {
        console.error('🔌 Failed to connect WS on reload:', err);
      } finally {
        setIsReady(true);
      }
    };

    void run();
  }, []);

  // silent refresh on mount
  useEffect(() => {
    const run = async () => {
      if (token) {
        const refreshTime = getAccessTokenRefreshDelay(token, 5);

        if (refreshTime <= 0) {
          try {
            await performRefresh(() => {
              setTimeout(() => {
                sendAuth(); // WebSocket auth
              }, 100);
            });
          } catch (err) {
            console.error('Silent refresh failed at startup', err);
          }
        }

        startSilentRefresh(token, () => {
          setTimeout(() => {
            sendAuth(); // WebSocket auth after each refresh
          }, 100);
        });
      }

      setIsLoading(false);
    };

    void run();
  }, [sendAuth, token]);

  // theme logic
  useEffect(() => {
    if (theme !== undefined) {
      document.body.setAttribute('theme', theme);
      if (theme === 'dark') {
        document.body.classList.add('dark');
      } else if (theme === 'light') {
        document.body.classList.remove('dark');
      }
    }
  }, [theme]);

  // show loading screen while token refresh is in progress
  if (
    !isReady ||
    isLoading ||
    (!token && !!user && !(user as UserTwoFactor).twoFactor)
  ) {
    return loading();
  }

  const modalRoutes = authProtectedFlattenRoutes.reduce((acc, route) => {
    return [
      ...acc,
      ...(route.subroutes
        ?.filter((subroute: any) => subroute.config?.redirect)
        .map((subroute: any) => subroute.path) || []),
    ];
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop modalRoutes={modalRoutes} />
      <ReferralIdObserver />
      <Switch>
        <SentryRoute path={authProtectedFlattenRoutes.map((r: any) => r.path)}>
          <VerticalLayout {...props}>
            <Switch>
              {authProtectedFlattenRoutes.map((route) => {
                if (route.children) return null;

                if (route.subroutes) {
                  return (
                    <route.route key={route.path} path={route.path}>
                      <>
                        <route.component />
                        {route.subroutes.map((subroute: any) => (
                          <subroute.route
                            key={subroute.path}
                            path={subroute.path}
                            exact={subroute.exact}
                          >
                            <subroute.component {...subroute.config} />
                          </subroute.route>
                        ))}
                      </>
                    </route.route>
                  );
                }

                return (
                  <route.route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </VerticalLayout>
        </SentryRoute>

        <SentryRoute path={noLayoutFlattenRoutes.map((r: any) => r.path)}>
          <Suspense fallback={loading()}>
            <Switch>
              {noLayoutFlattenRoutes.map((route) => {
                return (
                  !route.children && (
                    <route.route
                      key={route.path}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                );
              })}
            </Switch>
          </Suspense>
        </SentryRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
