import { StarIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { IconButton } from 'modules/shared-components/button/IconButton';
import {
  ContractService,
  contractServiceKeys,
} from 'api/services/httpServices/ContractService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { twMerge } from 'tailwind-merge';
import { Chains } from 'api/types/httpsTypes/d-wallets';

export function AddToFavouritesButton({
  contractAddress,
  chain,
  className,
  ...rest
}: {
  chain: Chains;
  contractAddress: string;
  className?: string;
} & React.ComponentProps<typeof IconButton>) {
  const queryClient = useQueryClient();
  const { addNewMessage } = useSnackBar();
  const { data, isLoading } = useQuery({
    queryFn: () => ContractService.getFavouriteContracts(),
    queryKey: contractServiceKeys.getFavouriteContracts(),
  });
  const isFavourite = !!data?.some(
    (c) => c.address === contractAddress && c.chain.key === chain
  );

  const { mutate, isLoading: isEditing } = useMutation({
    mutationFn: () =>
      isFavourite
        ? ContractService.removeFromFavourites(chain, contractAddress)
        : ContractService.addToFavourites(chain, contractAddress),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        contractServiceKeys.getFavouriteContracts()
      );
      addNewMessage({
        type: 'success',
        title: 'Success',
        message: isFavourite
          ? 'Contract removed from favourites.'
          : 'Contract added to favourites.',
      });
    },
    onError: () => {
      addNewMessage({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong. Please try again later.',
      });
    },
  });

  if (!data) {
    return null;
  }

  return (
    <IconButton
      {...rest}
      className={twMerge(isEditing && 'animate-pulse', className)}
      disabled={isLoading || isEditing}
      type="button"
      onClick={(e: any) => {
        e?.stopPropagation();
        mutate();
      }}
    >
      {!isFavourite ? (
        <StarIcon className="xxs:w-4 xxs:h-4" />
      ) : (
        <StarIconSolid className="xxs:w-4 xxs:h-4" />
      )}
    </IconButton>
  );
}
