import React from 'react';
import { Link } from 'react-router-dom';
import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { NotificationDex } from '../../../app/dex/components/alerts/notification';

interface FooterProps {
  isDex?: boolean;
}

export const FullPageFooter = ({ isDex }: FooterProps) => {
  return (
    <footer className="flex-shrink-0 max-w-7xl w-full mx-auto xxs:px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center space-x-4">
        <a
          href={
            isDex
              ? 'https://docs.mizar.com/sniper-bot-manual'
              : 'https://docs.mizar.com/c-mizar-manual/'
          }
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-medium text-white-800 hover:text-white-900 dark:text-white-600 dark:hover:text-white-700"
        >
          {isDex ? 'Sniper Manual' : 'Docs'}
        </a>
        <span
          className="inline-block border-l border-tailwind border-gray-300"
          aria-hidden="true"
        />
        <a
          href="https://discord.gg/gM4mAYMeWG"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-medium text-white-800 hover:text-white-900 dark:text-white-600 dark:hover:text-white-700"
        >
          Live Support
        </a>
        <span
          className="inline-block border-tailwind border-l border-gray-300"
          aria-hidden="true"
        />
        <a
          href="https://twitter.com/mizar_com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-medium text-white-800 hover:text-white-900 dark:text-white-600 dark:hover:text-white-700"
        >
          Twitter
        </a>
      </nav>
    </footer>
  );
};
type Props = {
  height?: string;
  icon?: React.ReactNode;
  title: string;
  subTitle?: string;
  href?: string;
  link?: string | null;
  hrefTitle?: string;
  footer?: boolean;
  minHeight?: string;
  errorMessage?: string;
};
export const FullPageNotification = ({
  height,
  icon,
  title,
  subTitle,
  href,
  link,
  hrefTitle,
  footer,
  minHeight,
  errorMessage,
}: Props) => {
  const { mode } = useExchangeMode();
  const isDex = mode === DexSwitchMode.Dex;
  return (
    <div
      style={{
        ...(height && { height }),
        ...(minHeight && { minHeight }),
      }}
    >
      <div className="min-h-full pt-16 pb-12 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto xxs:px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              {icon}
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-semibold tracking-tight sm:text-5xl sm:tracking-tight text-white-900 dark:text-white-300">
                {title}
              </h1>
              <p className="mt-2 text-base text-white-800 dark:text-white-600">
                {subTitle}
              </p>

              {errorMessage && (
                <div className="flex justify-center">
                  <NotificationDex type="error">{errorMessage}</NotificationDex>
                </div>
              )}
              <div className="mt-6">
                {link && (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base font-medium text-blue-500 hover:text-blue-600"
                  >
                    {' '}
                    {hrefTitle}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                )}
                {href && (
                  <Link
                    to={href}
                    className="text-base font-medium text-blue-500 hover:text-blue-600"
                  >
                    {hrefTitle} <span aria-hidden="true"> &rarr;</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </main>
        {footer && <FullPageFooter isDex={isDex} />}
      </div>
    </div>
  );
};
